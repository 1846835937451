import styled from "styled-components";
import Box from "@material-ui/core/Box";
import { theme } from "../../../../../util/theme";
import { Table } from "@material-ui/core";

export const BreadCrumbWrapper = styled.div`
  margin-bottom: 40px;
  a {
    height: 16px;
    width: 197px;
    color: #2aa96d;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: none;
  }
  a:visited {
    color: #2aa96d;
  }
`;


export const BOQContentWrapper = styled.div`
  padding: 15px 0 10px 0;

  .MuiTableHead-root {
    background-color: white;
    padding: 20px 0 !important;
  }

  .MuiTableCell-head {
    font-weight: bold;
    font-size: 16px;
  }
`;

export const PageTitle = styled.h1`
  color: #1e2b37;
  font-size: 28px;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 0;
  font-weight: normal;
`;

export const CardWrapper = styled.div`
  box-sizing: border-box;
  border: 1px solid #c9d4db;
  border-radius: 3px;
  position: relative;
  box-shadow: 0 2px 4px 0 #c9d4db;
  overflow: hidden;
  width: 100%;
  padding: ${(props) => (props.noPadding ? "0px" : "20px")};
  margin-bottom: 40px;
  background-color: ${(props) => (props.gray ? "#FCFCFD" : "#FFFFFF")};
`;

export const TopSection = styled(Box)`
  margin-bottom: 20px;
`;

export const SerialText = styled.span`
  color: #1e2b37;
  font-size: 14px;
  padding-bottom: 5px;
  padding-left: 20px;
  letter-spacing: 0;
  line-height: 16px;
  font-weight: 600;
`;

export const TopSectionTitle = styled.div`
  color: ${theme.color.textSecondary};
  font-weight: 400;  
  font-size: 12px;
  line-height: 150%;
`

export const TopSectionBody = styled.div`
  color: ${theme.color.textSecondary};  
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
`;

export const TopDocumentSection = styled(Box)`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CardLabel = styled.p`
  color: #5f7483;
  font-size: 14px;
  padding-bottom: 5px;
  letter-spacing: 0;
  line-height: 16px;
  font-weight: bold;
`;

export const CardBody = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 15px;
  align-items: start;
`;

export const ModalBody = styled.div`
  position: absolute;
  top: 140px;
  left: calc(50% - 200px);
  width: 100%;
  max-width: 440px;
  min-height: 200px;
  border: 1px solid #EBEBEB;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  padding: 30px;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

export const ModalDescription = styled.p`
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #3F5673;
  margin-bottom: 20px;
`;

export const ModalTitle = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #3F5673;
  margin-bottom: 30px;
  text-align: center;
`;

export const TableWrapper = styled.div`
  .cell-no-padding {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const StyledTable = styled(Table)`
  & thead {
    border: 1px solid #C9D4DB;
    tr {
      background-color: #C9D4DB;
    }
  }
`;

export const StatusBox = styled.div`
  width: fit-content;
  background: ${(prop) => prop.colorStatus === "Pending"
    ? theme.color.tags.pending.background
    : prop.colorStatus === "Endorsed" ? "#F0F5FF"
      : theme.color.tags.approved.background};
  border: 2px solid #FFFFFF;
  border-radius: 10px;
  color: ${prop => prop.colorStatus === "Pending"
    ? theme.color.tags.pending.text
    : prop.colorStatus === "Endorsed" ? "#0050C8"
      : theme.color.tags.approved.text};
  line-height: 0.8;
  padding: 10px 10px;
`


export const ContentWrapper = styled.div`
  padding: 15px 0 10px 0;

  .MuiTableHead-root {
    background-color: white;
    padding: 20px 0 !important;
  }

  .MuiTableCell-head {
    font-weight: bold;
    font-size: 16px;
  }
`;
