import React, { useState } from "react";
import { Tab, makeStyles } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import withAdminDashboard from "../HOC/withAdminDashboard";
import { BreadcrumbActive, BreadcrumbLabel } from "../../Homepage/TenderList/style";
import contractsAPI from "../../../redux/api/contractAPI";
import useAlert from "../../../hooks/useAlert";
import { TenderTitle, TitleContainer } from "../../Homepage/ContractDetail/style";
import { CardContentWrapper, PageTabs } from "../../../util/theme";
import { PlanCardContainer, TabContainer } from "../Procurement/Activitiies/SpecificProcurementNotice/style";
import EditPlanningTab from "./EditTabs/EditPlanningTab";
import EditTenderTab from "./EditTabs/EditTenderTab";
import EditBidderTab from "./EditTabs/EditBidderTab";
import EditAwardTab from "./EditTabs/EditAwardTab";

const useStyles = makeStyles({
  tabWrapper: {
    textTransform: "none",
  },
  root: {
    minWidth: 90,
  },
});

const EditContract = () => {
  const { id } = useParams();
  const [value, setValue] = useState(0);
  const { Toast, showAlert } = useAlert();

  const classes = useStyles();
  const linkStyle = { textDecoration: "none", color: "inherit" };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data: { data } = {}, mutate, isLoading, handleEdit } = useQuery(["contractOCDS", id], contractsAPI.getContractOCDS, {
    onError: (error) => {
      showAlert({
        severity: "error",
        message: error.message || "Failed to fetch data from the server",
      });
    },
  });

  return (
    <div>
      <Toast float />
      <BreadcrumbLabel>
        <Link style={linkStyle} to="/admin/dashboard/overview">
          Overview
        </Link>
        /
        <Link style={linkStyle} to="/admin/ocdsList/ocds">
          Awarded Contracts
        </Link>
        /<BreadcrumbActive>{data?.title}</BreadcrumbActive>
      </BreadcrumbLabel>
      <TitleContainer>
        <TenderTitle>{data?.title}</TenderTitle>
      </TitleContainer>

      <CardContentWrapper>
        <PlanCardContainer>
          <TabContainer>
            <PageTabs value={value} onChange={handleChange}>
              <Tab
                label="Planning"
                classes={{ root: classes.root, wrapper: classes.tabWrapper }}
                activetab={(value === 0).toString()}
              />
              <Tab
                label="Tender"
                classes={{ root: classes.root, wrapper: classes.tabWrapper }}
                activetab={(value === 1).toString()}
              />
              <Tab
                label="Bidders"
                classes={{ root: classes.root, wrapper: classes.tabWrapper }}
                activetab={(value === 2).toString()}
              />
              <Tab
                label="Award"
                classes={{ root: classes.root, wrapper: classes.tabWrapper }}
                activetab={(value === 3).toString()}
              />
            </PageTabs>
          </TabContainer>
          {value === 0 && <EditPlanningTab mutate={mutate} isLoading={isLoading} handleEdit={handleEdit} data={data} />}
          {value === 1 && <EditTenderTab mutate={mutate} isLoading={isLoading} handleEdit={handleEdit} data={data} />}
          {value === 2 && <EditBidderTab mutate={mutate} isLoading={isLoading} handleEdit={handleEdit} data={data} />}
          {value === 3 && <EditAwardTab mutate={mutate} isLoading={isLoading} handleEdit={handleEdit} data={data} />}
          {/* {value === 4 && <ContactBiddersTab contractOCDS={contractOCDS} />}
        {value === 5 && <EvaluatorsTab contractOCDS={contractOCDS} />} */}
        </PlanCardContainer>
      </CardContentWrapper>
    </div>
  );
};

export default withAdminDashboard(EditContract);
