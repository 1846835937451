import React from "react";
import {
  CuteModal,
  InnerModalBox,
  MainText,
  ModalHeader,
  ModalWrapper,
  ModalProceedBtn,
  CuteGrid,
  ModalCloseBtn,
} from "../style";
import { ReactComponent as CheckIcon } from "../../../../assets/check_mark.svg";
import { Box } from "@material-ui/core";
import { Procurement_Method } from "../../../../util/constants";
import { ProcurementMethodAccessControl } from "../../../../shared";

const ExpressInterestModal = ({ numOfUploadedDocs, numOfRequiredDocs, open, setModal, isLoading, handleOnClick, method, canRenderAccessControl }) => {
  return (
    <CuteModal open={open}>
      <ModalWrapper>
        <ProcurementMethodAccessControl
          allowedMethod={Object.values(Procurement_Method).filter((value) => value !== Procurement_Method.REQ_QUOTATION)}
          method={method}
          isSuccess={canRenderAccessControl}
        >
          <ModalHeader>Expression of interest</ModalHeader>
          <MainText mt="30px">Proceed to express interest in the contract</MainText>
          <InnerModalBox>
            <CuteGrid width="550px">
              <div>
                <MainText>{numOfRequiredDocs} Required Documents</MainText>
                <MainText mt="10px">{numOfUploadedDocs} Uploaded Documents</MainText>
              </div>
              <div>
                <CheckIcon />
              </div>
            </CuteGrid>
          </InnerModalBox>
          <MainText mt="30px"></MainText>
          <Box display="flex" justifyContent="flex-start">
            <ModalProceedBtn disabled={isLoading} onClick={handleOnClick}>
              {isLoading ? "Please wait..." : "Submit"}
            </ModalProceedBtn>
            <ModalCloseBtn disabled={isLoading} onClick={() => setModal(false)}>
              Close
            </ModalCloseBtn>
          </Box>
        </ProcurementMethodAccessControl>
        <ProcurementMethodAccessControl
          allowedMethod={[Procurement_Method.REQ_QUOTATION]}
          method={method}
          isSuccess={canRenderAccessControl}
        >
          <ModalHeader>Submission of quotation</ModalHeader>
          <MainText mt="30px">Proceed to submit quotation</MainText>
          <InnerModalBox>
            <CuteGrid width="550px">
              <div>
                <MainText>{numOfRequiredDocs} Required Documents</MainText>
                <MainText mt="10px">{numOfUploadedDocs} Uploaded Documents</MainText>
              </div>
              <div>
                <CheckIcon />
              </div>
            </CuteGrid>
          </InnerModalBox>
          <MainText mt="30px"></MainText>
          <Box display="flex" justifyContent="flex-start">
            <ModalProceedBtn disabled={isLoading} onClick={handleOnClick}>
              {isLoading ? "Please wait..." : "Submit"}
            </ModalProceedBtn>
            <ModalCloseBtn disabled={isLoading} onClick={() => setModal(false)}>
              Close
            </ModalCloseBtn>
          </Box>
        </ProcurementMethodAccessControl>
      </ModalWrapper>
    </CuteModal>
  );
};

export default ExpressInterestModal;
