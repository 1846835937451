import React from "react";
import { Route, Switch } from "react-router-dom";
import CertificateOfNoObjection from "./index";
import RFQCertificateOfNoObjection from "./RFQCertificateOfNoObjection"
import CertificatePage from "./CertificatePage";
import withAdminDashboard from "../../../HOC/withAdminDashboard";
import VendorEvaluation from "../BidderResponseReceipt/VendorEvaluation";

const CertificateOfNoObjectionWrapper = () => {
  return (
    <Switch>
      <Route
        path="/admin/procurement/activity/certificateofnoobjection/:activityId/:procurementId"
        component={CertificateOfNoObjection}
        exact
      />
      <Route
        path="/admin/procurement/activity/certificateofnoobjection/:activityId/:procurementId/certificate"
        component={CertificatePage}
        exact
      />
      <Route path="/admin/procurement/activity/certificateofnoobjection/:activityId/:procurementId/evaluation/:vendorId">
        <VendorEvaluation previewMode />
      </Route>
      <Route
        path="/admin/procurement/activity/certificateofnoobjectionforpayment/:activityId/:procurementId"
        component={RFQCertificateOfNoObjection}
        exact
      />
      <Route
        path="/admin/procurement/activity/certificateofnoobjectionforpayment/:activityId/:procurementId/certificate"
        component={CertificatePage}
        exact
      />
      <Route path="/admin/procurement/activity/certificateofnoobjectionforpayment/:activityId/:procurementId/evaluation/:vendorId">
        <VendorEvaluation previewMode />
      </Route>
    </Switch>
  );
};

export default withAdminDashboard(CertificateOfNoObjectionWrapper);
