import React from 'react'
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/styles'
import EmptyTableBody from '../EmptyTableBody';
import { StyledTableCell } from '../SearchTable';
import { theme, StyledTableRow } from '../../util/theme';
import { EmptyTableSubTitle } from './style';
import { StyledTableSortLabel } from './style';

const useStyles = makeStyles({
  activeStatus: {
    fontSize: 14,
    backgroundColor: "#EDF9F0",
    borderRadius: "16px",
    color: `${theme.color.primary}`,
    textAlign: "center",
    textTransform: "capitalize"
  },
  inactiveStatus: {
    fontSize: 14,
    backgroundColor: "#CDCED9",
    borderRadius: "16px",
    textAlign: "center",
    color: "#393A4A",
    textTransform: "capitalize"
  }
})
const CustomSupplierTable = ({
  suppliers = [],
  urlLink,
  emptyRows,
  column,
}) => {
  const history = useHistory();
  const classes = useStyles();
  return suppliers && suppliers.length > 0
    ? (
      <React.Fragment>
        {
          suppliers.map(eachSupplier => {
            const link = `${urlLink}/${eachSupplier.id}`;
            return (
              <StyledTableRow
                key={eachSupplier.id}
                onClick={() => history.push(link)}
                style={{ backgroundColor: "#fff" }}
              >
                <StyledTableCell style={{ width: 200 }}>
                  <p style={{ width: 200, textTransform: "capitalize" }}>
                    {eachSupplier?.companyName?.toLowerCase()}
                  </p>
                </StyledTableCell>
                <StyledTableCell>
                  <StyledTableSortLabel hideSortIcon />
                  <p style={{ width: 100 }}>
                    {eachSupplier?.category}
                  </p>
                </StyledTableCell>
                <StyledTableCell>
                  <p style={{ width: 100, textTransform: "capitalize" }}>
                    {eachSupplier?.competence?.toLowerCase() || "..."}
                  </p>
                </StyledTableCell>
                <StyledTableCell>
                  <p style={{ width: 100 }}>
                    {eachSupplier?.incorporationYear ?? "..."}
                  </p>
                </StyledTableCell>
                <StyledTableCell>
                  <p style={{ width: 100 }}>
                    {eachSupplier?.cacRegistrationNumber}
                  </p>
                </StyledTableCell>
                <StyledTableCell align='left' style={{ textAlign: "left" }}>
                  <p
                    style={{
                      textAlign: "center",
                      padding: "2px 2px",
                      width: "60px"
                    }}
                    className={
                      eachSupplier?.status === "Active"
                        ? classes.activeStatus
                        : classes.inactiveStatus}
                  >
                    {eachSupplier?.status}
                  </p>
                </StyledTableCell>
              </StyledTableRow>
            )
          })
        }
        {emptyRows > 0 && (
          <StyledTableRow style={{ height: 40 * emptyRows }}>
            <StyledTableCell style={{ backgroundColor: "#fff" }} colSpan={column.length} />
          </StyledTableRow>
        )}
      </React.Fragment>
    ) : (
      <EmptyTableBody>
        <EmptyTableSubTitle>
          There is currently no supplier list
        </EmptyTableSubTitle>
      </EmptyTableBody>
    )
}

export default CustomSupplierTable
