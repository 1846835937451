import { Box, Typography } from "@material-ui/core";
import styled from "styled-components";
import { theme } from "../../util/theme";


export const StyledButtonContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px 24px;
`;

export const StyledTitleContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const StyledTitle = styled(Typography)`
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 1.5;
  color: ${theme.color.textMain};
  flex: 1;
`;
