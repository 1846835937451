import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styled from "styled-components";
import { FormSubtitle, theme } from "../../../../util/theme";

export const SummaryCards = styled.div`
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #c9d4db;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #c9d4db;
  padding: 20px;
`;

export const CardWrapper = styled.div`
  box-sizing: border-box;
  border: 1px solid #c9d4db;
  border-radius: 3px;
  background-color: ${({ activeCategory }) => (activeCategory ? "#eeeeee80" : "#ffffff")};
  padding: 20.5px;
  cursor: pointer;

  &:hover {
    background-color: #eeeeee80;
  }
`;

export const CardStatus = styled.div`
  display: flex;
  justify-content: left;

  p {
    margin-right: 14px;
  }
`;

export const StatusContainer = styled(FormSubtitle)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: ${({ bgcolor }) => bgcolor};
  color: ${({ textcolor }) => textcolor};
  font-size: 12px !important;
  letter-spacing: 0 !important;
  line-height: 12px !important;
  border-radius: 3px !important;
`;

export const CardLabelWrapper = styled.p`
  margin: 0 0 10px 0;
  height: 19px;
  color: #5f7483;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
`;

export const CardNumberText = styled.p`
  height: 35px;
  width: 32px;
  color: #1e2b37;
  font-family: "Nunito Sans";
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 35px;
`;

export const CardInfoText = styled.p`
  height: 16px;
  width: 90%;
  color: #5f7483;
  font-family: "Nunito Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 7px;
`;

export const CardIconContainer = styled.div`
  border-radius: 50%;
  height: 45px;
  width: 45px;
  display: flex;
  background-color: rgba(3, 173, 235, 0.13);
  align-items: center;
  justify-content: center;
`;

export const Accordion = styled(MuiAccordion)`
  margin-bottom: 15px;
  box-shadow: 0 2px 4px 0 #c9d4db !important;
  width: 100%;

  .MuiAccordionSummary-root.Mui-expanded {
    color: ${theme.color.primary};
  }

  .MuiAccordionDetails-root {
    padding: 20px 0;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }

  .MuiCollapse-wrapper {
    display: flex;
    margin-bottom: 30px;
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  color: #212b36;
  font-size: 14px !important;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  display: flex;
  justify-content: space-between;

  .MuiAccordionSummary-content {
    margin: 20px 0;
    border-radius: 3px;
    background-color: #ffffff;
    align-items: center;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 64px;
    color: blue;
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  border-radius: 3px;
  background-color: #ffffff;
  width: 96%;
  margin: 0 auto;
  border-top: 1px solid #e5e9ec;

  p {
    color: #637381;
    font-size: 14px;
  }
`;

export const AccordionExpandMoreIcon = styled(ExpandMoreIcon)`
  color: "#2AA96D";
`;

export const Notice = styled.div`
  margin-bottom: 15px;
`;
export const NoticeTitle = styled(Typography)`
  color: #5f7483;
  font-size: 13px !important;
  letter-spacing: 0;
  line-height: 16px;
`;
export const NoticeContent = styled(Typography)`
  .MuiTypography-body1 {
    height: 21px;
    color: #212b36;
    font-size: 14px !important;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
  }
`;
export const ContractDetails = styled.div`
  margin-top: 14px;
`;
export const NoticeDivider = styled.hr`
  box-sizing: border-box;
  height: 1px;
  border: 1px solid #cbd4da;
`;
export const ContractDetail = styled.div`
margin-bottom: 15px;
`;
export const ContractDetailTitle = styled.p`
  height: 16px;
  color: #5f7483;
  font-family: "Nunito Sans";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
`;

export const BudgetDetailTitle = styled(ContractDetailTitle)`
margin-bottom: 8px;
`

export const ContractDetailValue = styled.p`
  height: 20px;
  color: #5f7483;
  font-family: "Nunito Sans";
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 20px;
`;

export const StatusLabelText = styled.p`
  color: #2AA96D;
  font-family: "SF Pro Text";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
`;
export const NoticeInformation = styled.p`
  color: #1e2b37;
  font-family: "SF Pro Text";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 10px;
`;
export const StatusLabelWrapper = styled.div`
  height: 34px;
  padding: 0 10px;
  min-width: 107px;
  border-radius: 3px;
  background-color: ${({ bgcolor }) => bgcolor};
  color: ${({ textcolor }) => textcolor} !important;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ActionButton = styled(Button)`
  text-transform: none !important;
  text-decoration: underline;
  margin-right: 30px;
  color: #2AA96D !important;
  margin-bottom: 20px !important;
  margin-left: 0px !important;
  font-size: 14px !important;
  .MuiButton-label {
    text-decoration: underline;
  }
`;

export const SendForApprovalButton = styled(Button)`
  width: 194px;
  height: 40px;
  background: #3bd278 !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
  border-radius: 3px !important;
  text-transform: none !important;
  color: #ffffff !important;
  margin-left: 10px !important;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > button {
    margin-right: 15px;
  }
`;

export const BudgetValue = styled.p`
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #212b36 !important;
`;

export const DisabledButton = styled(Button)`
  width: 194px;
  height: 40px;
  background: #abb4bc !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
  border-radius: 3px !important;
  text-transform: none !important;
  color: #ffffff !important;
  margin-left: 10px !important;
`;

export const ViewMoreText = styled.p`
  font-size: 14px !important;
  line-height: 20px !important;
  color: #2AA96D !important;
  cursor: pointer;
`;

export const PlanDetailsDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 0;
  }
`;

export const PlanDetailsDialogContent = styled(DialogContent)`
  margin-top: 0px !important;
  height: 1106px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  margin-right: 0px !important;
`;
export const CloseButton = styled(Button)`
  position: absolute;
  width: 93px !important;
  height: 40px !important;
  margin-top: 30px !important;
  background: #ffffff !important;
  border: 1px solid #adb4bb !important;
  border-radius: 3px !important;
  text-transform: none !important;
`;

export const ModalButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FinalApprovalDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 0;
  }
`;

export const FinalApprovalDialogContent = styled(DialogContent)`
  height: 358px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
`;

export const FinalApprovalDialogTitle = styled.p`
  height: 33px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 30px;
  color: #3f5673;
`;

export const FinalApprovalDialogDesc = styled.p`
  height: 44px;
  width: 552px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #3f5673;
  margin-bottom: 36px;
`;

export const FinalApprovalDetails = styled.div`
  width: 552px;
  height: 80px;
  background: #ffffff;
  border: 1px solid #2AA96D;
  margin-bottom: 25px;
  border-radius: 3px;
`;

export const FinalApprovalDetailsText = styled.p`
  height: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #424f5c;
  margin: 10px;
`;

export const ApprovalActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CancelButton = styled(Button)`
  width: 93px !important;
  height: 40px !important;
  background: #ffffff !important;
  border: 1px solid #adb4bb !important;
  border-radius: 3px !important;
  text-transform: none !important;
  margin-left: 10px !important;
`;

export const EditButton = styled(Button)`
  text-transform: none !important;
  margin-right: 30px;
  color: #5f7483 !important;
  margin-bottom: 20px !important;
  margin-left: 0px !important;
  font-size: 14px !important;
  align-items: center;
  .MuiButton-label {
    text-decoration: underline;
  }
`;



// MOJ Contract Details Styles

export const TopCard = styled.div`
background: #FFFFFF;
border: 1px solid #C9D4DB;
box-shadow: 0px 2px 4px #C9D4DB;
border-radius: 3px;
padding: 20px;
margin: 30px 0 20px;
`;

export const StatusTag = styled.div`
background: ${(props) => props.red ? `red` : `#219653`};
border-radius: 3px;
padding: 5px 15px;
margin-right: 20px;
color: #fff;
`;

export const MainText = styled.p`
 font-size: ${(props) => props.fontSize};
 color: ${(props) => props.color ? props.color : `#5F7483`}; 
 margin-top: ${(props) => props.mt};
 margin-right: 40px;
 font-weight:  ${(props) => props.fw};

`;

export const MainTitle = styled.p`
  height: 24px;
  color: #1e2b37;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  
`;

export const HL = styled.hr`
margin-top: ${(props) => props.mt};
border: 1px solid #F2F2F2;
`;

export const BlueLink = styled.p`
text-decoration-line: underline;
color: #2AA96D;
font-size: 14px;
line-height: 20px;
margin-left: auto;
margin-top: 2px;
`;

export const InfoCardWrapper = styled.div`
box-sizing: border-box;
border: 1px solid #cbd4da;
border-radius: 3px;
background-color: #ffffff;
padding: 20.5px !important;
margin-bottom: 20px;
min-height: 500px;
position: relative;

> .topRightCard {
background-color: #F2F2F2;
border: 1px solid #C9D4DB;
border-radius: 0px 0px 0px 3px;
padding: 0 20px 20px;
position: absolute;
right: 0%;
top: 0%;
min-width: 21%;
}

> .oneCard {
   min-width: 4%;
}
`;

export const Pad = styled.div`
padding: 20.5px;
`;

export const FileBoard = styled.div`
background-color: #FCFCFC;
padding: 20px;
margin-top: 20px;
`;

export const FileCard = styled.div`
background: #FFFFFF;
border: 1px solid #C9D4DB;
border-radius: 3px;
padding: 20px 50px;
margin: 20px 35px 20px 0;
`;

export const FileText = styled.p`
font-size: ${(props) => props.fontSize};
text-align: center;
margin-top: ${(props) => props.mt};
font-weight:  ${(props) => props.fw};
color: ${(props) => props.color ? props.color : `#5F7483`}; 
width: ${(props) => props.wd};
`;

export const CuteGrid = styled.div`
display: flex;
justify-content: ${(props) => props.start ? `flex-start` : `space-between`};
margin-top: ${(props) => props.mt};
width: ${(props) => props.width};
flex-wrap: wrap;
`;

export const CardWrap = styled.div`
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #cbd4da;
  margin-top: ${(props) => props.mt};
`;

export const DocumentSection = styled.div`
background-color: #FCFCFD;
border: 1px solid #C9D4DB;
padding: 20.5px !important;
margin-top: ${(props) => props.mt};
`;


export const SubmitButton = styled.button`
  color: #ffffff;
  box-sizing: border-box;
  height: 41px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  background-color: #2AA96D;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;

  &:disabled,
  &[disabled]{
  background-color: #c9d4db;
  cursor: not-allowed;
}
`;

export const AddNewButton = styled(Button)`
    && {
    color: ${theme.color.primary};
    margin-right: 20px;
  }
`;



