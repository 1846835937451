import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RadioGroup from "@material-ui/core/RadioGroup";
import Container from "@material-ui/core/Container";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { useHistory, Link } from "react-router-dom";
import {
  // CustomRadiosWrapper,
  CustomFormLabel,
  CustomFormlabelSubtitle,
  StyledRadioLabel,
  StyledRadio,
  RadioButtonsWrapper,
} from "./style";
// import NavigationButtons from "../../NavigationButtons";
import {
  getVendorRegistrationPlan,
  getGeneralCompanyDetails,
} from "../../../redux/reducer/vendorReducer";
import { formatNumber, separateNumByComma } from "../../../util/truncateValue";
import withVendorRegLoader from "../../../pages/Common/HOC/withVendorRegLoader";
import ToastContext from "../../../util/toastContext";
import { useQuery } from "react-query";
import vendorsAPI from "../../../redux/api/vendorsAPI";
import useAlert from "../../../hooks/useAlert";
import { LandingNavbarStatic } from "../../HomepageHeader/style";
import { Navbar } from "../..";
import { Wrapper, CloseButton, NavMargin } from "./style";
import UserProfileTab from "../../AdminDashboardNav/UserProfileTab";
import usersAPI from "../../../redux/api/usersAPI";
import { DoubleWrapper, SaveAndExit, SaveButton } from "../../NavigationButtons/style";


const Form = ({ dispatch, userId }) => {
  const { selectedRegPlan, companyDetails} = useSelector((state) => state.vendor);
  //setState of companyDetails
  const [value, setValue] = useState("");
  const { showAlert, Toast } = useAlert();

  const { data: vendorProfileData } = useQuery(
    ["getVendorProfile", { userId }],
    usersAPI.getVendorProfile,
    {
      enabled: !!userId,
      onError: (error) => {
        showAlert({
          message: error.message,
          severity: "error",
        });
      },
    }
  );

  const { data: registrationPlans } = useQuery(
    [
      "registrationPlans",
      { coreCompetency: companyDetails?.coreCompetency },
    ],
    vendorsAPI.getVendorRegistrationPlans,
    { enabled: !!companyDetails?.coreCompetency }
  );


  useEffect(() => {
    if (Object.keys(selectedRegPlan).length) setValue(selectedRegPlan.id);
  }, [selectedRegPlan]);

  useEffect(() => {
    if (vendorProfileData?.registrationPlanId) {
      setValue(vendorProfileData?.registrationPlanId)
    }
    // eslint-disable-next-line
  }, [vendorProfileData?.registrationPlanId])

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  // const onSubmit = (cb) => () => {
  //   if (selectedRegPlan.id === value) return cb();
  //   setLoading(true);

  //   dispatch(postVendorRegistrationPlan({ registrationPlanId: value, userId })).then((res) => {
  //     if (!res.error) {
  //       setLoading(false);
  //       showAlert({ severity: "success", message: "Registration Plan Saved" });
  //       return cb();
  //     }
  //     showAlert({ severity: "error", message: res.payload });
  //   });
  // };

  return (
    <>

      <Container>
        <Wrapper style={{ marginBottom: 60 }}>
          <Toast customStyles={{ zIndex: "9999" }} float />
          <CustomRadios
            vendorGrade={vendorProfileData?.grade}
            registrationPlans={registrationPlans || []}
            handleChange={handleChange} value={value}
          />
          {/* <NavigationButtons nextActionHandler={(cb) => onSubmit(cb)} value={loading ? "Please Wait..." : null} /> */}
          <DoubleWrapper style={{ marginRight: 16, marginLeft: 16, marginTop: 30 }}>
            <SaveAndExit
            // {...props}
            // onClick={(event) => {
            //   event.preventDefault();
            // }}
            >
              Go back
            </SaveAndExit>
            <SaveButton
              disabled={!value}
              component={Link}
              to={`/vendor-registration/categories/payment?newGradeId=${value}`}
            >
              Make payment
            </SaveButton>
          </DoubleWrapper>
        </Wrapper>
      </Container>
    </>
  );
};

const UpdateRegistrationCategories = () => {
  const history = useHistory();
  const { showAlert, Toast } = useAlert();
  const { user: { UserId } } = useSelector((state) => state.user);

  return (
    <>
      <ToastContext.Provider value={{ showAlert: showAlert, Toast: Toast }}>

        <LandingNavbarStatic>
          <Navbar
            rightContent={
              <>
                <NavMargin>
                  <UserProfileTab />
                </NavMargin>
                <CloseButton
                  variant="outlined"
                  color="default"
                  onClick={() => {
                    history.push("/vendor/overview");
                  }}
                >
                  Back to dashboard
                </CloseButton>
              </>
            }
          />
        </LandingNavbarStatic>
        <Container maxWidth="sm">
          {withVendorRegLoader(Form)({
            title: <div style={{ textAlign: "center", marginTop: 20 }}>Registration Categories</div>,
            description: "Fill in the fields below",
            apis: [getVendorRegistrationPlan(UserId), getGeneralCompanyDetails(UserId)],
            userId: UserId,
            conditioners: ["selectedRegPlan", "companyDetails"],
          })}
        </Container>

      </ToastContext.Provider>
    </>
  );
};

const CustomRadios = ({ registrationPlans, handleChange, value, vendorGrade }) => {
  const formatedVendorGrade = !!vendorGrade && vendorGrade.split(" ")[1].toLowerCase();
  return (
    <FormControl component="fieldset">
      <CustomFormLabel style={{ textAlign: "center" }}>Select Your New Registration Type</CustomFormLabel>
      <CustomFormlabelSubtitle style={{ textAlign: "center", marginBottom: 20 }}>Select a contract category</CustomFormlabelSubtitle>
      <RadioGroup
        aria-label="class"
        name="registration-categories-radios"
        value={value}
        onChange={handleChange}
      >
        <RadioButtonsWrapper>
          {registrationPlans.map((plan) => {
            const grade = plan.grade.split(" ")[1].toLowerCase();
            return (
              <FormControlLabel
                key={plan.id}
                value={plan.id}
                control={<StyledRadio color="primary" />}
                label={<CustomLabel {...plan} />}
                labelPlacement="bottom"
                disabled={formatedVendorGrade >= grade}
              />
            )
          })}
        </RadioButtonsWrapper>
      </RadioGroup>
    </FormControl>
  )
};

const CustomLabel = ({ grade, fee, contractMaxValue, contractMinValue, anualRenewalFee }) => (
  <StyledRadioLabel>
    <div className="class">
      <p className="value">{grade}</p>
    </div>

    <div className="contract-fee">
      <p className="fee">N{separateNumByComma(fee)}</p>
      <p className="title">Registration Fee</p>
    </div>
    <div className="separator" />
    <div className="renewal">Renew at N{separateNumByComma(anualRenewalFee)} annually</div>
    <div className="long-separator" />
    <div className="contract-value">
      {formatNumber(contractMinValue) === "1B" ? (
        <p className="value">1B and Above</p>
      ) : (
        <p className="value">
          {formatNumber(contractMinValue)} - {formatNumber(contractMaxValue)}
        </p>
      )}
      <p className="title">Contract sum</p>
    </div>
  </StyledRadioLabel>
);

export default UpdateRegistrationCategories;
