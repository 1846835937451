import styled from "styled-components";
import { Typography, Grid, Button, Box,Card } from "@material-ui/core";
import { theme } from "../../../util/theme";
import { StyledTableCell } from "../../../components/SearchTable";

export const VendorDetailsBreadcrumbs = styled(Typography)`
  height: 16px;
  color: #2AA96D;
  font-size: 12px !important;
  letter-spacing: 0;
  line-height: 16px !important;
`;

export const VendorDetailsContainer = styled(Grid)`
  margin-top: 25px !important;
`;

export const CommonCardStyled = styled.div`
  box-sizing: border-box;
  border: 1px solid #c9d4db !important;
  border-radius: 3px !important;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #c9d4db !important;
  padding: 20px;
`;

export const VendorCardContainer = styled(CommonCardStyled)`
minHeight:180px,
`;

export const VendorInfoCard = styled(CommonCardStyled)`

`;

export const ActivitiesCard = styled(VendorInfoCard)`
  min-height: 643px;
  margin-top: 20px !important;
  padding: 25px 20px 16px;
`;

export const StatusButton = styled(Button)`
  border-radius: 3px !important;
  background-color: ${({ status }) =>
    status === "APPROVED" ? "#B0E7AE !important" : status === "DISAPPROVED" ? "#FFC8BE !important" : status === "SUSPENDED" ? "#f1a99e59 !important" : "#FCF1CD !important"};
  margin-right: 15px !important;
  color: ${({ status }) =>
    status === "APPROVED" ? "#00823C !important" : status === "DISAPPROVED" ? "#DD5E47 !important" : status === "SUSPENDED" ? `${theme.color.tags.rejected.background} !important` : "#F49342 !important"};
  text-transform: none !important;
`;

export const PendingContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px !important;
`;

export const RCText = styled(Typography)`
  color: #1e2b37;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 24px !important;
`;

export const TextLabel = styled(Typography)`
  color: #5f7483 !important;
  font-size: 13px !important;
  letter-spacing: 0;
  line-height: 16px !important;
`;

export const ValueText = styled(Typography)`
  color: #5f7483 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 20px !important;
  width: 90% !important;
`;

export const InfoText = styled(ValueText)`
  font-size: 12px !important;
`;

export const BusinessAddressContainer = styled.div`

`;

export const ApproveButton = styled(Button)`
  height: 40px;
  width: auto;
  border-radius: 3px;
  background-color: #6ccf81 !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  margin-right: 15px !important;
  text-transform: none !important;
  color: #fff !important;
`;

export const RejectButton = styled(ApproveButton)`
  background-color: #de3618 !important;
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 15px !important;
`;

export const CountText = styled(Typography)`
  margin-top: 10px !important;
  color: #1e2b37;
  font-size: 26px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 35px !important;
`;

export const ActiveText = styled(Typography)`
  height: 22px;
  border-radius: 3px;
  background-color: #b0e7ae;
  color: #00823c;
  padding: 0 10px;
  margin-right: 10px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 16px !important;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CompletedText = styled(ActiveText)`
  background-color: rgba(0, 176, 241, 0.13);
  color: #2AA96D;
`;

export const RejectText = styled(ActiveText)`
  background-color: #ffc8be;
  color: #dd5e47;
`;

export const SignedText = styled(ActiveText)`
  background-color: #c1eeff;
  color: #2AA96D;
`;
export const UnsignedText = styled(ActiveText)`
  background-color: #cbd4da;
  color: #637381;
`;

export const TextFlexContainer = styled.div`
  display: flex;
  margin-top: 10px !important;
  
`;

export const CardHeaderText = styled(Typography)`
  color: #1e2b37;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 24px !important;
  margin-bottom: 10px !important;
`;

export const SubscriptionTextContainer = styled(TextFlexContainer)`
  display:flex;
  width:100%;
  justify-content: space-between;
  // padding:10px;
  
`;

export const FeeContainer = styled.div`
  width:120px;
  display:flex;
  justify-content: flex-start;
  flex-direction:column;
`;

export const TextGroupContainer = styled.div`
min-width:180px;
`;

export const SubscriptionIcon = styled(Typography)`
  padding: 10px;
  text-transform: capitalize;
  border-radius: 3px;
  background-color: rgba(0, 176, 241, 0.07);
  color: #2AA96D;
  font-size: 36px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 36px !important;
  text-align: center !important;
  height:30px;
  marginBottom:10px;
   
`;

export const IdText = styled(Typography)`
  color: ${theme.color.primary} !important;
  font-size: 14px;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 19px !important;
`;

export const TableFirstCell = styled(StyledTableCell)`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  padding-top: 10px !important;
`;

export const ActivityText = styled(Typography)`
  color: #5f7483;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
`;

export const ActivityTextContainer = styled.div`
  margin-bottom: 15px !important;
`;

export const ViewAllText = styled(Typography)`
  color: #212b36;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 25px !important;
  text-align: center;
  cursor: pointer;
`;

export const ActivityIdText = styled.span`
  color: ${theme.color.primary};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
`;

export const ActivityTimeText = styled(Typography)`
  height: 20px;
  color: #8e9fac;
  font-size: 14px !important;
  letter-spacing: 0;
  line-height: 20px;
`;

export const CardContainer = styled(Card)`
//  min-height:80px;
 display:flex;
 padding:20px 10px;
 flex-direction: column
`;
export const DirectorHolder = styled(Box)`
margin:10px;
`;
export const ContactHolder = styled(Box)`
width:500px;
// min-height:70px;
background-color: #F0F5FF;
display:flex;
flex-direction:column;
padding:5px 10px;
font-size:12px;
border-radius:5px;
margin:10px;
`;
export const DocumentHolder = styled(Box)`
width:430px;
display:flex;
border-bottom:1px dashed #d3d3d3;
padding:10px;
`;
export const ContactRole = styled(Box)`
border: 1px solid green;
border-radius:2px;
color:green;
margin:0px 3px;
padding: 2px;
font-size:10px;
font-weight:bold;

`;
export const Directors = styled(Box)`
display:flex;
margin: 10px 0px;
`;
export const DirAvatar = styled.div`
width:45px;
height:45px;
border-radius: 50%;
object-fit:contain;
margin: 0px 10px ;
background-color:lavender;
display:flex;
justify-content:center;
align-items:center
`;

export const WrapText = styled.div`
width: 150px;
`

export const FlexContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // min-height:170px
`;