import React, { useContext, useState } from "react";
import { capitalize } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import AccessControl from "../../../../components/AccessControl";
import Breadcrumb from "../../../Common/PageElements/Breadcrumb";
import { queryCache, useQuery } from "react-query";
import { TitleContainer } from "../../../Common/style";
import withAdminDashboard from "../../HOC/withAdminDashboard";
import { PageTitle } from "../../PlanDetails/style";
import { Roles } from "../../../../components/AccessControl/UserRoles";
import { AddButton } from "../style";
import { ContentWrapper, StatusBox } from "./style";
import { format } from "date-fns";
import SearchTable, { StyledTableCell } from "../../../../components/SearchTable";
import { HoverTableStyle } from "../../../../util/theme";
import procurementAPI from "../../../../redux/api/procurementAPI";
import ToastContext from "../../../../util/toastContext";
import { formatCurrency } from "../../../../util/formatCurrency";
import MenuDropDown from "./MenuDropdown";
import ReviewModal from "./ReviewModal";
import StatusAlert from "./StatusAlert";
import {
  useCreateSupplementaryBudget,
  useFetchSupplementaryList,
  useSupplementaryStatusToggle,
} from "../../../../hooks/queries/useSupplementaryBudget";

const SupplementaryBudget = () => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = React.useState(false);
  const [dataSource, setDataSource] = React.useState([]);
  const [reviewAmount, setReviewAmount] = useState(0);
  const { showAlert } = useContext(ToastContext);
  const history = useHistory();

  const toggleModal = () => {
    setOpen(!open);
  };
  const { id } = useParams();

  const { loadingSupplementaryList } = useFetchSupplementaryList({ showAlert, id, setDataSource });

  const { data: procurementData } = useQuery(["APPData", id], procurementAPI.getAPPBudgetById, {
    onError: () => {
      showAlert({
        severity: "error",
        message: "Failed to get procurement details",
      });
    },
  });
  const { toggleSupplementaryStatus, togglingSupplementaryStatus } = useSupplementaryStatusToggle({
    showAlert,
    setModal: setSelected,
    queryCache,
  });

  const handleMenuClick = (values, keyType) => {
    switch (keyType) {
      case "view-list": {
        history.push(`/admin/procurement/plans/${id}/supplementary-budget/${values.id}`);
        break;
      }
      case "approve-list": {
        setSelected({ ...values, openMenu: true });
        break;
      }
      case "reject-list": {
        setSelected({ ...values, openMenu: true });
        break;
      }

      default:
        break;
    }
  };

  const { createSupplementaryBudget, creatingSuppBudget } = useCreateSupplementaryBudget({
    showAlert,
    queryCache,
    setOpen: toggleModal,
  });

  const handleSupplementaryBugetCreation = () => {
    const params = {
      id,
      type: "Upward",
      budget: procurementData?.budget,
      newBudget: parseInt(reviewAmount),
    };
    createSupplementaryBudget({ payload: params });
  };

  const GeneralPlanDetailsLink = [
    {
      url: "/admin/dashboard/overview",
      title: "Home",
      forwardSlash: true,
    },
    {
      url: "/admin/procurement/plans",
      title: "Procurement Plans",
      forwardSlash: true,
    },
    {
      url: `/admin/procurement/plans/${id}`,
      title: "Plan",
      forwardSlash: false,
    },
  ];

  const toggleStatus = (values) => {
    toggleSupplementaryStatus({ approveStatus: values?.menuValue, budgetId: values?.id });
  };

  return (
    <ContentWrapper>
      <TitleContainer>
        <div>
          <PageTitle>Supplementary Budget</PageTitle>
          <Breadcrumb values={GeneralPlanDetailsLink} />
        </div>
        <AccessControl allowedRoles={[Roles.PROCUREMENT_OFFICER]}>
          <AddButton onClick={toggleModal}>Request Review</AddButton>
        </AccessControl>
      </TitleContainer>

      <SearchTable
        noRows
        headerText={`Showing ${dataSource?.length} Result(s)`}
        columns={["Date requested", "Type", "Approved Amount", "Amount Requested", "Status", ""]}
        loading={loadingSupplementaryList}
      >
        {dataSource?.map((budget) => (
          <HoverTableStyle onClick={() => history.push(`/admin/procurement/plans/${id}/supplementary-budget/${budget.id}`)}>
            <StyledTableCell>{format(new Date(), "dd MMM, yyyy")}</StyledTableCell>
            <StyledTableCell>{budget.type}</StyledTableCell>
            <StyledTableCell>{formatCurrency(budget.approvedAmount, true)}</StyledTableCell>
            <StyledTableCell>{formatCurrency(budget.amountRequested, true)}</StyledTableCell>
            <StyledTableCell>
              <StatusBox colorStatus={budget.status}>{capitalize(budget?.status || "")}</StatusBox>
            </StyledTableCell>
            <StyledTableCell>
              <MenuDropDown data={budget} menuActionClick={handleMenuClick} />
            </StyledTableCell>
          </HoverTableStyle>
        ))}
      </SearchTable>
      <ReviewModal
        title="Request Review"
        subtitle="Upward review request for Annual Procurement Plan"
        open={open}
        onAction={handleSupplementaryBugetCreation}
        reviewAmount={reviewAmount}
        setReviewAmount={setReviewAmount}
        onClose={toggleModal}
        procurementData={procurementData}
        loading={creatingSuppBudget}
      />
      <StatusAlert
        open={Boolean(selected?.openMenu)}
        onClose={() => setSelected(null)}
        selected={selected}
        reviewType={selected?.type || ""}
        onAction={toggleStatus}
        statusKey={selected?.menuValue?.toLowerCase()}
        loading={togglingSupplementaryStatus}
      />
    </ContentWrapper>
  );
};

export default withAdminDashboard(SupplementaryBudget);
