import baseAxiosMethod from "./baseAxiosMethod";
import { handleAxiosError } from "./handleApiError";

const receiptOfResponseApi = {

}


receiptOfResponseApi.getInterestedVendors = async (key, { procurementId, Status, PageNumber, PageSize }) => {
  let response;
  try {
    if (Status === 8) {
      response = await baseAxiosMethod(`procurements/${procurementId}/shortlistedVendor`, {
        params: {
          PageNumber,
          PageSize
        }
      })
    }
    else {
      response = await baseAxiosMethod(`procurements/${procurementId}/interestedVendor`, {
        params: {
          Status,
          PageNumber,
          PageSize
        }
      })

    }
    const { data, meta: { pagination } } = response.data

    return { interestedVendorsData: data, pagination }
  } catch (e) {
    throw new Error(handleAxiosError(e))
  }
}

receiptOfResponseApi.getSpecificVendor = async (key, { procurementId, vendorId }) => {
  try {
    const response = await baseAxiosMethod(`procurements/${procurementId}/interestedVendor/${vendorId}`)

    return response.data.data
  } catch (e) {
    throw new Error(handleAxiosError(e))
  }
}

receiptOfResponseApi.getEvaluatedVendorScore = async (key, { procurementId, vendorId }) => {
  try {
    const response = await baseAxiosMethod(`procurements/${procurementId}/vendor/${vendorId}/evaluation-score`)

    return response.data.data
  } catch (e) {
    throw new Error(handleAxiosError(e))
  }
}

receiptOfResponseApi.approveVendor = async ({ activityId, vendors }) => {
  try {
    await baseAxiosMethod.post(`procurements/approveInterestedVendor/${activityId}`, vendors)
  } catch (e) {
    throw new Error(handleAxiosError(e))
  }
}

receiptOfResponseApi.technicalEvaluationApproval = async ({ activityId }) => {
  try {
    await baseAxiosMethod.post(`procurements/technical-evaluation-approval/activity/${activityId}`)
  } catch (e) {
    throw new Error(handleAxiosError(e))
  }
}

receiptOfResponseApi.rejectVendor = async ({ activityId, vendors }) => {
  try {
    await baseAxiosMethod.post(`procurements/rejectInterestedVendor/${activityId}`, vendors)
  } catch (e) {
    throw new Error(handleAxiosError(e))
  }
}

receiptOfResponseApi.DPMEndorseVendor = async ({ activityId }) => {
  try {
    await baseAxiosMethod.post(`procurements/${activityId}/endorse-dpm`)
  } catch (e) {
    throw new Error(handleAxiosError(e))
  }
}

receiptOfResponseApi.sendDPMForEndorsement = async ({ procurementId }) => {
  try {
    await baseAxiosMethod.post(`procurements/${procurementId}/dpm-endorsement-approval`)
  } catch (e) {
    throw new Error(handleAxiosError(e))
  }
}

receiptOfResponseApi.getDPMEndorsements = async (key, { procurementId }) => {
  try {
    const response = await baseAxiosMethod(`procurements/${procurementId}/endorse-dpm`)

    return response.data.data
  } catch (e) {
    throw new Error(handleAxiosError(e))
  }
}

export default receiptOfResponseApi
