import React, { useContext, useState } from "react";
import moment from "moment";
import { Drawer, Box } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { queryCache, useMutation, useQuery } from "react-query";
import InputField from "../../../../../components/InputField";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  ErrorContainer,
  CancelButton,
  UpdateButton,
  DateRangeWrapper,
  DrawerWrapper,
  BtnBoX,
  DrawerArchiveContractTitle,
} from "../ocdsEdit.styles";
import ToastContext from "../../../../../util/toastContext";
import Dropdown from "../../../../../components/DropdownField";
import Loader from "../../../../../components/Common/Loader";
import contractsAPI from "../../../../../redux/api/contractAPI";
import adminAPI from "../../../../../redux/api/adminAPI";
// import Dropdown from "../../../../../components/DropdownField";

const archiveCategoryOptions = [
  {
    label: "Works",
    value: "0",
  },
  {
    label: "Goods",
    value: "1",
  },
  {
    label: "Consultation",
    value: "2",
  },
  {
    label: "Non-Consultation",
    value: "3",
  },
];

const archiveCategoryFunc = function (key = "") {
  if (typeof key === "string") {
    switch (key) {
      case "works":
        return "0";
      case "goods":
        return "1";
      case "consultation":
        return "2";

      case "non consultation":
        return "3";

      default:
        return "0";
    }
  }
  return "0";
};

// const archiveDetails = {
//   budgetDescription: "",
//   datePublished: new Date(),
//   procuringEntity: "",
//   procurementCategory: 0,
//   contractStartDate: new Date(),
//   contractEndDate: new Date(),
//   contractor: "",
//   contractSum: 0,
//   projectTitle: "",
//   ocidNumber: "",
// };

const EditDrawer = ({ open, toggleDrawer, id }) => {
  const { showAlert } = useContext(ToastContext);
  const [ministries, setMinistries] = useState([]);
  const { register, handleSubmit, errors, control, reset, watch } = useForm({ defaultValues: { awardedDate: "" } });
  // const [awardedDate, setAwardedDate] = useState(null);
  // const categoryOptions = ["Works", "Goods", "Consultation", "Non-Consultation"].map((item) => ({ label: item, value: item }));

  useQuery(
    [
      "ministries",
      {
        pageSize: 0,
        pageNumber: null,
      },
    ],
    adminAPI.getMinistriesAxios,

    {
      onSuccess: ({ data }) => {
        const options = data.map(({ name }) => ({
          label: name,
          value: name,
        }));
        setMinistries(options);
      },
    }
  );

  const { isLoading } = useQuery(["single-contract", id], contractsAPI.getContractOCDS, {
    enabled: !!id,
    onSuccess: (res) => {
      let { data = {} } = res;
      const payload = {
        mda: data.ministryName,
        title: data.title,
        procurementCategory: archiveCategoryFunc(data.procurementCategory.toLowerCase()),
        contractor: data.awardedContractor,
        dateOfAward: moment(new Date(data.awardedDate)).format("YYYY-MM-DD"),
        value: +data.contractAmount,
      };
      reset(payload);
    },
    onError: (e) => {
      showAlert({
        message: "Error fetching contract",
        severity: "error",
      });
    },
  });

  const [mutate, { isLoading: updatingContract }] = useMutation(contractsAPI.updateOcdsContract, {
    onSuccess: () => {
      showAlert({
        message: "Successfully updated contract",
        severity: "success",
      });
      toggleDrawer();
      queryCache.invalidateQueries("tenderAndAwardRequest");
    },
  });

  const onSubmit = (payload) => {
    payload.dateOfAward = moment(new Date(payload.dateOfAward));
    payload.projectTitle = payload.title;
    payload.value = +payload.value;
    payload.category = payload.procurementCategory;
    delete payload.title;
    delete payload.procurementCategory;
    mutate({ payload, id });
  };
  const onRenderError = (error) => <ErrorContainer style={{ marginLeft: "1rem" }}>{error && error.message}</ErrorContainer>;
  return (
    <div>
      <Drawer anchor="right" open={open}>
        <DrawerWrapper>
          {isLoading ? (
            <Box mt="50%">
              <Loader feedback="loading.." />
            </Box>
          ) : (
            <>
              <DrawerArchiveContractTitle>Edit Archived Contract </DrawerArchiveContractTitle>

              <div>
                <InputField
                  label="Project Title"
                  name="title"
                  style={{ background: "#ffffff" }}
                  inputRef={register({
                    required: "Enter Project Name",
                  })}
                />
                {onRenderError(errors.projectName)}
              </div>

              <div>
                <DateRangeWrapper width={{ root: "97%" }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Controller
                      control={control}
                      name="dateOfAward"
                      render={(props) => (
                        <KeyboardDatePicker
                          label="Date of Award"
                          disableToolbar
                          openTo="year"
                          views={["year", "month", "date"]}
                          variant="inline"
                          format="dd-MMM-yyyy"
                          placeholder="MM/DD/YYYY"
                          margin="normal"
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          maxDate={new Date()}
                          {...props}
                        />
                      )}
                    />
                  </MuiPickersUtilsProvider>
                </DateRangeWrapper>

                {onRenderError(errors.datePublished)}
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                <div>
                  {/* <Controller
                    control={control}
                    name="mda"
                    render={(props) => (
                      <InputField label="MDA" name="procuringEntity" style={{ background: "#ffffff" }} {...props} />
                    )}
                  /> */}
                  <div>
                    <Controller
                      control={control}
                      name="mda"
                      render={(props) => (
                        <Dropdown
                          defaultValue={watch("mda")}
                          style={{ width: "inherit" }}
                          label="MDA"
                          values={ministries}
                          {...props}
                        />
                      )}
                    />
                    {onRenderError(errors.category)}
                  </div>
                </div>
                <div>
                  <Controller
                    control={control}
                    name="procurementCategory"
                    render={(props) => (
                      <Dropdown
                        // defaultValue={watch("procurementCategory")}
                        style={{ width: "inherit" }}
                        label="Category"
                        values={archiveCategoryOptions}
                        {...props}
                      />
                    )}
                  />
                  {onRenderError(errors.category)}
                </div>
              </div>
              <div>
                <InputField
                  label="Contractor Name"
                  name="contractor"
                  inputRef={register({
                    required: "Enter contractor's name",
                  })}
                />
              </div>
              <div style={{ background: "#ffffff" }}>
                <Controller
                  control={control}
                  name="value"
                  render={({ onChange, ...props }) => (
                    <NumberFormat
                      customInput={InputField}
                      name="value"
                      label="Contract Amount"
                      thousandSeparator
                      prefix="₦"
                      variant="outlined"
                      onValueChange={(values) => {
                        const { value } = values;
                        onChange(value);
                      }}
                      {...props}
                    />
                  )}
                />
              </div>
              <BtnBoX>
                <CancelButton onClick={toggleDrawer}>Cancel</CancelButton>
                <UpdateButton style={{ marginLeft: "1rem" }} onClick={handleSubmit(onSubmit)} disabled={updatingContract}>
                  Save
                </UpdateButton>
              </BtnBoX>
            </>
          )}
        </DrawerWrapper>
      </Drawer>
    </div>
  );
};

export default EditDrawer;
