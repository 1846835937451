import React, { useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/styles";
import { Close as CloseIcon } from "@material-ui/icons";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { StaffDrawerWrapper, StaffDrawerHeader, StaffFormTitle, ButtonContainer, TabDivider } from "./style";
import { ErrorContainer, SaveButton } from "../Forms/Common/style";
import AddNewStaff from "../AddNewStaff";
import PendingInvite from "../PendingInvite";
import withLoader from "../../pages/Common/HOC/withLoader";
import { userRoles, getMinistries } from "../../redux/reducer/userReducer";
import { getPendingStaffInvite } from "../../redux/reducer/adminReducer";

const StaffDrawer = ({ openModal, toggleDrawer }) => {
  const [value, setValue] = useState(0);
  const [showDropdownEllipsis, setShowDropdownEllipsis] = useState(false);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onRenderError = (error) => <ErrorContainer>{error && error.message}</ErrorContainer>;

  const renderButton = ({ TextLabel, handleClick, cancelText = "Cancel", hideSaveBtn = false }) => (
    <ButtonContainer>
      {/* <StaffCancelButton onClick={() => toggleDrawer()}>{cancelText}</StaffCancelButton> */}
      {!hideSaveBtn && (
        <SaveButton type="submit" onClick={handleClick}>
          {TextLabel}
        </SaveButton>
      )}
    </ButtonContainer>
  );

  const renderTabContent = {
    0: withLoader(AddNewStaff)({
      onRenderError,
      apis: [userRoles(), getMinistries()],
      conditioners: ["userRoles", "ministries"],
      stateName: "user",
      handleCancel: () => toggleDrawer(),
    }),
    1: withLoader(PendingInvite)({
      apis: [getPendingStaffInvite({ PageNumber: 1, PageSize: 10 })],
      conditioners: ["pendingStaff"],
      stateName: "admin",
      RenderButton: renderButton,
      onRenderError,
      showDropdownEllipsis,
      setShowDropdownEllipsis,
      handleCancel: () => toggleDrawer(),
    }),
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={openModal}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <StaffDrawerWrapper style={{ background: "transparent" }}>
          <StaffDrawerHeader>
            <StaffFormTitle> Add new staff</StaffFormTitle>
            <IconButton onClick={() => toggleDrawer()}>
              <CloseIcon />
            </IconButton>
          </StaffDrawerHeader>

          <Tabs value={value} onChange={handleChange}>
            <Tab label="Single" />
            <Tab label="Pending Invites" />
          </Tabs>
          <TabDivider />
          {renderTabContent[value]}
        </StaffDrawerWrapper>
      </Drawer>
    </>
  );
};

const useStyles = makeStyles({
  drawerPaper: {
    zIndex: 10,
  },
});

export default StaffDrawer;
