import React, { useState } from "react";
import { Box, Button } from "@material-ui/core";
import SecondaryTable from "../../../Homepage/ContractDetail/SecondaryTable";
import AddBidderModal from "./components/AddBidderModal";

const BiddersTab = ({ data: { bidders = [], referenceNumber } = {} }) => {
  const [open, toggleModal] = useState(false);

  const secondaryTableHeader = [
    { id: "s/n", title: "#", width: 50, align: "alignCenter", indexed: true },
    { id: "companyName", title: "Company Name", grid: 5 },
    { id: "amount", title: "Bid Amount", grid: 5 },
    // { id: "isRecommended", title: "Evaluation Status", grid: 5 },
  ];

  const tableBody = bidders?.map((bidder) => ({
    companyName: bidder.companyName,
    // evaluationComment: bidder.comment,
    amount:
      new Intl.NumberFormat("en-NG", {
        style: "currency",
        currency: "NGN",
      }).format(bidder.amount) || "Not Applicable",
    // isRecommended: bidder.isRecommended ? "Recommended" : "Not Recommended",
    id: bidder.id,
  }));

  return (
    <>
      <Box style={{ background: "transparent", padding: 15 }}>
        <Button variant="contained" color="primary" onClick={() => toggleModal(true)}>
          Add Bidder
        </Button>
        <AddBidderModal referenceNumber={referenceNumber} open={open} toggleModal={toggleModal} />
      </Box>
      <Box style={{ overflowX: "scroll" }}>
        <SecondaryTable tableBody={tableBody} tableHeader={secondaryTableHeader} />
      </Box>
    </>
  );
};

export default BiddersTab;
