import React, { useMemo } from "react";
import { Box } from "@material-ui/core";
import { GeneralTitlePage } from "../../SpecificProcurementNotice/style";
import Breadcrumb from "../../../../../Common/PageElements/Breadcrumb";
import TopStatisticsBar from "../../../../../../components/TopStatisticsBar";
import { useParams } from "react-router-dom";
import { ResponseStatsLabel, ResponseStatsValue } from "../style";
import { FilesGrid, ProgressBar, SectionDescription, SectionTitle, SectionWrapper, StatusIcon } from "./style";
import Paper from "@material-ui/core/Paper";
import FileProgress from "../../../../../../components/FileProgress/FileProgress";
import { ReactComponent as GreenCheck } from "../../../../../../assets/green-check.svg";
import { ReactComponent as GreyCheck } from "../../../../../../assets/grey-check.svg";
import { useQuery } from "react-query";
import receiptOfResponseApi from "../../../../../../redux/api/receiptOfResponseApi";
import Loader from "../../../../../../components/Common/Loader";
import Skeleton from "@material-ui/lab/Skeleton";
import EvaluationStatisticBar from "./EvaluationStatisticBar";
import { ProcurementMethodAccessControl } from "../../../../../../shared";
import { useProcurementMethod } from "../../../../../../hooks/queries/useProcurement";
import { Procurement_Method } from "../../../../../../util/constants";

const SpecificVendor = () => {
  const { procurementId, vendorId, activityId, vendorName } = useParams();

  const { method, isSuccess } = useProcurementMethod({ procurementId });

  const baseUrl = `/admin/procurement/activity/${
    method === Procurement_Method.DIRECT_PROCUREMENT ? `receiptofresponse` : `receiptofresponses`
  }/${activityId}/${procurementId}`;

  const specificVendor = useQuery({
    queryKey: [
      "specificVendor",
      {
        procurementId,
        vendorId,
      },
    ],
    queryFn: receiptOfResponseApi.getSpecificVendor,
  });

  const breadcrumbLinks = [
    {
      url: "/admin/procurement/plans",
      title: "Home",
      forwardSlash: true,
    },
    {
      url: `/admin/procurement/plan-details/${procurementId}`,
      title: "Activities List",
    },
    {
      url: baseUrl,
      title: "Receipt of Response",
    },
    {
      url: "#",
      title: "Specific Vendor",
    },
  ];

  const renderLeftContent = () => {
    const vendorStats = specificVendor.data.projectSummary || {};

    return (
      <>
        <ResponseStatsLabel color="#5F7483">Past Project</ResponseStatsLabel>
        <ResponseStatsValue>{vendorStats.pastProjects || 0}</ResponseStatsValue>
      </>
    );
  };

  const renderCenterContent = () => {
    const vendorStats = specificVendor.data.projectSummary || {};

    return (
      <>
        <Box mr={6}>
          <ResponseStatsLabel color="#FF8D26">Pending Projects</ResponseStatsLabel>
          <ResponseStatsValue>{vendorStats.pending || 0}</ResponseStatsValue>
        </Box>
        <Box mr="auto">
          <ResponseStatsLabel color="#219653">Completed</ResponseStatsLabel>
          <ResponseStatsValue>{vendorStats.completed || 0}</ResponseStatsValue>
        </Box>
        {/* <AccessControl allowedRoles={allowedRoles}>
          <TopButtons>
            <Box mr={2.5}>
              <LoadingButton
                color='#219653'
                onClick={() => approve([vendorId])}
                loading={approveIsLoading}
              >
                Approve
              </LoadingButton>
            </Box>
            <LoadingButton
              color='#EB5757'
              onClick={() => reject([vendorId])}
              loading={rejectIsLoading}
            >
              Reject
            </LoadingButton>
          </TopButtons>
        </AccessControl> */}
      </>
    );
  };

  const vendor = useMemo(() => {
    const baseVendor = {};

    if (specificVendor.isSuccess) {
      baseVendor.uploadedDocuments = specificVendor.data.documentUpload;
      baseVendor.totalDocuments = specificVendor.data.totalRequiredDocuments;
      baseVendor.requirements = specificVendor.data.requirements;
    }

    return baseVendor;
  }, [specificVendor.isSuccess, specificVendor.data]);

  const openFile = (url) => {
    window.open(url, "blank");
  };

  const percentageCompleted = useMemo(() => {
    if (specificVendor.isSuccess) {
      const percentage = (vendor.uploadedDocuments / vendor.totalDocuments) * 100;
      return isNaN(percentage) ? 0 : percentage;
    } else {
      return 0;
    }
  }, [specificVendor.isSuccess, vendor]);

  return (
    <>
      <Box pb={10}>
        <Box mb={4} mt={2}>
          {specificVendor.isLoading ? (
            <Skeleton height="52px" width="300px" />
          ) : (
            <GeneralTitlePage>{vendorName}</GeneralTitlePage>
          )}
          <Breadcrumb values={breadcrumbLinks} />
        </Box>
        {specificVendor.isLoading ? (
          <Loader feedback="Fetching vendor details" />
        ) : (
          <>
            <ProcurementMethodAccessControl
              method={method}
              allowedMethod={Object.values(Procurement_Method).filter((value) => value !== Procurement_Method.DIRECT_PROCUREMENT)}
              isSuccess={isSuccess}
            >
              <TopStatisticsBar
                status={specificVendor.data.projectSummary.pending > 0 ? "pending" : "approved"}
                leftContent={renderLeftContent()}
                centerContent={renderCenterContent()}
              />
            <EvaluationStatisticBar />
            </ProcurementMethodAccessControl>
            <Paper elevation={2}>
              <Box px={2.5} pt={2.5} pb={3.75}>
                <SectionTitle>Requirements</SectionTitle>
                <SectionDescription>Below are the list of all required documents</SectionDescription>
                <Paper elevation={2}>
                  <Box px={2.5} pt={2.5} pb={5}>
                    <SectionDescription>
                      You have uploaded {vendor.uploadedDocuments} of {vendor.totalDocuments} documents
                    </SectionDescription>
                    <ProgressBar value={percentageCompleted} variant="determinate" />
                  </Box>
                </Paper>
              </Box>
              <Box pb={0.5}>
                {vendor.requirements?.map(({ id, title, description, requiredDocuments }) => {
                  return (
                    <SectionWrapper key={id}>
                      <SectionTitle>{title}</SectionTitle>
                      <SectionDescription>{description}</SectionDescription>
                      <FilesGrid>
                        {requiredDocuments.map((file) => (
                          <FileProgress
                            fileName={file.file.original_filename}
                            fileSize={file.file.bytes}
                            progress={100}
                            onClickEyes={() => openFile(file.file.url)}
                            hasEyes
                          />
                        ))}
                      </FilesGrid>
                      <StatusIcon>
                        {/*TODO: Green check*/}
                        {!!requiredDocuments.length ? <GreenCheck /> : <GreyCheck />}
                      </StatusIcon>
                    </SectionWrapper>
                  );
                })}
              </Box>
            </Paper>
          </>
        )}
      </Box>
    </>
  );
};

export default SpecificVendor;
