import styled from "styled-components";
import { theme, FormTitle } from "../../../../../util/theme";
import { withStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";

export const NameInitials = styled.span`
  border-radius: 50%;
  background: ${theme.color.success};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  text-transform: uppercase;
  font-size: 14px;
  color: ${theme.color.white};
  margin-right: 10px;
`;

export const CertificateOfNoObjectionName = styled.span`
  display: flex;
  align-items: center;
  p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    span:first-child {
      font-size: 14px;
      color: #1c2125;
    }
    span:last-child {
      font-size: 12px;
      color: #5f7483;
    }
  }
`;

export const CertificateOfNoObjectionWidgetLeft = styled.div`
  p:first-child {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #5f7483;
  }
  p:last-child {
    font-weight: 600;
    font-size: 26px;
    line-height: 35px;
    color: #1e2b37;
  }
`;

export const CertificateOfNoObjectionWidgetMiddle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  > div:first-child {
    flex: 1;
    p:first-child {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #219653;
    }
    p:last-child {
      font-weight: 600;
      font-size: 18px;
      line-height: 35px;
      color: #1e2b37;
    }
  }
  > div:nth-child(2) {
    flex: 1;
    p:first-child {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #eb5757;
    }
    p:last-child {
      font-weight: 600;
      font-size: 18px;
      line-height: 35px;
      color: #1e2b37;
    }
  }
  > div:last-child {
    flex: 2;
    width: 100%;
    margin-left: auto;
    display: flex;
    align-items: flex-end;
  }
`;

export const DrawerWrapper = styled.div`
  width: 609px;
  padding: 0 1rem;


  .MuiTab-root {
    min-width: 80px;
    text-transform: none;
  }

  .MuiTabs-root {
    padding: 0 1rem;
  }

  .MuiTabs-indicator {
    background-color: ${theme.color.primary};
  }

  .MuiTabs-Fixed {
    padding: 0 7px !important;
  }
`;

export const DrawerFormTitle = styled(FormTitle)`
  font-size: 24px !important;
  font-weight: bold !important;
  padding-top: 25.5px !important;
  padding-left: 25.5px !important;
`;


export const InputFieldBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalBody = styled.div`
  position: absolute;
  top: 140px;
  left: calc(50% - 200px);
  width: 100%;
  max-width: 400px;
  min-height: 200px;
  border: 1px solid #EBEBEB;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  padding: 30px;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

export const ModalTitle = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #3F5673;
  margin-bottom: 30px;
`;

export const ModalDescription = styled.p`
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #3F5673;
  margin-bottom: 20px;
`;

export const ModalButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.7fr;
  grid-column-gap: 10px;
  margin-top: auto;
`;

export const InstructionHeader = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
  color: #1e2b37;
  font-weight: bold;
`;

export const InstructionDescription = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #637381;
  margin-bottom: 15px;
`;

export const UploadButton = withStyles({
  root: {
    backgroundColor: "#2AA96D",
    color: "white",
    textTransform: "capitalize",
  },
})(Button);

export const PageContainer = styled.div`
  margin-top: '2rem';
`;

export const BreadCrumbWrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;

  a {
    height: 16px;
    width: 197px;
    color: #2aa96d;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: none;
  }

  a:visited {
    color: #2aa96d;
  }
`;

export const CertificateContainer = styled.div`
  background-color: #fff;
  padding: 3rem;
  position: relative;
`;

export const PageTitle = styled.h1`
  color: #1e2b37;
  font-size: 28px;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 0;
  margin-top: 2rem;
  font-weight: normal;
`;


export const ContentWrapper = styled.div`
  padding: 15px 0 80px 0;

  .MuiTableHead-root {
    background-color: white;
    padding: 20px 0 !important;
  }

  .MuiTableCell-head {
    font-weight: bold;
    font-size: 16px;
  }
`;

export const WaterMark1 = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
  `;

export const WaterMark2 = styled.div`
    position: absolute;
    top: 30%;
    left: 30%;
`;

export const WaterMark3 = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

export const CertificateTitle = styled.p`
    font-size: 14px;
    color: #1E0A3C;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
    margin-bottom: 1rem;
`;

export const RedTitle = styled.p`
    font-size: 14px;
    color: #DA1414;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    margin-bottom: 1rem;
`;

export const GreenTitle = styled.p`
 font-size: 18px;
 color: #118D54;
 font-weight: 700;
 text-align: center;
 margin-bottom: 1rem;
`;

export const CertificateBody = styled.p`
 font-size: 14px;
 color: #1E0A3C;
 font-weight: 400;
 margin-bottom: 1rem;
 line-height: 20px;
`;

export const CertificateItemTitle = styled.p`
 font-size: 14px;
 color: #6B6C7E;
 font-weight: 400;
`;

export const CertificateItemAnswer = styled.p`
 font-size: 14px;
 color: #393A4A;
 font-weight: 700;
 text-transform: capitalize;
`;

export const CertificateItemsBox = styled.div`
    display: flex;
    margin-bottom: 1rem;
`;

export const CertificateTitleBox = styled.div`
    width: 40%;
 `;

export const CertificateAnswerBox = styled.div`
    width: 50%;
`;

export const CertificateItemsTitle = styled.p`
 font-size: 14px;
 color: #1E0A3C;
 font-weight: 700;
 line-height: 18px;
 margin-bottom: 0.8rem;
`;

export const LogoDiv = styled.div`
 /* text-align: center; */
 margin-bottom: 1rem;
 display: flex;
 justify-content: space-between;
`;

export const CertNoTitle = styled.p`
 font-size: 12px;
 color: #6B6C7E;
 font-weight: 500;
 margin-bottom: 0.8rem;
 text-align: center;
`;

export const CertNoAnswer = styled.span`
 font-size: 12px;
 color: #6B6C7E;
 font-weight: 400;
 margin-bottom: 0.8rem;
 font-style: italic;
 text-align: center;
 margin-left: 0.4rem;
`;

export const SignatureBox = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 10rem;
`;

export const QRCodeStyle = styled.div`
   
`;

export const SignatureContainer = styled.div`
   /* margin-top: 1rem; */
`;

export const SignatureTitle = styled.p`
    color: #6B6C7E;
    font-size: 12px;
    font-weight: 700;
`;

export const UpdateButton = styled(Button)`
    && {
        background-color:  ${theme.color.primary};
    border-radius: 8px;
    color: white;
    &:hover {
      background-color:  ${theme.color.primary};
      opacity: 0.8;
    }
    &:disabled {
      background-color: ${theme.color.primary};
      cursor: not-allowed !important;
      opacity: 0.5;
      color: white;
    }
  }
`;

export const CertName = styled.p`
 font-size: 12px;
 color: #1E0A3C;
 font-weight: 700;
 text-transform: capitalize;
`;



export const VerifyWatermark1 = styled.div`
  position: absolute;
  top: 21%;
  right: 0
`;

export const VerifyWatermark2 = styled.div`
  position: absolute;
  bottom: 0;
  left: 0
`;

export const VerifyDate = styled.div`
  position: absolute;
  top: 6%;
  right: 0
`;




export const Title = styled.p`
  font-size: ${(props) => (props.fontSize ?? 14)};
  color: ${(props) => (props.color ?? '#393A4A')};
  font-weight: ${(props) => (props.fontWeight ?? 400)};
  line-height: 21px;
  text-align: center;
`;

export const StyledTableTitle = styled.p`
  font-weight: ${(props) => (props.fontWeight ?? 400)};
  font-size: ${(props) => (props.fontSize ?? "12px")};
  line-height: 150%;
  text-transform: capitalize;
  color: ${(props) => (props.color ?? theme.color.textSecondary)};
`;


export const DLMFormTitle = styled(FormTitle)`
  font-size: 24px !important;
  font-weight: bold !important;
  padding-top: 25.5px !important;
  padding-left: 8px !important;
`;
