import baseApiMethod from "./baseApiMethod";
import baseAxiosMethod from "./baseAxiosMethod";
import { handleAxiosError } from "./handleApiError";

const usersAPI = {};

usersAPI.signup = (user) => baseApiMethod(user, "vendors/signup", "post");

usersAPI.login = (user) => baseApiMethod(user, "users/login", "post");

usersAPI.resendInvite = (email) => baseApiMethod(email, "users/resendInvitation", "post");

usersAPI.confirmEmail = (user) => baseApiMethod(user, "users/confirmEmail", "post");

usersAPI.getUserRoles = () => baseApiMethod(null, "roles", "get");

usersAPI.getMinistries = () => baseApiMethod(null, "ministries", "get");

usersAPI.postMinistries = (data) => baseApiMethod(data, "ministries", "post");

usersAPI.forgotPassword = (email) => baseApiMethod(email, "users/forgotPassword", "post");

usersAPI.resetPassword = (data) => baseApiMethod(data, "users/resetPassword", "post");

usersAPI.getUserByToken = () => baseApiMethod(null, "users/token", "get");

usersAPI.getStaffProfile = async function (key, { userId }) {
  try {
    const response = await baseAxiosMethod(`staffs/${userId}/profile`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

usersAPI.getStaffProfile = async function (key, { userId }) {
  try {
    const response = await baseAxiosMethod(`staffs/${userId}/profile`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

usersAPI.updateStaffProfile = async function ({ data, userId }) {
  try {
    const response = await baseAxiosMethod.put(`staffs/${userId}/profile`, data);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

usersAPI.getVendorProfile = async function (key, { userId }) {
  try {
    const response = await baseAxiosMethod(`vendors/${userId}/profile`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

usersAPI.updateVendorProfile = async function ({ data, userId }) {
  try {
    const response = await baseAxiosMethod.put(`vendors/${userId}/profile`, data);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

usersAPI.uploadProfileImage = async function ({ data, userId }) {
  try {
    const response = await baseAxiosMethod.post(`users/${userId}/profilePicture`, data);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

usersAPI.getUser = async function () {
  try {
    const response = await baseAxiosMethod("users/token");
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

usersAPI.updateUser = async function (key, user) {
  try {
    const response = await baseAxiosMethod.patch(`users/${user.userId}`, user.data);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

usersAPI.setupStaffProfile = async (data) => {
  try {
    const response = await baseAxiosMethod.post("staffs/register", data);
    return response.data.data;
  } catch (e) {
    throw handleAxiosError(e);
  }
};

usersAPI.getCertificateDetails = async ({ procurementId, type}) => {
  try {
    const { data } = await baseAxiosMethod.get(`procurements/${procurementId}/verify-cno?type=${type}`)
    return data?.data || data
  } catch (err) {
    throw new Error(handleAxiosError(err))
  }
}

export default usersAPI;
