import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeletesModal from "../../Admin/OCDS/EditTabs/components/delete";
import { ContractDetailTabText, ContractRemoveText } from "./style";
import EmptyTableBody from "../../../components/EmptyTableBody";
import { EmptyTableSubTitle } from "../../../components/EmptyTableBody/style";
import contractsAPI from "../../../redux/api/contractAPI";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { queryCache, useMutation } from "react-query";

const useStyles = makeStyles({
  alignCenter: {
    textAlign: "center",
  },
});

const SecondaryTable = ({ tableBody, tableHeader, noDataText, isPublic }) => {
  const classes = useStyles();
  const [isOpen, setModal] = useState(false);
  const params = useParams();
  const [bidder, setBidder] = useState(null);
  const [deleteBidder, { isLoading }] = useMutation(contractsAPI.deleteBidder, {
    onSuccess: () => {
      setModal(false);
      queryCache.invalidateQueries("contractOCDS");
    },
  });
  const onBidderDelete = (detail) => () => {
    setModal(true);
    setBidder(detail);
  };

  const onClickDelete = () => {
    deleteBidder({ contractId: params.id, id: bidder.id });
  };

  return (
    <>
      <DeletesModal isLoading={isLoading} bidder={bidder} onClickDelete={onClickDelete} open={isOpen} setModal={setModal} />
      <Grid direction="row" style={{ borderBottom: "1px solid #CBD4DA", width: "100%", backgroundColor: "#2AA96D" }} container>
        {tableHeader.map((detail, i) => (
          <Grid
            key={i}
            className={classes[detail.align]}
            item
            xs={detail.grid}
            style={{ backgroundColor: detail.color, width: detail.width, borderLeft: "1px solid #CBD4DA" }}
          >
            <ContractDetailTabText style={{ color: "#fff", paddingTop: 10 }}>{detail.title}</ContractDetailTabText>
          </Grid>
        ))}
      </Grid>
      {tableBody.map((el, i) => (
        <Grid key={i} direction="row" style={{ borderBottom: "1px solid #CBD4DA" }} container>
          {tableHeader.map((detail) => (
            <Grid
              key={detail.id + i}
              className={classes[detail.align]}
              item
              xs={detail.grid}
              style={{ width: detail.width, borderLeft: "1px solid #CBD4DA", display: "flex", justifyContent: "space-between" }}
            >
              <ContractDetailTabText>{detail.indexed ? i + 1 : el[detail.id]}</ContractDetailTabText>
              {!isPublic && detail.id === "amount" && (
                <ContractRemoveText style={{ cursor: "pointer" }} onClick={onBidderDelete(el)}>
                  Remove
                </ContractRemoveText>
              )}
            </Grid>
          ))}
        </Grid>
      ))}
      {tableBody.length === 0 && (
        <EmptyTableBody width={"60%"}>
          <EmptyTableSubTitle>{noDataText || "No data yet"}</EmptyTableSubTitle>
        </EmptyTableBody>
      )}
    </>
  );
};

export default SecondaryTable;
