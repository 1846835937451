import Drawer from "@material-ui/core/Drawer";

import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, queryCache } from "react-query";
import { useForm } from "react-hook-form";
import { InputField } from "../../../../../components";
import { Roles } from "../../../../../components/AccessControl/UserRoles";
import { ErrorContainer } from "../../../../../components/Forms/Common/style";
import { useRoles } from "../../../../../hooks/useUserDetails";
import ToastContext from "../../../../../util/toastContext";
import { BtnBox, CancelButton } from "../../../Administration/Settings/style";
import { DrawerWrapper, InputFieldBox, DLMFormTitle } from "./style";
import { ReactComponent as CancelIcon } from "../../../../../assets/cancel.svg";
import { useHistory, useParams } from "react-router-dom";
import procurementAPI from "../../../../../redux/api/procurementAPI";
import LoadingButton from "../../../../../components/LoadingButton/LoadingButton";
import NumberFormat from "react-number-format";

function FormDrawer({ openDrawer, toggleDrawer }) {
  const { register, handleSubmit, errors, reset} = useForm();
  const onRenderError = (error) => <ErrorContainer>{error && error.message}</ErrorContainer>;
  const { showAlert } = useContext(ToastContext);
  const history = useHistory();
  const { activityId, procurementId } = useParams();
  const [, setIsEdit] = useState(false);
  const [reviewedQuotation, setReviewedQuotation] = React.useState(0);
  const [costReduction, setCostReduction] = React.useState(0);

  //Access control config
  const { role, isSuccess: getUserRolesSuccess } = useRoles();
  const isAdmin = role === Roles.KWPPA;
  const isProcurementOfficer = role === Roles.PROCUREMENT_OFFICER;

  // console.log({ watch: watch() });
  const { data: certificateData } = useQuery(["getCNOForPaymentData", procurementId], procurementAPI.getCNOForPaymentData, {
    enabled: true,
    onError: (error) => {
      showAlert({
        message: error.message,
        severity: "error",
      });
    },
    onSuccess: (data) => {
      if (data.data.message) {
        setIsEdit(true);
      }
    },
  });
  const [updateCertificateMutation, { isLoading: updatingPreviewCert }] = useMutation(
    ({ data, procurementId }) => procurementAPI.updateCNOForPaymentData({ data, procurementId }),
    {
      onSuccess: () => {
        showAlert({
          message: "Certificate data added successfully",
          severity: "success",
        });
        toggleDrawer();
        queryCache.invalidateQueries("activityDetails");
        history.push(`/admin/procurement/activity/certificateofnoobjectionforpayment/${activityId}/${procurementId}/certificate`);
      },
      onError: (error) => {
        showAlert({
          message: error.message,
          severity: "error",
        });
      },
      onSettled() {
        queryCache.invalidateQueries("getCertificateData");
      },
    }
  );
  // console.log({ cno: certificateData?.data });

  const [postCertificateMutation, { isLoading: previewingCert }] = useMutation(procurementAPI.postCNOForPaymentData, {
    onSuccess: () => {
      showAlert({
        message: "Certificate data added successfully",
        severity: "success",
      });
      toggleDrawer();
      queryCache.invalidateQueries("activityDetails");
      history.push(`/admin/procurement/activity/certificateofnoobjection/${activityId}/${procurementId}/certificate`);
    },
    onError: (error) => {
      showAlert({
        message: error.message,
        severity: "error",
      });
    },
    onSettled() {
      queryCache.invalidateQueries("getCertificateData");
    },
  });

  const onSubmit = (values) => {
    const data = {
      headCode: values.headCode,
      subHeadCode: values.subHeadCode,
      appropriationCode: values.appropriationCode,
      reviewCost: parseInt(reviewedQuotation),
      costReduction: parseInt(costReduction),
    };
    if (certificateData?.data?.headCode) {
      // console.log("update");
      updateCertificateMutation({ data, procurementId });
    } else {
      // console.log("create");
      postCertificateMutation({ data, activityId });
    }
  };

  // const redirectToCert = () => {
  //   history.push(`/admin/procurement/activity/certificateofnoobjection/${activityId}/${procurementId}/certificate`)
  // }

  useEffect(() => {
    reset({
      approvedSum: certificateData?.data?.quotation,
      headCode: certificateData?.data?.headCode,
      subHeadCode: certificateData?.data?.subHeadCode,
      appropriationCode: certificateData?.data?.appropriationCode,
      reviewCost: certificateData?.data?.reviewedQuotation,
      costReduction: certificateData?.data?.costReduction,
    });
    setReviewedQuotation(!Number.isNaN(certificateData?.data?.reviewedQuotation) ? certificateData?.data?.reviewedQuotation : 0);
  }, [reset, certificateData]);

  useEffect(() => {
    if (!getUserRolesSuccess) {
      return showAlert({
        severity: "error",
        message: "Network Error. Kindly Refresh",
      });
    }
  }, [getUserRolesSuccess, showAlert]);

  useEffect(() => {
    const newCostReduction = certificateData?.data?.quotation - reviewedQuotation;
    if (reviewedQuotation === 0 || reviewedQuotation === "") {
      setCostReduction(0);
      return;
    }
    // console.log({ newCostReduction });
    if (newCostReduction < 0) {
      showAlert({
        message: "Review cost cannot be larger than approved sum",
        severity: "error",
        duration: 2000,
      });
      setReviewedQuotation(0);
    } else {
      setCostReduction(newCostReduction);
    }
    // eslint-disable-next-line
  }, [reviewedQuotation]);

  return (
    <div>
      <Drawer anchor="right" open={openDrawer}>
        <DrawerWrapper>
          <div
            style={{
              width: "20px",
              height: "20px",
              display: "flex",
              float: "right",
              marginTop: "1rem",
              cursor: "pointer",
            }}
            onClick={toggleDrawer}
          >
            <CancelIcon />
          </div>
          <DLMFormTitle>Certificate of No Objection for Payment</DLMFormTitle>

          <div style={{ background: "#ffffff" }}>
            <InputField
              label="Contract Ref. No."
              style={{ background: "#ffffff" }}
              value={certificateData?.data?.packageNumber}
              disabled
            />
          </div>
          <div style={{ background: "#ffffff" }}>
            <InputField
              label="Project Description"
              style={{ background: "#ffffff" }}
              value={certificateData?.data?.projectDescription}
              disabled
            />
          </div>
          <div style={{ background: "#ffffff" }}>
            <NumberFormat
              customInput={InputField}
              name="budget"
              label="Proposed Cost"
              thousandSeparator
              prefix="₦"
              variant="outlined"
              value={certificateData?.data?.quotation}
              disabled
            />
          </div>
          <div style={{ background: "#ffffff" }}>
            <NumberFormat
              customInput={InputField}
              name="approvedSum"
              label="Approved Sum"
              thousandSeparator
              prefix="₦"
              variant="outlined"
              value={certificateData?.data?.quotation}
              disabled
            />
            {onRenderError(errors.approvedSum)}
          </div>
          <div style={{ background: "#ffffff" }}>
            <NumberFormat
              customInput={InputField}
              label="PPA Review Cost"
              name="reviewCost"
              thousandSeparator
              prefix="₦"
              variant="outlined"
              onValueChange={(values) => {
                // eslint-disable-next-line
                const { _, value } = values;
                setReviewedQuotation(value);
              }}
              disabled={isProcurementOfficer}
              value={reviewedQuotation}
              style={{ background: "#ffffff" }}
              inputRef={register({
                required: "Enter review cost",
              })}
            />
            {onRenderError(errors.ppaReviewCost)}
          </div>
          <div style={{ background: "#ffffff" }}>
            <NumberFormat
              customInput={InputField}
              label="Cost Reduction"
              name="costReduction"
              thousandSeparator
              prefix="₦"
              variant="outlined"
              value={costReduction}
              style={{ background: "#ffffff" }}
              disabled
            />
            {onRenderError(errors.costReduction)}
          </div>
          <div style={{ background: "#ffffff" }}>
            <InputField
              label="Client Ministry/ Department/ Agency"
              style={{ background: "#ffffff" }}
              disabled
              value={certificateData?.data?.ministry}
            />
          </div>

          <InputFieldBox>
            <div style={{ background: "#ffffff" }}>
              <InputField
                label="Head Code	"
                name="headCode"
                style={{ background: "#ffffff" }}
                inputRef={register({
                  required: "Enter Head Code",
                })}
              />
              {onRenderError(errors.headCode)}
            </div>
            <div style={{ background: "#ffffff" }}>
              <InputField
                label="Sub-Head Code"
                name="subHeadCode"
                style={{ background: "#ffffff" }}
                inputRef={register({
                  required: "Enter Sub-head code",
                })}
              />
              {onRenderError(errors.subHeadCode)}
            </div>
            <div style={{ background: "#ffffff" }}>
              <InputField
                label="Appropriation Code"
                name="appropriationCode"
                style={{ background: "#ffffff" }}
                inputRef={register({
                  required: "Enter Appropriation Code",
                })}
              />
              {onRenderError(errors.appropriationCode)}
            </div>
          </InputFieldBox>
          {isAdmin && (
            <BtnBox>
              <CancelButton onClick={toggleDrawer}>Cancel</CancelButton>
              <LoadingButton
                loading={previewingCert || updatingPreviewCert}
                style={{ marginLeft: "1rem" }}
                onClick={handleSubmit(onSubmit)}
              >
                Preview
              </LoadingButton>
            </BtnBox>
          )}
        </DrawerWrapper>
      </Drawer>
    </div>
  );
}

export default FormDrawer;
