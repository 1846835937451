import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";
import { Grid, Box, Divider, Typography, Collapse } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "react-query";

import { StyledRadioLabel } from "./style";
import {
  getVendorRegistrationPlan,
  getGeneralCompanyDetails,
} from "../../../redux/reducer/vendorReducer";
import { formatNumber, separateNumByComma } from "../../../util/truncateValue";
import withVendorRegLoader from "../../../pages/Common/HOC/withVendorRegLoader";
import ToastContext from "../../../util/toastContext";
import vendorsAPI from "../../../redux/api/vendorsAPI";
import useAlert from "../../../hooks/useAlert";
import { LandingNavbarStatic } from "../../HomepageHeader/style";
import { Navbar } from "../..";
import { Wrapper, CloseButton, NavMargin } from "./style";
import UserProfileTab from "../../AdminDashboardNav/UserProfileTab";
import usersAPI from "../../../redux/api/usersAPI";
import { SaveButton } from "../../NavigationButtons/style";
import { theme } from "../../../util/theme";
import paymentSuccessfulImg from "../../../assets/payment_successful.png";
import ConfirmVerificationModal from "../ConfirmVerificationModal";

const RegistrationCategoryPayment = () => {
  const history = useHistory();
  const { showAlert, Toast } = useAlert();
  const { user: { UserId } } = useSelector((state) => state.user);
  const { search } = useLocation();
  const paymentStatus = new URLSearchParams(search).get("paymentStatus");
  const isPaymentSuccessful = paymentStatus === "success";

  return (
    <>
      <ToastContext.Provider value={{ showAlert: showAlert, Toast: Toast }}>

        <LandingNavbarStatic>
          <Navbar
            rightContent={
              <>
                <NavMargin>
                  <UserProfileTab />
                </NavMargin>
                <CloseButton
                  variant="outlined"
                  color="default"
                  onClick={() => {
                    history.push("/vendor/overview");
                  }}
                >
                  Back to dashboard
                </CloseButton>
              </>
            }
          />
        </LandingNavbarStatic>
        <Container maxWidth="sm">
          {withVendorRegLoader(Form)({
            title: !isPaymentSuccessful && <div style={{ textAlign: "center", marginTop: 20 }}>Payment</div>,
            description: !isPaymentSuccessful && "Ensure the information on this page are correct before clicking the “Pay” button.",
            apis: [getVendorRegistrationPlan(UserId), getGeneralCompanyDetails(UserId)],
            userId: UserId,
            conditioners: ["selectedRegPlan", "companyDetails"],
            isPaymentSuccessful
          })}
        </Container>

      </ToastContext.Provider>
    </>
  );
};

const Form = ({ dispatch, userId, isPaymentSuccessful }) => {
  const { selectedRegPlan, companyDetails} = useSelector((state) => state.vendor);
  //setState of companyDetails
  const [value, setValue] = useState("");
  // eslint-disable-next-line
  const [_, setLoading] = useState(false);
  const { showAlert, Toast } = useAlert();

  const { data: vendorProfileData} = useQuery(
    ["getVendorProfile", { userId }],
    usersAPI.getVendorProfile,
    {
      enabled: !!userId,
      onError: (error) => {
        showAlert({
          message: error.message,
          severity: "error",
        });
      },
    }
  );

  const { data: registrationPlans } = useQuery(
    [
      "registrationPlans",
      {
        coreCompetency: companyDetails?.coreCompetency,
      },
    ],
    vendorsAPI.getVendorRegistrationPlans,
    { enabled: !!companyDetails?.coreCompetency }
  );


  useEffect(() => {
    if (Object.keys(selectedRegPlan).length) setValue(selectedRegPlan.id);
  }, [selectedRegPlan]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  // const onSubmit = (cb) => () => {
  //   if (selectedRegPlan.id === value) return cb();
  //   setLoading(true);

  //   dispatch(postVendorRegistrationPlan({ registrationPlanId: value, userId })).then((res) => {
  //     if (!res.error) {
  //       setLoading(false);
  //       showAlert({ severity: "success", message: "Registration Plan Saved" });
  //       return cb();
  //     }
  //     showAlert({ severity: "error", message: res.payload });
  //   });
  // };

  return (
    <>

      <Container>
        <Wrapper style={{ marginBottom: 60 }}>
          <Toast customStyles={{ zIndex: "9999" }} float />
          <CustomRadios
            vendorProfileData={vendorProfileData}
            registrationPlans={registrationPlans || []}
            handleChange={handleChange}
            value={value}
            isPaymentSuccessful={isPaymentSuccessful}
          />
        </Wrapper>
      </Container>
    </>
  );
};

const CustomRadios = ({
  registrationPlans,
  vendorProfileData,
  isPaymentSuccessful
}) => {
  const history = useHistory();
  const vendorGrade = vendorProfileData?.grade;
  const { search } = useLocation();
  const newGradeId = new URLSearchParams(search).get("newGradeId");
  const { vendorGradeStyle, duePayment } = useStyles();
  const [openPaymentModal, setOpenPaymentModal] = useState(false);

  console.log("hgjhgkjhcgjghc ", isPaymentSuccessful);

  const formatedVendorGrade = !!vendorGrade && vendorGrade.split(" ")[1].toLowerCase();
  const vendorCurrentGrade = registrationPlans.find(plan => {
    return plan.grade.split(" ")[1].toLowerCase() === formatedVendorGrade
  }) || {};
  const vendorSelectedGrade = registrationPlans.find(plan => {
    return plan.id === newGradeId
  }) || {};

  const titleStyle = {
    textAlign: "center",
    marginBottom: 8
  };
  const textStyle = {
    fontWeight: 800
  }

  const vendorSelctedGradeFee = !!vendorSelectedGrade?.fee ? vendorSelectedGrade.fee : 0;
  const vendorCurrentGradeFee = !!vendorCurrentGrade?.fee ? vendorCurrentGrade.fee : 0;

  const handleMakePayment = () => {
    setOpenPaymentModal(prevState => !prevState)
  };

  const handlePaymentSuccessful = () => {
    const params = new URLSearchParams();
    params.append("paymentStatus", "success");
    history.push({
      search: params
    });
  };

  const renderPaymentSummary = (title) => (
    <Box>
      <div style={titleStyle}>
        <p style={textStyle}>{title || "Due Payment"}</p>
      </div>
      <Box p={2}
        display="flex"
        style={{
          border: `1px solid ${theme.color.primary}`,
          borderRadius: "3px"
        }}>
        <Box className={vendorGradeStyle}>
          <h4 >{vendorSelectedGrade?.grade?.split(" ")[1]}</h4>
        </Box>
        <Box ml={2}>
          <Box className={duePayment}>
            <Typography component="p">{vendorSelectedGrade?.grade} (N{separateNumByComma(vendorSelctedGradeFee)}) - {vendorCurrentGrade?.grade} (N{separateNumByComma(vendorCurrentGradeFee)}):</Typography>
            <Typography component="h6">N{separateNumByComma(vendorSelctedGradeFee - vendorCurrentGradeFee)}</Typography>
          </Box>
          <Box className={duePayment}>
            <Typography component="p">Processing Fee:</Typography>
            <Typography component="h6">N0.00</Typography>
          </Box>
          <Divider style={{ marginTop: 8, marginBottom: 8 }} />
          <Box className={duePayment}>
            <Typography component="p">Total Payment:</Typography>
            <Typography component="h6">N{separateNumByComma(vendorSelctedGradeFee - vendorCurrentGradeFee)}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const renderValidity = () => (
    <>
      <div style={titleStyle}>
        <p style={textStyle}>Validity</p>
      </div>
      <Typography component="p" variant="body2" style={{ textAlign: "center" }}>Valid till December 31, {new Date().getFullYear()}</Typography>
      <Divider style={{ marginTop: 8, marginBottom: 8 }} />
    </>
  )

  return (
    <Box>
      <Collapse in={!isPaymentSuccessful}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <div style={titleStyle}>
              <p style={textStyle}>Current Category</p>
            </div>
            {!!vendorCurrentGrade.id && <CustomLabel {...vendorCurrentGrade} />}
          </Grid>
          <Grid item xs={6}>
            <div style={titleStyle}>
              <p style={textStyle}>New Category</p>
            </div>
            {!!vendorSelectedGrade.id && <CustomLabel {...vendorSelectedGrade} />}
          </Grid>
        </Grid>
        {renderPaymentSummary()}
        <Box mt={3}>
          {renderValidity()}
          <Box p={3} style={{ borderRadius: "3px", backgroundColor: "rgba(0, 176, 241, 0.0715144)" }}>
            <Typography component="p" variant="body2">
              Are you ready to make payment now? Click on the action button below to proceed.
            </Typography>
            <SaveButton
              style={{ marginLeft: 0, marginTop: 20 }}
              onClick={handleMakePayment}
            // disabled={disabled} 
            // onClick={handleNext} loading={loading || updateStageQuery?.isLoading} {...otherProps}
            >
              Make payment
            </SaveButton>
          </Box>
        </Box>
      </Collapse>
      <Collapse in={isPaymentSuccessful}>
        <Box mb={5}>
          <Box
            component="img"
            style={{ margin: "auto", display: "flex" }}
            src={paymentSuccessfulImg}
          />
        </Box>
        <Box mb={5}>
          <div style={titleStyle}>
            <p style={{ ...textStyle, fontSize: 24 }}>Payment Successful</p>
          </div>
        </Box>
        {renderPaymentSummary("Payment Made")}
        <Box mt={3}>
          {renderValidity()}
          <SaveButton
            style={{ marginLeft: 0, margin: "auto", marginTop: 20 }}
          // disabled={disabled} 
          // onClick={handleNext} loading={loading || updateStageQuery?.isLoading} {...otherProps}
          >
            Back to Home
          </SaveButton>
        </Box>
      </Collapse>
      <ConfirmVerificationModal
        isOpen={openPaymentModal}
        toggleModal={handleMakePayment}
        vendorProfileData={vendorProfileData || {}}
        variant={4}
        onPaymentSuccess={handlePaymentSuccessful}
        registerationPlanId={vendorSelectedGrade?.id}
        vendorClass={vendorSelectedGrade}
        ammount={vendorSelctedGradeFee - vendorCurrentGradeFee}
      />
    </Box>
  )
};

const CustomLabel = ({ grade, fee, contractMaxValue, contractMinValue, anualRenewalFee }) => (
  <StyledRadioLabel>
    <div className="class">
      <p className="value">{grade}</p>
    </div>

    <div className="contract-fee">
      <p className="fee">N{separateNumByComma(fee)}</p>
      <p className="title">Registration Fee</p>
    </div>
    <div className="separator" />
    <div className="renewal">Renew at N{separateNumByComma(anualRenewalFee)} annually</div>
    <div className="long-separator" />
    <div className="contract-value">
      {formatNumber(contractMinValue) === "1B" ? (
        <p className="value">1B and Above</p>
      ) : (
        <p className="value">
          {formatNumber(contractMinValue)} - {formatNumber(contractMaxValue)}
        </p>
      )}
      <p className="title">Contract sum</p>
    </div>
  </StyledRadioLabel>
);

const useStyles = makeStyles((theme) => ({
  vendorGradeStyle: {

    height: 50,
    width: 50,
    backgroundColor: "rgba(0, 176, 241, 0.0735632)",
    fontWeight: 600,
    fontSize: 36,
    lineHeight: "87px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#2AA96D",

  },
  duePayment: {
    display: "flex",
    "& h6": {
      fontWeight: 800,
      color: "#424F5C"
    },
    "& p": {
      color: "#424F5C",
      fontSize: 14,
      marginRight: 8

    },
  }
}))

export default RegistrationCategoryPayment;
