import React from 'react'
import PropTypes from 'prop-types'
import warning from '../../assets/threshold-warning.gif'
import { StyledModal, ModalTitle, ModalBody, BodyText, ModalButtons, ConfirmButton, CancelBtn, StyledImageWrapper } from './styles'


const ThresholdModal = ({
  open = false,
  onClose,
  onAction,
  loading
}) => {
  return (
    <React.Fragment>
      <StyledModal open={open} onClose={onClose} >
        <ModalBody>
          <StyledImageWrapper>
            <img alt="warning" src={warning} height={104} width={100} />
          </StyledImageWrapper>
          <ModalTitle>
            Permission Exists
          </ModalTitle>
          <BodyText>
            if you wish to continue with with the process, already existing permission will be deactivated.
          </BodyText>
          <ModalButtons>
            <div></div>
            <ConfirmButton onClick={onAction} loading={loading}>
              Confirm
            </ConfirmButton>
            <CancelBtn onClick={onClose}>
              Cancel
            </CancelBtn>
            <div></div>
          </ModalButtons>
        </ModalBody>
      </StyledModal>
    </React.Fragment>
  )
}

ThresholdModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  loading: PropTypes.bool
}

ThresholdModal.defaultProps = {
  onClose: () => { },
  onAction: () => { },
  loading: false
}

export default ThresholdModal
