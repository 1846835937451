import { useQuery } from "react-query";
import adminAPI from "../../redux/api/adminAPI";

export const useFetchVendors = ({ showAlert, params }) => {
  const { data, isLoading, ...rest } = useQuery(
    [`vendors`, { params }], () => adminAPI.getVendors('vendors', { ...params })
  )


  return {
    vendorList: data,
    isFetchingVendor: isLoading,
    ...rest
  }
}

