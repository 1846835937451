import styled from "styled-components";
import { FormControl as MuiFormControl } from "@material-ui/core"
import { theme } from "../../util/theme";

export const FormControl = styled(MuiFormControl)`
  &.MuiFormControl-root {
    label {
      color: ${theme.color.textMain}
    }

    & .MuiFormGroup-root {
      & .MuiFormControlLabel-root {
        & .MuiCheckbox-root {
          color: ${theme.color.primary}
        }
      }
    }
  }
`;
