import { notificationTypes } from "../components/Notification/Notification";

const links = {
  [notificationTypes.Special_procurement_notice]: "/vendor/procurement/:itemId",
  [notificationTypes.Bid_Evaluation]: "/vendor/bids/:itemId",
  [notificationTypes.Letter_of_Acceptance]: "/vendor/contracts/:itemId/letter-of-acceptance", //TODO: Find out if this URL is correct
  [notificationTypes.Contract_Signing]: "/vendor/contracts/:itemId/contract-signing",
  [notificationTypes.Public_Award_Notice]: "/vendor/bid/:itemId/report",
  [notificationTypes.Contract_offer_rejection]: "/vendor/contracts/rejected",
  [notificationTypes.Contract_offer_acceptance]: "/vendor/contracts/rejected",
  [notificationTypes.Proof_of_Payment]: "/vendor/transactions/:itemId",
  [notificationTypes.Bid_Submission]: "/admin/vendorsList/:itemId",
  [notificationTypes.Special_procurement_notice_admin]: "/admin/procurement/plans/:itemId",
  [notificationTypes.Bid_Invitation_admin]: "/admin/procurement/plans/:itemId",
  [notificationTypes.Bid_Evaluation_Report_admin]: "/admin/procurement/plans/:itemId",
  [notificationTypes.Letter_of_Acceptance_admin]: "/admin/contracts/:itemId",
  [notificationTypes.Contract_Signing_admin]: "/admin/contracts/:itemId",
  [notificationTypes.Public_Award_Notice_admin]: "/admin/procurement/plans/:itemId",
  [notificationTypes.Tender_Activity]: "/admin/procurement/plan-details/:itemId",
  [notificationTypes.Annual_Procurement_Plan]: "/admin/procurement/plans/:itemId",
  [notificationTypes.Annual_Procurement_Plan_Ammend]: "/admin/procurement/plans/notice/:itemId",
  [notificationTypes.Tender_Review]: "/admin/procurement/plans/:secondaryId/create/keydetails/:itemId",
  [notificationTypes.Boq_Endorsement]: "/admin/procurement/plan-details/:itemId",
  [notificationTypes.Rfq_Endorsement]: "/admin/procurement/plan-details/:itemId",
 };

export const getNotificationLink = (notificationClass, actionId, secondaryId) => {
 
  return links[notificationClass]?.replace(":itemId", actionId).replace(":secondaryId", secondaryId).trim();
};
