import React, { useContext } from "react";
import { queryCache, useMutation, useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { Roles } from "../../../../../components/AccessControl/UserRoles";
import { useProcurementPlanDetails } from "../../../../../hooks/queries/useProcurement";
import procurementAPI from "../../../../../redux/api/procurementAPI";
import ToastContext from "../../../../../util/toastContext";
import withAdminDashboard from "../../../HOC/withAdminDashboard";
import {
  BreadCrumbWrapper,
  CardBody,
  CardSection,
  CardWrapper,
  PageTitle,
  TopDocumentSection,
  TopSection,
  TopSectionBody,
  TopSectionTitle,
  ContentWrapper,
  TableWrapper,
  StyledTable,
  StatusBox,
} from "./style";
import ApproveEndorsementModal from "./ApproveEndorsementModal";
import FileProgress from "../../../../../components/FileProgress/FileProgress";
import { useRoles } from "../../../../../hooks/useUserDetails";
import AccessControl from "../../../../../components/AccessControl";
import { NextButton } from "../SpecificProcurementNotice/style";
import { Box, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { format } from "date-fns";
import SearchTable, { StyledTableCell } from "../../../../../components/SearchTable";
import { HoverTableStyle } from "../../../../../util/theme";

const ApprovalOfQuotationRequirement = () => {
  const [openEndorse, setOpenEndorse] = React.useState(false);
  const { procurementId, activityId } = useParams();

  const [dataSource, setDataSource] = React.useState([]);
  const { showAlert } = useContext(ToastContext);
  const { role } = useRoles();

  const { data: procurementDetails } = useProcurementPlanDetails({ procurementId });

  useQuery([`rfq-endorsement-data`], () => procurementAPI.getRFQEndorsements(procurementId), {
    enabled: !!procurementId,
    onError: (err) => {
      showAlert({
        message: err.message,
        severity: "error",
      });
    },
    onSuccess: ({ data }) => {
      setDataSource(data);
    },
  });

  const { data: RFQRequirements, isLoading: loadingRequirements } = useQuery(
    [`rfq-requirement-data`],
    () => procurementAPI.getRFQuotationRequirements(procurementId),
    {
      enabled: !!procurementId,
      onError: (err) => {
        showAlert({
          message: err.message,
          severity: "error",
        });
      },
    }
  );

  const [mutate, { isLoading }] = useMutation(procurementAPI.endorseRFQ, {
    onSuccess: () => {
      queryCache.invalidateQueries("rfq-endorsement-data");
      showAlert({
        message: `Successfully endorsed`,
        severity: "success",
      });
      setOpenEndorse(false);
    },
    onError: (err) => {
      setOpenEndorse(false);
      showAlert({
        message: `${err}`,
        severity: "error",
      });
    },
  });

  const RFQStatus = dataSource[dataSource?.length - 1]?.status?.toLowerCase();

  const handleEndorsementClick = () => {
    setOpenEndorse(true);
  };

  const redirectToFileUrl = (url) => {
    window.open(url, "_blank");
  };

  return (
    <>
      <ContentWrapper>
        <PageTitle>Approval of Quotation Requirements</PageTitle>
        <BreadCrumbWrapper>
          <Link to={"/admin/dashboard/overview"}>Home {`/ `}</Link>
          <Link to={"/admin/procurement/plans"}>Plans {`/ `}</Link>
          <Link to={`/admin/procurement/plan-details/${procurementId}`}>{procurementDetails?.name}</Link>
        </BreadCrumbWrapper>
        <CardWrapper>
          <TopSection>
            <TopSectionTitle>Note</TopSectionTitle>
            <TopSectionBody>{procurementDetails?.description}</TopSectionBody>
          </TopSection>
          <TopSection>
            <TopDocumentSection>
              <CardSection>
                {RFQRequirements?.data?.supportingDocuments?.length > 0 && (
                  <div>
                    <TopSectionTitle>Quotation Requirements Documents</TopSectionTitle>
                    <CardBody style={{ gridColumnGap: 15, marginTop: 10 }} spacing={4}>
                      {RFQRequirements?.data?.supportingDocuments?.map((memo) => (
                        <FileProgress
                          progress={100}
                          fileName={memo?.file.original_filename}
                          fileSize={memo?.file.bytes}
                          hasEyes={true}
                          onClickEyes={() => redirectToFileUrl(memo?.file.url)}
                        />
                      ))}
                    </CardBody>
                  </div>
                )}
              </CardSection>
              <NextButton
                disabled={
                  role === Roles.PROCUREMENT_OFFICER ||
                  ([Roles.ACCOUNTING_OFFICER, Roles.PERMANENT_SECRETARY].includes(role) && dataSource?.[0]?.status === "Endorsed") ||
                  ([Roles.SUPERVISING_OFFICER, Roles.COMMISSIONER].includes(role) && dataSource?.[1]?.status === "Endorsed")
                }
                onClick={handleEndorsementClick}
                loading={false}
              >
                <AccessControl allowedRoles={[Roles.PROCUREMENT_OFFICER]}>
                  {RFQStatus === "endorsed" ? `Approved` : `Waiting for bill Approval`}
                </AccessControl>
                <AccessControl
                  allowedRoles={[
                    Roles.ACCOUNTING_OFFICER,
                    Roles.PERMANENT_SECRETARY,
                    Roles.COMMISSIONER,
                    Roles.SUPERVISING_OFFICER,
                  ]}
                >
                  Endorse Requirement
                </AccessControl>
              </NextButton>
            </TopDocumentSection>
          </TopSection>
        </CardWrapper>
      </ContentWrapper>
      <TableWrapper>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableCell>Role</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSource?.map((field) => (
              <TableRow key={field.role}>
                <TableCell>{field.role}</TableCell>
                <TableCell>
                  <StatusBox colorStatus={field.status}>{field.status}</StatusBox>
                </TableCell>
                <TableCell>{field.endorseDate ? format(new Date(field.endorseDate), "dd MMM yyyy") : " Not Available"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
        <Box style={{ width: "100%", padding: "40px" }} />
      </TableWrapper>

      <SearchTable
        noRows
        headerText={`Showing ${RFQRequirements?.data?.vendors.length} Result(s)`}
        columns={["Name", "Class", "Date", ""]}
        loading={loadingRequirements}
      >
        {RFQRequirements?.data?.vendors?.map(({ class: vendorClass, companyName }) => (
          <HoverTableStyle>
            <StyledTableCell>{companyName}</StyledTableCell>
            <StyledTableCell>{vendorClass}</StyledTableCell>
            <StyledTableCell>{format(new Date(RFQRequirements?.data?.createdAt), "dd MMM, yyyy")}</StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </HoverTableStyle>
        ))}
      </SearchTable>

      <ApproveEndorsementModal
        open={Boolean(openEndorse)}
        onClose={() => setOpenEndorse(false)}
        onAction={() => mutate(activityId)}
        loading={isLoading}
      />
    </>
  );
};

export default withAdminDashboard(ApprovalOfQuotationRequirement);
