import React from "react";
import { useSelector } from "react-redux";

import SecondaryTable from "./SecondaryTable";

const ContactBiddersTab = () => {
  const {
    adminContractDetails: { bidders = [] },
  } = useSelector((state) => state.admin);

  const secondaryTableHeader = [
    { id: "s/n", title: "#", width: 50, align: "alignCenter", indexed: true },
    { id: "companyName", title: "Company Name", grid: 3 },
    { id: "regName", title: "Registeration Name", grid: 3 },
    { id: "amount", title: "Bid amount", grid: 3 },
    // { id: "evalStatus", title: "Evaluation Status" },
  ];

  const tableBody = bidders.map((bidder) => ({
    companyName: bidder.companyName,
    regName: bidder.registrationNumber,
    amount: bidder.amount,
    // evalStatus: bidder.status,
  }));

  return <SecondaryTable tableBody={tableBody} tableHeader={secondaryTableHeader} />;
};

export default ContactBiddersTab;
