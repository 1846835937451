import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
// import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControl } from './style';
import { Box, Typography } from '@material-ui/core';
import { theme } from '../../util/theme';



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));



const CustomCheckbox = ({
  name,
  options,
  allText,
  value = [],
  setValue,
  label,
  row,
  helperText,
  specialLabel
}) => {
  const classes = useStyles();

  const formatOptionValue = options?.map(field => field?.value)
  const handleCheck = (e, val) => {
    if (value === "all") {
      if (value?.length === options?.length) {
        return []
      }
      return formatOptionValue
    }
    if (!value.includes(val)) {
      return [...value, val]
    }
    const filteredValue = value?.filter(field => field !== val)
    return filteredValue
  }

  return (
    <FormControl name={name} component="fieldset" className={classes.formControl}>
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup row={row}>
        {allText && (
          <FormControlLabel
            labelPlacement='end'
            disableRipple
            control={
              <Checkbox
                size="medium"
                onChange={(e) => setValue(handleCheck(e, "all"))}
                checked={value?.length === formatOptionValue?.length}
                indeterminate={value?.length !== formatOptionValue?.length}
                disableRipple
              />
            }
            label={allText || "All"}
          />
        )}
        {options?.map(field => (
          <FormControlLabel
            key={field?.value}
            labelPlacement='end'
            disableRipple
            control={
              <Checkbox
                size="medium"
                onChange={(e) => setValue(handleCheck(e, field?.value))}
                checked={value?.includes(field?.value)}
                disableRipple
              />
            }
            // label={field?.label}
            label={specialLabel ?
              <Box children={
                <div style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column"
                }}
                >
                  <Typography
                    style={{
                      fontSize: "14px",
                      lineHeight: 1.5,
                      color: theme.color.textMain
                    }}
                  >
                    {field?.label}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "12px",
                      lineHeight: 1.5,
                      color: theme.color.textSecondary
                    }}
                  >
                    {field?.label2}
                  </Typography>
                </div>
              } />
              : field?.label
            }
          // checked={value === field?.value}
          />
        ))}
      </FormGroup>
      {helperText && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

CustomCheckbox.propTypes = {

}

export default CustomCheckbox
