import React, { useState, useContext } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { Box } from "@material-ui/core";
import {
  EmptyState,
  EmptyStateDescription,
  EmptyStateHeader,
  ModdedAvatar,
  TableCellEmail,
  TableTop,
  TableWrapper,
} from "../VendorsTable/style";
import { AcceptedTableHeader, RejectedTableHeader, StyledTableCell } from "./style";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import Grid from "@material-ui/core/Grid";
import { ReactComponent as RightIcon } from "../../../../../../assets/right_icon.svg";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { useQuery } from "react-query";
import Loader from "../../../../../../components/Common/Loader";
import LoadingButton from "../../../../../../components/LoadingButton/LoadingButton";
import receiptOfBiddersResponseApi from "../../../../../../redux/api/receiptOfBiddersResponseApi";
import ToastContext from "../../../../../../util/toastContext";
import AccessControl from "../../../../../../components/AccessControl";
import { Roles } from "../../../../../../components/AccessControl/UserRoles";
import { useProcurementMethod } from "../../../../../../hooks/queries/useProcurement";
import { Procurement_Method } from "../../../../../../util/constants";
import { ProcurementMethodAccessControl } from "../../../../../../shared";

const VendorsCategorized = ({ type, procurementId, activityId, evaluate, hasBenchmark, status }) => {
  const { pathname } = useLocation();
  const { showAlert } = useContext(ToastContext);
  const rejected = type === "rejected";

  const { method, isSuccess } = useProcurementMethod({ procurementId });

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const vendorsTechnicalEvaluation = useQuery({
    queryFn: receiptOfBiddersResponseApi.getEvaluatedVendors,
    queryKey: [
      "technical-evaluation",
      {
        pageSize: pagination.pageSize,
        pageNumber: pagination.pageNumber,
        procurementId,
        status,
      },
    ],
    config: {
      onError: (error) =>
        showAlert({
          severity: "error",
          message: error?.message,
        }),
    },
  });

  const onChangePage = (event, value) => {
    setPagination({
      ...pagination,
      pageNumber: value + 1,
    });
  };

  const onChangeRowsPerPage = (event) => {
    setPagination({
      pageNumber: 1,
      pageSize: parseInt(event.target.value),
    });
  };

  const history = useHistory();

  const redirectUser = (id, name) => {
    history.push(
      `/admin/procurement/activity/${
        method === Procurement_Method.DIRECT_PROCUREMENT ? `receiptofresponse` : `receiptofresponses`
      }/${activityId}/${procurementId}/vendorsDetails/${id}/${name}`
    );
  };

  const { pagination: apiPagination, data } = vendorsTechnicalEvaluation?.data || {};

  const renderHeader = () => {
    if (rejected) {
      return (
        <RejectedTableHeader>
          <StyledTableCell>NAME</StyledTableCell>
          <StyledTableCell>REGISTRATION CLASS</StyledTableCell>
          <StyledTableCell align="right">ACTION</StyledTableCell>
        </RejectedTableHeader>
      );
    }

    return (
      <AcceptedTableHeader>
        <StyledTableCell>NAME</StyledTableCell>
        <StyledTableCell>REGISTRATION CLASS</StyledTableCell>
        <StyledTableCell align="right">ACTION</StyledTableCell>
      </AcceptedTableHeader>
    );
  };

  return (
    <Box>
      {vendorsTechnicalEvaluation.isLoading ? (
        <Loader feedback="Fetching details" />
      ) : (
        <TableWrapper>
          <TableTop>
            <span>Showing {data?.length} results</span>
          </TableTop>
          {apiPagination?.totalEntries > 0 ? (
            <Table>
              {renderHeader()}
              {data?.map(({ companyName, email, registrationClass, userId }) => (
                <TableRow
                  hover
                  onClick={() => {
                    redirectUser(userId, companyName);
                  }}
                  key={userId}
                >
                  <TableCell>
                    <Grid spacing={1} container wrap="nowrap">
                      <Box mr={2}>
                        <ModdedAvatar size={34} color="#50B83C">
                          {companyName?.slice(0, 2)}
                        </ModdedAvatar>
                      </Box>
                      <Grid container direction="column" spacing={1}>
                        <span>{companyName}</span>
                        <TableCellEmail>{email}</TableCellEmail>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>{registrationClass}</TableCell>
                  <TableCell align="right" onClick={(e) => e.stopPropagation()}>
                    {evaluate && (
                      <AccessControl
                        allowedRoles={[Roles.MINISTERIAL_TENDER_BOARD]}
                        placeholder={<RightIcon fill="#637381" width={18} />}
                      >
                        <LoadingButton
                          color="#219653"
                          disabled={hasBenchmark !== true}
                          component={Link}
                          style={{ width: "min-content" }}
                          to={`${pathname}/vendorsDetails/${userId}/${companyName}/vendor-evaluation`}
                        >
                          Evaluate
                        </LoadingButton>
                      </AccessControl>
                    )}
                    {!evaluate && <RightIcon fill="#637381" width={18} />}
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          ) : (
            <EmptyState>
              <EmptyStateHeader>All caught up!</EmptyStateHeader>
              <EmptyStateDescription>You do not have any {type} vendors</EmptyStateDescription>
            </EmptyState>
          )}
          <ProcurementMethodAccessControl
            method={method}
            allowedMethod={Object.values(Procurement_Method).filter((value) => value !== Procurement_Method.DIRECT_PROCUREMENT)}
            isSuccess={isSuccess}
            noLoader
          >
            <Box pt={3}>
              <Grid container justify="flex-end">
                <TablePagination
                  count={apiPagination?.totalEntries}
                  rowsPerPage={apiPagination?.perPage}
                  page={pagination.pageNumber - 1}
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                />
              </Grid>
            </Box>
          </ProcurementMethodAccessControl>
        </TableWrapper>
      )}
    </Box>
  );
};

export default VendorsCategorized;
