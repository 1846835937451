import styled from "styled-components";
import { PageTab } from "../../../../../util/theme";
import { PageTitle } from "../../../../Common/style";
import { Box, Typography } from "@material-ui/core";

export const GeneralContentWrapper = styled.div`
  padding: 15px 0;
  padding-right: 25px;
`;


export const GeneralTitlePage = styled(PageTitle)`
  margin: 0 !important;
  margin-bottom: 5px !important;
`;


export const CardContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .MuiInput-underline.Mui-disabled:before {
    border-bottom-style: none;
}
`;


export const PlanCardContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #C9D4DB !important;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #C9D4DB !important;
  margin-right: 25px !important;
`;


export const GeneralTabContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid #C9D4DB !important;
  border-radius: 3px 3px 0 0 !important;
  background-color: #E4E9EC;
`;


export const GeneralPlanTab = styled(PageTab)`
  color: #5F7483;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 24px !important;
  text-transform: none !important;
`;

export const GeneralCardContentWrapper = styled.div`
  box-sizing: border-box;
  border: 1px solid #c9d4db !important;
  background-color: #fcfcfd !important;
  padding: 19.5px 20.5px 13.5px !important;

  .general-editor-style {
    min-height: 274px !important;
    background-color: #ffffff;
  }

  .general-editor-container-style {
    width: 100% !important;
  }
`;

export const GeneralButtonContainer = styled(Box)`
  padding: 35px 20.5px 40px !important;
`;

export const ReviewContainer = styled(GeneralCardContentWrapper)`
  padding-top: 69px !important;
`;

export const TitleLabel = styled(Typography)`
  color: #1e2b37;
  font-size: 17px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 24px !important;
  margin: 20px 0 15px !important;
  padding-left: 20.5px !important;
`;


export const DurationContainer = styled.div`
  background: #FCFCFD;
  border: 1px solid #C9D4DB;
  padding: 20px !important;

  .MuiFilledInput-input {
    padding: 27px 12px 10px !important;
  }
`;
