import React from "react";
import { Box, Container } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { format } from "date-fns/esm";
import WatermarkOne from "../../../../../assets/watermark3.png";
import WatermarkTwo from "../../../../../assets/watermark1.png";
import LoadingIcon from "../../../../../assets/QR_Loading.gif";
import ErrorIcon from "../../../../../assets/QR_Error.gif";
import Logo from "../../../../../assets/kwara-logo-png.png";
import { ReactComponent as Checkmark } from "../../../../../assets/verify_cert_checkmark.svg";
import { VerifyWatermark1, VerifyWatermark2, GreenTitle, Title, StyledTableTitle, VerifyDate } from "./style";
import usersAPI from "../../../../../redux/api/usersAPI";
import { formatCurrency } from "../../../../../util/formatCurrency";

const CertificateTypes = {
  '1': `TO COMMENCE BIDDING`,
  '2': `FOR THE AWARD OF CONTRACT`,
  '3': `FOR PAYMENT`
}

const VerifyCertificate = () => {
  const { id, type } = useParams();

  const { data, isLoading, isSuccess, isError } = useQuery(
    ["cert-details-verify"],
    () => usersAPI.getCertificateDetails({ procurementId: id, type }),
    {
      enabled: !!id,
    }
  );
  return (
    <Container maxWidth="sm">
      <div style={{ position: "relative", maxWidth: "500px" }}>
        <VerifyWatermark1>
          <img src={WatermarkOne} alt="Kwara state logo" />
        </VerifyWatermark1>
        <VerifyWatermark2>
          <img src={WatermarkTwo} alt="Kwara state logo" />
        </VerifyWatermark2>
        <VerifyDate>
          <Box mb={2} mt="8px">
            <StyledTableTitle>Date issued</StyledTableTitle>
            <StyledTableTitle fontSize="14px" fontWeight={700}>{data && format(new Date(data?.issueDate), "d/MMMM/yyyy")}</StyledTableTitle>
          </Box>
        </VerifyDate>
        <Box pt={5} px={4}>
          <Box mb={5} display="flex" justifyContent="center">
            <img src={Logo} alt="Kwarra state logo" />
          </Box>

          <Title color="#DA1414" fontWeight={600} fontSize={14}>
            GOVERNMENT OF KWARA STATE NIGERIA
          </Title>
          <Title fontWeight={700}>KWARA STATE PUBLIC PROCUREMENT AGENCY</Title>
          <Box display="flex" justifyContent="center" my={5}>
            {isLoading && <img width={200} height={200} src={LoadingIcon} alt="verifying certificate" />}
            {isError && <img width={200} height={200} src={ErrorIcon} alt="Failed to verify certificate" />}
            {isSuccess && <Checkmark />}
          </Box>

          {isSuccess && (
            <>
              <GreenTitle>{`CERTIFICATE OF NO OBJECTION ${
                CertificateTypes[type]
              }`}</GreenTitle>
              <Title>Issued to ({data?.ministry ?? `Not Applicable`})</Title>
              <>
                <Box mt={5} mb={2}>
                  <StyledTableTitle>Project description</StyledTableTitle>
                  <StyledTableTitle fontSize="14px" fontWeight={700}>
                    {data?.projectDescription ?? `Not Applicable`}
                  </StyledTableTitle>
                </Box>
                <Box mb={2} mt="8px">
                  <StyledTableTitle>Project cost as Requested</StyledTableTitle>
                  <StyledTableTitle fontSize="14px" fontWeight={700}>
                    {data?.projectCost ? formatCurrency(data?.projectCost, true) : `Not Applicable`}
                  </StyledTableTitle>
                </Box>
                <Box mb={2} mt="8px">
                  <StyledTableTitle>KWPPA reviewed Total Project Cost</StyledTableTitle>
                  <StyledTableTitle fontSize="14px" fontWeight={700}>
                    {data?.reviewedProjectCost ? formatCurrency(data?.reviewedProjectCost, true) : `Not Applicable`}
                  </StyledTableTitle>
                </Box>
                <Box mb={2} mt="8px">
                  <StyledTableTitle>Cost Reduction</StyledTableTitle>
                  <StyledTableTitle fontSize="14px" fontWeight={700}>
                    {data?.costReduction ? formatCurrency(data?.costReduction, true) : `Not Applicable`}
                  </StyledTableTitle>
                </Box>
                <Box mb={2} mt="8px">
                  <StyledTableTitle>Project description</StyledTableTitle>
                  <StyledTableTitle fontSize="14px" fontWeight={700}>
                    {data?.projectDescription ?? `Not Applicable`}
                  </StyledTableTitle>
                </Box>
                <Box mb={2} mt="8px">
                  <StyledTableTitle>client ministry/department/agency</StyledTableTitle>
                  <StyledTableTitle fontSize="14px" fontWeight={700}>
                    {data?.ministry ?? `Not Applicable`}
                  </StyledTableTitle>
                </Box>
                <Box mb={2} mt="8px">
                  <StyledTableTitle>Head/Sub-Head/Appropriation Code</StyledTableTitle>
                  <StyledTableTitle
                    fontSize="14px"
                    fontWeight={700}
                  >{`${data?.headCode}/${data?.subHeadCode}/${data?.appropriationCode}`}</StyledTableTitle>
                </Box>
                <Box display="flex" justifyContent="center">

                </Box>
              </>
            </>
          )}
        </Box>
      </div>
    </Container>
  );
};

export default VerifyCertificate;
