import React from "react";
import PropTypes from "prop-types";
import { CuteModal } from "../../../Vendor/Procurement/style";
import { BtnBox, ModalHeader, ModalWrapper } from "./style";
import NumberFormat from "react-number-format";
import { Box, Typography } from "@material-ui/core";
import { InputField, LoadingButton } from "../../../../components";
import { CancelButton } from "../GeneralPlanDetails/style";

const ReviewModal = ({ title, subtitle, open, onClose, procurementData, reviewAmount, loading, setReviewAmount, onAction }) => {
  return (
    <CuteModal open={Boolean(open)} onClose={onClose}>
      <ModalWrapper>
        <ModalHeader>{title}</ModalHeader>
        <Typography color="#3F5673" fontWeight={400} fontSize="18px">
          {subtitle}
        </Typography>
        <Box mt="40px">
          <NumberFormat
            customInput={InputField}
            name="approvedAmount"
            label="Approved Amount"
            thousandSeparator
            prefix="₦"
            variant="outlined"
            value={procurementData?.budget}
            disabled
          />
          <div style={{ background: "#ffffff" }}>
            <NumberFormat
              customInput={InputField}
              label="Review Amount Requested"
              name="reviewedQuotation"
              thousandSeparator
              prefix="₦"
              value={reviewAmount}
              onValueChange={(values) => {
                // eslint-disable-next-line
                const { _, value } = values;
                setReviewAmount(value);
              }}
              style={{ background: "#ffffff" }}
            />
          </div>
        </Box>
        <BtnBox>
          <LoadingButton
            disabled={procurementData?.budget > reviewAmount || loading}
            style={{ borderRadius: "8px" }}
            onClick={onAction}
            loading={loading}
          >
            Send Request
          </LoadingButton>
          <CancelButton onClick={onClose}>Cancel</CancelButton>
        </BtnBox>
      </ModalWrapper>
    </CuteModal>
  );
};

ReviewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  setReviewAmount: PropTypes.func,
  reviewAmount: PropTypes.number,
  procurementData: PropTypes.shape({
    budget: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
};

ReviewModal.defaultProps = {
  onAction: () => {},
  onClose: () => {},
  loading: false,
  setReviewAmount: () => {},
  reviewAmount: 0,
};

export default ReviewModal;
