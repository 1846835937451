import { Box, Grid } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useMutation, useQuery, queryCache } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { LoadingButton } from "../../../../components";
import SearchTable from "../../../../components/SearchTable";
import generalPlansAPI from "../../../../redux/api/generalPlansAPI";
import procurementAPI from "../../../../redux/api/procurementAPI";
import { formatCurrency } from "../../../../util/formatCurrency";
import ToastContext from "../../../../util/toastContext";
import Breadcrumb from "../../../Common/PageElements/Breadcrumb";
import withAdminDashboard from "../../HOC/withAdminDashboard";
import { PageTitle } from "../../PlanDetails/style";
import FullGeneralPlanDetails from "../GeneralPlanDetails/FullGeneralPlanDetails";
import {
  ButtonContainer,
  CancelButton,
  CardWrapper,
  Notice,
  NoticeContent,
  NoticeDivider,
  NoticeInformation,
  NoticeTitle,
  StatusLabelWrapper,
  ViewMoreText,
} from "../GeneralPlanDetails/style";
import { TitleContainer } from "../style";
import TableBody from "../GeneralPlanDetails/TableBody";
import { BudgetBox, ContentWrapper } from "./style";
import Loader from "../../../../components/Common/Loader";
import AccessControl from "../../../../components/AccessControl";
import { Roles } from "../../../../components/AccessControl/UserRoles";
import { useRoles } from "../../../../hooks/useUserDetails";
import StatusAlert from "./StatusAlert";
import { useSupplementaryDetail, useSupplementaryStatusToggle } from "../../../../hooks/queries/useSupplementaryBudget";
import { supplementaryBudgetStatus } from "../../../../util/constants";

const pickStatus = (status) => {
  switch (status) {
    case "Completed":
      return (
        <StatusLabelWrapper textcolor="#414F3E" bgcolor="#BBE5B3">
          {status}
        </StatusLabelWrapper>
      );
    case "Under Review":
      return (
        <StatusLabelWrapper textcolor="#FF8D26" bgcolor="#FCEBDB">
          {status}
        </StatusLabelWrapper>
      );
    case "In Progress":
      return (
        <StatusLabelWrapper textcolor="#FF8D26" bgcolor="#FCEBDB">
          {status}
        </StatusLabelWrapper>
      );
    default:
      return (
        <StatusLabelWrapper textcolor="#454F5B" bgcolor="#ABB4BC">
          {status}
        </StatusLabelWrapper>
      );
  }
};

const BudgetDetails = () => {
  const { id, budgetId } = useParams();
  const { role } = useRoles();
  const [isOpen, toggleModal] = useState(false);
  const { showAlert } = useContext(ToastContext);
  const [selected, setSelected] = React.useState(null);
  const history = useHistory();
  const GeneralPlanDetailsLink = [
    {
      url: "/admin/dashboard/overview",
      title: "Home",
      forwardSlash: true,
    },
    {
      url: "/admin/procurement/plans",
      title: "Procurement Plans",
      forwardSlash: true,
    },
    {
      url: `/admin/procurement/plans/${id}`,
      title: "Plan",
      forwardSlash: false,
    },
    {
      url: `/admin/procurement/plans/${id}/supplementary-budget`,
      title: "Supplementary budget",
      forwardSlash: false,
    },
  ];

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const updatePagination = (key) => (value) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      [key]: value,
    }));
  };

  const [{ isLoading }] = useMutation(procurementAPI.toggleSupplementaryBudgetStatus, {
    onError: (e) => {
      showAlert({
        severity: "error",
        message: e.message,
      });
    },
  });

  const generalPlanQueryKey = ["generalPlans", id, { ...pagination }];

  const generalPlanQuery = useQuery({
    queryKey: generalPlanQueryKey,
    queryFn: generalPlansAPI.getProcurementPlans,
  });

  const generalPlanDetails = useQuery({
    queryKey: ["generalPlanDetails", id],
    queryFn: generalPlansAPI.getGeneralPlansDetails,
    config: {
      staleTime: 900 * 1000,
    },
  });

  const handleButtonClick = (key) => (values) => {
    switch (key) {
      case "approved": {
        setSelected({ ...values, menuOpen: true });
        break;
      }
      case "rejected": {
        setSelected({ ...values, menuOpen: true });
        break;
      }

      default:
        break;
    }
  };
  const { toggleSupplementaryStatus, togglingSupplementaryStatus } = useSupplementaryStatusToggle({
    showAlert,
    setModal: setSelected,
    queryCache,
  });

  const { supplementaryBudget } = useSupplementaryDetail({ id: budgetId, showAlert });

  const toggleStatus = (values) => {
    toggleSupplementaryStatus({ approveStatus: values?.actionValue, budgetId: values?.id });
  };

  const accessPermission = (key) => {
    switch (key) {
      case Roles.ACCOUNTING_OFFICER: {
        return {
          text: "Reviewed",
          value: "Reviewed",
          disabled: supplementaryBudget?.status?.toLowerCase() !== supplementaryBudgetStatus.pending,
        };
      }
      case Roles.PERMANENT_SECRETARY: {
        return {
          text: "Reviewed",
          value: "Reviewed",
          disabled: supplementaryBudget?.status?.toLowerCase() !== supplementaryBudgetStatus.pending,
        };
      }
      case Roles.COMMISSIONER: {
        return {
          text: "Endorse",
          value: "Endorsed",
          disabled: supplementaryBudget?.status?.toLowerCase() !== supplementaryBudgetStatus.reviewed,
        };
      }
      case Roles.SUPERVISING_OFFICER: {
        return {
          text: "Endorse",
          value: "Endorsed",
          disabled: supplementaryBudget?.status?.toLowerCase() !== supplementaryBudgetStatus.reviewed,
        };
      }
      case Roles.KWPPA: {
        return {
          text: "Approve",
          value: "Approved",
          disabled: supplementaryBudget?.status?.toLowerCase() !== supplementaryBudgetStatus.endorsed,
        };
      }
      // case Roles.GOVERNOR: {
      //   return {
      //     text: "Approve",
      //     value: "Approved",
      //     disabled: supplementaryBudget?.status?.toLowerCase() !== supplementaryBudgetStatus.endorsed,
      //   };
      // }

      default:
        return {
          text: "Reviewed",
          value: "Reviewed",
          disabled: true,
        };
    }
  };

  return (
    <ContentWrapper>
      <TitleContainer>
        <div>
          <PageTitle>{generalPlanDetails?.data?.ministry?.name}</PageTitle>
          <Breadcrumb values={GeneralPlanDetailsLink} />
        </div>
        <AccessControl allowedRoles={[Roles.COMMISSIONER, Roles.SUPERVISING_OFFICER, Roles.KWPPA, Roles.PERMANENT_SECRETARY]}>
          <ButtonContainer>
            <CancelButton
              loading={isLoading}
              disabled={accessPermission(role).disabled}
              onClick={() => handleButtonClick("rejected")({ ...supplementaryBudget, actionValue: "Rejected" })}
            >
              Reject
            </CancelButton>
            <LoadingButton
              loading={isLoading}
              style={{ marginLeft: "1rem" }}
              onClick={() => handleButtonClick("approved")({ ...supplementaryBudget, actionValue: accessPermission(role).value })}
              disabled={accessPermission(role).disabled}
            >
              {accessPermission(role).text}
            </LoadingButton>
          </ButtonContainer>
        </AccessControl>
      </TitleContainer>
      {generalPlanDetails?.isLoading ? (
        <Loader />
      ) : (
        <CardWrapper>
          <Grid container alignItems="center">
            {pickStatus(generalPlanDetails?.data?.status) || "N/A"}
            <NoticeInformation>Procurement Plan Information</NoticeInformation>
          </Grid>
          <Box mt="20px">
            <Notice>
              <NoticeTitle>Note</NoticeTitle>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <NoticeContent>{generalPlanDetails?.data?.description || "N/A"}...</NoticeContent>
                <ViewMoreText onClick={toggleModal}>view more</ViewMoreText>
              </div>
            </Notice>
            <NoticeDivider />
          </Box>
          <div
            style={{ padding: "15px 0", display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}
          >
            <div>
              <NoticeTitle>Initial Budget</NoticeTitle>
              <BudgetBox>
                <div className="budget">{formatCurrency(supplementaryBudget?.approvedAmount ?? 0, true)}</div>
              </BudgetBox>
            </div>

            <div>
              <NoticeTitle>Review Amount Requested</NoticeTitle>
              <BudgetBox>
                <div className="supplementary">{formatCurrency(supplementaryBudget?.amountRequested ?? 0, true)}</div>
              </BudgetBox>
            </div>
          </div>
        </CardWrapper>
      )}

      <SearchTable
        rows={generalPlanQuery.data}
        columns={["package no/name", "category", "method", "status", "stage", "budget", ""]}
        loading={generalPlanQuery.isLoading}
        page={pagination.pageNumber}
        setPage={updatePagination("pageNumber")}
        rowsPerPage={pagination.pageSize}
        setRowsPerPage={updatePagination("pageSize")}
        totalNumberOfPages={generalPlanQuery.data?.pagination?.totalEntries}
      >
        <TableBody filtered={generalPlanQuery.data?.data} id={id} planDetails={generalPlanDetails.data} history={history} />
      </SearchTable>
      <FullGeneralPlanDetails isOpen={isOpen} toggleModal={toggleModal} />
      <StatusAlert
        open={Boolean(selected?.menuOpen)}
        onClose={() => setSelected(null)}
        selected={selected}
        reviewType={selected?.type || ""}
        statusKey={selected?.actionValue?.toLowerCase()}
        onAction={toggleStatus}
        loading={togglingSupplementaryStatus}
      />
    </ContentWrapper>
  );
};

export default withAdminDashboard(BudgetDetails);
