import { useMutation, useQuery } from "react-query";
import procurementAPI from "../../redux/api/procurementAPI";

export const useSupplementaryStatusToggle = ({ showAlert, setModal, queryCache }) => {
  const [mutate, { isLoading, ...rest }] = useMutation(
    ({ approveStatus, budgetId }) => procurementAPI.toggleSupplementaryBudgetStatus({ id: budgetId, approveStatus }),
    {
      onError: (e) => {
        if (showAlert) {
          showAlert({
            severity: "error",
            message: e.message,
          });
        }
      },
      onSuccess: (values) => {
        if (setModal) {
          setModal(null);
          queryCache.invalidateQueries("useFetchSupplementaryList");
        }
      },
      onSettled: (values) => {
        console.log("");
      },
    }
  );

  return {
    toggleSupplementaryStatus: mutate,
    togglingSupplementaryStatus: isLoading,
    ...rest,
  };
};

export const useCreateSupplementaryBudget = ({ showAlert, queryCache, setOpen }) => {
  const [mutate, { isLoading, ...rest }] = useMutation(({ payload }) => procurementAPI.createSupplementaryBudget(payload), {
    onSuccess: (values) => {
      if (showAlert) {
        showAlert({
          severity: "success",
          message: "Supplementary Budget created Successfully",
        });
        if (setOpen) {
          setOpen(false);
        }
      }
      if (queryCache) queryCache.invalidateQueries("useSupplementaryList");
    },
    onError: (error) => {
      if (showAlert) {
        showAlert({
          severity: "error",
          message: "Failed to Create supplementary budgets",
        });
      }
    },
  });

  return {
    createSupplementaryBudget: mutate,
    creatingSuppBudget: isLoading,
    ...rest,
  };
};

export const useFetchSupplementaryList = ({ id, showAlert, setDataSource }) => {
  const { data, isLoading, ...rest } = useQuery(
    [`useFetchSupplementaryList`],
    () => procurementAPI.getSupplementaryBudgets("", id),
    {
      onError: (error) => {
        if (showAlert) {
          showAlert({
            severity: "error",
            message: "Failed to load supplementary budgets List",
          });
        }
      },
      onSuccess: (values) => {
        if (setDataSource) {
          setDataSource(values);
        }
      },
    }
  );

  return {
    supplementaryList: data,
    loadingSupplementaryList: isLoading,
    ...rest,
  };
};

export const useSupplementaryDetail = ({ id, showAlert }) => {
  const { data, isLoading, ...rest } = useQuery(
    [`useSupplementaryDetail-${id}`, id],
    () => procurementAPI.getSupplementaryBudgetById("", id),
    {
      onError: (error) => {
        if (showAlert) {
          showAlert({
            severity: "error",
            message: "Failed to load supplementary budgets Details",
          });
        }
      },
    }
  );

  return {
    supplementaryBudget: data,
    loadingSupplementaryDetail: isLoading,
    ...rest,
  };
};
