import React, { useState, useEffect, useContext } from "react";
import { useQuery, useMutation, queryCache } from "react-query";
import { Alert } from "@material-ui/lab";
import { useDropzone } from "react-dropzone";
import { saveAs } from "file-saver";
import { Box, Button } from "@material-ui/core";

import AdminSearchFilter from "../../../components/AdminSearchFilter";
import StatisticsCards from "../../../components/StatisticsCards";
import withLoader from "../../Common/HOC/withLoader";
import CustomSearchTable from "../../../components/SearchTable/SupplierSearchTable";
import Loader from "../../../components/Common/Loader";
import withAdminDashboard from "../HOC/withAdminDashboard";
import VendorUpgradeRequestTable from "./UploadedVendorTable";
import { RequestInputField } from "./style";
import DropdownField from "../../../components/DropdownField";

import { ContentWrapper, TitleContainer } from "../Administration/Staff/style";
import {
  CustomDialog,
  CustomDialogTitle,
  CustomDialogContent,
  CustomDialogTitleWrapper,
  SubmitButton,
  PrevBtn,
} from "../../../components/Forms/ConfirmVerificationModal/style";
import { DropzoneArea } from "../../../components/DocumentUploadField/style";
import FileError from "../../../components/FileUploadField/FileError";
import { MaxFileSize } from "../../../util/constants";
import FileProgress from "../../../components/FileProgress/FileProgress";
// context
import ToastContext from "../../../util/toastContext";
import { getVendorsSummary } from "../../../redux/reducer/adminReducer";
import vendorsAPI from "../../../redux/api/vendorsAPI";
import { PageTitle } from "../../Common/style";
import Breadcrumb from "../../Common/PageElements/Breadcrumb";
import AccessControl from "../../../components/AccessControl";
import { Roles } from "../../../components/AccessControl/UserRoles";
import { AddButton } from "../Procurement/style";
import { CuteModal, ModalWrapper, ModalHeader, MainText, ModalRejectBtn, ModalCloseBtn } from "../../Vendor/Procurement/style";
import { useUploadVendor } from "../../../hooks/queries/useAdmin";

// const VENDOR_BULK_UPLOAD_FORMAT = `Tender Package Number,MinistryCode,Procuring Entity,Project Title/Description,Year,Procurement Category,Selection Method,Contractor Name,Contract Sum,Date of Award,Contract Duration`;

const VENDO_BULK_UPLOAD_FORMAT = `FirstName,LastName,CompanyName,Email Address,Category,Date Uploaded,Reg. Payment Date,Status`;

const createCardStats = (title, count, subTitle, type) => {
  return {
    title,
    count,
    subTitle,
    type,
  };
};

const RenderStatCards = () => {
  const { data: uploadStat } = useQuery("getVendorsUploadStat", vendorsAPI.getVendorsUploadStat);

  const cards = [
    createCardStats("Total Uploaded", uploadStat?.totalCount, "All Vendors", null),
    createCardStats("Approved Vendors", uploadStat?.approved, "", "active"),
    createCardStats("Onboarded Vendors", uploadStat?.onboardedCount, "", "pending"),
  ];
  return <StatisticsCards cards={cards} />;
};
const createHeader = (id, label) => {
  return {
    id,
    label,
  };
};
const headLabel = [
  createHeader("email", "EMAIL ADDRESS"),
  createHeader("category", "CATEGORY"),
  createHeader("uploadedDate", "UPLOADED DATE"),
  createHeader("regPaymentDate", "REG. PAYMENT DATE"),
  createHeader("status", "STATUS"),
  // createHeader("CompanyName", "COMPANY NAME"),
  // createHeader("NewCategory", "NEW CATEGORY"),
  // createHeader("Status", "STATUS"),
  createHeader("action", "ACTION"),
];

const UploadedVendor = () => {
  const [openUpload, setOpenUpload] = useState(false);
  // const [pageNumber, setPageNumber] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableParams, setTableParams] = useState({
    Search: "",
    Sort: "-createdAt",
    Status: "",
    Filter: "",
    pagination: {
      pageNumber: 0,
      pageSize: 10,
      total: 1,
    },
  });
  // const { pathname } = useLocation()
  // const [filters, setFilters] = useState({
  //   Search: "",
  //   Sort: "-createdAt",
  //   Status: "",
  //   // category:"",
  //   Filter: "",
  //   FilterBy: "",
  // });
  const [file, setFile] = useState(null);
  const fileMaxSize = MaxFileSize;

  const { showAlert } = useContext(ToastContext);
  // const linkStyle = { textDecoration: "none", color: "inherit" };
  const marginTop = "40px";
  const status = "loading";

  const formatErrorToCSV = (values = []) => {
    const CSV = ["Email, upload error"];
    values.forEach((el) => {
      let uploadError = "";
      const { email, errorMessage = [] } = el;
      if (Array.isArray(errorMessage)) {
        uploadError = errorMessage.join("; ");
      }
      if (typeof errorMessage === "string") {
        const errMsg = errorMessage?.split(",");
        uploadError = errMsg.join("; ");
      }

      CSV.push(`\n${email}, ${uploadError}`);
    });

    return CSV;
  };

  const saveFileError = (data, name) => {
    const csvFile = new File(data, `Vendor${name}.csv`, { type: "text/csv;charset=utf-8" });
    saveAs(csvFile);
  };

  const handleCloseModal = () => {
    setFile(null);
    setOpenUpload(false);
  };

  const generalVendorUploadQueryKey = [
    "getVendorsUploadList",
    {
      PageNumber: tableParams.pagination.pageNumber + 1,
      PageSize: tableParams.pagination.pageSize,
      Search: tableParams.Search,
      Sort: tableParams.Sort,
      Status: tableParams.Status,
      Filter: tableParams.Filter,
    },
  ];

  const { data: apiData, isLoading, error = {}, isError, isSuccess: venSuccess } = useQuery(
    generalVendorUploadQueryKey,
    vendorsAPI.getVendorsUploadList
  );
  const { uploadVendor, upoadingVendor } = useUploadVendor({
    showAlert,
    queryCache,
    setModal: handleCloseModal,
    formatErrorToCSV,
    saveFile: saveFileError,
  });
  const [SelectedVendorUploadToDelete, setSelectedVendorUploadToDelete] = useState(null);

  const [deleteVendorUpload, deleteVendorUploadQueryResponse] = useMutation(vendorsAPI.deleteVendorUploadList, {
    onSuccess: () => {
      showAlert({
        severity: "success",
        message: "Upload list was deleted successfully",
      });
      queryCache.removeQueries(generalVendorUploadQueryKey);
      setSelectedVendorUploadToDelete(null);
    },
    onError: (error) => {
      showAlert({
        severity: "error",
        message: error.message,
      });
    },
  });
  const handleDeleteVendorUpload = (uploadId) => {
    deleteVendorUpload(uploadId);
  };

  // const [uploadOCDS, uploadOCDSQuery] = useMutation(contractsAPI.uploadOCDS, {
  //   onSuccess: () => {
  //     showAlert({
  //       message: "Upload was successful",
  //       severity: "success",
  //     });
  //     queryCache.invalidateQueries("tenderAndAwardRequest", {
  //       exact: false,
  //       throwOnError: true,
  //       refetchActive: true,
  //       refetchInactive: false,
  //     });
  //     handleCloseModal();
  //     // refetch();
  //   },
  //   onError: (error) => {
  //     showAlert({
  //       message: error.message,
  //       severity: "error",
  //     });
  //   },
  // });

  const vendorListData = apiData?.data || [];
  const meta = apiData?.meta || {};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (venSuccess) {
      setTableParams((prev) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          total: meta?.pagination?.totalEntries,
        },
      }));
    }
     // eslint-disable-next-line
  }, [venSuccess]);

  useEffect(() => {
    if (isError) {
      showAlert({
        severity: "error",
        message: error?.message,
        durationInMs: 3000,
      });
    }
    // eslint-disable-next-line
  }, [showAlert]);

  const dropzone = useDropzone({
    onDrop: (files) => {
      setFile(files[0]);
    },
    multiple: false,
    validator: fileSizeValidator,
  });

  const updateFilters = (key) => (value) => {
    // setFilters((prevFilters) => ({
    //   ...prevFilters,
    //   [key]: value,
    // }));
    setTableParams((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const emptyRows =
    tableParams.pagination.pageNumber > 0 ? Math.max(0, tableParams.pagination.pageSize - vendorListData?.length) : 0;

  const handleChangePage = (event, pageNumber) => {
    // setPageNumber(pageNumber);
    setTableParams((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        pageNumber: pageNumber,
      },
    }));
  };

  function fileSizeValidator(file) {
    if (file.size > fileMaxSize) {
      return {
        code: "tooLarge",
        message: `File size is larger than ${fileMaxSize / 1000}kb`,
      };
    }

    return null;
  }

  const saveFile = () => {
    const data = [VENDO_BULK_UPLOAD_FORMAT];
    const name = "Vendor_Upload_Format";

    const csvFile = new File(data, `${name}.csv`, { type: "text/csv;charset=utf-8" });
    saveAs(csvFile);
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPageNumber(0);
    setTableParams((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        pageSize: parseInt(event.target.value, 10),
        pageNumber: 0,
      },
    }));
  };

  const handleUpload = async () => {
    const fd = new FormData();
    fd.set("File", file);

    try {
      uploadVendor({
        payload: fd,
      });
    } catch (e) {
      showAlert({
        message: e.message,
        severity: "error",
      });
    }
  };

  const renderVendorRequestTable = (
    <div id="request">
      <CustomSearchTable
        rows={vendorListData}
        pageNumber={tableParams.pagination.pageNumber}
        rowsPerPage={tableParams.pagination.pageSize}
        loading={isLoading}
        columns={headLabel}
        showPadding={false}
        totalNumberOfPages={meta?.pagination?.totalEntries}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSortRequest={updateFilters("Sort")}
      >
        <VendorUpgradeRequestTable
          emptyRows={emptyRows}
          requests={vendorListData}
          column={headLabel}
          handleDeleteVendorUpload={handleDeleteVendorUpload}
          setSelectedVendorUploadToDelete={setSelectedVendorUploadToDelete}
        />
      </CustomSearchTable>
      <CuteModal open={Boolean(SelectedVendorUploadToDelete)}>
        <ModalWrapper>
          <ModalHeader>Delete "{SelectedVendorUploadToDelete?.email}" </ModalHeader>
          <MainText mt="30px">Proceed to delete this Vendor Upload. You cannot undo this action</MainText>
          <MainText mt="30px"></MainText>
          <Box display="flex" justifyContent="flex-start">
            <ModalRejectBtn
              disabled={deleteVendorUploadQueryResponse.isLoading}
              onClick={() => handleDeleteVendorUpload(SelectedVendorUploadToDelete?.id)}
            >
              {deleteVendorUploadQueryResponse.isLoading ? "Please wait..." : "Delete"}
            </ModalRejectBtn>
            <ModalCloseBtn onClick={() => setSelectedVendorUploadToDelete(null)}>Close</ModalCloseBtn>
          </Box>
        </ModalWrapper>
      </CuteModal>
    </div>
  );

  const GeneralPlanLinks = [{ url: "/admin/vendorsList/vendors", title: "Vendors" }, { title: "Uploaded Vendors" }];

  return (
    <ContentWrapper>
      <TitleContainer>
        <div>
          <PageTitle>Uploaded Vendor</PageTitle>
          <Breadcrumb values={GeneralPlanLinks} />
        </div>
        <AccessControl allowedRoles={[Roles.KWPPA]}>
          <AddButton onClick={() => setOpenUpload(true)}>Upload Vendor</AddButton>
        </AccessControl>
      </TitleContainer>
      {withLoader(RenderStatCards)({
        stateName: "admin",
        conditioners: ["vendorsSummary"],
        apis: [getVendorsSummary()],
      })}
      <React.Fragment>
        <AdminSearchFilter normalSearch={true} title="Filter">
          <div style={{ display: "flex" }}>
            <RequestInputField type="text" label="Search Name" style={{ width: 300 }} setValue={updateFilters("Search")} />

            <DropdownField
              name="approval"
              label="Approval Status"
              style={{ width: 150 }}
              onChange={updateFilters("Status")}
              required
              values={[
                { label: "All", value: null },
                { label: "Onboarded", value: "ONBOARDED" },
                { label: "Approved", value: "APPROVED" },
              ]}
            />
          </div>
        </AdminSearchFilter>
        <div style={{ marginTop }}>
          {status === "loadig" ? (
            <div style={{ width: "100%" }}>
              <Loader feedback="Fetching Vendor Upgrade Request Lists" />
            </div>
          ) : (
            renderVendorRequestTable
          )}
        </div>
      </React.Fragment>
      <CustomDialog scroll="body" onClose={handleCloseModal} open={openUpload}>
        <CustomDialogTitleWrapper>
          <CustomDialogTitle disableTypography>Upload Vendor</CustomDialogTitle>
        </CustomDialogTitleWrapper>
        <CustomDialogContent>
          <Box>
            <Button variant="text" color="primary" style={{ textTransform: "none" }} onClick={saveFile}>
              <b>Download vendor upload sample file here</b>
            </Button>
          </Box>
          <Alert severity="info">
            <b>Info:</b> You agree to have thoroughly gone through the draft and agree that the information are valid and correct?
          </Alert>
          <Box>
            {file ? (
              <FileProgress progress={100} fileName={file.name} fileSize={file.size} hasCancel onCancel={() => setFile(null)} />
            ) : (
              <DropzoneArea {...dropzone.getRootProps()}>
                <input {...dropzone.getInputProps()} />
                <div>
                  <p>Upload or drop file here</p>
                  <span style={{ fontSize: ".9em" }}>{"File size should not exceed 5,000kb (5mb)"}</span>
                  <FileError fileRejections={dropzone.fileRejections} />
                </div>
              </DropzoneArea>
            )}
          </Box>
        </CustomDialogContent>

        <AccessControl allowedRoles={[Roles.KWPPA]}>
          <Box>
            <SubmitButton onClick={handleUpload} style={{ marginRight: 16 }} disabled={upoadingVendor || !file}>
              {upoadingVendor ? "Uploading..." : "Upload New"}
            </SubmitButton>
            <PrevBtn
              onClick={() => {
                setOpenUpload(false);
                setFile(null);
              }}
            >
              Cancel
            </PrevBtn>
          </Box>
        </AccessControl>
      </CustomDialog>
    </ContentWrapper>
  );
};

export default withAdminDashboard(UploadedVendor);
