import styled from "styled-components";

export const DropwdownWrapper = styled.div`
  width: 100%;
  margin: 8px;
  background-color: ${(props) => (props.disabled ? "rgba(0, 0, 0, 0.12) !important" : "transparent")};
  border: 1px solid #cbd4da;
  border-radius: 3px;

  .MuiFormLabel-root {
    width: 100%;
  }

  .MuiInput-underline:after,
  .MuiInput-underline:before {
    display: none;
  }

  label {
    padding: 8px 16px 0;
    text-align: left;
  }

  select {
    padding: 0 12px 8px;
  }

  .MuiInput-root {
    width: 100%;
  }
  .MuiNativeSelect-icon {
    top: -10px;
    right: 14px;
  }
  .MuiNativeSelect-root {
    padding-right: 24px;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    color: #637381;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
  }

  .MuiNativeSelect-select:focus {
    background-color: transparent !important;
  }

  &:hover {
    border: 1px solid #2aa96d;
  }
`;
