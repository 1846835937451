import { Box } from "@material-ui/core";
import React, { useState, useContext } from "react";
import { queryCache, useMutation, useQuery } from "react-query";
import { Link, useHistory, useParams } from "react-router-dom";
import ActivityTabs from "../../../../../components/ActivityTabs/ActivityTabs";
import { useProcurementMethod } from "../../../../../hooks/queries/useProcurement";
import procurementAPI from "../../../../../redux/api/procurementAPI";
import ToastContext from "../../../../../util/toastContext";
import withAdminDashboard from "../../../HOC/withAdminDashboard";
import { BreadCrumbWrapper } from "../BIllOfQuantityAppraval/style";
import { GeneralContentWrapper, GeneralTitlePage } from "../BillOfQuantityPreparation/style";
import Requirements from "../PrequalificationDocuments/Requirements/Requirements";
import SupportingDocuments from "../PrequalificationDocuments/SupportingDocuments/SupportingDocuments";

const QuotationRequirements = () => {
  const { procurementId, activityId } = useParams();
  const [formValues, setFormValues] = useState({});
  const [entries, setEntries] = useState([]);
  const { showAlert } = useContext(ToastContext);
  const history = useHistory();

  const { data: planDetails, method} = useProcurementMethod({ procurementId });

  const { data: activityDetails } = useQuery({
    queryKey: [
      "activityDetails",
      {
        activityId,
      },
    ],
    queryFn: procurementAPI.getActivityById,
  });

  const activityIsApproved = activityDetails?.procurementPlanActivityStatus === "Approved";

  const [prepareRFQ, { isLoading: submittingRFQ }] = useMutation(procurementAPI.prepareRFQuotation, {
    onSuccess: () => {
      queryCache.invalidateQueries("generalPlanDetails");
      showAlert({
        message: "Successfully saved quotation requirements",
        severity: "success",
        durationInMs: 3000,
      });
      history.push(`/admin/procurement/plan-details/${procurementId}`);
    },
    onError: (e) => {
      showAlert({
        message: e.message,
        severity: "error",
        durationInMs: 3000,
      });
    },
  });

  useQuery([`rfq-requirement-data`], () => procurementAPI.getRFQuotationRequirements(procurementId), {
    enabled: !!procurementId,
    onSuccess: ({ data }) => {
      const newFormValues = {};

      const baseState = {
        supportingDocuments: [],
        prequalificationRequirements: [
          {
            title: "",
            description: "",
            isCompulsory: false,
          },
        ],
        description: "",
        deadline: null,
      };

      const { supportingDocuments, prequalificationRequirements, description, expiryDate: deadline, vendors } = Object.assign(
        baseState,
        data
      );

      newFormValues.supporting = supportingDocuments?.map((file) => ({
        inDb: true,
        id: file.id,
        name: file.file.original_filename,
        size: file.file.bytes,
        downloadUrl: file.file.url,
      }));

      newFormValues.requiredDocuments = prequalificationRequirements

      newFormValues.editorState = description;

      newFormValues.deadline = deadline;

      newFormValues.vendors = vendors;

      setFormValues(newFormValues);
    },
  });

  const saveState = (newState) => {
    setFormValues({
      ...formValues,
      ...newState,
    });
  };
  const handleSendForApproval = ({ supporting, removed }) => {
    saveState({ supporting, removed });
    prepareRFQ({ ...formValues, supporting, removed, activityId });
  };

  return (
    <GeneralContentWrapper>
      <Box marginBottom="35px">
        <GeneralTitlePage>Preparation of Quotation Requirements</GeneralTitlePage>
        <BreadCrumbWrapper>
          <Link to={"/admin/dashboard/overview"}>Home {`/ `}</Link>
          <Link to={"/admin/procurement/plans"}>Plans {`/ `}</Link>
          <Link to={`/admin/procurement/plan-details/${procurementId}`}>{planDetails?.name}</Link>
        </BreadCrumbWrapper>
      </Box>
      <ActivityTabs
        tabs={[
          {
            title: "Requirements",
            component: (
              <Requirements
                initialState={formValues}
                saveState={saveState}
                activityIsApproved={activityIsApproved}
                procurementId={procurementId}
                method={method}
                setEntries={setEntries}
              />
            ),
          },
          {
            title: "Documents",
            component: (
              <SupportingDocuments
                initialState={formValues}
                loading={submittingRFQ}
                activityIsApproved={activityIsApproved}
                onPublish={handleSendForApproval}
                method={method}
              />
            ),
          },
        ]}
        completeEntries={entries}
        entries={["Notice Info", "Select Vendors", "Deadline", "Required Documents"]}
        loading={false}
      />
    </GeneralContentWrapper>
  );
};

export default withAdminDashboard(QuotationRequirements);
