import { useMutation, useQuery } from "react-query";
import procurementAPI from "../../redux/api/procurementAPI"

export const useProcurementPlanDetails = ({ procurementId }) => {
  return useQuery(
    ["generalPlanDetails", procurementId], () => procurementAPI.getPlanDetails("", procurementId),
    {
      enabled: !!procurementId,
      cacheTime: 3600 * 100,
      staleTime: 3600 * 100,
    }
  )
}

export const useProcurementMethod = ({ procurementId }) => {
  const { data, isSuccess, isLoading} = useQuery(
    ["generalPlanDetails", procurementId], () => procurementAPI.getPlanDetails("", procurementId),
    {
      enabled: !!procurementId,
      cacheTime: 3600 * 100,
      staleTime: 3600 * 100,
    }
  )

  return {
    method : data?.procurementMethod?.code?.toLowerCase(),
    isSuccess,
    isLoading,
    data
  }
}


export const useProcurementPresetStage = ({ params, code }) => {
  return useQuery(
    ['presetStages', { ...params }], () => procurementAPI.fetchPresetStage({ params }),
    {
      enabled: code || true
    }
  )
}


export const usePostBillOfQuantity = ({ activityId, showAlert }) => {
  return useMutation(({ payload }) => procurementAPI.postBillOfQuantity({ activityId, payload }), {
    onError: (err) => {
      showAlert && showAlert({
        message: err.message,
        severity: "error",
      });
},
    onSuccess: (data) => {
      showAlert && showAlert({
        message: "Bill of quantity successfully submitted",
        severity: "success"
      })
  
    }
  })
}


export const useFetchBoQDocuments = ({ procurementId }) => {
  return useQuery([`boq-${procurementId}`, procurementId], () => procurementAPI.getBOQDocuments({ procurementId }), {
    enabled: !!procurementId
  })
}

export const useFetchDlmEndorsement = ({ procurementId, showAlert }) => {
  return useQuery([`endorse-boq-${procurementId}`], () => procurementAPI.getBoQEndorse({ procurementId }), {
    enabled: !!procurementId,
    onError: (err) => {
      showAlert && showAlert({
        message: err.message,
        severity: "error",
      });
    }
  })
}
