import React from 'react';
import { Link, useParams } from "react-router-dom";
import { Box, Grid } from "@material-ui/core"
import moment from 'moment';
import {
  StyledContainer,
  BreadCrumbContainer,
  BreadCrumblabel,
  BreadcrumbActive,
  TitleText,
  ContactTitle,
  DetailContainer,
  GridContainer,
  SupBody,
  SupTitle
} from './style';
import withHeaderFooterHOC from '../HOC/withHeaderFooterHOC'
import vendorsAPI from '../../../redux/api/vendorsAPI';
import { useQuery } from 'react-query';

const DetailInfoText = ({ Title, BodyText, bodyStyle }) => (
  <Grid item xs={6} sm={3} spacing={1.1}>
    <SupTitle>{Title}</SupTitle>
    <SupBody style={bodyStyle}>{BodyText}</SupBody>
  </Grid>
)
const SupplierDetail = () => {
  const { id } = useParams()
  const linkStyle = { textDecoration: "none", color: "inherit" };
  const { data: supplierDetails } = useQuery(
    ["getWebVendorDetails", id],
    vendorsAPI.getWebVendor
  )
  return (
    <div>
      <StyledContainer>
        <BreadCrumbContainer>
          <BreadCrumblabel>
            <Link style={linkStyle} to="/">
              {"Home "}
            </Link> &nbsp;
            <Link style={linkStyle} to="/supplier" >
              /{"Suppliers Catalogue"}
            </Link>{" "}
            <BreadcrumbActive>/{" Company Details"}</BreadcrumbActive>
          </BreadCrumblabel>
        </BreadCrumbContainer>
        <Box marginBottom={1.2}>
          <TitleText>Company Details <span className='active'>Active</span></TitleText>
        </Box>
        <DetailContainer>
          <GridContainer container spacing={2}>
            <DetailInfoText
              Title={"Name of Company"}
              BodyText={supplierDetails?.companyName}
            />
            <DetailInfoText
              Title={"Registration category"}
              BodyText={supplierDetails?.category}
            />
            <DetailInfoText
              Title={"Area of Competence"}
              BodyText={supplierDetails?.competence}
            />
            <DetailInfoText
              Title={"Year of incorporation"}
              BodyText={supplierDetails?.incorporationYear}
            />
            <DetailInfoText
              Title={"CAC registration number"}
              BodyText={supplierDetails?.cacRegistrationNumber}
            />
            <DetailInfoText
              Title={"Date of registration"}
              BodyText={moment(supplierDetails?.registrationDate).format("DD/MM/YYYY")}
            />
            <DetailInfoText
              Title={"Company Website URL"}
              BodyText={supplierDetails?.website || "..."}
              bodyStyle={{ color: "#0050C8" }}
            />
          </GridContainer>
        </DetailContainer>
        <Box marginTop={1.5} marginBottom={1.2}>
          <ContactTitle>Contact</ContactTitle>
        </Box>
        <DetailContainer>
          <GridContainer container>
            <DetailInfoText
              Title={"E-mail"}
              BodyText={supplierDetails?.email}
            />
            <DetailInfoText
              Title={"Phone"}
              BodyText={supplierDetails?.phone ?? "..."}
            />
            <DetailInfoText
              Title={"Name of contact person"}
              BodyText={`${supplierDetails?.firstName} ${supplierDetails?.lastName}`}
            />
          </GridContainer>
        </DetailContainer>
      </StyledContainer>
    </div>
  )
}

export default withHeaderFooterHOC(SupplierDetail)
