import { Container, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { useQuery } from "react-query";
import moment from "moment";

import { BidTenderButton, ButtonsWrapper, TenderSubDetailsWrapper, TenderTitle } from "./style";
import { BreadcrumbActive, BreadcrumbLabel } from "../TenderList/style";
import HomepageNavBar from "../../../components/HomepageHeader";
import AdminSearchFilter from "../../../components/AdminSearchFilter";
import ChatBox from "../../../components/ChatBox/ChatBox";
import { addComment, getComments } from "../../../redux/reducer/commentReducer";
import { getUserByToken } from "../../../redux/reducer/userReducer";
import Loader from "../../../components/Common/Loader";
import procurementAPI from "../../../redux/api/procurementAPI";
import useAlert from "../../../hooks/useAlert";
import { EmptyTableSubTitle } from "../../../components/EmptyTableBody/style";

const SingleTenderDetails = ({ title }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { showAlert } = useAlert();
  const [fetchComment, setFetchComment] = useState(false);
  const { comments, loading } = useSelector((state) => state.comment);
  const { user } = useSelector((state) => state.user);
  const linkStyle = { textDecoration: "none", color: "inherit" };

  const bidUrl = user?.role === "Vendor" ? `/vendor/procurement/${id}` : "/login";

  // http://localhost:3000/vendor/procurement/61afa15d-1e63-413e-21fc-08da28edd8cb
  console.log("jhjgjhdkhgddd ", user);
  const { data: tenderInfo = {}, status, error, isError } = useQuery(["getTenderDetails", id], procurementAPI.getTenderDetails, {
    onSuccess: () => {
      setFetchComment(true);
    },
  });

  useEffect(() => {
    const fetchComments = async () => {
      await dispatch(getComments({ id }));
    };
    if (user === null) {
      dispatch(getUserByToken());
    }
    if (fetchComment) fetchComments();
    // eslint-disable-next-line
  }, [fetchComment]);

  useEffect(() => {
    if (!!error) {
      showAlert({ severity: "error", message: error.message });
    }
    // eslint-disable-next-line
  }, [error]);

  const handleSubmit = (message) => {
    const data = {
      type: "COMMENT",
      objectId: id,
      body: message,
      email: user.email,
      fullName: `${user.firstName} ${user.lastName}`,
      phoneNumber: user.phone,
      ref: Date.now(),
      status: "Sending...",
    };
    dispatch(addComment(data));
    return true;
  };

  const handleFileUpload = (file) => {
    // setChats((prevChats) => [
    //   ...prevChats,
    //   {
    //     type: "file",
    //     file,
    //     fileLabel: file.name,
    //   },
    // ]);
  };

  const getTenderStatus = (closeDate) => {
    let status;
    let date = moment(closeDate);
    let now = moment();

    if (date > now) {
      status = "Opened";
    } else {
      status = "Closed";
    }
    return status;
  };

  const renderChat = () => {
    const messages = !!comments[id] ? (Array.isArray(comments[id].data) ? comments[id].data : []) : [];
    return loading === "pending" ? (
      <>
        {!!comments[id] ? (
          <ChatBox messages={messages} onSubmit={handleSubmit} onUploadFile={handleFileUpload} />
        ) : (
          <div style={{ margin: 20 }}>
            <Loader />
          </div>
        )}
      </>
    ) : (
      <ChatBox messages={messages} onSubmit={handleSubmit} onUploadFile={handleFileUpload} />
    );
  };

  return (
    <div>
      <HomepageNavBar />
      <Container style={{ marginBottom: 120 }}>
        <BreadcrumbLabel>
          Home{" "}
          <BreadcrumbActive>
            {" "}
            /{" "}
            <Link style={linkStyle} to="/ocds-portal/tenders-list">
              {" "}
              Tenders Lists
            </Link>{" "}
            / {tenderInfo.packageNumber}
          </BreadcrumbActive>
        </BreadcrumbLabel>

        {status === "loading" ? (
          <Loader />
        ) : isError ? (
          <div style={{ textAlign: "center", margin: 50 }}>
            <EmptyTableSubTitle>{error.message}</EmptyTableSubTitle>
          </div>
        ) : (
          <>
            <TenderTitle>{tenderInfo.name}</TenderTitle>
            <Grid container spacing={3} style={{ marginBottom: 24 }}>
              <Grid item xs={6} sm={3}>
                <TenderSubDetailsWrapper>
                  <span>Tender ID</span>
                  <p>Tender Status</p>
                </TenderSubDetailsWrapper>
              </Grid>
              <Grid item xs={6} sm={3}>
                <TenderSubDetailsWrapper>
                  <span>Status</span>
                  <p>{getTenderStatus(tenderInfo.closeDate)}</p>
                </TenderSubDetailsWrapper>
              </Grid>
              <Grid item xs={6} sm={3}>
                <TenderSubDetailsWrapper>
                  <span>Date Published</span>
                  <p>{moment(tenderInfo.openDate).format("DD/MM/YY")}</p>
                </TenderSubDetailsWrapper>
              </Grid>
              <Grid item xs={6} sm={3}>
                <TenderSubDetailsWrapper>
                  <span>Expiry Date</span>
                  <p>{moment(tenderInfo.closeDate).format("DD/MM/YY")}</p>
                </TenderSubDetailsWrapper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TenderSubDetailsWrapper>
                  <span>Tender Description</span>
                  <p dangerouslySetInnerHTML={{ __html: tenderInfo.description }}></p>
                </TenderSubDetailsWrapper>
              </Grid>
              <Grid item xs={6} sm={3}>
                <TenderSubDetailsWrapper>
                  <span>Value</span>
                  <p>&#8358;{tenderInfo.budget?.toLocaleString()}</p>
                </TenderSubDetailsWrapper>
              </Grid>
              <Grid item xs={6} sm={3}>
                <TenderSubDetailsWrapper>
                  <span>Ministry</span>
                  <p>{tenderInfo.ministry}</p>
                </TenderSubDetailsWrapper>
              </Grid>
            </Grid>
            {
              getTenderStatus(tenderInfo.closeDate) !== "Closed" && ( 
                <>
                 <ButtonsWrapper>
                <Link style={{ textDecoration: "none" }} to={bidUrl}>
                  <BidTenderButton>Bid for tender</BidTenderButton>
                </Link>
              </ButtonsWrapper>
              <AdminSearchFilter title="Comments" noPadding normalSearch>
              {renderChat()}
            </AdminSearchFilter>              
              </>)
            }
          
           

          </>
        )}
      </Container>
    </div>
  );
};

export default SingleTenderDetails;
