import { Box } from "@material-ui/core";
import React from "react";
import { CuteModal, SubmitButton, ModalWrapper, ModalHeader, ModalCloseBtn, CautionText } from "./style";
import CheckImage from "../../../../../../assets/subtract-circle 1.svg";

const DeletesModal = ({ open, setModal, isLoading, onClickDelete = () => {}, bidder }) => {
  return (
    <CuteModal open={open}>
      <ModalWrapper width="41%">
        <Box style={{ display: "flex", justifyConetent: "center", flexDirection: "column" }}>
          <Box style={{ margin: "0 auto" }}>
            <img style={{ height: "8rem", width: "8rem" }} alt="success" src={CheckImage} />
          </Box>
          <ModalHeader style={{ margin: "0 auto", color: "#DA1414" }}>Remove Bidder </ModalHeader>
          <CautionText>
            You are about to remove <span style={{ fontWeight: "bold" }}>{bidder?.companyName}</span> as a bidder Click confirm to
            remove bidder.
          </CautionText>
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <ModalCloseBtn style={{ marginRight: 16 }} onClick={() => setModal(false)}>
              Close
            </ModalCloseBtn>
            <SubmitButton onClick={onClickDelete} type="submit" color="primary" disabled={isLoading}>
              {isLoading ? "Confirm..." : "Confirm"}
            </SubmitButton>
          </Box>
        </Box>
      </ModalWrapper>
    </CuteModal>
  );
};

export default DeletesModal;
