import Drawer from "@material-ui/core/Drawer";

import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { InputField } from "../../../../../components";
import { Roles } from "../../../../../components/AccessControl/UserRoles";
import { ErrorContainer } from "../../../../../components/Forms/Common/style";
import { useRoles } from "../../../../../hooks/useUserDetails";
import ToastContext from "../../../../../util/toastContext";
import { BtnBox, CancelButton, UpdateButton } from "../../../Administration/Settings/style";
import { DrawerWrapper, InputFieldBox, ModalBody, ModalTitle, ModalDescription, DLMFormTitle } from "./style";
import Modal from "@material-ui/core/Modal";
import { Box, TextField } from "@material-ui/core";
import { ReactComponent as CancelIcon } from "../../../../../assets/cancel.svg";
import { useHistory, useParams } from "react-router-dom";
import procurementAPI from "../../../../../redux/api/procurementAPI";
import { useMutation, useQuery, queryCache } from "react-query";
import LoadingButton from "../../../../../components/LoadingButton/LoadingButton";
import NumberFormat from "react-number-format";

function CNOForRFQPaymentDrawer({ openDrawer, toggleDrawer }) {
  const { register, handleSubmit, errors, reset, control } = useForm();
  const onRenderError = (error) => <ErrorContainer>{error && error.message}</ErrorContainer>;
  const { showAlert } = useContext(ToastContext);
  const [openModal, setOpenModal] = useState(false);
  const { activityId, procurementId } = useParams();
  const history = useHistory();
  const [isEdit, setIsEdit] = useState(false);
  const [costReduction, setCostReduction] = React.useState(0);
  const [reviewedQuotation, setReviewedQuotation] = React.useState(0);

  //Access control config
  const { role, isSuccess: getUserRolesSuccess } = useRoles();
  const isAdmin = role === Roles.KWPPA;
  const isProcurementOfficer = role === Roles.PROCUREMENT_OFFICER;

  const redirectToCert = () => {
    history.push(`/admin/procurement/activity/certificateofnoobjectionforpayment/${activityId}/${procurementId}/certificate`);
  };

  const [postCertificateMutation, { isLoading }] = useMutation(
    isEdit ? procurementAPI.updateCNOForPaymentData : procurementAPI.postCNOForPaymentData,
    {
      onSuccess: () => {
        showAlert({
          message: "Certificate data updated successfully",
          severity: "success",
        });
        isAdmin && redirectToCert();
        toggleDrawer();
        queryCache.invalidateQueries("activityDetails");
      },
      onError: (error) => {
        showAlert({
          message: error.message,
          severity: "error",
        });
      },
      onSettled() {
        queryCache.invalidateQueries("getCertificateData");
      },
    }
  );

  

  const [requestAmendmentMuation, { isLoading: isrequestAmendmentLoading }] = useMutation(
    procurementAPI.requestRFQAdmendmentCNO,
    {
      onSuccess: () => {
        showAlert({
          message: "Amendment requested successfully",
          severity: "success",
        });
        setOpenModal(false);
        toggleDrawer();
      },
      onError: (error) => {
        showAlert({
          message: error.message,
          severity: "error",
        });
      },
    }
  );

  const onSubmit = (values) => {
    const data = {
      ...values,
      costReduction,
      reviewedQuotation : parseFloat(reviewedQuotation),
      reviewCost: parseFloat(reviewedQuotation),
    };

    isEdit ? postCertificateMutation({ data, procurementId }) : postCertificateMutation({ data, activityId });
  };

  const { data: certificateData } = useQuery(["getPaymentCertificateData", procurementId], procurementAPI.getCNOForPaymentData, {
    onError: (error) => {
      showAlert({
        message: error.message,
        severity: "error",
      });
    },
    onSuccess: (data) => {
      if (data.data.message || data.data.headCode) {
        setIsEdit(true);
      }
    },
  });

  useEffect(() => {
    reset({
      serialNumber: certificateData?.data?.serialNumber,
      headCode: certificateData?.data?.headCode,
      subHeadCode: certificateData?.data?.subHeadCode,
      appropriationCode: certificateData?.data?.appropriationCode,
      reviewedQuotation: certificateData?.data?.reviewedQuotation,
      costReduction: certificateData?.data?.costReduction,
    });
    // setCostReduction(certificateData?.data?.costReduction);
    setReviewedQuotation(certificateData?.data?.reviewedQuotation)
  }, [reset, certificateData]);

  useEffect(() => {
    if (!getUserRolesSuccess) {
      return showAlert({
        severity: "error",
        message: "Network Error. Kindly Refresh",
      });
    }
  }, [getUserRolesSuccess, showAlert]);

  useEffect(() => {
    const newCostReduction = certificateData?.data?.quotation - reviewedQuotation;
    if (reviewedQuotation === 0 || reviewedQuotation === "") {
      setCostReduction(0);
      return;
    }
    if (newCostReduction < 0) {
      showAlert({
        message: "cost review cannot be larger than cost as submitted",
        severity: "error",
        duration: 2000,
      });
      setReviewedQuotation(0);
    } else {
      setCostReduction(newCostReduction);
    } // eslint-disable-next-line
  }, [reviewedQuotation]);

  return (
    <div>
      <Drawer anchor="right" open={openDrawer}>
        <DrawerWrapper>
          <div
            style={{
              width: "20px",
              height: "20px",
              display: "flex",
              float: "right",
              marginTop: "1rem",
              cursor: "pointer",
            }}
            onClick={toggleDrawer}
          >
            <CancelIcon />
          </div>
          <DLMFormTitle>Certificate of No Objection for Payment</DLMFormTitle>
          {certificateData?.data?.message && isProcurementOfficer && (
            <div style={{ background: "#ffffff", margin: "0 10px" }}>
              <TextField
                id="outlined-textarea"
                label="Message from KWPPA"
                multiline
                disabled
                InputProps={{
                  disableUnderline: true,
                }}
                value={certificateData?.data?.message}
                variant="outlined"
                rows={2}
                style={{ marginBottom: 20, width: "100%" }}
              />
            </div>
          )}

          <div style={{ background: "#ffffff" }}>
            <InputField
              label="Contract Ref. No."
              style={{ background: "#ffffff" }}
              value={certificateData?.data?.packageNumber}
              disabled
            />
          </div>
          <div style={{ background: "#ffffff" }}>
            <InputField
              label="Project Description"
              style={{ background: "#ffffff" }}
              value={certificateData?.data?.projectDescription}
              disabled
            />
          </div>
          <div style={{ background: "#ffffff" }}>
            <InputField
              label="Project Contractor"
              style={{ background: "#ffffff" }}
              value={certificateData?.data?.contractor}
              disabled
            />
          </div>
          <div style={{ background: "#ffffff" }}>
            <NumberFormat
              customInput={InputField}
              label="Project Cost"
              thousandSeparator
              prefix="₦"
              value={certificateData?.data?.quotation}
              disabled
              style={{ background: "#ffffff" }}
            />
          </div>
          <div style={{ background: "#ffffff" }}>
            <NumberFormat
              customInput={InputField}
              label="Approved Sum"
              thousandSeparator
              prefix="₦"
              value={certificateData?.data?.quotation}
              disabled
              style={{ background: "#ffffff" }}
            />
          </div>
          <div style={{ background: "#ffffff" }}>
            <NumberFormat
              customInput={InputField}
              label="PPA Review Cost"
              name="reviewedQuotation"
              control={control}
              thousandSeparator
              prefix="₦"
              disabled={!isAdmin}
              value={reviewedQuotation}
              onValueChange={(values) => {
                // eslint-disable-next-line
                const { _, value } = values;
                setReviewedQuotation(value);
              }}
              style={{ background: "#ffffff" }}
              inputRef={register({
                required: "Enter total project cost",
              })}
            />
            {onRenderError(errors.reviewedQuotation)}
          </div>
          <div style={{ background: "#ffffff" }}>
            <NumberFormat
              customInput={InputField}
              label="Cost Reduction"
              name="costReduction"
              thousandSeparator
              prefix="₦"
              variant="outlined"
              value={costReduction}
              style={{ background: "#ffffff" }}
              disabled
            />
            {onRenderError(errors.costReduction)}
          </div>
          <div style={{ background: "#ffffff" }}>
            <InputField
              label="Client Ministry/ Department/ Agency"
              style={{ background: "#ffffff" }}
              disabled
              value={certificateData?.data?.ministry}
            />
          </div>

          <InputFieldBox>
            <div style={{ background: "#ffffff" }}>
              <InputField
                label="Head Code	"
                name="headCode"
                style={{ background: "#ffffff" }}
                disabled={isAdmin}
                inputRef={register({
                  required: "Enter Head Code",
                })}
              />
              {onRenderError(errors.headCode)}
            </div>
            <div style={{ background: "#ffffff" }}>
              <InputField
                label="Sub-Head Code"
                name="subHeadCode"
                style={{ background: "#ffffff" }}
                disabled={isAdmin}
                inputRef={register({
                  required: "Enter Sub-head code",
                })}
              />
              {onRenderError(errors.subHeadCode)}
            </div>
            <div style={{ background: "#ffffff" }}>
              <InputField
                label="Appropriation Code"
                name="appropriationCode"
                style={{ background: "#ffffff" }}
                disabled={isAdmin}
                inputRef={register({
                  required: "Enter Appropriation Code",
                })}
              />
              {onRenderError(errors.appropriationCode)}
            </div>
          </InputFieldBox>

          {!isAdmin ? (
            <BtnBox>
              <CancelButton onClick={toggleDrawer}>Cancel</CancelButton>
              <LoadingButton loading={isLoading} style={{ marginLeft: "1rem" }} onClick={handleSubmit(onSubmit)}>
                Send Request
              </LoadingButton>
            </BtnBox>
          ) : (
            <BtnBox>
              <CancelButton onClick={() => setOpenModal(true)}>Request Amendment</CancelButton>
              <LoadingButton
                style={{ marginLeft: "1rem", borderRadius: "8px", textTransform: "capitalize" }}
                onClick={handleSubmit(onSubmit)}
                loading={isLoading}
              >
                Preview Certificate
              </LoadingButton>
            </BtnBox>
          )}
        </DrawerWrapper>
      </Drawer>
      <AdminModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        requestAmendmentMuation={requestAmendmentMuation}
        isrequestAmendmentLoading={isrequestAmendmentLoading}
        activityId={activityId}
      />
    </div>
  );
}

export default CNOForRFQPaymentDrawer;

const AdminModal = ({ openModal, setOpenModal, requestAmendmentMuation, isrequestAmendmentLoading, activityId }) => {
  const [reasonForAmendment, setReasonForAmendment] = useState("");

  const handleClick = () => {
    let payload = {
      message: reasonForAmendment,
    };
    requestAmendmentMuation({ payload, activityId });
  };

  return (
    <Modal open={openModal} onClose={() => setOpenModal(false)}>
      <ModalBody>
        <ModalTitle>Request Amendment</ModalTitle>
        <ModalDescription>Send a follow-up message to the procurement officer</ModalDescription>
        <TextField
          id="outlined-textarea"
          multiline
          InputProps={{
            disableUnderline: true,
          }}
          onChange={(e) => setReasonForAmendment(e.target.value)}
          value={reasonForAmendment}
          variant="outlined"
          placeholder="Type reason here"
          rows={4}
          style={{ marginBottom: 20 }}
        />
        <Box sx={{ display: "flex" }}>
          <UpdateButton disabled={isrequestAmendmentLoading} onClick={handleClick}>
            {isrequestAmendmentLoading ? "Requesting..." : "Request Amendment"}
          </UpdateButton>
          <CancelButton
            onClick={() => setOpenModal(false)}
            style={{
              marginLeft: "1rem",
            }}
          >
            Cancel
          </CancelButton>
        </Box>
      </ModalBody>
    </Modal>
  );
};
