import React, { useContext, useState } from "react";
import Certificate from "./Certificate";
import withAdminDashboard from "../../HOC/withAdminDashboard";
import procurementAPI from "../../../../redux/api/procurementAPI";
import { ContentWrapper } from "./style";
import Loader from "../../../../components/Common/Loader";
import { PageContainer, PageTitle, BreadCrumbWrapper } from "./style";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link, useParams, useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import ToastContext from "../../../../util/toastContext";
import LoadingButton from "../../../../components/LoadingButton/LoadingButton";
import GenerateCertificateModal from "./GenerateCerificateModal";
import { useProcurementMethod } from "../../../../hooks/queries/useProcurement";

function CertificatePage() {
  const { procurementId } = useParams();
  const ref = React.createRef();
  const { showAlert } = useContext(ToastContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const { data = {}, isLoading } = useProcurementMethod({ procurementId });

  const [uploadCertificateOfNoObjectionTender, uploadCertificateOfNoObjectionTenderQuery] = useMutation(
    procurementAPI.uploadCertificateOfNoObjectionTender,
    {
      onSuccess: () => {
        setLoading(false);
        showAlert({
          message: "Certificate of no objection has been generated successfully",
          severity: "success",
        });
        setTimeout(() => {
          history.push(`/admin/procurement/plan-details/${procurementId}`);
        }, 500);
      },
      onError: (error) => {
        showAlert({
          message: error.message,
          severity: "error",
        });
      },
    }
  );

  const handleGenerateCertificate = () => {
    setShowPreview(false);
    setOpenModal(true);
  };

  const handleDownloadPdf = async () => {
    setLoading(true);
    const element = ref.current;
    const canvas = await html2canvas(element, {
      allowTaint: false,
      useCORS: true,
    });
    const data = canvas.toDataURL("image/png");
    // document.body.appendChild(canvas);
    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("CNO_commence_bidding.pdf");

    var blob = pdf.output("blob", "CNO_commence_bidding.pdf");

    uploadCertificateOfNoObjectionTender({
      procurementPlanId: procurementId,
      file: blob,
    });
    setShowPreview(true);
  };

  if (isLoading) {
    return (
      <ContentWrapper>
        <Loader />
      </ContentWrapper>
    );
  }

  return (
    <PageContainer>
      <PageTitle>{data?.name}</PageTitle>
      <BreadCrumbWrapper>
        <Breadcrumbs>
          <Link to={"/admin/dashboard/overview"}>Home</Link>
          <Link to={"/admin/procurement/plans"}>Plans</Link>
          <Link to={`/admin/procurement/certificate-of-no-objection/${procurementId}`}>Certificate of No Objection</Link>
        </Breadcrumbs>
        <LoadingButton onClick={() => handleGenerateCertificate()} loading={loading}>
          Generate Certificate
        </LoadingButton>
      </BreadCrumbWrapper>
      <div style={{ width: "70%" }}>
        <Certificate CertRef={ref} showPreview={showPreview} />
      </div>
      <GenerateCertificateModal
        open={openModal}
        onAction={handleDownloadPdf}
        loading={uploadCertificateOfNoObjectionTenderQuery.isLoading}
        onClose={() => setOpenModal(false)}
      />
    </PageContainer>
  );
}

export default withAdminDashboard(CertificatePage);
