import { Button, Typography } from "@material-ui/core";
import styled from "styled-components";
import { theme } from "../.../../../../../util/theme";
import { TextareaAutosize as MUITextareaAutosize } from "@material-ui/core";

export const CancelButton = styled(Button)`
  text-transform: capitalize !important;
  && {
    background-color: #f0f5ff;
    border-radius: 8px;
    color: ${theme.color.primary};
    &:hover {
      background-color: #f0f5ff;
      opacity: 0.7;
    }
  }
`;
export const UpdateButton = styled(Button)`
  text-transform: capitalize !important;
  && {
    background-color: ${theme.color.primary};
    border-radius: 8px;
    color: white;
    &:hover {
      background-color: ${theme.color.primary};
      opacity: 0.8;
    }
    &:disabled {
      background-color: ${theme.color.primary};
      cursor: not-allowed !important;
      opacity: 0.5;
      color: white;
    }
  }
`;

export const ErrorContainer = styled(Typography)`
  margin: 5px 0;
  font-size: 10px !important;
  color: red;
`;

export const TextareaAutosize = styled(MUITextareaAutosize)`
  border: 1px solid #c9d4db;
  border-radius: 7px;
  background-color: #ffffff !important;
  height: 49px !important;
`;

export const DateRangeWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-left: 8px;

  .MuiFormControl-root {
    height: 49px;
    width:  ${(props) => props.width?.root || "47%"} ;
    border: 1px solid #cbd4da;
    margin-right: 3px;
    border-radius: 3px;
    background-color: ${theme.color.white};
  }
  .MuiInputBase-input {
    font-size: 14px;
    margin-top: 4px;
    margin-left: -3px;
    color: #637381;
  }
  .MuiButtonBase-root {
    bottom: 8px;
  }
  .MuiFormControl-root:hover {
    border: 1px solid ${theme.color.primary};
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${theme.color.primary};
  }
  .MuiFormControl-root > label {
    padding-left: 16px;
    padding-top: 8px;
  }
  .MuiFormControl-root > div {
    padding-left: 16px;
    padding-right: 12px;
  }
  .MuiInput-underline::before {
    border-bottom: none;
  }
  .MuiInput-underline::after {
    border-bottom: none;
  }
  .MuiFormControl-marginNormal {
    margin-top: 10px;
    margin-bottom: 8px;
  }
  .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom: none;
  }
}
`;

export const DrawerWrapper = styled.div`
  width: 609px;
  padding: 0 1rem;

  .MuiTab-root {
    min-width: 80px;
    text-transform: none;
  }

  .MuiTabs-root {
    padding: 0 1rem;
  }

  .MuiTabs-indicator {
    background-color: ${theme.color.primary};
  }

  .MuiTabs-Fixed {
    padding: 0 7px !important;
  }
`;

export const BtnBoX = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 2rem;
`;

const FormTitle = styled(Typography)`
  color: #445571;
  font-size: 24px !important;
  font-weight: 800 !important;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 10px !important;
`;

export const DrawerArchiveContractTitle = styled(FormTitle)`
  font-size: 24px !important;
  font-weight: bold !important;
  padding-top: 25.5px !important;
  padding-left: 5.5px !important;
`;
