import React, { useState } from "react";
import withAdminDashboard from "../../HOC/withAdminDashboard";
import { TitleContainer, PageTitle, AddButton, CirclePlusIconWrapper } from "../../../Common/style";
import { ContentWrapper } from "../../Procurement/style";
import Breadcrumb from "../../../Common/PageElements/Breadcrumb";
import AdminSearchFilter from "../../../../components/AdminSearchFilter";
import { Box, capitalize } from "@material-ui/core";
import { StyledStatus } from "./style";
import AddPermissionDrawer from "./AddPermissionDrawer";
import { useQuery } from "react-query";
import adminAPI from "../../../../redux/api/adminAPI";
import Loader from "../../../../components/Common/Loader";
import { Roles } from "../../../../components/AccessControl/UserRoles";
import AccessControl from "../../../../components/AccessControl";
import SearchTable, { StyledTableRow, StyledTableCell } from "../../../../components/SearchTable";
import { formatCurrency } from "../../../../util/formatCurrency";
import moment from "moment";
import { theme } from "../../../../util/theme";
import { Dropdown } from "../../../../components";
import useAlert from "../../../../hooks/useAlert";

function Permission() {
  const [showDrawer, setShowDrawer] = useState(false);
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    total: null,
    pageCount: null
  });
  const [role, setRole] = useState("");
  const [thresholdStatus, setThresholdStatus] = useState("");
  const { showAlert } = useAlert();


  const updatePagination = (key) => (value) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      [key]: value,
    }));
  };

  const { data: { data: roles = [] } = {} } = useQuery(
    [
      "roles",
      {
        Search: "",
      },
    ],
    adminAPI.getRoles
  );

  const { isFetching, data: { data = [] } = {}, isLoading } = useQuery(
    [
      "getRolesThreshholds",
      {
        PageSize: pagination.pageSize,
        PageNumber: pagination.pageNumber,
        role,
        status: thresholdStatus,
      },
    ],
    adminAPI.getRolesThreshholds,
    {
      onSuccess: ({ meta: { pagination : pageData } }) => {
        const { totalEntries, totalPages, perPage } = pageData;
        setPagination((prevPagination) => ({
          ...prevPagination,
          total: totalEntries,
          pageSize: perPage,
          pageCount: totalPages
        }));
      },
      onError: () => {
        showAlert({
          message: "Failed to fetch thresholds",
          severity: "error",
        });
      },
    }
  );



  const { data: categories = [] } = useQuery({
    queryFn: adminAPI.getProcurementCategories,
    queryKey: ["categories"],
  });

  const breadcrumbLinks = [
    {
      title: "Home",
      url: "/admin/dashboard/overview",
    },
    {
      title: "Administration",
    },
  ];

  const handleClickRole = (value) => {
    setRole(value);
  };

  return (
    <ContentWrapper>
      <TitleContainer>
        <div>
          <PageTitle>Permissions</PageTitle>
          <Breadcrumb values={breadcrumbLinks} />
        </div>
        <AccessControl allowedRoles={Roles.KWPPA}>
          <AddButton onClick={() => setShowDrawer(true)}>
            <CirclePlusIconWrapper />
            Set Threshold
          </AddButton>
        </AccessControl>
      </TitleContainer>
      <Box mb={3.125}>
        <AdminSearchFilter title="Filter" normalSearch>
          {/* <Box as="form" display="flex" alignItems="center">
            <SearchInput type="text" label="Search" setValue={(value) => setSearchText(value)} />
            <SearchButton onClick={handleClickSearch} loading={isFetching} disabled={isFetching}>
              Search
            </SearchButton>
          </Box> */}
          <Box>
            <Dropdown
              label="Role"
              values={[{ id: "", title: "All roles" }, ...roles].map((role) => ({
                label: role.title,
                value: role.id,
              }))}
              style={{ width: "100%", margin: "0 !important" }}
              onChange={(value) => handleClickRole(value)}
              name="roleId"
            />
          </Box>
          <Box ml={4}>
            <Dropdown
              label="Status"
              values={[
                { value: "", label: "All" },
                { value: "Active", label: "Active" },
                { value: "InActive", label: "Inactive" },
              ]}
              style={{ width: "100%", margin: "0 !important" }}
              onChange={(value) => setThresholdStatus(value)}
              name="status"
            />
          </Box>
        </AdminSearchFilter>
      </Box>
      {isLoading && <Loader />}
      {!isLoading && (
        <SearchTable
          page={pagination.pageNumber}
          setPage={updatePagination("pageNumber")}
          rowsPerPage={pagination.pageSize}
          setRowsPerPage={updatePagination("pageSize")}
          totalNumberOfPages={pagination.total}
          loading={isFetching}
          empty={data.length === 0}
          columns={[
            "S/N",
            "Role",
            "Category",
            "Lower threshold limit",
            "Upper threshold limit",
            "CREATION DATE",
            "EXPIRY DATE",
            "STATUS",
          ]}
        >
          {data.map(({ role, category, lowerLimitThreshold, upperLimitThreshold, expiryDate, status, createdAt }, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>{index + 1}</StyledTableCell>
              <StyledTableCell>{role}</StyledTableCell>
              <StyledTableCell>{category?.name}</StyledTableCell>
              <StyledTableCell>{formatCurrency(lowerLimitThreshold, true)}</StyledTableCell>
              <StyledTableCell>{formatCurrency(upperLimitThreshold, true)}</StyledTableCell>
              <StyledTableCell>{createdAt ? moment(createdAt).format("MMM DD, yyyy") : "N/A"}</StyledTableCell>
              <StyledTableCell>{expiryDate ? moment(expiryDate).format("MMM DD, yyyy") : "N/A"}</StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}>
                <StyledStatus
                  style={
                    status && status === "Active"
                      ? {
                          backgroundColor: theme.color.tags.approved.background,
                          border: `2px solid ${theme.color.tags.approved.background}`,
                          color: theme.color.tags.approved.text,
                        }
                      : status && status === "InActive"
                      ? {
                          backgroundColor: theme.color.tags.rejected.background,
                          border: `2px solid ${theme.color.tags.rejected.background}`,
                          color: theme.color.tags.rejected.text,
                        }
                      : {}
                  }
                >
                  {status ? capitalize(status) : "N/A"}
                </StyledStatus>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </SearchTable>
      )}
      <AddPermissionDrawer roles={roles} categories={categories?.data} show={showDrawer} onClose={() => setShowDrawer(false)} />
    </ContentWrapper>
  );
}

export default withAdminDashboard(Permission);
