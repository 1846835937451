import React from "react";
import { Box } from "@material-ui/core";
import DocumentUploadField from "../../../../../components/DocumentUploadField";
import { TitleContainer, BorderedBox } from "./style";

const LetterOfIntentUpload = ({ documents, setDocuments, disabled, renderDeadline}) => {
  const removeFile = (file) => {
    setDocuments([]);
  };
  
  const addFiles = (newDocuments) => {
    setDocuments(newDocuments);
  };
  
  return (
    <div>
      <TitleContainer>Upload Letter of Intent</TitleContainer>
      <BorderedBox>
        <DocumentUploadField disabled={disabled} addFiles={addFiles} removeFile={removeFile} files={documents} multiple={false} />
      </BorderedBox>
      <Box mt={2} mb={4}>
      {renderDeadline()}
      </Box>
    </div>
  );
};

export default LetterOfIntentUpload;
