import React, { useContext, useEffect } from "react";
import { AppDrawwer, Checkbox, SearchInput } from "../../../../../../shared";
import { Box } from "@material-ui/core";
import { Procurement_Method } from "../../../../../../util/constants";
import ToastContext from "../../../../../../util/toastContext";

const VendorDrawer = ({
  open,
  onClose,
  onAction,
  vendor,
  setVendor,
  search,
  setSearch,
  vendorList,
  options,
  loading,
  method,
}) => {
  const { showAlert } = useContext(ToastContext);

  useEffect(() => {
    if (method === Procurement_Method.DIRECT_PROCUREMENT && vendor.length > 1) {
      showAlert({ message: "You can only pick one vendor", severity: "error" });
      setVendor([vendor[0]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendor]);

  return (
    <AppDrawwer
      open={open}
      onCloseCancel
      onAction={onClose}
      onClose={onClose}
      closeOnCancel
      title="Select Vendor"
      actionText="Save"
      loading={loading}
    >
      <Box style={{ width: "450px" }}>
        <div style={{ width: "100%" }}>
          <SearchInput value={search} setValue={setSearch} label="Search vendor" />
        </div>
        <div>
          <Checkbox value={vendor} setValue={setVendor} options={options} specialLabel />
        </div>
      </Box>
    </AppDrawwer>
  );
};

export default VendorDrawer;
