import React, { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import NumberFormat from "react-number-format";
import InputField from "../../../../components/InputField";
import { ErrorContainer, CancelButton, UpdateButton, DateRangeWrapper } from "./ocdsEdit.styles";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import contractsAPI from "../../../../redux/api/contractAPI";
import ToastContext from "../../../../util/toastContext";
import { queryCache, useMutation } from "react-query";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Dropdown from "../../../../components/DropdownField";

const contractAwardedDefaultValues = {
  awardedDate: null,
  awardCriteria: "",
  projectName: "",
  projectDescription: "",
  budgetDescription: "",
  contractSum: 0,
  contractor: "",
};

export const archiveContractDurationOptions = [
  {
    label: "Days",
    value: "days",
  },
  {
    label: "Weeks",
    value: "weeks",
  },
  {
    label: "Months",
    value: "months",
  },
];

const contractAwardedSchema = yup.object({
  awardedDate: yup.date().required("Awarded Date is rquired"),
  awardCriteria: yup.string(),
  projectName: yup.string().nullable(),
  projectDescription: yup.string().nullable(),
  budgetDescription: yup.string().nullable(),
  contractSum: yup.number().nullable(),
  contractor: yup.string().nullable(),
});

const EditAwardedCompanyTab = ({ data, isLoading, handleEdit }) => {
  const history = useHistory();
  const { errors, reset, control, handleSubmit } = useForm({
    defaultValues: contractAwardedDefaultValues,
    resolver: yupResolver(contractAwardedSchema),
  });
  const { showAlert } = useContext(ToastContext);
  const [mutate] = useMutation(contractsAPI.updateOcdsContractAwardPortal, {
    onSuccess: () => {
      showAlert({
        message: "Successfully updated contract",
        severity: "success",
      });
      queryCache.invalidateQueries("tenderAndAwardRequest");
      setTimeout(() => history.push(`/admin/ocdsList/ocds/${data.id}`), 1000);
    },
  });

  useEffect(() => {
    let payload = {};
    console.log({ data });
    if (data) {
      const durationVal = data.contractDuration.split(" ");
      payload.duration = durationVal[0].startsWith(0) ? durationVal[0]?.slice(1) : durationVal[0];
      payload.durationType = durationVal[1];
      payload.awardedDate = data?.awardedDate || new Date();
      payload.projectDescription = data?.projectDescription || data?.title || "";
      payload.projectName = data?.title || "";
      payload.budgetDescription = data?.budgetDescription || "";
      payload.awardCriteria = data?.awardCriteria || "";
      payload.contractSum = data?.contractAmount || 0;
      payload.contractor = data?.awardedContractor || "";
    }
    reset(payload);
  }, [reset, data]);

  const onSubmit = (e) => {
    console.log({ e });

    mutate({
      payload: {
        contractSum: e.contractSum,
        dateOfAward: data.awardedDate,
        projectTitle: data.title,
        projectDescription: data.projectDescription,
        contractDuration: e.duration + " " + e.durationType,
        contractor: data?.awardedContractor || "",
      },
      id: data.id,
    });
  };
  const onRenderError = (error) => <ErrorContainer>{error && error.message}</ErrorContainer>;
  return (
    <div>
      <div>
        <DateRangeWrapper width={{ root: "97%" }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              control={control}
              name="awardedDate"
              render={(props) => (
                <KeyboardDatePicker
                  label="Date of Award"
                  disabled
                  disableToolbar
                  openTo="year"
                  views={["year", "month", "date"]}
                  variant="inline"
                  format="dd-MMM-yyyy"
                  placeholder="MM/DD/YYYY"
                  margin="normal"
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...props}
                />
              )}
            />
          </MuiPickersUtilsProvider>
        </DateRangeWrapper>

        {onRenderError(errors.awardDate)}
      </div>
      <div>
        <Controller
          control={control}
          name="projectName"
          render={({ ref, ...props }) => (
            <InputField
              disabled
              label="Project Title"
              inputRef={ref}
              name="projectName"
              style={{ background: "#ffffff" }}
              {...props}
            />
          )}
        />
      </div>
      <div>
        <Controller
          control={control}
          name="projectDescription"
          render={({ ref, ...props }) => (
            <InputField
              disabled
              label="Project Desciption"
              inputRef={ref}
              // name="projectDescription"
              style={{ background: "#ffffff" }}
              multiline
              {...props}
            />
          )}
        />{" "}
      </div>

      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 10rem" }}>
          <div style={{ background: "#ffffff" }}>
            <Controller
              control={control}
              name="duration"
              render={({ onChange, ...props }) => (
                // <InputField
                //   label="Contract Duration"
                //   inputRef={ref}
                //   type="number"
                //   name="duration"
                //   style={{ background: "#ffffff" }}
                //   {...props}
                // />
                <NumberFormat
                  allowNegative={false}
                  customInput={InputField}
                  label="Contract Duration"
                  variant="outlined"
                  {...props}
                  onValueChange={(values) => {
                    const { value } = values;
                    onChange(value);
                  }}
                />
              )}
            />
            {onRenderError(errors.duration)}
          </div>
          <div>
            <Controller
              control={control}
              name="durationType"
              render={({ ref, ...props }) => (
                <Dropdown
                  inputRef={ref}
                  style={{ width: "inherit" }}
                  label="Contract Status"
                  {...props}
                  values={archiveContractDurationOptions}
                />
              )}
            />
            {onRenderError(errors.contractStatus)}
          </div>
        </div>
        <div style={{ background: "#ffffff" }}>
          <Controller
            control={control}
            name="contractSum"
            render={({ onChange, ...props }) => (
              <NumberFormat
                customInput={InputField}
                label="Contract Sum"
                thousandSeparator
                prefix="₦"
                variant="outlined"
                {...props}
                onValueChange={(values) => {
                  const { value } = values;
                  onChange(value);
                }}
              />
            )}
          />{" "}
        </div>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div>
          <Controller
            control={control}
            name="contractor"
            render={({ ref, ...props }) => (
              <InputField disabled label="Contractor" inputRef={ref} style={{ background: "#ffffff" }} {...props} />
            )}
          />{" "}
          {onRenderError(errors.contractor)}
        </div>
      </div>
      <div
        style={{
          padding: "2rem",
          display: "grid",
          gridTemplateColumns: "150px 1fr",
          placeItems: "center start",
          marginTop: "30px",
        }}
      >
        <UpdateButton onClick={handleSubmit(onSubmit)}>{isLoading ? "Saving Changes..." : "Save Changes"}</UpdateButton>
        <CancelButton onClick={() => history.push(`/admin/ocdsList/ocds/${data.id}`)}>Cancel</CancelButton>
      </div>
    </div>
  );
};

EditAwardedCompanyTab.propTypes = {};

export default EditAwardedCompanyTab;
