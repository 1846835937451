import styled from "styled-components";
import { Modal } from "@material-ui/core";

export const MainText = styled.p`
  font-size: ${(props) => props.fontSize};
  color: ${(props) => (props.color ? props.color : `#5F7483`)};
  margin-top: ${(props) => props.mt};
  margin-right: 40px;
  font-weight: ${(props) => props.fw};
`;

export const MainTitle = styled.p`
  height: 24px;
  color: #1e2b37;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
`;

export const InfoCardWrapper = styled.div`
  box-sizing: border-box;
  border: 1px solid #cbd4da;
  border-radius: 3px;
  background-color: #ffffff;
  padding: 20.5px !important;
  margin-bottom: 20px;
  min-height: 400px;
  position: relative;

  > .topRightCard {
    background-color: #f2f2f2;
    border: 1px solid #c9d4db;
    border-radius: 0px 0px 0px 3px;
    padding: 0 20px 20px;
    position: absolute;
    right: 0%;
    top: 0%;
    min-width: 21%;
  }

  > .oneCard {
    min-width: 4%;
  }
`;

export const HL = styled.hr`
  margin-top: ${(props) => props.mt};
  border: 1px solid #f2f2f2;
`;

export const CuteGrid = styled.div`
  display: flex;
  justify-content: ${(props) => (props.start ? `flex-start` : `space-between`)};
  margin-top: ${(props) => props.mt};
  width: ${(props) => props.width};
  flex-wrap: wrap;
`;

export const CardWrapper = styled.div`
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #cbd4da;
  margin-top: ${(props) => props.mt};
`;

export const Pad = styled.div`
  padding: 20.5px;
`;

export const DocumentSection = styled.div`
  background-color: #fcfcfd;
  border: 1px solid #c9d4db;
  padding: 20.5px !important;
  position: relative;

  > .icon {
    position: absolute;
    right: 2%;
    top: 8%;
  }
`;

export const CuteModal = styled(Modal)`
  display: grid;
  place-items: center;
`;

export const ModalHeader = styled.div`
  font-weight: bold;
  font-size: 24px;
  color: #3f5673;
  line-height: 33px;
`;

export const ModalWrapper = styled.div`
  padding: 20.5px;
  background: white;
  margin-bottom: 20%;
`;

export const InnerModalBox = styled.div`
  margin-top: 30px;
  padding: 15px 20px;
  background: white;
  border: 1px solid #00b0f1;
  border-radius: 3px;
`;

export const ModalProceedBtn = styled.button`
  background: #3bd278;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #3bd278;
  width: 40%;
  margin-right: 2%;
  padding: 10px;

  &:hover,
  &:focus {
    background: #48b072;
    border: 1px solid transparent;
  }
`;

export const ModalRejectBtn = styled.button`
  background: #eb5757;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #eb5757;
  width: 30%;
  margin-right: 2%;
  padding: 10px;

  &:hover,
  &:focus {
    background: #e33f3f;
    border: 1px solid transparent;
  }
`;

export const ModalCloseBtn = styled.button`
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  color: #979797;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border: none;
  width: 20%;
  padding: 10px;

  &:hover,
  &:focus {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
    border: 1px solid transparent;
  }
`;

export const InterestExpressedPane = styled.div`
  background: ${(props) => (props.state === "pending" ? `#F2994A` : `#6FCF97`)};
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 20px;
`;

export const ProgressWrap = styled.div`
  padding: 20px 20px 40px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  margin: 22px 0 30px 0;
`;

export const FileBoard = styled.div`
  background-color: #fcfcfc;
  padding: 20px;
  margin-top: 20px;
`;

export const FileCard = styled.div`
  background: #ffffff;
  border: 1px solid #c9d4db;
  border-radius: 3px;
  padding: 20px 50px;
  margin: 20px 35px 20px 0;
`;

export const FileText = styled.p`
  font-size: ${(props) => props.fontSize};
  text-align: center;
  margin-top: ${(props) => props.mt};
  font-weight: ${(props) => props.fw};
  color: ${(props) => (props.color ? props.color : `#5F7483`)};
  width: ${(props) => props.wd};
`;

export const EmptyDocText = styled.div`
  display: grid;
  place-items: center;
  margin: 5%;
  height: 24px;
  color: #5f7483;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
`;

export const AwaitContract = styled.div`
  background: #fcfcfd;
  border: 1px solid #c9d4db;
`;

export const CautionText = styled.p`
  width: 385px;
  height: 88px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
`;

export const SubmitButton = styled.button`
  color: #ffffff;
  box-sizing: border-box;
  height: 41px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  background-color: #03adeb;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;

  &:disabled,
  &[disabled] {
    background-color: #c9d4db;
    cursor: not-allowed;
  }
`;
