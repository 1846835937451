import React from 'react'
import { EditorState } from "draft-js";
import { useParams, useHistory } from "react-router-dom";
import { PageTabs } from "../../../../../util/theme";
import Breadcrumb from "../../../../Common/PageElements/Breadcrumb";
import { Box } from "@material-ui/core";
import {
  GeneralContentWrapper,
  GeneralTitlePage,
  GeneralPlanTab,
  CardContentWrapper,
  GeneralTabContainer,
  PlanCardContainer,
  GeneralButtonContainer
} from './style'
import { CancelButton } from '../../../../../components/Forms/Common/style'
import CheckEntryCard from '../../../../../components/CheckEntryCard'
import withAdminDashboard from '../../../HOC/withAdminDashboard';
import NoticeInforContent from './NoticeInforContent';
import ReviewCommentsSection from '../../../../../components/ReviewCommentsSection';
import { useForm } from 'react-hook-form';
import useAlert from '../../../../../hooks/useAlert';
import { useRoles } from '../../../../../hooks/useUserDetails';
import { Roles } from '../../../../../components/AccessControl/UserRoles';
import { queryCache, useMutation } from 'react-query';
import procurementAPI from '../../../../../redux/api/procurementAPI';
import { LoadingButton } from '../../../../../components';


const generalPlanLinks = [
  {
    url: "/admin/procurement/plans",
    title: "Home",
    forwardSlash: true,
  },
  {
    url: "#",
    title: "Plan",
  },
];

const BillOfQuantityPreparation = () => {
  const { role } = useRoles();
  const history = useHistory();
  const isMaker = role === Roles.PROCUREMENT_OFFICER;
  const [tabValue, setTabValue] = React.useState(0);
  const [next, setNext] = React.useState(0);
  const { activityId, procurementId } = useParams();
  const [completedForm, setCompletedForm] = React.useState([]);
  const [dataState, setDataState] = React.useState({
    files: [],
    editorError: null,
    wordCount: 0,
    editorState: EditorState.createEmpty(),
    comment: "",
    completionNumber: null,
    completionPeriod: null,
  });


  const { showAlert, Toast } = useAlert();

  const { handleSubmit } = useForm()
  React.useEffect(() => {
    const durationCompleted = completedForm.includes(0);
    
    const hasDuration = Boolean(dataState.completionNumber && dataState.completionPeriod)

    if(!durationCompleted && hasDuration) {
      setCompletedForm((prev) => [...prev, 0]);
    } else {
      setCompletedForm((prev) => prev.filter((x) => x !== 0));
    }
    // eslint-disable-next-line
  },[dataState.completionPeriod, dataState.completionNumber])

  React.useEffect(() => {
    const editorCompleted = completedForm.includes(1);
    if (dataState.editorState.getCurrentContent().getPlainText("\u0001").length >= 1) {
      if (!editorCompleted) {
        setCompletedForm((prev) => [...prev, 1]);
      }
    } else {
      if (editorCompleted) {
        setCompletedForm((prev) => prev.filter((el) => el !== 1));
      }
    }
    // eslint-disable-next-line
  }, [dataState.editorState.getCurrentContent().getPlainText("\u0001")])

  React.useEffect(() => {
    const billDocumentUploaded = completedForm.includes(2);
    if (dataState.files.length >= 1) {
      if (!billDocumentUploaded) {
        setCompletedForm((prev) => [...prev, 2]);
      }
    } else {
      if (billDocumentUploaded) {
        setCompletedForm((prev) => prev.filter((el) => el !== 2));
      }
    }
    // eslint-disable-next-line
  }, [dataState.files])
  const handleTabChange = (evt, newValue) => {
    setNext(newValue);
    setTabValue(newValue);
  };

  const handleMovePrev = () => {
    next > 0 && setNext(0);
    next > 0 && setTabValue(0);
  };

  const handleMoveNext = () => {
    next < 1 && setNext(next + 1);
    next < 1 && setTabValue(next + 1);
    next <= 1 && !completedForm.includes(next) && setCompletedForm([...completedForm, next]);
  };

  const [mutate, { isLoading }] = useMutation(procurementAPI.postBillOfQuantity, {
    onError: (err) => {
      showAlert &&
        showAlert({
          message: err.message,
          severity: "error",
        });
    },
    onSuccess: () => {
      showAlert &&
        showAlert({
          message: "Bill of quantity successfully submitted",
          severity: "success",
        });
      queryCache.invalidateQueries("generalPlanDetails");
      history.push(`/admin/procurement/plan-details/${procurementId}`);
    },
  });

  useMutation(({ payload }) => procurementAPI.postBillOfQuantity({ activityId, payload }), {
    onError: (err) => {
      showAlert &&
        showAlert({
          message: err.message,
          severity: "error",
        });
    },
    onSuccess: (data) => {
      showAlert &&
        showAlert({
          message: "Bill of quantity successfully submitted",
          severity: "success",
        });
    },
  });

  const tabPages = {
    0: <NoticeInforContent dataState={dataState} setData={setDataState} />,
    1: <ReviewCommentsSection objectId={activityId} />,
  };
  const onSubmit = (data) => {
    const formData = new FormData();
    if (!dataState.files?.length) return;
    if (!dataState.completionNumber) return;
    if (!dataState.completionPeriod) return;
    if (!dataState.wordCount) {
      setDataState((prev) => ({
        ...prev,
        editorError: true,
      }));
      return;
    }
    const description = dataState.editorState.getCurrentContent().getPlainText("\u0001");
    formData.append("description", description);
    formData.append("completionNumber", dataState.completionNumber);
    formData.append("completionPeriod", dataState.completionPeriod);
    // eslint-disable-next-line
    dataState.files?.map((eachDoc) => formData.append("file", eachDoc));

    mutate({ payload: formData, activityId });
  };

  return (
    <GeneralContentWrapper>
      <Toast float />
      <Box marginBottom="35px">
        <GeneralTitlePage>Preparation of Bill of Quantity Documents</GeneralTitlePage>
        <Breadcrumb values={generalPlanLinks} />
      </Box>
      <CardContentWrapper>
        <PlanCardContainer>
          <GeneralTabContainer>
            <PageTabs value={tabValue} onChange={handleTabChange}>
              <GeneralPlanTab label="Notice Info" activetab={(tabValue === 0).toString()} />
              <GeneralPlanTab label="Review" activetab={(tabValue === 1).toString()} />
            </PageTabs>
          </GeneralTabContainer>
          {tabPages[tabValue]}
          <GeneralButtonContainer>
            <CardContentWrapper>
              <CancelButton disabled={!tabValue} onClick={() => handleMovePrev()}>
                Back
              </CancelButton>
              {isMaker && tabValue !== 1 && (
                <LoadingButton
                  onClick={tabValue === 0 ? handleSubmit(onSubmit) : handleMoveNext}
                  loading={isLoading}
                  disabled={!Boolean(dataState.completionNumber && dataState.completionPeriod)}
                >
                  Submit
                </LoadingButton>
              )}
            </CardContentWrapper>
          </GeneralButtonContainer>
        </PlanCardContainer>
        <CheckEntryCard
          cardTitle="Check Entries"
          textValues={["Completion Period", "Notice Info", "Bill of Quantity"]}
          completedForm={completedForm}
        />
      </CardContentWrapper>
    </GeneralContentWrapper>
  );
};

export default withAdminDashboard(React.memo(BillOfQuantityPreparation));
