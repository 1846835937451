import React, { useContext, useState } from "react";
import Certificate from "./Certificate";

import Loader from "../../../../../components/Common/Loader";
import { PageContainer, PageTitle, ContentWrapper, BreadCrumbWrapper } from "./style";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import ToastContext from "../../../../../util/toastContext";
import LoadingButton from "../../../../../components/LoadingButton/LoadingButton";
import certificateOfNoObjectionApi from "../../../../../redux/api/certificateOfNoObjectionApi";
import GenerateCertificateModal from "./GenerateCertificateModal";
import { useProcurementMethod } from "../../../../../hooks/queries/useProcurement";
import { ProcurementMethodAccessControl } from "../../../../../shared";
import CNOForPaymentCertificate from "./CNOForPaymentCertificate";
import { Procurement_Method } from "../../../../../util/constants";

const getActivityName = (title) =>
  title
    .replace(/[^\w\s]/gi, "")
    .split(" ")
    .join("")
    .toLowerCase();

function CertificatePage() {
  const { activityId, procurementId, activityName } = useParams();
  const location = useLocation();

  const ref = React.createRef();
  const { showAlert } = useContext(ToastContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const { data = {}, isFetching, method, isSuccess } = useProcurementMethod({ procurementId });

  // This is a hack for RFQ because there are two CNO's on the activity list, we use this to know which is which payment or award
  const activityInfo = data?.procurementPlanActivities?.find(
    (x) => getActivityName(x.title) === window.location.pathname.split("/")[4]
  );

  const isPayment =
    [Procurement_Method.DIRECT_LABOUR, Procurement_Method.REQ_QUOTATION, Procurement_Method.DIRECT_PROCUREMENT].includes(
      method
    ) && activityInfo?.index !== 4;

  const isCNOPayment = location.pathname.includes("certificateofnoobjectionforpayment");
  console.log({ isPayment, isCNOPayment, activityName });
  const [uploadCertificate, uploadCertificateQuery] = useMutation(
    isPayment && isCNOPayment
      ? certificateOfNoObjectionApi.uploadCNOForPayment
      : certificateOfNoObjectionApi.uploadCertificateOfObjection,
    {
      onSuccess: () => {
        setLoading(false);
        showAlert({
          message: "Certificate of no objection has been generated successfully",
          severity: "success",
        });
        setTimeout(() => {
          [Procurement_Method.REQ_QUOTATION].includes(method) && isPayment
            ? history.push(`/admin/procurement/activity/certificateofnoobjectionforpayment/${activityId}/${procurementId}`)
            : history.push(`/admin/procurement/activity/certificateofnoobjection/${activityId}/${procurementId}`);
        }, 500);
      },
      onError: (error) => {
        setLoading(false);
        showAlert({
          message: error.message,
          severity: "error",
        });
      },
    }
  );


  const handleGenerateCertificate = () => {
    setShowPreview(false);
    setOpenModal(true);
  };

  const handleDownloadPdf = async () => {
    setLoading(true);
    const fileName = isPayment && isCNOPayment ? "CNO_for_payment" : "CNO_award_contract.pdf";
    const element = ref.current;
    const canvas = await html2canvas(element, {
      allowTaint: false,
      useCORS: true,
    });
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(fileName);

    var blob = pdf.output("blob", fileName);
    uploadCertificate({
      activityId,
      File: blob,
    });

    setShowPreview(true);
  };

  if (isFetching) {
    return (
      <ContentWrapper>
        <Loader />
      </ContentWrapper>
    );
  }

  return (
    <PageContainer>
      <PageTitle>Certificate of No Objection</PageTitle>
      <BreadCrumbWrapper>
        <Breadcrumbs>
          <Link to={"/admin/dashboard/overview"}>Home</Link>
          <Link to={"/admin/procurement/plans"}>Plans</Link>
          <Link to={`/admin/procurement/certificate-of-no-objection/${procurementId}`}>{data?.name}</Link>
        </Breadcrumbs>
        <LoadingButton onClick={() => handleGenerateCertificate()} loading={loading}>
          Generate Certificate
        </LoadingButton>
      </BreadCrumbWrapper>
      <div style={{ width: "70%" }}>
        <ProcurementMethodAccessControl
          allowedMethod={[
            // Procurement_Method.DIRECT_LABOUR,
            Procurement_Method.INTERNATIONAL_COMPETITIVE_BIDDING,
            Procurement_Method.NATIONAL_COMPETITIVE_BIDDING,
            Procurement_Method.RESTRICTED_TENDERING_METHOD,
            !isPayment && Procurement_Method.REQ_QUOTATION,
            isPayment && !isCNOPayment && Procurement_Method.DIRECT_PROCUREMENT,
          ]}
          method={method}
          isSuccess={isSuccess}
        >
          <Certificate CertRef={ref} showPreview={showPreview} />
        </ProcurementMethodAccessControl>
        <ProcurementMethodAccessControl
          allowedMethod={[
            Procurement_Method.DIRECT_LABOUR,
            isPayment && Procurement_Method.REQ_QUOTATION,
            isPayment && isCNOPayment && Procurement_Method.DIRECT_PROCUREMENT,
          ]}
          method={method}
          isSuccess={isSuccess}
        >
          <CNOForPaymentCertificate isPayment={isPayment} CertRef={ref} showPreview={showPreview} />
        </ProcurementMethodAccessControl>
      </div>
      <GenerateCertificateModal
        method={method}
        open={openModal}
        onAction={handleDownloadPdf}
        loading={uploadCertificateQuery.isLoading}
        onClose={() => setOpenModal(false)}
      />
    </PageContainer>
  );
}

export default CertificatePage;
