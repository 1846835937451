import React from "react"
import styled from "styled-components";
import FormControl from '@material-ui/core/FormControl';
import { theme } from "../../util/theme";
import { withStyles } from "@material-ui/core/styles";
import { Radio } from "@material-ui/core";


export const StyledFormControl = styled(FormControl)`
  &.MuiFormControl-root {

    & .MuiFormLabel-root {
      color: ${theme.color.textSecondary};
      font-weight: 700;      
      font-size: 16px;
      line-height: 1.5;
    }
    & .MuiFormGroup-root {
      & .MuiFormControlLabel-root {
        & .MuiRadio-root {
          &.Mui-checked {
            color: ${theme.color.primary};
          }
          &.Mui-checked + span > div {
            border: 1.5px solid ${theme.color.primary};
          }
        }
      }
    }
  }
`;


export const CustomRadio = withStyles({
  root: {
    color: "#C4CDD5",
    "&$checked": {
      color: "#2D9CDB",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
