import React, { useEffect, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { CancelButton,  ErrorContainer, UpdateButton } from "./ocdsEdit.styles";
import InputField from "../../../../components/InputField";
import Dropdown from "../../../../components/DropdownField";
import contractsAPI from "../../../../redux/api/contractAPI";
import { queryCache, useMutation } from "react-query";
import ToastContext from "../../../../util/toastContext";
import { tenderingCompetitiveBiddingFunc, tenderingReviewMethodFunc, tenderingStagesFunc } from "./contract.utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom";


const EditTenderDetailsTab = ({ data }) => {
  const { register, errors, watch, reset, control, handleSubmit } = useForm();
  const { showAlert } = useContext(ToastContext);
  const history = useHistory();
  const statusOption = ["Active", "Completed"].map((item) => ({ label: item, value: item }));
  const procurementMethodOptions = [
    "International Competitive Bidding",
    "Direct Labor",
    "National Competitive Bidding",
    "Direct Procurement Method",
    "Restricted Tendering Method",
    "Request For Quotations",
  ];

  const tenderingStagesOption = ["Single Stage Tendering", "Two Stage Tendering"].map((item, i) => ({
    label: item,
    value: i + 1,
  }));
  const reviewMethodOPtions = ["Prior Review", "Post Review"].map((item, i) => ({ label: item, value: i }));

  useEffect(() => {
    data.tenderingStage = tenderingStagesFunc(data.tenderingStage);
    data.reviewMethod = tenderingReviewMethodFunc(data.reviewMethod);
    data.procurementMethod = tenderingCompetitiveBiddingFunc(data.procurementMethod);
    reset(data);
  }, [data, reset]);

  const [mutate, { isLoading: updatingContract }] = useMutation(contractsAPI.updateOcdsContractTenderPortal, {
    onSuccess: () => {
      showAlert({
        message: "Successfully updated contract",
        severity: "success",
      });
      queryCache.invalidateQueries("tenderAndAwardRequest");
      setTimeout(() => history.push(`/admin/ocdsList/ocds/${data.id}`), 1000);
    },
  });

  const onSubmit = (py) => {
    const payload = {
      tenderTitle: py.title,
      tenderStatus: py.tenderStatus,
      // evaluationStatus: "string",
      tenderPackageNumber: py.packageNumber,
      procurementMethod: py.procurementMethod,
      tenderingStage: py.tenderingStage,
      reviewingMethod: py.reviewingMethod,
    };

    mutate({ payload, id: data.id });
  };

  const onRenderError = (error) => <ErrorContainer>{error && error.message}</ErrorContainer>;
  return (
    <div>
      <div>
        <Controller
          control={control}
          name="title"
          render={(props) => <InputField disabled label="Tender Title" style={{ background: "#ffffff" }} {...props} />}
        />
      </div>
      {/* <div>
        <DateRangeWrapper width={{ root: "97%" }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              control={control}
              name="bidSolicitationDate"
              render={(props) => (
                <KeyboardDatePicker
                  label="Tender Date"
                  disableToolbar
                  openTo="year"
                  views={["year", "month", "date"]}
                  variant="inline"
                  format="dd-MMM-yyyy"
                  placeholder="MM/DD/YYYY"
                  margin="normal"
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...props}
                />
              )}
            />
          </MuiPickersUtilsProvider>
        </DateRangeWrapper>
      </div> */}
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div>
          <Controller
            control={control}
            name="tenderStatus"
            render={(props) => (
              <Dropdown
                inputRef={register({
                  required: "Select Tender Stage",
                })}
                defaultValue={watch("tenderStatus")}
                style={{ width: "inherit" }}
                label="Tender Status"
                {...props}
                values={statusOption}
              />
            )}
          />{" "}
          {onRenderError(errors?.tender?.tenderStatus)}
        </div>
        <div>
          <Controller
            control={control}
            name="packageNumber"
            render={(props) => (
              <InputField
                label="Package Number"
                inputRef={register({
                  required: "Package Number is required",
                })}
                style={{ background: "#ffffff" }}
                {...props}
              />
            )}
          />{" "}
          {onRenderError(errors?.tender?.packageNumber)}
        </div>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div>
          <Controller
            control={control}
            name="procurementMethod"
            render={(props) => (
              <Dropdown
                name="procurementMethod"
                defaultValue={watch("procurementMethod")}
                style={{ width: "inherit" }}
                label="Procurement Method"
                {...props}
                values={procurementMethodOptions.map((item, i) => ({ label: item, value: i }))}
              />
            )}
          />{" "}
          {onRenderError(errors.startDate)}
        </div>
        <div>
          <Controller
            control={control}
            name="numberOfTenderers"
            render={(props) => (
              <InputField
                disabled
                label="Number of Tenderers"
                type="number"
                style={{ background: "#ffffff" }}
                {...props}
                value={data?.bidders?.length || 0}
              />
            )}
          />{" "}
          {onRenderError(errors.tenderingNumber)}
        </div>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr  1fr" }}>
        <div>
          <Controller
            control={control}
            name="tenderingStage"
            render={(props) => (
              <Dropdown
                defaultValue={watch("tenderingStage")}
                name="tenderingStage"
                style={{ width: "inherit" }}
                label="Tendering Stages"
                {...props}
                values={tenderingStagesOption}
              />
            )}
          />{" "}
        </div>
        <div>
          <Controller
            control={control}
            name="reviewMethod"
            render={(props) => (
              <Dropdown
                inputRef={register({
                  required: "Review Method",
                })}
                defaultValue={watch("reviewingMethod")}
                name="reviewMethod"
                style={{ width: "inherit" }}
                label="Review Method"
                {...props}
                values={reviewMethodOPtions}
              />
            )}
          />{" "}
          {onRenderError(errors?.tender?.reviewMethod)}
        </div>
      </div>
      <div
        style={{
          padding: "2rem",
          display: "grid",
          gridTemplateColumns: "150px 1fr",
          placeItems: "center start",
          marginTop: "30px",
        }}
      >
        <UpdateButton onClick={handleSubmit(onSubmit)}>{updatingContract ? "Saving Changes..." : "Save Changes"}</UpdateButton>
        <CancelButton onClick={() => history.push(`/admin/ocdsList/ocds/${data.id}`)}>Cancel</CancelButton>
      </div>
    </div>
  );
};

EditTenderDetailsTab.propTypes = {};

export default EditTenderDetailsTab;
