import React from "react";
import { Box, Grid } from "@material-ui/core";
import DraftEditor from "../../../../../components/DraftEditor";
import { CardContentWrapper, GeneralCardContentWrapper, TitleLabel, DurationContainer } from "./style";
import DocumentUploadField from "../../../../../components/DocumentUploadField";
import { Roles } from "../../../../../components/AccessControl/UserRoles";
import { useRoles } from "../../../../../hooks/useUserDetails";
import ControlledDropdown from "../../../../../components/ControlledDropdownField";
import { InputField } from "../../../../../components";

const NoticeInforContent = ({ setData, dataState }) => {
  const { role } = useRoles();
  const isDisabled = role !== Roles.PROCUREMENT_OFFICER;
  const marginRight = "0";
  const width = "100%";


  const updateFIeld = (key) => (value) => {
    if (key === "remove-file") {
      const filteredFile = dataState?.files?.filter((item) => item?.name !== value.name);

      setData((pre) => ({
        ...pre,
        files: filteredFile,
      }));
      return;
    }
    if (key === "files") {
      setData((prev) => ({
        ...prev,
        [key]: [...dataState?.files, ...value],
      }));

      return;
    }

    if (key === "editor") {
      setData((prev) => ({
        ...prev,
        editorError: null,
        editorState: value,
        wordCount: value.getCurrentContent().getPlainText().length,
      }));
      return;
    }

    if (key === "completionPeriod" || key === "completionNumber") {
      setData((prev) => ({
        ...prev,
        [key]: value === "" ? null : value,
      }));
      return;
    }
    setData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  return (
    <Box>
      <TitleLabel>Completion Period</TitleLabel>
      <Box mb={2.5}>
        <DurationContainer>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <InputField
                label="Enter Number"
                name="completionNumber"
                value={dataState?.completionNumber}
                setValue={updateFIeld("completionNumber")}
              />
            </Grid>
            <Grid item xs={6}>
              <ControlledDropdown
                label="Select Duration"
                name="completionPeriod"
                value={dataState.completionPeriod}
                onChange={({ target }) => updateFIeld("completionPeriod")(target.value)}
                options={[
                  { label: "", value: null },
                  { label: "Week(s)", value: 1 },
                  { label: "Month(s)", value: 2 },
                  { label: "Year(s)", value: 3 },
                ]}
              />
            </Grid>
          </Grid>
        </DurationContainer>
      </Box>
      <TitleLabel>Note</TitleLabel>
      <GeneralCardContentWrapper>
        <DraftEditor
          editorError={dataState?.editorError}
          editorState={dataState?.editorState}
          setEditorState={updateFIeld("editor")}
          isDisabled={isDisabled}
        />
      </GeneralCardContentWrapper>
      <GeneralCardContentWrapper>
        <CardContentWrapper>
          <div style={{ width, marginRight }}>
            <DocumentUploadField
              files={dataState?.files}
              addFiles={updateFIeld("files")}
              removeFile={updateFIeld("remove-file")}
              uploadSubText="Format (jpg, png, xls, pdf, csv, ppt). Maximum of 5MB"
              loading={false}
            />
          </div>
        </CardContentWrapper>
      </GeneralCardContentWrapper>
    </Box>
  );
};

NoticeInforContent.propTypes = {};

export default NoticeInforContent;
