import React, { useContext } from 'react';
import { TextField, Box, InputAdornment, styled, CircularProgress } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";

import Breadcrumb from '../../../../../Common/PageElements/Breadcrumb';
import { ContentWrapper } from '../../../../Administration/Staff/style';
import { GeneralTitlePage } from '../../../../../../components/AddGeneralPlan/style';
import {
  FormWrapper,
  FormTitle,
  FormSubTitle,
  BenchMarkWrapper,
  BenmarkLabel,
  BtnWrapper,
  BackButton,
  ProceedButton,
  LoadingButton,
} from './style';
import { useMutation } from "react-query";
import ToastContext from '../../../../../../util/toastContext';
import { useHistory, useParams } from "react-router-dom";
import procurementAPI from '../../../../../../redux/api/procurementAPI';


const CustomTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#666666',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#666666',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#666666',
    },
  },
});


function CreateTechnicalEvaluation() {
  const { showAlert } = useContext(ToastContext);
  const history = useHistory();
  const { control, handleSubmit, errors } = useForm();

  const { procurementId, activityId } = useParams()

  const redirectUrl = `/admin/procurement/activity/receiptofresponses/${activityId}/${procurementId}`
  const pageUrl = `/admin/procurement/activity/receiptofresponses/${activityId}/${procurementId}/create-technical-evaluation`;

  const [mutate, { isLoading, isError, isSuccess, error }] = useMutation(procurementAPI.addTechEvaluation);

  const onSubmit = ({ benchmark }) => {
      mutate({
        procurementId,
        payload: {
          hasEvaluation: true,
          benchmark,
        }
      });
  }


  if (isError) {
    showAlert({
      message: error.message,
      severity: "error",
      durationInMs: 2000,
    });
  }

  if (isSuccess) {
    showAlert({
      message: "Technical evaluation created successfully",
      severity: "success",
      durationInMs: 2000,
    });
    history.push(redirectUrl);
  }

  return (
    <ContentWrapper>
      <Box mt={2}>
        <GeneralTitlePage>Evaluation</GeneralTitlePage>
        <Breadcrumb
          values={[
            {
              url: "/dashboard/overview",
              title: "Home",
            },
            {
              url: redirectUrl,
              title: "Evaluation",
            },
            {
              url: pageUrl,
              title: "Create Technical Evaluation",
            },
          ]}
        />
      </Box>
      <FormWrapper>
        <FormTitle>Create Technical Evaluation Requirement hgjkhgjk</FormTitle>
        <FormSubTitle>Kindly fill in the fields</FormSubTitle>
        <BenchMarkWrapper>
          <BenmarkLabel>Benchmark</BenmarkLabel>
          <Controller
            name="benchmark"
            control={control}
            rules={{
              required: "Field is required",
              min: { value: 0, message: `Minimum value is 0` },
              max: { value: 100, message: `Maximum value is 100` }
            }}
            render={(field) => {
              return (
                <CustomTextField
                  {...field}
                  label="Benchmark for vendors"
                  variant="outlined"
                  fullWidth
                  type='number'
                  error={!!errors?.benchmark?.message}
                  helperText={errors?.benchmark?.message}
                  inputProps={{ min: 0, max: 100 }}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}
                />
              )
            }}
          />

        </BenchMarkWrapper>
        <BtnWrapper>
          <BackButton size="large" onClick={() => history.goBack()}>Back</BackButton>
          {
            isLoading ?
              <LoadingButton disabled>
                Wait... {" "}<CircularProgress size={14} />
              </LoadingButton>
              :
              <ProceedButton
                variant="contained"
                size="large"
                onClick={handleSubmit(onSubmit)}
              >
                Proceed
              </ProceedButton>
          }
        </BtnWrapper>
      </FormWrapper>
    </ContentWrapper>
  )
}

export default CreateTechnicalEvaluation;