import React from "react";
import { Wrapper } from "./style";

const EmptyTableBody = ({ children, width }) => (
  <div style={{ minHeight: "50vh", display: "flex", alignItems: "center" }}>
    <Wrapper width={width}>{children}</Wrapper>
  </div>
);

export default EmptyTableBody;
