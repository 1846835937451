import React, { useState } from "react"
import { CardContainer,ContactHolder, DirectorHolder,DocumentHolder,ContactRole, Directors, DirAvatar, ValueText, InfoText } from "./style";
import {IoMdArrowDropdown,IoMdArrowDropup, } from "react-icons/io"
import {BsDownload, BsPersonFill} from "react-icons/bs"
import {AiOutlineFilePdf} from "react-icons/ai"
import { useQuery } from "react-query";
import adminAPI from "../../../redux/api/adminAPI";
import { useParams } from "react-router-dom";
import vendorsAPI from "../../../redux/api/vendorsAPI";
import moment from "moment"
import {Box, Typography} from "@material-ui/core"



const MoreInformation = ()=>{
    const { vendorId } = useParams();
    const [toggle, setToggle] = useState(false)
    const changeToggle = ()=>{
        setToggle(!toggle)
    }

    const {vendorId: id } = useParams();
    const { data: documentDetails = [] } = useQuery(
        ["documentDetail", id],
        adminAPI.getUploadedDocuments
      );
    const { data: directorDetails = [] } = useQuery(
        ["directorDetails", id],
        adminAPI.getDirectorDetails
      );
    
      const { data: vendorDetails } = useQuery(["companyDetails", { vendorId }], vendorsAPI.getVendorsCompanyDetails);

const vendorContact = vendorDetails?.vendorContact



     
    return(
        <CardContainer>
            <Box style={{width:"100%",
             display:"flex", justifyContent: 'space-between'}}>
             <Typography>More Information</Typography>
                <div onClick ={changeToggle}>
                    {toggle?<IoMdArrowDropup/>: <IoMdArrowDropdown/>}
                </div>
            </Box>
            <Box style={{display:toggle? "flex": "none", justifyContent:"space-between", margin:"10px 0px"}}>
                <Box>
                    <Box>
                        <ValueText>Contact Person</ValueText>
                        <ContactHolder>
                            <div style={{display:"flex", alignItems:"center"}}>
                            <div style={{fontSize:"11px !important"}}>{vendorContact?.firstName} {vendorContact?.lastName}</div>
                            <ContactRole>{vendorContact?.position}</ContactRole>
                            </div>
                            <div>
                                
                                <InfoText>{vendorContact?.email} . {vendorContact?.phoneNumber}</InfoText>
                                
                            </div>
                        </ContactHolder>
                    </Box>
                    <DirectorHolder>
                        <ValueText>Directors</ValueText>
                       {
                        directorDetails.length > 0?
                        
                            directorDetails.map((props)=>(
                                <Directors key={props.id}>
                              <DirAvatar>
                            {
                                props.passportPhoto === ""? <BsPersonFill/>:  <img alt="passport"  style={{borderRadius:"50%" ,width:"40px", height:"40px"}} src={JSON.parse(props.passportPhoto).url}/>
                            }
                            
                        </DirAvatar>
                        <Box style={{display:"flex", flexDirection:"column" , minWidth:"400px"}}>
                             <Typography style={{display:"flex", alignItems:"center", width:"100%",fontSize:"11px", whiteSpace:"nowrap" }}>
                                <b>{props.title}. {props.firstName} {props.lastName}</b>
                                <span style={{margin:"0px 5px"}}> . </span>
                                <InfoText>{props.phoneNumber}</InfoText>
                            </Typography> 
                            <InfoText>{props.addressLine1} </InfoText>                             
                        </Box>
                    </Directors>
                       
                            ))
                        
                        :    
                        <InfoText>No DIrector Available</InfoText>
                       }
                    </DirectorHolder>
                </Box>
                <Box style={{minWidth:"300px"}}>
                    <ValueText>Document Uploaded</ValueText>
                    {
                        documentDetails.length > 0?
                        
                            documentDetails.map((props)=>{
                                const url =JSON.parse(props.file).url
                                return(
                                <DocumentHolder>
                            <AiOutlineFilePdf />
                            <Box style={{width:"400px", margin: "0px 10px", wordWrap:"break-word" }}>
                                <ValueText>{props.name}</ValueText>
                                <InfoText>{moment(props.createdAt).format("DD, MMMM, yyyy")}</InfoText>
                            </Box>
                            
                            <a rel="noopener noreferrer"  href={url} download={props.name} target="_blank" style={{textDecoration:"none", color:"black"}}>
                            <BsDownload/>
                            </a>
                        </DocumentHolder>
                            )})
                       :<InfoText>No Document Available</InfoText>
                    }
                </Box>
            </Box>
        </CardContainer>
    )
}

export default MoreInformation