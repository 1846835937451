import React, { useEffect } from "react";
import { queryCache, useMutation, useQuery } from "react-query";
import { ChatArea } from "./style";
import ChatBox from "../ChatBox/ChatBox";
import Loader from "../Common/Loader";
import useAlert from "../../hooks/useAlert";
import procurementAPI from "../../redux/api/procurementAPI";
import AdminSearchFilter from "../AdminSearchFilter";

export default function ReviewCommentsSection({ objectId }) {
  const queryKey = ["getReviews", objectId];
  const { status, error, data: messages } = useQuery(queryKey, procurementAPI.getReviews, {
    enabled: objectId !== "new"
  });
  const [addReview] = useMutation(procurementAPI.addReview, {
    onMutate({ message, id }) {
      queryCache.cancelQueries(queryKey);
      queryCache.setQueryData(queryKey, (prevData) => [
        ...prevData,
        {
          type: "message",
          optimisticUpdateId: id,
          author: "Sending...",
          message,
          date: new Date(),
        },
      ]);
      return () => {
        queryCache.setQueryData(queryKey, (previousReviews) => (
          previousReviews.filter((review) => review.optimisticUpdateId !== id)
        ));
      };
    },
    onError(mutationError, variables, rollback) {
      rollback();
    },
    onSuccess(review, { id }) {
      queryCache.setQueryData(queryKey, (prevData) => {
        const newData = [...prevData];
        const optimisticReviewIndex = newData.findIndex((r) => r.optimisticUpdateId === id);
        newData[optimisticReviewIndex] = review;
        return newData;
      });
    },
    onSettled() {
      queryCache.invalidateQueries(queryKey);
    },
  });

  const { Toast, showAlert } = useAlert();

  const handleSubmit = async (message) => {
    try {
      await addReview({
        id: messages.length + 1,
        objectId,
        message,
      });
      return true;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    if (status === "error") {
      showAlert({
        severity: "error",
        message: error.message,
        durationInMs: 3000,
      });
    }
  }, [status, error, showAlert]);

  return (
    <React.Fragment>
      {status === "loading" ? (
        <ChatArea>
          <Loader />
        </ChatArea>
      ) : null}
      {messages ? (
        <AdminSearchFilter title="Comments" noPadding normalSearch>
          <ChatBox
            messages={messages}
            onSubmit={handleSubmit}
            onUploadFile={() => {
              /* noop */
            }}
          />
        </AdminSearchFilter>
      ) : null}
      <Toast float />
    </React.Fragment>
  );
}
