import React from 'react'
import { InputAdornment, TextField } from '@material-ui/core'
import { ReactComponent as SearchIcon } from "../../assets/search.svg"
import { InputWrapper } from './style'

const SearchInput = ({
  value,
  setValue,
  name,
  label,
  style,
  ...rest
}) => {

  return (
    <InputWrapper>
      <TextField
        value={value}
        fullWidth
        onChange={(e) => setValue(e.target.value)}
        InputProps={{
          startAdornment: <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        }}
        style={style}
        {...rest}
        variant="filled"
      />
    </InputWrapper>
  )
}


export default SearchInput
