import { Box, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { format } from "date-fns/esm";
import React, { useContext } from "react";
import { useMutation, queryCache, useQuery } from "react-query";
import { Link, useHistory, useParams } from "react-router-dom";
import AccessControl from "../../../../../components/AccessControl";
import { Roles } from "../../../../../components/AccessControl/UserRoles";
import FileProgress from "../../../../../components/FileProgress/FileProgress";
import {
  useFetchBoQDocuments,
  useProcurementPlanDetails,
} from "../../../../../hooks/queries/useProcurement";
import { useRoles } from "../../../../../hooks/useUserDetails";
import procurementAPI from "../../../../../redux/api/procurementAPI";
import ToastContext from "../../../../../util/toastContext";
// import { theme } from '../../../../../util/theme'
import withAdminDashboard from "../../../HOC/withAdminDashboard";
import { NextButton } from "../SpecificProcurementNotice/style";
import EndorseBillModal from "./EndorseBillModal";
import {
  BreadCrumbWrapper,
  CardBody,
  BOQContentWrapper,
  // CardLabel,
  CardSection,
  CardWrapper,
  PageTitle,
  StatusBox,
  StyledTable,
  TableWrapper,
  TopDocumentSection,
  TopSection,
  TopSectionBody,
  TopSectionTitle,
} from "./style";

const BIllOfQuantityApproval = () => {
  const [openEndorse, setOpenEndorse] = React.useState(false);
  const { procurementId, activityId } = useParams();
  const [dataSource, setDataSource] = React.useState([]);
  const { showAlert } = useContext(ToastContext);
  const history = useHistory();
  const { role } = useRoles();

  const { data: procurementDetails } = useProcurementPlanDetails({ procurementId });

  useQuery([`boq-endorsement-data`], () => procurementAPI.getBoQEndorse({ procurementId }), {
    enabled: !!procurementId,
    staleTime: 0,
    onError: (err) => {
      showAlert &&
        showAlert({
          message: err.message,
          severity: "error",
        });
    },
    onSuccess: ({ data }) => {
      setDataSource(data);
    },
  });

  const { data } = useFetchBoQDocuments({ procurementId });
  const [mutate, { isLoading }] = useMutation(procurementAPI.endorseBOQ, {
    onSuccess: () => {
      queryCache.invalidateQueries("boq-endorsement-data");
      showAlert({
        message: `${role === Roles.GOVERNOR ? `Successfully Approved`: `Successfully endorsed`}`,
        severity: "success",
      });
      setOpenEndorse(false);
    },
    onError: (err) => {
      setOpenEndorse(false);
      showAlert({
        message: `${err}`,
        severity: "error",
      });
    },
  });

  const boqDocumentArray = data?.data?.documents;

  const BOQStatus = dataSource[dataSource?.length - 1]?.status?.toLowerCase();

  const redirectToFileUrl = (url) => {
    window.open(url, "_blank");
  };

  const handleEndorsementClick = () => {
    if (BOQStatus === "endorsed" && role === Roles.PROCUREMENT_OFFICER) {
      history.push(`/admin/procurement/plan-details/${procurementId}`);
    }
    setOpenEndorse(true);
  };

  return (
    <>
      <BOQContentWrapper>
        <PageTitle>Approval of Bill of Quantity</PageTitle>
        <BreadCrumbWrapper>
          <Link to={"/admin/dashboard/overview"}>Home {`/ `}</Link>
          <Link to={"/admin/procurement/plans"}>Plans {`/ `}</Link>
          <Link to={`/admin/procurement/plan-details/${procurementId}`}>{procurementDetails?.name}</Link>
        </BreadCrumbWrapper>
        <CardWrapper>
          <TopSection>
            <TopSectionTitle>Note</TopSectionTitle>
            <TopSectionBody>{procurementDetails?.description}</TopSectionBody>
          </TopSection>
          <TopSection>
            <TopDocumentSection>
              <CardSection>
                {Array.isArray(boqDocumentArray) && boqDocumentArray?.length > 0 && (
                  <div>
                    <TopSectionTitle>Bill of Quantity Document</TopSectionTitle>
                    <CardBody style={{ gridColumnGap: 15, marginTop: 10 }} spacing={4}>
                      {boqDocumentArray?.map((memo) => (
                        <FileProgress
                          progress={100}
                          fileName={memo?.file.original_filename}
                          fileSize={memo?.file.bytes}
                          hasEyes={true}
                          onClickEyes={() => redirectToFileUrl(memo?.file.url)}
                        />
                      ))}
                    </CardBody>
                  </div>
                )}
              </CardSection>
              <NextButton
                disabled={(role === Roles.PROCUREMENT_OFFICER && BOQStatus !== "endorsed")}
                onClick={handleEndorsementClick}
                loading={false}
              >
                <AccessControl allowedRoles={[Roles.PROCUREMENT_OFFICER]}>
                  {BOQStatus === "endorsed" ? `Approved` : `Waiting for bill Approval`}
                </AccessControl>
                <AccessControl
                  allowedRoles={[
                    Roles.ACCOUNTING_OFFICER,
                    Roles.COMMISSIONER,
                    Roles.SUPERVISING_OFFICER,
                    Roles.GOVERNOR,
                    Roles.KWPPA,
                    Roles.PERMANENT_SECRETARY,
                  ]}
                >
                  {role === Roles.GOVERNOR ? `Approve Bill` : `Endorse Bill`}
                </AccessControl>
              </NextButton>
            </TopDocumentSection>
          </TopSection>
        </CardWrapper>
      </BOQContentWrapper>
      <TableWrapper>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableCell>Role</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSource?.map((field) => (
              <TableRow key={field.role}>
                <TableCell>{field.role}</TableCell>
                <TableCell>
                  <StatusBox colorStatus={field.status}>{field.status}</StatusBox>
                </TableCell>
                <TableCell>{field.endorseDate ? format(new Date(field.endorseDate), "dd MMM yyyy") : " Not Available"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
        <Box style={{ width: "100%", padding: "40px" }} />
      </TableWrapper>
      <EndorseBillModal
        role={role}
        open={Boolean(openEndorse)}
        onClose={() => setOpenEndorse(false)}
        onAction={() => mutate(activityId)}
        loading={isLoading}
      />
    </>
  );
};

export default withAdminDashboard(BIllOfQuantityApproval);
