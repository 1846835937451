/**
 * formatCurrency takes an amount and returns a string that contains
 * the provided amount in currency form
 * @param {number} amount The amount to be formatted as a string
 * @param {boolean} withSymbol Keep the currency symbol as part of the returned string
 * @param {boolean} stripDecimals Remove decimals after the conversion
 */
export function formatCurrency(amount, withSymbol = false, stripDecimals = false) {
  let formatted = new Intl.NumberFormat("en-NG", {
    currency: "NGN",
    style: "currency",
  }).format(amount);
  if (!withSymbol) formatted = formatted.substr(withSymbol ? 0 : 1);
  if (stripDecimals) formatted = formatted.substring(0, formatted.indexOf("."));
  return formatted;
}

export function revertCurrencyValToNum(value = "0") {
  const numValue = value.toString().split(",").join("");
  return Number(numValue);
};

export function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

/**
 * formatCurrency takes an amount and returns a string that contains
 * the provided amount in currency form * 
 * @param {string} str the string of currency format
 */

export const validatCurrency = (str) => {
  const regEx = /^[1-9]\d*(((,\d{3}){1})?(\.\d{0,3})?)$/
  if (typeof str != "string") return false // we only process strings!  
  return regEx.test(str)
}
