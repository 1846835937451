import { useMutation } from "react-query";
import adminAPI from "../../redux/api/adminAPI";

export const useUploadVendor = ({ showAlert, queryCache, setModal, formatErrorToCSV, saveFile }) => {
  const [mutate, { isLoading, ...rest }] = useMutation(({ payload }) => adminAPI.uploadVendor({ payload }), {
    onSuccess: (values) => {
      if (showAlert) {
        showAlert({
          message: "Vendor Upload was successful",
          severity: "success",
        });
      }
      if (setModal) {
        setModal(null);
      }
      if (formatErrorToCSV) {
        if (values?.data?.failedEntries?.length > 1) {
          const csvArray = formatErrorToCSV(values?.data?.failedEntries || []);
          if (saveFile) {
            saveFile(csvArray, "vendor_list_failed_upload");
          }
        }
      }
      queryCache.invalidateQueries("getVendorsUploadList", {
        exact: false,
        throwOnError: true,
        refetchActive: true,
        refetchInactive: false,
      });
    },
    onError: (error) => {
      if (showAlert) {
        showAlert({
          message: error?.message || "Vendor Upload was successful",
          severity: "error",
        });
      }
    },
  });

  return {
    uploadVendor: mutate,
    upoadingVendor: isLoading,
    ...rest,
  };
};
