import React from "react";
import { Container } from "@material-ui/core";
import { Link } from "react-router-dom";

import { awardedContracts } from "./mock";
import withAwardsNTenders from "../HOC/withAwardsNTenders";
import withHeaderFooterHOC from "../HOC/withHeaderFooterHOC";
import { BreadcrumbLabel, BreadcrumbActive } from "../TenderList/style";
import useAlert from "../../../hooks/useAlert";
import ToastContext from "../../../util/toastContext";

const AwardedContracts = () => withAwardsNTenders(null)({ data: awardedContracts, title: "contracts" });

const linkStyle = { textDecoration: "none", color: "inherit" };

const HomeAwardedContracts = ({ title }) => {
  const { showAlert, Toast } = useAlert();

  return (
    <ToastContext.Provider value={{ showAlert: showAlert, Toast: Toast }}>
      <Container>
        <BreadcrumbLabel>
          <Link style={linkStyle} to="/">
            Home
          </Link>{" "}
          <BreadcrumbActive> /{title === "tenders" ? "Tenders Lists" : "Awarded Contracts"}</BreadcrumbActive>
        </BreadcrumbLabel>
        <AwardedContracts />
      </Container>
    </ToastContext.Provider>
  );
};

export default withHeaderFooterHOC(HomeAwardedContracts);
