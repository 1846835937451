import { Box } from '@material-ui/core'
import React from 'react'
import { CuteModal, ModalWrapper, ModalHeader, MainText, ModalRejectBtn } from '../style'

const NoEndorseModal = ({open, setModal}) => {
    return (
        <CuteModal open={open}>
          <ModalWrapper>
            <ModalHeader>This tender is not yet open to accept quotes</ModalHeader>
             <MainText mt="30px" mb="30px">Pleae check back later</MainText>
                <Box display="flex" justifyContent="center">
                    <ModalRejectBtn onClick={() => setModal(false)}>Close</ModalRejectBtn>
                </Box>
          </ModalWrapper>  
        </CuteModal>
    )
}

export default NoEndorseModal
