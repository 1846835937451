import React from "react";
import PropTypes from "prop-types";
import { capitalize } from "lodash";
import { CuteModal } from "../../../Vendor/Procurement/style";
import { BtnBox, ModalHeader, ModalWrapper } from "./style";
import { LoadingButton } from "../../../../components";
import { Box, Typography } from "@material-ui/core";
import { CancelButton } from "../../../../components/Forms/Common/style";

const StatusAlert = ({ open, onClose, onAction, selected, loading, statusKey, reviewType }) => {
  const titleMapping = {
    rejected: `Reject ${capitalize(reviewType)} Review`,
    reviewed: `Review ${capitalize(reviewType)} Review`,
    endorsed: `Endorse ${capitalize(reviewType)} Review`,
    approved: `Approve ${capitalize(reviewType)} Review`,
  };

  const okTextMapping = {
    rejected: "Reject",
    reviewed: "Review",
    endorsed: "Endorsed",
    approved: "Approve",
  };

  const bodyMapping = {
    rejected: `Are you sure you want to ${okTextMapping[statusKey]} this request`,
    reviewed: `Are you sure you want to ${okTextMapping[statusKey]} this request`,
    endorsed: `Are you sure you want to ${okTextMapping[statusKey]} this request`,
    approved: `Are you sure you want to ${okTextMapping[statusKey]} this request`,
  };

  // const severityMapping = {
  //   rejected: "Reject",
  //   reviewed: "Review",
  //   endorsed: "Endorsed",
  //   approved: "Approve",
  // };

  return (
    <CuteModal open={Boolean(open)} onClose={onClose}>
      <ModalWrapper style={{ display: "grid", gridTemplateRows: "30px 30px auto", placeItems: "center" }}>
        <ModalHeader>{titleMapping[statusKey]}</ModalHeader>
        <Typography color="#3F5673" fontWeight={400} fontSize="18px">
          {bodyMapping[statusKey]}
        </Typography>
        <Box style={{ display: "grid", placeItems: "center" }}>
          <BtnBox>
            <LoadingButton
              disabled={loading}
              style={{ borderRadius: "8px" }}
              onClick={() => onAction({ ...selected })}
              loading={loading}
            >
              {okTextMapping[statusKey]}
            </LoadingButton>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
          </BtnBox>
        </Box>
      </ModalWrapper>
    </CuteModal>
  );
};

StatusAlert.propTypes = {
  statusKey: PropTypes.oneOf(["rejected", "reviewed", "endorsed", "approved"]),
  reviewType: PropTypes.oneOf(["upward", "downward"]),
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  loading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
};

StatusAlert.defaultProps = {
  statusKey: "reviewed",
  reviewType: "upward",
  onClose: () => {},
  onAction: () => {},
  loading: false,
};

export default StatusAlert;
