import Drawer from "@material-ui/core/Drawer";

import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { InputField } from "../../../../components";
import { ErrorContainer } from "../../../../components/Forms/Common/style";
import ToastContext from "../../../../util/toastContext";
import { BtnBox, CancelButton } from "../../Administration/Settings/style";
import { DrawerWrapper, DrawerFormTitle, InputFieldBox } from "../../Procurement/Activitiies/CertificateOfNoObjection/style";
import { ReactComponent as CancelIcon } from "../../../../assets/cancel.svg";
import { useHistory, useParams } from "react-router-dom";
import procurementAPI from "../../../../redux/api/procurementAPI";
import { useMutation, useQuery, queryCache } from "react-query";
import LoadingButton from "../../../../components/LoadingButton/LoadingButton";

function FormDrawer({ openDrawer, toggleDrawer }) {
  const { register, handleSubmit, errors } = useForm();
  const onRenderError = (error) => <ErrorContainer>{error && error.message}</ErrorContainer>;
  const { showAlert } = useContext(ToastContext);
  const { procurementId } = useParams();
  const history = useHistory();
  const [costReview, setCostReview] = React.useState(0);
  const [costReduction, setCostReduction] = React.useState(0);

  const [generateCertificate, { isLoading: generatingCertificate }] = useMutation(procurementAPI.postCNObiddingCertInformation, {
    onSuccess: () => {
      history.push(`/admin/procurement/certificate-of-no-objection/${procurementId}`);
    },
    onError: (error) => {
      showAlert({
        message: error.message,
        severity: "error",
      });
    },
    onSettled() {
      queryCache.invalidateQueries("getCertificateData");
    },
  });

  const onSubmit = (data) => {

    const values = {
      ...data,
      costReduction,
    }

    generateCertificate({ values, procurementId });
  };

  const { data: certificateData } = useQuery(["getCertificateData", procurementId], procurementAPI.getCertificateData, {
    onSuccess: ({ data }) => {
      setCostReview(data?.budget);
    },
    onError: (error) => {
      showAlert({
        message: error.message,
        severity: "error",
      });
    },
  });


  React.useEffect(() => {
    const newCostReduction = certificateData?.data?.budget - costReview;
    if (costReview === 0 || costReview === "") {
      setCostReduction(0);
      return;
    }
    if (newCostReduction < 0) {
      showAlert({
        message: "cost review cannot be larger than cost as submitted",
        severity: "error",
        duration: 2000,
      });
      setCostReview(0);
    } else {
      setCostReduction(newCostReduction);
    }
    // eslint-disable-next-line
  }, [costReview]);

  return (
    <div>
      <Drawer anchor="right" open={openDrawer}>
        <DrawerWrapper>
          <div
            style={{
              width: "20px",
              height: "20px",
              display: "flex",
              float: "right",
              marginTop: "1rem",
              cursor: "pointer",
            }}
            onClick={toggleDrawer}
          >
            <CancelIcon />
          </div>
          <DrawerFormTitle style={{ clear: "right" }}> Certificate of No Objection to Commence Bidding</DrawerFormTitle>
          <div style={{ background: "#ffffff" }}>
            <InputField
              label="Contract Ref. No."
              style={{ background: "#ffffff" }}
              value={certificateData?.data?.packageNumber}
              disabled
            />
          </div>
          <div style={{ background: "#ffffff" }}>
            <InputField
              label="Project Description"
              style={{ background: "#ffffff" }}
              value={certificateData?.data?.description}
              disabled
            />
          </div>

          <div style={{ background: "#ffffff" }}>
            <InputField
              label="Project Cost as Submitted"
              style={{ background: "#fff" }}
              value={certificateData?.data?.budget}
              disabled
            />
            <div style={{ background: "#ffffff" }}>
              <InputField
                label="Project cost as reviewed"
                name="reviewCost"
                value={costReview}
                style={{ background: "#ffffff" }}
                setValue={setCostReview}
                inputRef={register({
                  required: "Enter cost as reviewed",
                  validate: (value) => !isNaN(parseFloat(value)) || "cost review should be in Numbers",
                })}
              />
              {onRenderError(errors.reviewCost)}
            </div>
          </div>
          <InputField
            label="Cost Reduction"
            name="costReduction"
            style={{ background: "#ffffff" }}
            value={costReduction}
            disabled
          />
          <div style={{ background: "#ffffff" }}>
            <InputField
              label="Client Ministry/ Department/ Agency"
              style={{ background: "#ffffff" }}
              disabled
              value={certificateData?.data?.ministryName}
            />
          </div>

          <InputFieldBox>
            <div style={{ background: "#ffffff" }}>
              <InputField
                label="Head Code	"
                name="headCode"
                style={{ background: "#ffffff" }}
                inputRef={register({
                  required: "Enter Head Code",
                })}
              />
              {onRenderError(errors.headCode)}
            </div>
            <div style={{ background: "#ffffff" }}>
              <InputField
                label="Sub-Head Code"
                name="subHeadCode"
                style={{ background: "#ffffff" }}
                inputRef={register({
                  required: "Enter Sub-head code",
                })}
              />
              {onRenderError(errors.subHeadCode)}
            </div>
            <div style={{ background: "#ffffff" }}>
              <InputField
                label="Appropriation Code"
                name="appropriationCode"
                style={{ background: "#ffffff" }}
                inputRef={register({
                  required: "Enter Appropriation Code",
                })}
              />
              {onRenderError(errors.appropriationCode)}
            </div>
          </InputFieldBox>
          <BtnBox>
            <CancelButton onClick={toggleDrawer}>Cancel</CancelButton>
            <LoadingButton
              style={{ marginLeft: "1rem", borderRadius: "8px" }}
              onClick={handleSubmit(onSubmit)}
              loading={generatingCertificate}
            >
              Preview
            </LoadingButton>
          </BtnBox>
        </DrawerWrapper>
      </Drawer>
    </div>
  );
}

export default FormDrawer;
