import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const createTenderProcumentDefaultValues = {
  procurementCategoryId: "",
  name: "",
  description: "",
  procurementMethodId: "",
  tenderingStageId: "",
  reviewMethodId: "",
  budget: "",
  packageNumber: "",
  ministryId: "",
  isEdit: false,
};

export const createTenderProcurementSchema = Yup.object({
  procurementCategoryId: Yup.string().required("Procurement Category is required"),
  name: Yup.string().required("Tender name is required"),
  description: Yup.string().required("Tender description is required"),
  procurementMethodId: Yup.string(),
  tenderingStageId: Yup.string(),
  reviewMethodId: Yup.string(),
  budget: Yup.number().required("Budget amount is required"),
  isEdit: Yup.boolean(),
  packageNumber: Yup.string().required("Package Number is required"),
  ministryId: Yup.string().when("isEdit", (isEdit, schma) => {
    return isEdit ? schma.required("Ministry is required") : schma.nullable();
  }),
});

export const createTenderProcurementResolver = yupResolver(createTenderProcurementSchema);
