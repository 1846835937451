import React from 'react'
import PropTypes from 'prop-types'
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { CustomRadio, StyledFormControl } from './style';

const DefaultRadio = ({
  options,
  name,
  onChange,
  label,
  value,
  allText,
  allValue,
  row
}) => {

  const [, setCheck] = React.useState(value)

  const handleCheck = (e) => {
    setCheck(e.target.value)
    onChange && onChange(e.target.value)
  }

  return (
    <StyledFormControl component="fieldset">
      <FormLabel id="demo-controlled-radio-buttons-group" component="legend">{label}</FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        aria-label={label}
        value={value} name={name}
        onChange={(e) => handleCheck(e)}
        row={row}
      >
        {allText && <FormControlLabel
          value={allValue}
          label={allText}
          control={<CustomRadio disableRipple />}
        />}
        {options?.map(field => (
          <FormControlLabel
            key={field?.value}
            value={field?.value}
            control={<CustomRadio disableRipple checked={value === field?.value} />}
            label={field?.label}
          // checked={value === field?.value}
          // checked
          />
        ))}
      </RadioGroup>
    </StyledFormControl>
  )
}

DefaultRadio.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
DefaultRadio.defaultProps = {
  label: "",
  value: ""
}


export default DefaultRadio
