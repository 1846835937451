import React, { useState } from "react";
import { Box, Button } from "@material-ui/core";
import { queryCache, useMutation } from "react-query";
import SuccessModal from "./successModal";
import {
  CustomDialog,
  CustomDialogTitle,
  CustomDialogTitleWrapper,
  PrevBtn,
} from "../../../../../components/Forms/ConfirmVerificationModal/style";
import CustomInputField from "../../../../../components/InputField";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import contractsAPI from "../../../../../redux/api/contractAPI";
import NumberFormat from "react-number-format";
import InputField from "../../../../../components/InputField";

const schema = yup.object().shape({
  companyName: yup.string().required("Company name is a required field"),
  amount: yup.string().required("Bid amount is a required field"),
});

const AddBidderModal = ({ open, toggleModal, referenceNumber }) => {
  const [isOpen, setModal] = useState(false);
  const param = useParams();
  const [createBidder, { isLoading }] = useMutation(contractsAPI.addBidder, {
    onSuccess: () => {
      queryCache.invalidateQueries("contractOCDS");
      toggleModal(false);
      setModal(true);
    },
  });

  const { handleSubmit, errors, control } = useForm({
    defaultValues: {
      ocidNumber: "",
      companyName: "",
      evaluationStatus: "",
      evaluationComment: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (payload) => {
    payload.amount = +payload.amount;
    createBidder({ payload, id: param.id });
  };

  return (
    <Box display="flex" justifyContent="center" flexDirection="column" py={2} minWidth="550px">
      <SuccessModal open={isOpen} setModal={setModal} />
      <CustomDialog scroll="body" onClose={toggleModal} open={open}>
        <CustomDialogTitleWrapper>
          <CustomDialogTitle disableTypography>Add Bidder</CustomDialogTitle>
        </CustomDialogTitleWrapper>
        <div style={{ marginBottom: "2rem" }}>
          <Controller
            name="companyName"
            control={control}
            render={(props) => (
              <CustomInputField
                style={{ width: "35rem" }}
                label="company Name"
                onChange={() => {}}
                onBlur={() => {}}
                name="companyName"
                {...props}
              />
            )}
          />
          {errors?.companyName && <span style={{ color: "red", fontSize: "12px" }}>{errors?.companyName?.message}</span>}
        </div>
        <div style={{ width: "35rem", marginBottom: "2rem" }}>
          {/* <div style={{ background: "#ffffff" }}> */}
          <Controller
            control={control}
            name="amount"
            render={({ onChange, ...props }) => (
              <NumberFormat
                customInput={InputField}
                label="Bid Amount"
                thousandSeparator
                prefix="₦"
                variant="outlined"
                {...props}
                onValueChange={(values) => {
                  const { value } = values;
                  onChange(value);
                }}
              />
            )}
          />{" "}
          {errors?.bidAmount && <span style={{ color: "red", fontSize: "12px" }}>{errors?.bidAmount?.message}</span>}
        </div>

        <Box>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleSubmit(onSubmit)}
            style={{ marginRight: 16, height: "41px", paddingLeft: "20px", paddingRight: "20px" }}
            disabled={isLoading}
          >
            {isLoading ? "Adding..." : "Add"}
          </Button>
          <PrevBtn
            onClick={() => {
              toggleModal();
            }}
          >
            Cancel
          </PrevBtn>
        </Box>
      </CustomDialog>{" "}
    </Box>
  );
};

export default AddBidderModal;
