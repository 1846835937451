import React from "react";
import { useSelector } from "react-redux";
import Dropdown from "../DropdownField";
import SingleInviteTab, { FORM_TYPES } from "../Common/SingleTabForm";
import { CustomInputField, FormWrapper } from "./style";
import { inviteStaffs } from "../../redux/reducer/adminReducer";

const AddNewStaff = ({ handleCancel }) => {
  const width = "100%";
  const margin = "0 !important";
  const { userRoles, ministries } = useSelector((state) => state.user);

  const Form = ({ formIndex, handleChange, validateInput, errors, generalError, disableMinistryField }) => (
    <FormWrapper>
      {generalError[formIndex] && <span>{generalError[formIndex]}</span>}

      <CustomInputField
        type="email"
        label="Email Address"
        id={`email-${formIndex}`}
        style={{ width }}
        onChange={(e) => handleChange(e, formIndex)}
        onBlur={() =>
          validateInput(formIndex, "email", (value, errorCb) => {
            if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(value)) {
              return errorCb(null);
            }
            errorCb("Email is not valid");
          })
        }
        name="email"
      />
      {errors[formIndex]?.email && <span>{errors[formIndex]?.email}</span>}
      <Dropdown
        id={`role-${formIndex}`}
        label="Role"
        values={[{ id: "", title: "Select a Role" }, ...userRoles].map((role) => ({ label: role.title, value: Number(role.id) }))}
        style={{ width, marginBottom: 10 }}
        onChange={(value) => handleChange({ target: { value, name: "roleId" } }, formIndex)}
        name="roleId"
      />
      <Dropdown
        id={`ministry-${formIndex}`}
        label="Ministry"
        values={[{ id: "", name: "Select a Ministry" }, ...ministries].map((ministry) => ({
          label: ministry.name,
          value: ministry.id,
        }))}
        style={{ width, margin }}
        onChange={(value) => handleChange({ target: { value, name: "ministryId" } }, formIndex)}
        name="ministryId"
        disabled={disableMinistryField}
      />
    </FormWrapper>
  );

  return (
    <SingleInviteTab
      defaultValues={{ ministryId: "", email: "", roleId: "" }}
      submitKey="userInvite"
      handleCancel={handleCancel}
      multipleInviteAPI={inviteStaffs}
      formType={FORM_TYPES.staff}
    >
      <Form />
    </SingleInviteTab>
  );
};

export default AddNewStaff;
