import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { capitalize, upperCase } from "lodash";
import { coreCompetencies, organizationTypes } from "../../../components/Forms/GeneralCompanyDetails/mock";
import { StatusButton, RCText, TextLabel, ValueText, WrapText } from "./style";
import { Box, Chip, Grid } from "@material-ui/core";
import { SaveButton } from "../../../components/Forms/Common/style";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import vendorsAPI from "../../../redux/api/vendorsAPI";
import BlacklistModal from "./VendorVerify/BlacklistModal";

const vendorState = {
  PENDING: "Verify vendor",
  APPROVED: "Suspend vendor",
  SUSPENDED: "Activate vendor",
  REJECTED: "Verify vendor",
  ONBOARDED: "Verify Vendor",
};

const VendorCompanyDetails = () => {
  const { companyDetails } = useSelector((state) => state.vendor);
  const [isOpen, toggleModal] = useState(false);
  const [status, setStatus] = useState(companyDetails?.status);
  const { coreCompetency, organizationType } = Object.keys(companyDetails).length && companyDetails;
  const history = useHistory();
  const { vendorId } = useParams();
  const { data: vendorDetails } = useQuery(["companyDetails", { vendorId }], vendorsAPI.getVendorsCompanyDetails);

  const [toggleVendorStatus, { isLoading }] = useMutation(vendorsAPI.toggleVendorStatus, {
    onSuccess: (data) => {
      toggleModal(false);
      setStatus(data?.status);
    },
  });

  const handleVendorAction = () => {
    if (status === "PENDING" || status === "REJECTED" || upperCase(status) === "ONBOARDED") {
      history.push(`/admin/vendors/${vendorId}/${companyDetails?.id}/verify-vendor`);
    } else if (status === "APPROVED") {
      toggleModal(true);
    } else if (status === "SUSPENDED") {
      toggleVendorStatus({ status: 2, vendorId });
    }
  };

  return (
    <Box>
      <BlacklistModal
        toggleModal={toggleModal}
        isOpen={isOpen}
        toggleFn={toggleVendorStatus}
        vendorId={vendorId}
        loading={isLoading}
      />
      <Grid container>
        <Grid>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="100%"
            borderRight="1px solid grey"
            marginRight="10px"
          >
            <Box paddingRight="10px">
              <StatusButton status={status}>{capitalize(status || "UNKNOWN")}</StatusButton>
              <RCText>{companyDetails.cacRegistrationNumber}</RCText>
              {vendorDetails?.uploadedVendor && (
                <Box>
                  <Chip label="Seeded Vendor" />
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
              alignItems: "flex-start",
              margin: "10px 0px",
            }}
          >
            <WrapText>
              <TextLabel>Area of Competence</TextLabel>
              <ValueText>{coreCompetency && coreCompetencies.find((c) => Number(c.value) === coreCompetency).label}</ValueText>
            </WrapText>
            <WrapText>
              <TextLabel>Type of Organization</TextLabel>
              <ValueText>
                {organizationType && organizationTypes.find((o) => Number(o.value) === organizationType).label}
              </ValueText>
            </WrapText>
            <WrapText>
              <TextLabel>Date of Registration</TextLabel>
              <ValueText>{moment(vendorDetails?.createdAt).format("Do MMM, YYYY") || "N/A"}</ValueText>
            </WrapText>
            <WrapText>
              <TextLabel>Capital Contribution By Partners</TextLabel>
              <ValueText>NGN {companyDetails.authorizedShareCapital}</ValueText>
            </WrapText>
          </Box>

          <Box
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
              alignItems: "flex-start",
              margin: "10px 0px",
            }}
          >
            <WrapText>
              <TextLabel>Country</TextLabel>
              <ValueText>{companyDetails.correspondenceCountry}</ValueText>
            </WrapText>
            <WrapText>
              <TextLabel>State</TextLabel>
              <ValueText>{companyDetails.correspondenceState}</ValueText>
            </WrapText>
            <WrapText>
              <TextLabel>City</TextLabel>
              <ValueText>{companyDetails.correspondenceCity}</ValueText>
            </WrapText>
            <WrapText>
              <TextLabel>Business Address</TextLabel>
              <ValueText>
                {companyDetails.correspondenceAddress1}, {companyDetails.correspondenceAddress2},{" "}
                {companyDetails.correspondenceCity}, {companyDetails.correspondenceState} State,{" "}
                {companyDetails.correspondenceCountry}
              </ValueText>
            </WrapText>
          </Box>
        </Grid>
        <Box flexShrink={0}>
          <SaveButton status={status} onClick={handleVendorAction}>
            {vendorState[upperCase(status || "PENDING")]}
          </SaveButton>
        </Box>
      </Grid>
    </Box>
  );
};

export default VendorCompanyDetails;
