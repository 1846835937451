import { handleAxiosError } from "./handleApiError";
import baseAxiosMethod from "./baseAxiosMethod";

const certificateOfNoObjectionApi = {};

certificateOfNoObjectionApi.getCertificate = async (key, { procurementId }) => {
  try {
    const response = await baseAxiosMethod(`procurements/${procurementId}/certificateoFObjection`);

    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

certificateOfNoObjectionApi.requestReview = async ({ activityId }) => {
  try {
    await baseAxiosMethod(`procurements/requestCertificateofObjection/${activityId}`);
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

certificateOfNoObjectionApi.getBidRecommendations = async (key, { procurementId, PageNumber, PageSize }) => {
  try {
    const response = await baseAxiosMethod(`procurements/${procurementId}/bidRecommendations`, {
      params: {
        PageSize,
        PageNumber,
      },
    });

    const {
      data,
      meta: { pagination },
    } = response.data;

    return { data, pagination };
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

certificateOfNoObjectionApi.uploadCertificateOfObjection = async ({ activityId, File }) => {
  try {
    const fd = new FormData();

    fd.set("File", File);

    await baseAxiosMethod.post(`procurements/${activityId}/certificateoFObjection`, fd);
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

certificateOfNoObjectionApi.uploadCNOForPayment = async ({ activityId, File }) => {
  try {
    const formData = new FormData();
    formData.append("File", File);

    await baseAxiosMethod.post(`procurements/${activityId}/generate-cno-payment`, formData);
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

export default certificateOfNoObjectionApi;
