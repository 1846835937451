import styled from "styled-components";
import { theme } from "../../../../util/theme";
import { Button } from "@material-ui/core";

export const PageContainer = styled.div`
  margin-top: "2rem";
`;

export const BreadCrumbWrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;

  a {
    height: 16px;
    width: 197px;
    color: #2aa96d;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: none;
  }

  a:visited {
    color: #2aa96d;
  }
`;

export const CertificateContainer = styled.div`
  background-color: #fff;
  padding: 3rem;
  position: relative;
`;

export const PageTitle = styled.h1`
  color: #1e2b37;
  font-size: 28px;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 0;
  margin-top: 2rem;
  font-weight: normal;
`;

export const ContentWrapper = styled.div`
  padding: 15px 0 80px 0;

  .MuiTableHead-root {
    background-color: white;
    padding: 20px 0 !important;
  }

  .MuiTableCell-head {
    font-weight: bold;
    font-size: 16px;
  }
`;

export const WaterMark1 = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const WaterMark2 = styled.div`
  position: absolute;
  top: 30%;
  left: 30%;
`;

export const WaterMark3 = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const CertificateTitle = styled.p`
  font-size: 14px;
  color: #1e0a3c;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  margin-bottom: 1rem;
`;

export const RedTitle = styled.p`
  font-size: 14px;
  color: #da1414;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  margin-bottom: 1rem;
`;

export const GreenTitle = styled.p`
  font-size: 18px;
  color: #118d54;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
`;

export const CertificateBody = styled.p`
  font-size: 14px;
  color: #1e0a3c;
  font-weight: 400;
  margin-bottom: 1rem;
  line-height: 20px;
`;

export const CertificateItemTitle = styled.p`
  font-size: 14px;
  color: #6b6c7e;
  font-weight: 400;
`;

export const CertificateItemAnswer = styled.p`
  font-size: 14px;
  color: #393a4a;
  font-weight: 700;
  text-transform: capitalize;
`;

export const CertificateItemsBox = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

export const CertificateTitleBox = styled.div`
  width: 40%;
`;

export const CertificateAnswerBox = styled.div`
  width: 50%;
`;

export const CertificateItemsTitle = styled.p`
  font-size: 14px;
  color: #1e0a3c;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 0.8rem;
`;

export const LogoDiv = styled.div`
  /* text-align: center; */
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
`;

export const CertNoTitle = styled.p`
  font-size: 12px;
  color: #6b6c7e;
  font-weight: 500;
  margin-bottom: 0.8rem;
  text-align: center;
`;

export const CertNoAnswer = styled.span`
  font-size: 12px;
  color: #6b6c7e;
  font-weight: 400;
  margin-bottom: 0.8rem;
  font-style: italic;
  text-align: center;
  margin-left: 0.4rem;
`;

export const SignatureBox = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 3rem;
`;

export const QRCodeStyle = styled.div``;

export const SignatureContainer = styled.div`
  /* margin-top: 1rem; */
`;

export const SignatureTitle = styled.p`
  color: #6b6c7e;
  font-size: 12px;
  font-weight: 700;
`;

export const UpdateButton = styled(Button)`
  && {
    background-color: ${theme.color.primary};
    border-radius: 8px;
    color: white;
    &:hover {
      background-color: ${theme.color.primary};
      opacity: 0.8;
    }
    &:disabled {
      background-color: ${theme.color.primary};
      cursor: not-allowed !important;
      opacity: 0.5;
      color: white;
    }
  }
`;

export const CertName = styled.p`
  font-size: 12px;
  color: #1e0a3c;
  font-weight: 700;
  text-transform: capitalize;
`;

export const ModalBody = styled.div`
  position: absolute;
  top: 140px;
  left: calc(50% - 200px);
  width: 100%;
  max-width: 400px;
  min-height: 200px;
  border: 1px solid #ebebeb;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  padding: 30px;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

export const ModalTitle = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #3f5673;
  margin-bottom: 30px;
`;

export const ModalDescription = styled.p`
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #3f5673;
  margin-bottom: 20px;
`;
