import React, { useState, useContext } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Tabs, Tab, Typography, makeStyles, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";

import { GeneralTitlePage } from "../SpecificProcurementNotice/style";
import Breadcrumb from "../../../../Common/PageElements/Breadcrumb";
import { ResponseStatsLabel, ResponseStatsValue } from "./style";
import TopStatisticsBar from "../../../../../components/TopStatisticsBar";
import { useQuery, useMutation, queryCache } from "react-query";
import Loader from "../../../../../components/Common/Loader";
import LoadingButton from "../../../../../components/LoadingButton/LoadingButton";
import receiptOfResponseApi from "../../../../../redux/api/receiptOfResponseApi";
import VendorsCategorized from "./VendorsCategorized/VendorsCategorized";
import receiptOfBiddersResponseApi from "../../../../../redux/api/receiptOfBiddersResponseApi";
import ToastContext from "../../../../../util/toastContext";
import AccessControl from "../../../../../components/AccessControl";
import { Roles } from "../../../../../components/AccessControl/UserRoles";
import procurementAPI from "../../../../../redux/api/procurementAPI";
import { ProcurementMethodAccessControl } from "../../../../../shared";
import { Procurement_Method } from "../../../../../util/constants";
import { useProcurementMethod } from "../../../../../hooks/queries/useProcurement";
import { useRoles } from "../../../../../hooks/useUserDetails";
import { StatusBox, StyledTable, TableWrapper } from "../ApprovalOfQuotationRequirement/style";
import { format } from "date-fns";

export const convertVendors = (vendors = [], totalCount) => {
  return vendors.map((vendor) => ({
    name: vendor.companyName,
    email: vendor.email,
    documents: {
      count: vendor.documentsUploaded,
      total: totalCount,
    },
    REGID: vendor.registrationPlan.grade,
    id: vendor.userId,
  }));
};

const ReceiptOfResponse = () => {
  const { procurementId = "", activityId } = useParams();
  const [value, setValue] = useState(0);
  const [hasBenchmark, setHasBenchmark] = useState(null);
  const classes = useStyles();
  const { pathname } = useLocation();
  const { showAlert } = useContext(ToastContext);
  const { role } = useRoles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { method, isSuccess } = useProcurementMethod({ procurementId });

  const routeMap = {
    [Procurement_Method.DIRECT_PROCUREMENT]: "receiptofresponse",
  };

  const baseUrl = `/admin/procurement/activity/${routeMap[method] ?? `receiptofresponses`}/${activityId}/${procurementId}`;

  const [pagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const vendors = useQuery({
    queryKey: [
      "interestedVendors",
      {
        procurementId,
        Status: 7,
        PageNumber: pagination.pageNumber,
        PageSize: pagination.pageSize,
      },
    ],
    queryFn: receiptOfResponseApi.getInterestedVendors,
  });

  const { data: technicalReviewsStat = {} } = useQuery({
    queryFn: receiptOfBiddersResponseApi.getEvaluatedVendorsStat,
    queryKey: ["technical-evaluation-stat", { procurementId }],
    config: {
      onError: (error) =>
        showAlert({
          severity: "error",
          message: error?.message,
        }),
    },
  });

  const { data: activity = {}, ...activityQueryFeedback } = useQuery({
    queryFn: procurementAPI.getActivityById,
    queryKey: ["activity", { activityId }],
    config: {
      onError: (error) =>
        showAlert({
          severity: "error",
          message: error?.message,
        }),
    },
  });

  const { data: DPMEndorsements } = useQuery({
    queryFn: receiptOfResponseApi.getDPMEndorsements,
    queryKey: ["get-DPM-Endorsements", { procurementId }],
    config: {
      onError: (error) =>
        showAlert({
          severity: "error",
          message: error?.message,
        }),
    },
  });

  const benchmarkQuery = useQuery({
    queryFn: receiptOfBiddersResponseApi.getTechnicalEvaluation,
    queryKey: ["technical-evaluation", { id: procurementId }],
    config: {
      onSuccess: (data) => {
        setHasBenchmark(data?.hasEvaluation);
      },
    },
  });

  const [mutate, { isLoading }] = useMutation(receiptOfResponseApi.technicalEvaluationApproval, {
    onSuccess: () => {
      showAlert({
        message: "Approval was successful, you can proceed to the next stage",
        severity: "success",
        durationInMs: 3000,
      });
      activityQueryFeedback.refetch();
    },
    onError: (error) => {
      showAlert({
        message: error.message,
        severity: "error",
        durationInMs: 2000,
      });
    },
  });

  const [endorseVendorForDPM, { isLoading: endorsingDPM }] = useMutation(
    role === Roles.PROCUREMENT_OFFICER ? receiptOfResponseApi.sendDPMForEndorsement : receiptOfResponseApi.DPMEndorseVendor,
    {
      onSuccess: () => {
        showAlert({
          message: "Request successfully sent",
          severity: "success",
          durationInMs: 3000,
        });
        queryCache.invalidateQueries("get-DPM-Endorsements");
      },
      onError: (error) => {
        showAlert({
          message: error.message,
          severity: "error",
          durationInMs: 2000,
        });
      },
    }
  );

  const breadcrumbLinks = [
    {
      url: "/admin/procurement/plans",
      title: "Home",
      forwardSlash: true,
    },
    {
      url: `/admin/procurement/plan-details/${procurementId}`,
      title: "Activities List",
    },
    {
      url: baseUrl,
      title: "Receipt of Response",
    },
  ];

  const stats = vendors.data?.interestedVendorsData?.totalResponse || {};
  const total = technicalReviewsStat.totalPassed + technicalReviewsStat.totalPending + technicalReviewsStat.totalFailed;

  const renderLeftContent = () => {
    return (
      <>
        <ResponseStatsLabel color="#5F7483">Total Vendors</ResponseStatsLabel>
        <ResponseStatsValue>{isNaN(total) ? 0 : total}</ResponseStatsValue>
      </>
    );
  };

  const renderCenterContent = () => {
    return (
      <>
        <ProcurementMethodAccessControl
          method={method}
          allowedMethod={Object.values(Procurement_Method).filter((value) => value !== Procurement_Method.DIRECT_PROCUREMENT)}
          isSuccess={isSuccess}
        >
          <Box mr={6}>
            <ResponseStatsLabel color="#FF8D26">Pending</ResponseStatsLabel>
            <ResponseStatsValue>{technicalReviewsStat.totalPending || 0}</ResponseStatsValue>
          </Box>
          <Box mr={6}>
            <ResponseStatsLabel color="#219653">Passed</ResponseStatsLabel>
            <ResponseStatsValue>{technicalReviewsStat.totalPassed || 0}</ResponseStatsValue>
          </Box>
          <Box mr="auto">
            <ResponseStatsLabel color="#EB5757">Failed</ResponseStatsLabel>
            <ResponseStatsValue>{technicalReviewsStat.totalFailed || 0}</ResponseStatsValue>
          </Box>
        </ProcurementMethodAccessControl>
        <AccessControl allowedRoles={[Roles.MINISTERIAL_TENDER_BOARD]}>
          <LoadingButton
            color="#219653"
            component={Link}
            disabled={benchmarkQuery.isError || benchmarkQuery.isLoading || hasBenchmark === true || hasBenchmark === null}
            to={`${pathname}/create-technical-evaluation`}
          >
            Set Benchmark
          </LoadingButton>
          <Box ml={3}>
            <LoadingButton
              color="#219653"
              // eslint-disable-next-line
              disabled={
                activityQueryFeedback.isError ||
                activityQueryFeedback.isLoading ||
                activity.procurementPlanActivityStatus === "Approved" ||
                total === 0 ||
                (total > 0 && technicalReviewsStat.totalPending > 0)
              }
              onClick={() => mutate({ activityId })}
              loading={isLoading}
            >
              Proceed
            </LoadingButton>
          </Box>
        </AccessControl>
        <ProcurementMethodAccessControl
          method={method}
          allowedMethod={[Procurement_Method.DIRECT_PROCUREMENT]}
          isSuccess={isSuccess}
          noLoader
        >
          <AccessControl
            allowedRoles={[
              Roles.PERMANENT_SECRETARY,
              Roles.ACCOUNTING_OFFICER,
              Roles.COMMISSIONER,
              Roles.EXECUTIVE,
              Roles.SUPERVISING_OFFICER,
              Roles.GOVERNOR,
            ]}
          >
            <LoadingButton
              color="#219653"
              disabled={
                ([Roles.ACCOUNTING_OFFICER, Roles.PERMANENT_SECRETARY].includes(role) &&
                  DPMEndorsements?.[0]?.status === "Endorsed") ||
                ([Roles.SUPERVISING_OFFICER, Roles.COMMISSIONER].includes(role) && DPMEndorsements?.[1]?.status === "Endorsed") ||
                ([Roles.GOVERNOR, Roles.EXECUTIVE].includes(role) && DPMEndorsements?.[2]?.status === "Approved")
              }
              onClick={() => endorseVendorForDPM({ activityId })}
              loading={endorsingDPM}
            >
              {[Roles.GOVERNOR, Roles.EXECUTIVE].includes(role) ? `Approve Vendor` : "Endorse Vendor"}
            </LoadingButton>
          </AccessControl>
          <AccessControl allowedRoles={[Roles.PROCUREMENT_OFFICER]}>
            <LoadingButton
              color="#219653"
              disabled={false}
              onClick={() => endorseVendorForDPM({ procurementId })}
              loading={endorsingDPM}
            >
              Send For Endorsement
            </LoadingButton>
          </AccessControl>
        </ProcurementMethodAccessControl>
      </>
    );
  };

  return (
    <Box pb={10}>
      <Box mb={4} mt={2}>
        <GeneralTitlePage>Receipt of Response</GeneralTitlePage>
        <Breadcrumb values={breadcrumbLinks} />
      </Box>
      {vendors.isLoading ? (
        <Loader feedback="Fetching details" />
      ) : (
        <>
          <TopStatisticsBar
            leftContent={renderLeftContent()}
            centerContent={renderCenterContent()}
            status={stats.pending > 0 ? "pending" : "approved"}
          />

          <ProcurementMethodAccessControl
            method={method}
            allowedMethod={Object.values(Procurement_Method).filter((value) => value !== Procurement_Method.DIRECT_PROCUREMENT)}
            isSuccess={isSuccess}
          >
            <Box style={{ width: "100%" }}>
              <Box style={{ borderBottom: "1px solid #CDCED9" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  classes={{ indicator: classes.tabIndicator }}
                  aria-label="basic tabs example"
                >
                  <Tab classes={{ root: classes.tabRoot, selected: classes.selected }} label="Pending Review" {...a11yProps(0)} />
                  <Tab classes={{ root: classes.tabRoot, selected: classes.selected }} label="Passed Review" {...a11yProps(1)} />
                  <Tab classes={{ root: classes.tabRoot, selected: classes.selected }} label="Failed Review" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <Box mt={3}>
                <TabPanel value={value} index={0}>
                  <VendorsCategorized
                    type="pending"
                    evaluate
                    hasBenchmark={hasBenchmark}
                    status="Pending"
                    activityId={activityId}
                    procurementId={procurementId}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <VendorsCategorized type="accepted" status="Passed" activityId={activityId} procurementId={procurementId} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <VendorsCategorized type="rejected" status="Failed" activityId={activityId} procurementId={procurementId} />
                </TabPanel>
              </Box>
            </Box>
          </ProcurementMethodAccessControl>

          <ProcurementMethodAccessControl
            method={method}
            allowedMethod={[Procurement_Method.DIRECT_PROCUREMENT]}
            isSuccess={isSuccess}
            noLoader
          >
            <VendorsCategorized
              type="pending"
              evaluate
              hasBenchmark={hasBenchmark}
              status="Pending"
              activityId={activityId}
              procurementId={procurementId}
            />

            <Box mt="40px">
              <TableWrapper>
                <StyledTable>
                  <TableHead>
                    <TableRow>
                      <TableCell>Role</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {DPMEndorsements?.map((field) => (
                      <TableRow key={field.role}>
                        <TableCell>{field.role}</TableCell>
                        <TableCell>
                          <StatusBox colorStatus={field.status}>{field.status}</StatusBox>
                        </TableCell>
                        <TableCell>
                          {field.endorseDate ? format(new Date(field.endorseDate), "dd MMM yyyy") : " Not Available"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </StyledTable>
                <Box style={{ width: "100%", padding: "40px" }} />
              </TableWrapper>
            </Box>
          </ProcurementMethodAccessControl>
        </>
      )}
    </Box>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  tabRoot: {
    minWidth: "min-content",
    padding: 0,
    marginRight: 20,
    textTransform: "none",
    fontSize: "16px",
    fontWeight: 400,
  },
  selected: {
    color: "#2AA96D",
    fontWeight: 700,
  },
  tabIndicator: {
    width: "80px",
    color: "#2AA96D",
    backgroundColor: "#2AA96D",
  },
});

export default ReceiptOfResponse;
