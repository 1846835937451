import React, { useContext, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
// import AdminCard from "../../../components/AdminCard";
import AdminSearchFilter from "../../../components/AdminSearchFilter";
import Dropdown from "../../../components/DropdownField";
import SearchTable from "../../../components/SearchTable";
import { getVendorsSummary } from "../../../redux/reducer/adminReducer";
import withLoader from "../../Common/HOC/withLoader";
import { ContentWrapper, TitleContainer } from "../Administration/Staff/style";
import withAdminDashboard from "../HOC/withAdminDashboard";
import { VendorTitlePage, VendorViewAllTextContainer, VendorViewAllText } from "./style";
import VendorTable from "./VendorsTable";
import { queryCache, useMutation, useQuery } from "react-query";
import adminAPI from "../../../redux/api/adminAPI";
import ToastContext from "../../../util/toastContext";
import { ContractInputField } from "../Contracts/style";
import Breadcrumb from "../../Common/PageElements/Breadcrumb";
import StatisticsCards from "../../../components/StatisticsCards";
import VendorPaymentRenewalTable from "../../../components/VendorPaymentRenewalTable";
import { useHistory, useLocation } from "react-router-dom";
import { RequestButton } from "../../../components/SearchTable/style";
import LoadingButton from "../../../components/LoadingButton/LoadingButton";
import { CuteModal, ModalWrapper, MainText, ModalCloseBtn, ModalHeader, ModalRejectBtn } from "../../Vendor/Procurement/style";

const RenderStatCards = () => {
  const { vendorsSummary } = useSelector((state) => state.admin);
  const cards = [
    {
      title: "Total Vendor",
      count: vendorsSummary?.total,
      subTitle: "All Vendors",
    },
    {
      title: "Active",
      count: vendorsSummary?.activeTotal,
      type: "active",
    },
    {
      title: "Pending",
      count: vendorsSummary?.pendingTotal,
      type: "pending",
    },
    {
      title: "Rejected",
      count: vendorsSummary?.rejectedTotal,
      type: "rejected",
    },
    {
      title: "Suspended",
      count: vendorsSummary?.blacklistedTotal,
      type: "suspended",
    },
  ];
  return <StatisticsCards cards={cards} />;
};

//This was previously used before the design was changed, and RenderStatCards above had to be created.
// const VendorCardSummary = () => {
//   const { vendorsSummary } = useSelector((state) => state.admin);
//   const {
//     color: {
//       tags: { approved, rejected, pending, blacklist },
//     },
//   } = theme;
//   return (
//     <AdminCard
//       title={"Total Vendors"}
//       count={(vendorsSummary && vendorsSummary.total) || 0}
//       description={""}
//       statuses={[
//         { text: `${vendorsSummary?.activeTotal} Approved`, bgColor: approved.background, textColor: approved.text },
//         {
//           text: `${vendorsSummary?.pendingTotal} Pending`,
//           bgColor: pending.background,
//           textColor: pending.text,
//         },
//         {
//           text: `${vendorsSummary?.rejectedTotal} Rejected`,
//           bgColor: rejected.background,
//           textColor: rejected.text,
//         },
//         {
//           text: `${vendorsSummary?.blacklistedTotal} Blacklisted`,
//           bgColor: blacklist.background,
//           textColor: blacklist.text,
//         },
//       ]}
//     />
//   );
// };

const Vendors = () => {
  const [filters, setFilters] = useState({
    Search: null,
    Status: null,
  });
  const { pathname } = useLocation();
  const [switchPendingVendor, setSwitchPendingVendor] = useState(false);
  const [vendorToDelete, setVendorToDelete] = useState(null);
  const history = useHistory()

  const updateFilters = (key) => (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value === "All" ? null : value,
    }));
  };

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const vendorQueryKey = [
    "vendors",
    {
      pageSize: pagination.pageSize,
      pageNumber: pagination.pageNumber,
      Search: filters.Search,
      Status: filters.Status,
    },
  ];

  const { data = {}, isLoading, error = {}, isError } = useQuery({
    queryFn: adminAPI.getVendors,
    queryKey: vendorQueryKey,
  });

  const updatePagination = (key) => (value) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      [key]: value,
    }));
  };
  const handleRequestTable = (url) => {
    history.push(`${pathname}/${url}`);
  }

  const { showAlert } = useContext(ToastContext);

  useEffect(() => {
    if (isError) {
      showAlert({
        severity: "error",
        message: error?.message,
        durationInMs: 3000,
      });
    }
    //eslint-disable-next-line
  }, [showAlert]);
  const GeneralPlanLinks = [{ url: "/admin/dashboard/overview", title: "Home" }];



  const [deleteVendor, deleteVendorQueryResponse] = useMutation(adminAPI.deleteVendor, {
    onSuccess: () => {
      showAlert({
        severity: "success",
        message: "Vendor was deleted successfully",
      });
      queryCache.removeQueries(vendorQueryKey);
      setVendorToDelete(null);
    },
    onError: (error) => {
      showAlert({
        severity: "error",
        message: error.message,
      });
    },
  });

  const handleShowDeleteVendorModal = (event, vendor) => {
    // event.stopPropagation();
    setVendorToDelete(vendor);
  };

  
  const handleDeleteVendor = (event, vendorId) => {
    event.preventDefault();
    deleteVendor(vendorId);
  };

  return (
    <ContentWrapper>
      <TitleContainer>
        <Box>
          <VendorTitlePage>Vendors</VendorTitlePage>
          {/* <GeneralBreadCrumbLabel>Administration/Vendors</GeneralBreadCrumbLabel> */}
          <Breadcrumb values={GeneralPlanLinks} />
        </Box>
      </TitleContainer>
      {withLoader(RenderStatCards)({
        apis: [getVendorsSummary()],
        conditioners: ["vendorsSummary"],
        stateName: "admin",
      })}

      <VendorViewAllTextContainer>
        <VendorViewAllText
          onClick={() => {
            setSwitchPendingVendor(!switchPendingVendor);
          }}
        >
          {switchPendingVendor ? "Back to vendor List" : "View pending renewal payments"}
        </VendorViewAllText>
      </VendorViewAllTextContainer>

      {switchPendingVendor ? (
        <VendorPaymentRenewalTable />
      ) : (
        <React.Fragment>
          <AdminSearchFilter normalSearch={true} title="Filter">
            <div style={{ display: "flex" }}>
              <ContractInputField
                type="text"
                label="Search"
                style={{ width: 300 }}
                value={filters.Search}
                setValue={updateFilters("Search")}
              />
              <Dropdown
                name="approval"
                label="Approval Status"
                values={[
                  { label: "All", value: null },
                  { label: "Pending", value: 1 },
                  { label: "Approved", value: 2 },
                  { label: "Rejected", value: 3 },
                  { label: "Suspended", value: 4 },
                ]}
                onChange={updateFilters("Status")}
                style={{ width: 150 }}
                required
              />
            </div>
          </AdminSearchFilter>
          <SearchTable
            rows={data.data}
            columns={["name", "status", "reg. category", "location", "url", ""]}
            page={pagination.pageNumber}
            setPage={updatePagination("pageNumber")}
            rowsPerPage={pagination.pageSize}
            setRowsPerPage={updatePagination("pageSize")}
            loading={isLoading}
            totalNumberOfPages={data?.pagination?.totalEntries}
            // requestTab={"View Upgrade Request"}
            // handleRequestBtn={handleRequestTable}
            searchTableAction={
              <Box display="flex" alignItems="center">
                <LoadingButton
                  variant="contained"
                  onClick={() => handleRequestTable("uploaded-vendor")}
                  style={{ marginRight: 16 }}
                >
                  View Uploaded Vendor
                </LoadingButton>
                <RequestButton
                  variant="outlined"
                  onClick={() => handleRequestTable("vendor-upgrade-request")}
                >
                  View Upgrade Request
                </RequestButton>
              </Box>
            }
          >
            <VendorTable vendors={data.data} setVendorToDelete={setVendorToDelete} />
          </SearchTable>
        </React.Fragment>
      )}
      <CuteModal open={Boolean(vendorToDelete)}>
        <ModalWrapper>
          <ModalHeader>Delete "{vendorToDelete?.vendorProfile?.companyName}"</ModalHeader>
          <MainText mt="30px">Proceed to delete this vendor?. You cannot undo this action</MainText>
          <MainText mt="30px"></MainText>
          <Box display="flex" justifyContent="flex-start">
            <ModalRejectBtn
              disabled={deleteVendorQueryResponse.isLoading}
              onClick={(event) => handleDeleteVendor(event, vendorToDelete?.id)}
            >
              {deleteVendorQueryResponse.isLoading ? "Please wait..." : "Delete"}
            </ModalRejectBtn>
            <ModalCloseBtn onClick={(event) => handleShowDeleteVendorModal(event, null)}>Close</ModalCloseBtn>
          </Box>
        </ModalWrapper>
      </CuteModal>
    </ContentWrapper>
  );
};

export default withAdminDashboard(Vendors);
