export const MaxFileSize = 5000000;

export const ContractSignatureStatus = Object.freeze({
  Signed: "SIGNED",
  Unsigned: "UNSIGNED",
});

export const ContractStatus = Object.freeze({
  Accepted: "ACCEPTED",
  Rejected: "REJECTED",
  // N/BL Subject to change, awaiting actual value from
  // backend
  Pending: "PENDING",
});

const ContractStatusLabel = Object.freeze({
  [ContractStatus.Accepted]: "Cleared",
  [ContractStatus.Rejected]: "Rejected",
  [ContractStatus.Pending]: "Uncleared",
});

const ContractSignatureStatusLabel = Object.freeze({
  [ContractSignatureStatus.Signed]: "Signed",
  [ContractSignatureStatus.Unsigned]: "Unsigned",
});

export const supplementaryBudgetStatus = {
  pending: "pending",
  reviewed: "reviewed",
  endorsed: "endorsed",
  approved: "approved",
};

export const TagColorScheme = Object.freeze({
  pending: { bgColor: "#FFEAD9", textColor: "#ff8d6" },
  inprogress: { bgColor: "#D6EFD2", textColor: "#118043" },
  done: { bgColor: "#DCF6FF", textColor: "#2AA96D" },
});

export const getContractStatusLabel = (statusId) => ContractStatusLabel[statusId];
export const getContractSignatureStatusLabel = (signatureStatusId) => ContractSignatureStatusLabel[signatureStatusId];

export const isContractSigned = (contract) => contract.signatureStatus === ContractSignatureStatus.Signed;

export const ContractDurationType = Object.freeze({
  Month: "month",
  Year: "year",
});

export const VendorRecommendationStatus = Object.freeze({
  Recommended: 1,
  Evaluated: 2,
  Rejected: 3,
});

const ContractDurationTypeLabel = Object.freeze({
  [ContractDurationType.Month]: "Month",
  [ContractDurationType.Year]: "Year",
});

const VendorRecommendationStatusLabel = Object.freeze({
  [VendorRecommendationStatus.Recommended]: "Recommended",
  [VendorRecommendationStatus.Evaluated]: "Evaluated",
  [VendorRecommendationStatus.Rejected]: "Rejected",
});

export const getContractDurationTypeLabel = (durationType) => ContractDurationTypeLabel[durationType];
export const getVendorRecommendationStatusLabel = (recommendationStatus) => VendorRecommendationStatusLabel[recommendationStatus];

export const RECORD_STATUS = {
  APPROVED: "APPROVED",
  NEEDAMENDMENT: "NEEDS AMENDMENT",
  INREVIEW: "IN REVIEW",
  PENDING: "PENDING",
  DRAFT: "DRAFT",
};

export const RECORD_STAGE = {
  ONGOING: "ONGOING",
  COMPLETED: "COMPLETED",
  NOTSTARTED: "NOT STARTED",
};

export const CALLBACK_ROUTES = {
  RENEWAL: "vendor/procurement",
  REGISTRATION: "vendor/overview",
  BID: "vendor/bids",
};

export const CERTIFICATE_TYPES = {
  BIDDING: "1",
  AWARD: "2",
  PAYMENT: "3",
};

export const Procurement_Method = Object.freeze({
  DIRECT_LABOUR: "dlm",
  DIRECT_PROCUREMENT: "dpm",
  REQ_QUOTATION: "rq",
  INTERNATIONAL_COMPETITIVE_BIDDING: "icb",
  RESTRICTED_TENDERING_METHOD: "rtm",
  NATIONAL_COMPETITIVE_BIDDING: "ncb",
});
