import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { capitalize } from "lodash";
import { Chip, Box, Button } from "@material-ui/core";
import { theme } from "../../../../util/theme";
import { VendorUpgradeRequestRowStyle, StyledTableCell } from "../style";
import EmptyTableBody from "../../../../components/EmptyTableBody";
import { EmptyTableSubTitle } from "../../../../components/EmptyTableBody/style";
import moment from "moment";

const statusColors = {
  onboarded: theme.color.tags.pending,
  approved: theme.color.tags.approved,
};

const UploadedVendorTable = ({ requests = [], column, emptyRows, setSelectedVendorUploadToDelete }) => {
  const history = useHistory();
  const { pathname } = useLocation;
  const handleSelectVendorToDelete = (e, vendor) => {
    e.stopPropagation();

    setSelectedVendorUploadToDelete(vendor);
  };

  return requests?.length ? (
    <React.Fragment>
      {requests.map((request) => {
        const link = `${pathname}/${request?.id}`;
        return (
          <VendorUpgradeRequestRowStyle key={request?.id} onClick={true ? () => {} : () => history.push(link)}>
            <StyledTableCell>
              <Box style={{ width: 200 }}>
                <p>{request?.email}</p>
              </Box>
            </StyledTableCell>
            <StyledTableCell>{request?.category?.toLowerCase()}</StyledTableCell>
            <StyledTableCell>
              {request?.uploadedDate ? moment(request?.uploadedDate).format("DD, MMMM, yyy") : "Not Applicable"}
            </StyledTableCell>
            <StyledTableCell style={{ textAlign: "left" }}>
              {request?.paymentDate ? moment(request?.paymentDate).format("DD, MMMM, yyy") : "Not Applicable"}
            </StyledTableCell>
            <StyledTableCell>
              <Chip
                label={capitalize(request?.status?.toLowerCase() || "UNKNOWN")}
                style={{
                  background: statusColors[request?.status?.toLowerCase()]?.background || statusColors.onboarded.background,
                  color: statusColors[request?.status?.toLowerCase()]?.text,
                }}
              />
            </StyledTableCell>
            <StyledTableCell align="right">
              {request?.status?.toLowerCase() === "onboarded" && (
                <Button
                  color="error"
                  variant="text"
                  style={{ textTransform: "none", color: theme.color.danger }}
                  onClick={(e) => handleSelectVendorToDelete(e, request)}
                >
                  Delete
                </Button>
              )}
            </StyledTableCell>
          </VendorUpgradeRequestRowStyle>
        );
      })}
      {emptyRows > 0 && (
        <VendorUpgradeRequestRowStyle style={{ height: 50 * emptyRows }}>
          <StyledTableCell style={{ backgroundColor: "#fff" }} colSpan={column.length} />
        </VendorUpgradeRequestRowStyle>
      )}
    </React.Fragment>
  ) : (
    <EmptyTableBody>
      <EmptyTableSubTitle>No Vendor Upload Request yet</EmptyTableSubTitle>
    </EmptyTableBody>
  );
};

export default UploadedVendorTable;
