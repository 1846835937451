import React, { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { useEffect } from "react";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { CancelButton, DateRangeWrapper, ErrorContainer, UpdateButton } from "./ocdsEdit.styles";
import InputField from "../../../../components/InputField";
import Dropdown from "../../../../components/DropdownField";
import contractsAPI from "../../../../redux/api/contractAPI";
import { queryCache, useMutation } from "react-query";
// import Dropdown from "../../../../components/DropdownField";
import ToastContext from "../../../../util/toastContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

function EditPlanningTab({ data }) {
  const { errors, watch, reset, control, handleSubmit } = useForm();
  const { showAlert } = useContext(ToastContext);
  const history = useHistory();


  const categoryOptions = ["Works", "Goods", "Consultation", "Non-Consultation"].map((item) => ({ label: item, value: item }));

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  const [mutate, { isLoading: updatingContract }] = useMutation(contractsAPI.updateOcdsContractPlanningPortal, {
    onSuccess: () => {
      showAlert({
        message: "Successfully updated contract",
        severity: "success",
      });
      queryCache.invalidateQueries("tenderAndAwardRequest");
      setTimeout(() => history.push(`/admin/ocdsList/ocds/${data.id}`), 1000);
    },
  });
  const onSubmit = (payload) => {
    console.log({ payload });
    payload.procuringEntity = payload.ministryName;
    payload.budgetAmount = +payload.budgetAmount;
    payload.projectTitle = data.title;
    payload.projectYear = payload.budgetYear;
    delete payload.budgetYear;
    delete payload.ministryName;
    delete payload.title;
    mutate({ payload, id: data.id });
  };

  const onRenderError = (error) => <ErrorContainer>{error && error.message}</ErrorContainer>;
  return (
    <div>
      <div>
        <Controller
          control={control}
          name="title"
          render={(props) => (
            <InputField disabled name="title" label="Project Title" style={{ background: "#ffffff" }} {...props} />
          )}
        />
      </div>
      <div>
        <Controller
          control={control}
          name="projectDescription"
          render={(props) => <InputField label="Project Desciption" multiline style={{ background: "#ffffff" }} {...props} />}
        />
      </div>

      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div>
          <DateRangeWrapper width={{ root: "97%" }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                control={control}
                name="budgetYear"
                render={({ value, onChange, ...props }) => (
                  <KeyboardDatePicker
                    label="Project Year"
                    disableToolbar
                    openTo="year"
                    views={["year"]}
                    variant="inline"
                    format="yyyy"
                    placeholder="YYYY"
                    margin="normal"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...props}
                    value={new Date(Date.parse(value))}
                    onChange={(e) => {
                      onChange(e?.getFullYear());
                      return e;
                    }}
                  />
                )}
              />
            </MuiPickersUtilsProvider>
          </DateRangeWrapper>
          {onRenderError(errors.startDate)}
        </div>
        <div>
          <Controller
            control={control}
            name="budgetSource"
            render={(props) => (
              <InputField
                defaultValue="Kwara State Government (KWSG)"
                disabled
                label="Budget Source"
                style={{ background: "#ffffff" }}
                {...props}
              />
            )}
          />
        </div>
      </div>

      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div style={{ background: "#ffffff" }}>
          <Controller
            control={control}
            name="budgetAmount"
            render={({ onChange, ...props }) => (
              <NumberFormat
                customInput={InputField}
                name="budgetAmount"
                label="Budget Amount"
                thousandSeparator
                prefix="₦"
                variant="outlined"
                {...props}
                onValueChange={(values) => {
                  const { value } = values;
                  onChange(value);
                }}
              />
            )}
          />
        </div>
        <div>
          <Controller
            control={control}
            name="ministryName"
            render={(props) => (
              <InputField disabled label="Procuring Entity / SubSector" style={{ background: "#ffffff" }} {...props} />
            )}
          />
        </div>
      </div>
      <div>
        <Controller
          control={control}
          name="procurementCategory"
          render={(props) => {
            return (
              <Dropdown
                disabled
                defaultValue={watch("category")}
                name="procurementCategory"
                style={{ width: "inherit" }}
                label="Procurement Category"
                {...props}
                values={categoryOptions}
              />
            );
          }}
        />{" "}
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "150px 1fr",
          placeItems: "center start",
          marginTop: "30px",
          padding: "2rem",
        }}
      >
        <UpdateButton onClick={handleSubmit(onSubmit)}>{updatingContract ? "Saving Changes..." : "Save Changes"}</UpdateButton>
        <CancelButton onClick={() => history.push(`/admin/ocdsList/ocds/${data.id}`)}>Cancel</CancelButton>
      </div>
    </div>
  );
}

EditPlanningTab.propTypes = {};

export default EditPlanningTab;
