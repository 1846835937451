import React, { useContext, useEffect, useState } from "react";
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Box, IconButton, Switch, FormControlLabel, Grid, Collapse, Typography } from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import {
  ActionButton,
  AddNewDocumentButton,
  ButtonsRow,
  Editor,
  RequiredDocumentGrid,
  RequiredDocuments,
  SectionTitle,
  SectionWrapper,
  StyledVendorCard,
} from "../style";
import DraftEditor from "../../../../../../components/DraftEditor";
import InputField from "../../../../../../components/InputField";
import { ReactComponent as CancelIcon } from "../../../../../../assets/cancel.svg";
import { ReactComponent as DeleteIcon } from "../../../../../../assets/delete-check.svg";
import ControlledDatePicker from "../../../../../../components/ControlledDatePicker";
import { ProcurementMethodAccessControl } from "../../../../../../shared";
import { AddButton } from "../../../../../Common/style";
import VendorDrawer from "./VendorDrawer";
import { useFetchVendors } from "../../../../../../hooks/queries/useVendor";
import { useProcurementMethod } from "../../../../../../hooks/queries/useProcurement";
import { Procurement_Method } from "../../../../../../util/constants";
import ToastContext from "../../../../../../util/toastContext";


const useStyles = makeStyles({
  root: {
    [`& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track`]: {
      backgroundColor: "#777",
    },
  },
  thumb: {
    backgroundColor: (props) => (props.checked ? "#2AA96D" : "#777"),
  },
});

const getWordCount = (editorState) => {
  const plainText = editorState?.getCurrentContent()?.getPlainText("");
  const regex = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed
  const cleanString = plainText?.replace(regex, " ").trim(); // replace above characters w/ space
  const wordArray = cleanString?.match(/\S+/g); // matches words according to whitespace
  return wordArray ? wordArray?.length : 0;
};

const RequiredDocument = ({
  title,
  setTitle,
  description,
  isCompulsory,
  setDescription,
  activityIsApproved,
  onDelete,
  setIsCompulsory,
  isActive,
  onClickEdit,
  isCurrentEdit,
  activeEditIndex,
  procurementMtd,
}) => {
  const classes = useStyles({ checked: isCompulsory });
  const [showInputFields, setShowInputFields] = useState(true);

  useEffect(() => {
    if (!isActive) {
      setShowInputFields(false);
    }
  }, [isActive]);

  return (
    <RequiredDocumentGrid>
      <Grid container>
        <Grid item style={{ flex: 1 }}>
          <Collapse in={!isActive}>
            <Box mr={2}>
              <Box
                bgcolor="rgba(0,0,0, .05)"
                mb={showInputFields || isCurrentEdit ? 2 : 0}
                p={2}
                style={{ borderRadius: 3, minHeight: 50 }}
              >
                <Grid container>
                  <Grid item style={{ flex: 1 }}>
                    <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
                      {title}
                    </Typography>
                    <Box mt={1}>
                      <Typography variant="subtitle2" style={{ maxHeight: 100 }}>
                        {description}
                      </Typography>
                    </Box>
                  </Grid>
                  <IconButton onClick={onClickEdit} className="iconButton">
                    <EditIcon fill="#EB5757" style={{ fontSize: 12 }} />
                  </IconButton>
                </Grid>
              </Box>
            </Box>
          </Collapse>
          <Collapse in={isActive || isCurrentEdit}>
            <Box mr={2}>
              <Box mb={2}>
                <InputField
                  disabled={activityIsApproved}
                  value={title}
                  setValue={setTitle}
                  label="Document Title"
                  style={{ padding: 0 }}
                />
              </Box>
              <Box mb={2}>
                <InputField
                  disabled={activityIsApproved}
                  value={description}
                  setValue={setDescription}
                  label="Description"
                  style={{ padding: 0 }}
                />
              </Box>
              <FormControlLabel
                control={
                  <Switch
                    classes={{
                      root: classes.root,
                      thumb: classes.thumb,
                    }}
                    checked={isCompulsory}
                    disabled={activityIsApproved}
                    onChange={(event) => {
                      setIsCompulsory(event.target.checked);
                    }}
                    name="isCompulsory"
                  />
                }
                label="Is document required?"
              />
            </Box>
          </Collapse>
        </Grid>
        {!activityIsApproved && (
          <IconButton onClick={onDelete} className="iconButton">
            <CancelIcon fill="#EB5757" width={12} height={12} />
          </IconButton>
        )}
      </Grid>
    </RequiredDocumentGrid>
  );
};

const Requirements = ({ saveState, activityIsApproved, onNext, initialState = {}, procurementId, method = "", setEntries }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [activeEditIndex, setActiveEditIndex] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(null);
  const { showAlert } = useContext(ToastContext)
  const [vendor, setVendor] = useState([]);
  const [filter, setFilter] = useState({
    Search: null,
    Status: 2,
  });
  const params = {
    ...filter,
    PageNumber: 1,
    PageSize: 100,
  };
  const { vendorList } = useFetchVendors({ params });
  const { isSuccess } = useProcurementMethod({ procurementId });

  const updateFilter = (key) => (value) => {
    setFilter((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const optionFormat = vendorList?.data?.map((field) => ({
    value: field?.id,
    label: field?.vendorProfile?.companyName,
    label2: field?.vendorProfile?.registrationPlan?.grade,
  }));

  const [documents, setDocuments] = useState([
    {
      title: "",
      description: "",
      isCompulsory: false,
    },
  ]);
  const removeVendor = (id) => {
    setVendor((prev) => prev?.filter((field) => field !== id));
  };

  const [deadline, setDeadline] = useState(null);

  useEffect(() => {
    if (initialState?.requiredDocuments) {
      setDocuments(initialState.requiredDocuments);
    }

    if (initialState?.deadline) {
      setDeadline(initialState?.deadline);
    }

    if (initialState.editorState) {
      const blocks = convertFromHTML(initialState.editorState);
      const state = ContentState.createFromBlockArray(blocks.contentBlocks, blocks.entityMap);

      setEditorState(EditorState.createWithContent(state));
    }
  }, [initialState]);

  const updateDocument = (index, key) => (value) => {
    const newDocuments = [...documents];

    newDocuments[index][key] = value;

    setDocuments(newDocuments);
  };

  const onDeleteDocument = (index) => () => {
    setDocuments(documents.filter((_, idx) => idx !== index));
  };

  const addDocument = () => {
    setActiveEditIndex(null);
    setDocuments(
      documents.concat({
        title: "",
        description: "",
        isCompulsory: false,
      })
    );
  };
  const VendorCard = ({ grade, companyName, id }) => (
    <StyledVendorCard>
      <Box
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <Typography>{companyName}</Typography>
        <Typography>{grade}</Typography>
      </Box>
      <Box>
        <DeleteIcon onClick={() => removeVendor(id)} width={12} height={12} />
      </Box>
    </StyledVendorCard>
  );

  const addEntry = (entry) => {
    setEntries((prev) => [...prev, entry])
  }

  const removeEntry = (entry) => {
    setEntries((prev) => prev.filter((x) => x !== entry))
  }

  useEffect(() => {
    if(setEntries === null || setEntries === undefined) return
    getWordCount(editorState) > 0 ? addEntry("Notice Info") : removeEntry("Notice Info")
    documents[0]?.title !== "" ? addEntry("Required Documents") : removeEntry("Required Documents")
    deadline !== null ? addEntry("Deadline") :  removeEntry("Deadline")
    if(initialState?.vendors?.length > 0){
      addEntry("Select Vendors")
    }else {
      vendor.length > 2 ? addEntry("Select Vendors") : removeEntry("Select Vendors")
    }
  }, 
    // eslint-disable-next-line
  [documents,deadline,vendor, initialState, editorState])

  const onClickNext = () => {
    if([Procurement_Method.REQ_QUOTATION, Procurement_Method.RESTRICTED_TENDERING_METHOD].includes(method) && vendor.length < 3) {
     showAlert({message: "Select a minimum of three vendors", severity: "error"})
     return  
    }
    const editorStateHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    saveState({ requiredDocuments: documents, editorState: editorStateHTML, deadline, vendorIds: vendor });
    onNext();
  };

  return (
    <>
      <SectionTitle>Note</SectionTitle>
      <SectionWrapper>
        <Editor>
          <DraftEditor editorState={editorState} setEditorState={setEditorState} isDisabled={activityIsApproved} />
        </Editor>
      </SectionWrapper>
      {/* <ProcurementMethodAccessControl
        allowedMethod={[Procurement_Method.DIRECT_PROCUREMENT]}
        isSuccess={isSuccess}
        method={method}
      >
        <SectionTitle>Select Vendors</SectionTitle>
        <SectionWrapper>
          <CustomeRadio
            options={selectionOption}
            label="Manual Selection"
            row
            value={selection}
            onChange={handleSelection}
            name="selection"
          />
          {vendor?.map((field) => {
            const foundVendor = vendorList?.data?.find((fields) => fields?.id === field);
            return (
              <VendorCard
                grade={foundVendor?.vendorProfile?.registrationPlan?.grade || ""}
                companyName={foundVendor?.vendorProfile?.companyName}
                id={field}
              />
            );
          })}
          {Boolean(parseInt(selection)) && <AddButton onClick={() => setOpenDrawer(true)}>Add New Vendors</AddButton>}
        </SectionWrapper>
      </ProcurementMethodAccessControl> */}
      <ProcurementMethodAccessControl allowedMethod={[Procurement_Method.REQ_QUOTATION, Procurement_Method.DIRECT_PROCUREMENT]} isSuccess={isSuccess} method={method}>
        <SectionWrapper>
          {vendor?.map((field) => {
            const foundVendor = vendorList?.data?.find((fields) => fields?.id === field);
            return (
              <VendorCard
                grade={foundVendor?.vendorProfile?.registrationPlan?.grade || ""}
                companyName={foundVendor?.vendorProfile?.companyName}
                id={field}
              />
            );
          })}
          <>
            {activityIsApproved &&
              initialState?.vendors?.map(({ class: grade, companyName }) => (
                <VendorCard grade={grade} companyName={companyName} id={companyName} />
              ))}
          </>
          {
            <AddButton disabled={activityIsApproved} onClick={() => setOpenDrawer(true)}>
              Add New Vendors
            </AddButton>
          }
        </SectionWrapper>
      </ProcurementMethodAccessControl>

      <SectionTitle>Required Documents</SectionTitle>
      <SectionWrapper>
        <Box mb={3}>
          <ControlledDatePicker
            disabled={activityIsApproved}
            label="Deadline"
            value={deadline}
            onChange={setDeadline}
            style={{ margin: 0 }}
          />
        </Box>
        <RequiredDocuments>
          {documents.map(({ title, description, isCompulsory }, index) => (
            <RequiredDocument
              title={title}
              isActive={index + 1 === documents.length}
              activityIsApproved={activityIsApproved}
              setTitle={updateDocument(index, "title")}
              description={description}
              isCompulsory={isCompulsory}
              activeEditIndex={activeEditIndex}
              onClickEdit={() => setActiveEditIndex(index)}
              isCurrentEdit={activeEditIndex === index}
              setDescription={updateDocument(index, "description")}
              setIsCompulsory={updateDocument(index, "isCompulsory")}
              onDelete={onDeleteDocument(index)}
            />
          ))}
        </RequiredDocuments>
        {!activityIsApproved && (
          <AddNewDocumentButton style={{ margin: 0, marginTop: 20 }} onClick={addDocument}>
            Add New Document
          </AddNewDocumentButton>
        )}
      </SectionWrapper>
      <ButtonsRow>
        <div />
        <ActionButton disabled={activityIsApproved} variant="contained" onClick={onClickNext}>
          Next
        </ActionButton>
      </ButtonsRow>
      <VendorDrawer
        open={Boolean(openDrawer)}
        onClose={() => setOpenDrawer(false)}
        onAction={() => {}}
        vendor={vendor}
        setVendor={setVendor}
        search={filter.Search}
        setSearch={updateFilter("Search")}
        options={optionFormat}
        method={method}
      />
    </>
  );
};

export default Requirements;
