import React, { useContext, useState } from "react";
import { queryCache, useMutation, useQuery } from "react-query";
import { Chip, Grid } from "@material-ui/core";
import format from "date-fns/format";
import { TitleContainer } from "../../style";
import { PageTitle, PageWrapper } from "../../../../Common/style";
import Breadcrumb from "../../../../Common/PageElements/Breadcrumb";
import TopStatisticsBar from "../../../../../components/TopStatisticsBar";
import {
  CertificateOfNoObjectionWidgetLeft,
  CertificateOfNoObjectionWidgetMiddle,
  InstructionDescription,
  InstructionHeader,
  UploadButton,
} from "./style";
import FileProgress from "../../../../../components/FileProgress/FileProgress";
import LoadingButton from "../../../../../components/LoadingButton/LoadingButton";
import Box from "@material-ui/core/Box";
import EvaluatedVendorsTable from "../../../../../components/EvaluatedVendorsTable/EvaluatedVendorsTable";
import { useParams, useLocation } from "react-router-dom";
import certificateOfNoObjectionApi from "../../../../../redux/api/certificateOfNoObjectionApi";
import ToastContext from "../../../../../util/toastContext";
import Paper from "@material-ui/core/Paper";
import { ReactComponent as SaveIcon } from "../../../../../assets/save.svg";
import { ReactComponent as SendIcon } from "../../../../../assets/send-2.svg";
import { openFileUrlInNewWindow } from "../../../../Common/util";
import AccessControl from "../../../../../components/AccessControl";
import { Roles } from "../../../../../components/AccessControl/UserRoles";
import { useRoles } from "../../../../../hooks/useUserDetails";
import FormDrawer from "./FormDrawerAward";
import CNOForPaymentDrawer from "./CNOForPaymentDrawer";
import procurementAPI from "../../../../../redux/api/procurementAPI";
import { useProcurementMethod } from "../../../../../hooks/queries/useProcurement";
import { ProcurementMethodAccessControl } from "../../../../../shared";
import { Procurement_Method } from "../../../../../util/constants";
import { HoverTableStyle } from "../../../../../util/theme";
import SearchTable, { StyledTableCell } from "../../../../../components/SearchTable";
import { formatCurrency } from "../../../../../util/formatCurrency";

export const pickStatus = (status) => {
  switch (status) {
    case "Completed":
      return <Chip label={status} style={{ minWidth: "100px", height: "24px", color: "#414F3E", background: "#BBE5B3" }} />;
    case "In Progress":
      return <Chip label={status} style={{ minWidth: "100px", height: "24px", color: "#FF8D26", background: "#FCEBDB" }} />;
    case "Under Review":
      return <Chip label={status} style={{ minWidth: "100px", height: "24px", color: "#FF8D26", background: "#FCEBDB" }} />;
    case "APPROVED":
      return <Chip label={status} style={{ minWidth: "100px", height: "24px", color: "white", background: "#00AFF0" }} />;
    default:
      return <Chip label={status} style={{ minWidth: "100px", height: "24px", color: "#C9D4DB", background: "#414F3E" }} />;
  }
};
const baseVendorObject = {
  data: {
    responseSummary: {
      total: 0,
      recommended: 0,
      notRecommended: 0,
    },
    bidRecommendations: [],
  },
  pagination: {
    totalEntries: 0,
  },
};

const CertificateOfNoObjection = () => {
  const { procurementId, activityId } = useParams();
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDlm, setOpenDlm] = React.useState(false);
  const [proMTD, setProMTD] = React.useState("");

  const generalPlanLinks = [
    {
      url: "/dashboard/overview",
      title: "Home",
    },
    {
      url: `/admin/procurement/plans`,
      title: "Plans",
    },
    {
      url: `/admin/procurement/plan-details/${procurementId}`,
      title: "Plan Details",
    },
  ];

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const [uploadFile, setUploadFile] = useState(null);

  const bidRecommendationsQuery = useQuery({
    queryKey: [
      "bidRecommendations",
      {
        PageSize: pagination.pageSize,
        PageNumber: pagination.pageNumber,
        procurementId,
      },
    ],
    queryFn: certificateOfNoObjectionApi.getBidRecommendations,
  });

  const { data: planDetails, method, isSuccess, isLoading: loadingProcurement } = useProcurementMethod({ procurementId });

  // const isDLM = planDetails?.procurementMethod?.code.toLowerCase() === "dlm";
  // const isDPM = planDetails?.procurementMethod?.code.toLowerCase() === "dpm";
  const isCNOPAYMENT = location.pathname.includes("certificateofnoobjectionforpayment");
  const certificate = useQuery({
    queryFn: certificateOfNoObjectionApi.getCertificate,
    queryKey: [
      "certificateOfObjection",
      {
        procurementId,
      },
    ],
  });

  React.useEffect(() => {
    if (planDetails?.procurementMethod) {
      setProMTD(planDetails?.procurementMethod?.code.toLowerCase());
    }
    // eslint-disable-next-line
  }, [planDetails?.procurementMethod]);

  const { data: CNO } = useQuery(["getCNO", procurementId], procurementAPI.getCNOPaymentCertificate);

  const [uploadCertificate, uploadCertificateQuery] = useMutation(certificateOfNoObjectionApi.uploadCertificateOfObjection, {
    throwOnError: true,
  });

  const { showAlert } = useContext(ToastContext);

  const onClickUpload = async () => {
    try {
      await uploadCertificate({
        procurementId,
        File: uploadFile,
      });

      queryCache.removeQueries("certificateOfObjection");

      showAlert({
        message: "Successfully uploaded documents",
        severity: "success",
        durationInMs: 3000,
      });
    } catch (e) {
      showAlert({
        message: e.message,
        severity: "error",
      });
    }
  };
  console.log({ CNO: CNO?.[CNO?.length - 1] });

  const updatePagination = (key) => (value) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      [key]: value,
    }));
  };

  const {
    data: { bidRecommendations, responseSummary },
    pagination: apiPagination,
  } = Object.assign(baseVendorObject, bidRecommendationsQuery.data);
  const { data: certificateData } = certificate;

  const { role } = useRoles();

  const handleDrawerClick = () => {
    switch (proMTD) {
      case "dlm": {
        setOpenDlm(true);

        break;
      }
      case "dpm": {
        if (isCNOPAYMENT) {
          setOpenDlm(true);
        } else {
          setOpenDrawer(true);
        }

        break;
      }

      default: {
        setOpenDrawer(true);

        break;
      }
    }
  };

  const renderLeftContent = () => {
    if (certificateData?.length > 0 && role === Roles.PROCUREMENT_OFFICER) {
      const file = certificateData[0];

      return (
        <CertificateOfNoObjectionWidgetLeft>
          <p>Date Recieved</p>
          <p>{format(new Date(file.createdAt), "dd MMM, yyyy")}</p>
        </CertificateOfNoObjectionWidgetLeft>
      );
    }

    return (
      <CertificateOfNoObjectionWidgetLeft>
        <p>Total Vendors</p>
        <p>{responseSummary.total}</p>
      </CertificateOfNoObjectionWidgetLeft>
    );
  };

  const { data: activityDetails } = useQuery({
    queryKey: [
      "activityDetails",
      {
        activityId,
      },
    ],
    queryFn: procurementAPI.getActivityById,
  });

  const renderPaymentCNO = () => {
    return (
      <>
        {CNO?.length > 0 && (
          <CertificateOfNoObjectionWidgetLeft style={{ width: "73%" }}>
            <p>Certificate of No Objection</p>

            <Box mt={1.2} container>
              <Grid item xs={6}>
                <FileProgress
                  progress={100}
                  hasEyes
                  onClickEyes={() => openFileUrlInNewWindow(CNO[CNO?.length - 1].file.url)}
                  fileName="Certificate_of_no_Objection"
                  fileSize={CNO[CNO?.length - 1].file.bytes}
                />
              </Grid>
            </Box>
          </CertificateOfNoObjectionWidgetLeft>
        )}
      </>
    );
  };

  const renderPaymentCertificateDate = () => {
    return (
      <>
        {CNO?.length > 0 && (
          <CertificateOfNoObjectionWidgetLeft>
            <p>Date Recieved</p>
            <p>{format(new Date(CNO[CNO?.length - 1]?.createdAt), "dd MMM, yyyy")}</p>
          </CertificateOfNoObjectionWidgetLeft>
        )}
      </>
    );
  };
  const renderCenterContent = () => {
    const hasFile = certificateData?.length > 0;

    if (hasFile && role === Roles.PROCUREMENT_OFFICER) {
      const file = certificateData[0];

      return (
        <CertificateOfNoObjectionWidgetLeft style={{ width: "73%" }}>
          <p>Certificate of No Objection</p>
          <Box mt={1.2} container>
            <Grid item xs={6}>
              <FileProgress
                progress={100}
                hasEyes
                onClickEyes={() => openFileUrlInNewWindow(file.file.url)}
                fileName="CNO_award_contract"
                fileSize={file.file.bytes}
              />
            </Grid>
          </Box>
        </CertificateOfNoObjectionWidgetLeft>
      );
    }

    return (
      <CertificateOfNoObjectionWidgetMiddle>
        <div>
          <p>Recommended</p>
          <p>{responseSummary.recommended}</p>
        </div>
        <div>
          <p>Not Recommended</p>
          <p>{responseSummary.notRecommended}</p>
        </div>
        <div>
          <AccessControl allowedRoles={[Roles.PROCUREMENT_OFFICER]}>
            <LoadingButton
              disabled={activityDetails?.procurementPlanActivityStatus?.toLowerCase() === "awaiting"}
              onClick={handleDrawerClick}
              color="#00b0f1"
            >
              {activityDetails?.procurementPlanActivityStatus?.toLowerCase() === "awaiting"
                ? "Waiting for Certificate of No Objection"
                : activityDetails?.procurementPlanActivityStatus?.toLowerCase() === "amendment"
                ? "Amend CNO Request"
                : "Request for certificate of no objection"}
            </LoadingButton>
          </AccessControl>
        </div>
      </CertificateOfNoObjectionWidgetMiddle>
    );
  };

  const hasFile = certificateData?.length > 0;

  const renderActionSection = () => {
    let bottomSection;

    if (hasFile) {
      const file = certificateData[0];
      bottomSection = (
        <Box width="400px" mb={2.5}>
          <FileProgress
            progress={100}
            fileName="CNO_award_contract"
            fileSize={file.file.bytes}
            hasEyes
            onClickEyes={() => openFileUrlInNewWindow(file.file.url)}
          />
        </Box>
      );
    } else {
      if (uploadFile) {
        bottomSection = (
          <>
            <Box width="400px" mb={2.5}>
              <FileProgress
                progress={100}
                fileName={uploadFile.name}
                fileSize={uploadFile.size}
                hasCancel
                onCancel={() => setUploadFile(null)}
              />
            </Box>
            <LoadingButton
              color="#219653"
              endIcon={<SendIcon width="19px" />}
              style={{
                marginLeft: "0px",
              }}
              onClick={onClickUpload}
              loading={uploadCertificateQuery.isLoading}
            >
              Send Certificate
            </LoadingButton>
          </>
        );
      } else {
        bottomSection = (
          <>
            <UploadButton
              variant="contained"
              endIcon={
                <SaveIcon
                  width="19px"
                  fill="white"
                  style={{
                    transform: "rotate(180deg)",
                  }}
                />
              }
              onClick={handleDrawerClick}
            >
              Review and generate certificate
            </UploadButton>
          </>
        );
      }
    }

    return (
      <Paper elevation={2}>
        <Box p={3.75}>
          <InstructionHeader>Instruction</InstructionHeader>
          <InstructionDescription>Certificate of no objection</InstructionDescription>
          {bottomSection}
        </Box>
      </Paper>
    );
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  return (
    <>
      <PageWrapper>
        <ProcurementMethodAccessControl
          method={method}
          allowedMethod={Object.values(Procurement_Method).filter((val) => val !== Procurement_Method.DIRECT_LABOUR)}
          isSuccess={isSuccess}
        >
          <TitleContainer>
            <div>
              <PageTitle>Certificate of No Objection</PageTitle>
              <Breadcrumb values={generalPlanLinks} />
            </div>
          </TitleContainer>
          <Box>
            <TopStatisticsBar
              leftContent={renderLeftContent()}
              centerContent={renderCenterContent()}
              status={hasFile ? "approved" : "pending"}
              isLoading={certificate.isLoading || bidRecommendationsQuery.isLoading}
              hasMargin={false}
            />
            {certificate.isSuccess && <AccessControl allowedRoles={[Roles.KWPPA]}>{renderActionSection()}</AccessControl>}
          </Box>
          <EvaluatedVendorsTable
            isLoading={bidRecommendationsQuery.isLoading}
            vendors={bidRecommendations}
            showRecommended
            pageNumber={pagination.pageNumber}
            pageSize={pagination.pageSize}
            setPageNumber={updatePagination("pageNumber")}
            setPageSize={updatePagination("pageSize")}
            totalPages={apiPagination.totalEntries}
            type="certificate"
          />
        </ProcurementMethodAccessControl>

        {/* DLM CNO */}
        <ProcurementMethodAccessControl method={method} allowedMethod={[Procurement_Method.DIRECT_LABOUR]} isSuccess={isSuccess}>
          <TitleContainer>
            <div>
              <PageTitle>Certificate of No Objection</PageTitle>
              <Breadcrumb values={generalPlanLinks} />
            </div>
            {CNO?.length === 0 && (
              <AccessControl allowedRoles={[Roles.KWPPA]}>
                <LoadingButton
                  // disabled={activityDetails?.procurementPlanActivityStatus?.toLowerCase() === "awaiting"}
                  // onClick={isDLM || isDPM ? () => setOpenDlm(true) : () => setOpenDrawer(true)}
                  onClick={handleDrawerClick}
                  color="#00b0f1"
                >
                  Generate Certificate of no Objection
                </LoadingButton>
              </AccessControl>
            )}
          </TitleContainer>
          {CNO?.length > 0 ? (
            <>
              <Box>
                <TopStatisticsBar
                  leftContent={renderPaymentCertificateDate()}
                  centerContent={renderPaymentCNO()}
                  status={CNO?.length > 0 ? "approved" : "pending"}
                  isLoading={certificate.isLoading || bidRecommendationsQuery.isLoading}
                  hasMargin={false}
                />
              </Box>
              <SearchTable
                noRows
                headerText="Tender Information"
                columns={["package no/name", "category", "method", "status", "budget", ""]}
                loading={loadingProcurement}
              >
                <HoverTableStyle>
                  <StyledTableCell>
                    <div>
                      <p>{planDetails?.packageNumber}</p>
                      <span>{planDetails?.name}</span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>{planDetails?.procurementCategory.name}</StyledTableCell>
                  <StyledTableCell>DLM</StyledTableCell>
                  <StyledTableCell>{pickStatus(planDetails?.status)}</StyledTableCell>
                  <StyledTableCell>{formatCurrency(planDetails?.budget, true)}</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </HoverTableStyle>
              </SearchTable>
            </>
          ) : (
            <SearchTable
              noRows
              headerText="Tender Information"
              columns={["package no/name", "category", "method", "status", "budget", ""]}
              loading={loadingProcurement}
            >
              <HoverTableStyle>
                <StyledTableCell>
                  <div>
                    <p>{planDetails?.packageNumber}</p>
                    <span>{planDetails?.name}</span>
                  </div>
                </StyledTableCell>
                <StyledTableCell>{planDetails?.procurementCategory.name}</StyledTableCell>
                <StyledTableCell>DLM</StyledTableCell>
                <StyledTableCell>{pickStatus(planDetails?.status)}</StyledTableCell>
                <StyledTableCell>{formatCurrency(planDetails?.budget, true)}</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </HoverTableStyle>
            </SearchTable>
          )}
        </ProcurementMethodAccessControl>
      </PageWrapper>
      <FormDrawer toggleDrawer={onCloseDrawer} openDrawer={openDrawer} />
      <CNOForPaymentDrawer openDrawer={Boolean(openDlm)} toggleDrawer={() => setOpenDlm(null)} />
    </>
  );
};

export default CertificateOfNoObjection;
