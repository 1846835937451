import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles"
import { Divider, Box, Collapse, Grid, Typography } from "@material-ui/core"
// components
import RejectUpgradeModal from "./RejectUpgradeModal"
import ApprovalModal from './ApprovalModal';
import UpgradeLabel from "./CustomUpgradeLabel"
import { SaveButton } from "../NavigationButtons/style"
import {
  StyledBox,
  TextLogo,
  RejectButton,
  StyledTypogaphyText,
  StyledTypographyBold
} from "./style"
// utils
import { theme } from "../../util/theme";
import { separateNumByComma } from '../../util/truncateValue';



const useStyles = makeStyles((theme) => ({
  vendorGradeStyle: {

    height: 50,
    width: 50,
    backgroundColor: "rgba(0, 176, 241, 0.0735632)",
    fontWeight: 600,
    fontSize: 36,
    lineHeight: "87px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#2AA96D",

  },
  amountPaid: {
    display: "flex",
  }
}))
const CategoryUpgradeDetails = ({
  vendorUpgradeData,
  vendorId,
  userId,
  vendorStatus
}) => {
  // const { search } = useLocation();
  const [showRejectionModal, setShowRejectModal] = useState(false)
  const [showApprovalModal, setShowApprovalModal] = useState(false)
  const [loading, setLoading] = useState(false);
  const { vendorGradeStyle, amountPaid } = useStyles()

  const titleStyle = {
    textAlign: "center",
    marginBottom: 8,
    fontWeight: 800
  };
  const textStyle = {
    fontWeight: 800
  }

  const handleToggleModal = (key) => (value) => {
    if (key === "Approval") {
      setLoading(value)
      setShowApprovalModal(value)
    }
    if (key === "Rejection") {
      setShowRejectModal(value)
    }
    return
  }

  const currentClassData = vendorUpgradeData?.currentClass || {}
  const newClassData = vendorUpgradeData?.newClass || {}
  const currentFee = !!currentClassData?.fee ? currentClassData.fee : 0
  const newFee = !!newClassData?.fee ? newClassData.fee : 0
  const proccessingFee = !!vendorUpgradeData?.amountPaid ? vendorUpgradeData.amountPaid : 0

  const renderPaymentSummary = (title) => (
    <StyledBox>
      <div style={titleStyle}>
        <p style={textStyle} >{title || "Amount Paid"}</p>
      </div>
      <StyledBox display="flex" style={{
        border: `1px solid ${theme.color.primary}`,
        padding: "10px 10px",
        borderRadius: "3px"
      }}>
        <StyledBox className={vendorGradeStyle}>
          <TextLogo>G</TextLogo>
        </StyledBox>
        <StyledBox
          style={{
            width: "100%",
            padding: "10px 15px",
            "&.MuiBox-root": {
              padding: "20px 30px"
            },
          }}
        >
          <StyledBox display="flex">
            <StyledTypogaphyText>
              {newClassData?.name} (N{separateNumByComma(newFee)})
              - {currentClassData?.name} (N{separateNumByComma(currentFee)}): &nbsp; &nbsp;
            </StyledTypogaphyText>
            <StyledTypographyBold>
              N{separateNumByComma(newFee - currentFee)}
            </StyledTypographyBold>
          </StyledBox>
          <StyledBox className={amountPaid}>
            <StyledTypogaphyText>
              Processing Fee:  &nbsp; &nbsp;
            </StyledTypogaphyText>
            <StyledTypographyBold>
              N0.00
            </StyledTypographyBold>
          </StyledBox>
          <Divider style={{ marginTop: 8, marginBottom: 8 }} fullWidth />
          <StyledBox className={amountPaid}>
            <StyledTypogaphyText>Total Payment:  &nbsp; &nbsp;</StyledTypogaphyText>
            <StyledTypographyBold>
              N{proccessingFee}
            </StyledTypographyBold>
          </StyledBox>
        </StyledBox>
      </StyledBox>
    </StyledBox>
  )

  return (
    <>
      <Box>
        <Collapse in>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography style={titleStyle}>Current Category</Typography>
              <UpgradeLabel registrationPlan={vendorUpgradeData?.currentClass} />
            </Grid>
            <Grid item xs={6}>
              <Typography style={titleStyle}>New Category</Typography>
              <UpgradeLabel registrationPlan={vendorUpgradeData?.newClass} />
            </Grid>
          </Grid>
          {renderPaymentSummary()}
          <Box mt={3}>
            <Box p={3} style={{ borderRadius: "3px", backgroundColor: "rgba(0, 176, 241, 0.0715144)" }}>
              <Typography component="p">
                Not satisfied with the information provided on this screen.
                <Link
                  target={"_blank"}
                  to={`/admin/vendorsList/${vendorId}`}
                  style={{ textDecoration: "none", fontWeight: 700 }}
                > Click here</Link> to view addition information.
              </Typography>
              <Box display="flex" justifyContent="flex-start">
                <SaveButton
                  style={{
                    color: `${theme.color.primary}`,
                    marginLeft: 0,
                    marginTop: 20
                  }}
                  disabled={loading || !vendorStatus}
                  onClick={() => setShowApprovalModal(true)}
                >
                  Approve Upgrade
                </SaveButton>
                <RejectButton
                  style={{
                    marginLeft: "20px",
                    marginTop: 20
                  }}
                  onClick={() => setShowRejectModal(true)}
                  disabled={!vendorStatus}
                >
                  Reject Upgrade
                </RejectButton>
              </Box>
            </Box>
          </Box>
          <RejectUpgradeModal
            isOpen={showRejectionModal}
            toggleModal={handleToggleModal("Rejection")}
            userId={vendorId}
          />
          <ApprovalModal
            showApprovalModal={showApprovalModal}
            setShowApprovalModal={handleToggleModal("Approval")}
            userId={vendorId}
            classId={newClassData?.id}
          />
        </Collapse>
      </Box>
    </>
  )
}

export default CategoryUpgradeDetails
