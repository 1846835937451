import React from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

const ProcurementAccessControl = ({ allowedMethod = [], children, placeholder, isSuccess, method, noLoader }) => {
  if (!isSuccess) {
    return (
      <>
        {noLoader ? (
          <></>
        ) : (
          <Box padding={10} width={"100%"} display={"flex"} justifyContent={"center"}>
            <CircularProgress size={20} />
          </Box>
        )}
      </>
    );
  }
  if (allowedMethod.includes(method)) {
    return children;
  }
  return !!placeholder ? placeholder : null;
};

export default ProcurementAccessControl;
