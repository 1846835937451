import React, { useState, useEffect, useContext } from 'react'
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import AdminSearchFilter from '../../../components/AdminSearchFilter';
import StatisticsCards from '../../../components/StatisticsCards';
import withLoader from '../../Common/HOC/withLoader';
import CustomSearchTable from "../../../components/SearchTable/SupplierSearchTable";
import Loader from "../../../components/Common/Loader";
import withAdminDashboard from '../HOC/withAdminDashboard'
import VendorUpgradeRequestTable from './VendorRequestsTable';
import { RequestInputField } from './style';
import {
  ContentWrapper,
  TitleContainer
} from "../Administration/Staff/style";
// context
import ToastContext from "../../../util/toastContext"
import { getVendorsSummary } from "../../../redux/reducer/adminReducer";
import vendorsAPI from "../../../redux/api/vendorsAPI"
import { PageTitle } from '../../Common/style';
import Breadcrumb from '../../Common/PageElements/Breadcrumb';

const createCardStats = (title, count, subTitle, type) => {
  return {
    title,
    count,
    subTitle,
    type
  }
}
const RenderStatCards = () => {
  const { vendorsSummary } = useSelector((state) => state.admin);
  const cards = [
    createCardStats("Total Request", vendorsSummary?.total, "All Vendors", null),
    createCardStats("Approved", vendorsSummary?.activeTotal, "", "active"),
    createCardStats("Pending", vendorsSummary?.pendingTotal, "", "pending"),
  ]
  return <StatisticsCards cards={cards} />
}
const createHeader = (id, label) => {
  return {
    id,
    label
  }
}
const headLabel = [
  createHeader("CompanyName", "COMPANY NAME"),
  createHeader("CurrentCategory", "CURRENT CATEGORY"),
  createHeader("NewCategory", "NEW CATEGORY"),
  // createHeader("Status", "STATUS"),
  createHeader("AmountPaid", "AMOUNT PAID"),
]

const VendorUpgradeRequests = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filters, setFilters] = useState({
    Search: "",
    Sort: "",
    Status: "",
    Filter: "",
    FilterBy: "",
  })

  const { showAlert } = useContext(ToastContext);
  const marginTop = "40px";
  const status = "loading"

  const { data: apiData, isLoading, error = {}, isError } = useQuery(
    ["getVendorUpgradeList",
      {
        PageNumber: pageNumber + 1,
        PageSize: rowsPerPage,
        ...filters
      }
    ],
    vendorsAPI.getVendorUpgradeList
  )

  const vendorListData = apiData?.data || []
  const meta = apiData?.meta || {}

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isError) {
      showAlert({
        severity: "error",
        message: error?.message,
        durationInMs: 3000,
      })
    }
    // eslint-disable-next-line
  }, [showAlert])

  const updateFilters = (key) => (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };
  const emptyRows =
    pageNumber > 0 ? Math.max(0, rowsPerPage - vendorListData?.length) : 0

  const handleChangePage = (event, pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(0);
  };
  const renderVendorRequestTable = (
    <div id='request'>
      <CustomSearchTable
        rows={vendorListData}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        loading={isLoading}
        columns={headLabel}
        showPadding={false}
        totalNumberOfPages={meta?.pagination?.totalPages}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSortRequest={updateFilters("Sort")}
      >
        <VendorUpgradeRequestTable
          emptyRows={emptyRows}
          requests={vendorListData}
          column={headLabel}
        />
      </CustomSearchTable>
    </div>
  );


  const GeneralPlanLinks = [
    { url: "/admin/vendorsList/vendors", title: "Overview" },
    { title: "Vendor Upgrade Request" },
  ];

  return (
    <ContentWrapper>
      <TitleContainer>
        <div>
          <PageTitle>Vendor Upgrade Requests</PageTitle>
          <Breadcrumb values={GeneralPlanLinks} />
        </div>
      </TitleContainer>
      {
        withLoader(RenderStatCards)({
          stateName: "admin",
          conditioners: ["vendorsSummary"],
          apis: [getVendorsSummary()]
        })
      }
      <React.Fragment>
        <AdminSearchFilter normalSearch={true} title="Filter">
          <div style={{ display: "flex" }}>
            <RequestInputField
              type="text"
              label="Search Name"
              style={{ width: 300 }}
              setValue={updateFilters("Search")}
            />
            {/* <DropdownField
              name="approval"
              label="Approval Status"
              style={{ width: 150 }}
              onChange={updateFilters("Status")}
              required
              values={[
                { label: "All", value: null },
                { label: "Pending", value: 1 },
                { label: "Approved", value: 2 },
                { label: "Rejected", value: 3 },
              ]}
            /> */}
          </div>
        </AdminSearchFilter>
        <div style={{ marginTop }}>
          {status === "loadig" ?
            (
              <div style={{ width: "100%" }}>
                <Loader feedback="Fetching Vendor Upgrade Request Lists" />
              </div>
            ) : renderVendorRequestTable
          }

        </div>
      </React.Fragment>
    </ContentWrapper>
  )
}

export default withAdminDashboard(VendorUpgradeRequests)
