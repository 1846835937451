import { TableCell, TableRow } from "@material-ui/core";
import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";

import {
  CardWrapper,
  StatusCircle,
  StatusCirclePending,
  StatusCircleSuccess,
  TableWrapper,
  UnstyledLink,
  StatusCircleAwaiting,
} from "../../../../PlanDetails/style";
import { Overlay, Title, Subtitle } from "../../../../PlanDetails/ApprovalMemo/style";
import { UploadButton } from "../../CertificateOfNoObjection/style";
import ConfirmWithoutEvaluation from "./ConfirmWithoutEvaluation";
import AccessControl from "../../../../../../components/AccessControl";
import { Roles } from "../../../../../../components/AccessControl/UserRoles";

const statusCircles = {
  Pending: <StatusCirclePending marginRight={30} />,
  Awaiting: <StatusCircleAwaiting marginRight={30} />,
  Approved: <StatusCircleSuccess marginRight={30} />,
  InActive: <StatusCircle marginRight={30} />,
};

// const tableCellStyle = { padding: "8px 16px" };

const EvaluationTable = ({ evaluationStat = {} }) => {
  const { pathname } = useLocation();

  return (
    <CardWrapper noPadding gray>
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Stage</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell style={{ padding: "24px 16px" }}>
                {(evaluationStat?.financial?.totalEvaluated > 0  && (evaluationStat?.financial?.totalPending === 0))
                  ? statusCircles.Approved
                  : statusCircles.Pending}
                <UnstyledLink to={`${pathname}/financial-reviews`}>Financial review</UnstyledLink>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableWrapper>
    </CardWrapper>
  );
};

 // eslint-disable-next-line
function CertificateOfNoObjectionOverlay({ technicalEvaluation = {}, procurementId, onUpdateEvalSuccess }) {
  const { pathname } = useLocation();
  const [openWithoutTechnicalModal, setOpenWithoutTechnicalModal] = useState(false);

  return (
    <>
      <Overlay>
        <VisibilityOffOutlinedIcon style={{ fontSize: 36, marginBottom: 8 }} />
        <AccessControl
          allowedRoles={[
            Roles.KWPPA,
            Roles.COMMISSIONER,
            Roles.MINISTERIAL_TENDER_BOARD,
            Roles.PARASTATALS_TENDERS_BOARD,
            Roles.GOVERNOR,
            Roles.PERMANENT_SECRETARY,
            Roles.ACCOUNTING_OFFICER,
          ]}
        >
          <Title style={{ marginBottom: 8 }}>Evaluation type has not been set by appropriate personels</Title>
        </AccessControl>
        <AccessControl allowedRoles={[Roles.TENDER_BOARD]}>
          <Title style={{ marginBottom: 8 }}>Select Evaluation Type</Title>
          <Subtitle>To proceed to the milestone phases, click the</Subtitle>
          <div style={{ display: "flex", alignItems: "center", marginTop: 16 }}>
            <UploadButton
              variant="contained"
              style={{ marginRight: 8 }}
              component={Link}
              to={`${pathname}/create-technical-evaluation`}
            >
              With technical evaluation
            </UploadButton>
            <UploadButton
              variant="outlined"
              style={{
                backgroundColor: "transparent",
                borderColor: "white",
              }}
              onClick={() => setOpenWithoutTechnicalModal(true)}
            >
              Without technical evaluation
            </UploadButton>
          </div>
        </AccessControl>
      </Overlay>
      <ConfirmWithoutEvaluation
        isOpen={openWithoutTechnicalModal}
        procurementId={procurementId}
        technicalEvaluation={technicalEvaluation}
        onUpdateEvalSuccess={onUpdateEvalSuccess}
        onClose={() => setOpenWithoutTechnicalModal(false)}
      />
    </>
  );
}

export default EvaluationTable;
