import { Box, Grid } from "@material-ui/core";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";

import { InputField } from "../../../../../../components";
import Breadcrumb from "../../../../../Common/PageElements/Breadcrumb";
import { ContentWrapper } from "../../../../Administration/Staff/style";
import { GeneralTitlePage } from "../../SpecificProcurementNotice/style";
import { EvalFormControlContainer, EvaluationCard, EvaStyledContainer, EvaSubTitleText, EvaTitleText, FileGrid } from "./style";
import CheckEntryCard from "../../../../../../components/CheckEntryCard";
import { useMutation, useQuery } from "react-query";
import receiptOfBiddersResponseApi from "../../../../../../redux/api/receiptOfBiddersResponseApi";
import FileProgress from "../../../../../../components/FileProgress/FileProgress";
import { openFileUrlInNewWindow } from "../../../../../Common/util";
import AccessControl from "../../../../../../components/AccessControl";
import { Roles } from "../../../../../../components/AccessControl/UserRoles";
import Loader from "../../../../../../components/Common/Loader";
import ToastContext from "../../../../../../util/toastContext";
import LoadingButton from "../../../../../../components/LoadingButton/LoadingButton";
import { useRoles } from "../../../../../../hooks/useUserDetails";
import { ReactComponent as GreenCheck } from "../../../../../../assets/green-check.svg";
import { ReactComponent as RedCheck } from "../../../../../../assets/red-check.svg";
import WarningModal from "./WarningModal";
import ControlledDropdown from "../../../../../../components/ControlledDropdownField";

const VendorInfo = ({ titleText, titleSubtext }) => {
  return (
    <Box marginBottom="10px">
      <EvaTitleText>{titleText}</EvaTitleText>
      <EvaSubTitleText>{titleSubtext}</EvaSubTitleText>
    </Box>
  );
};

const VendorInfoOptionSelection = ({ titleText, name, handleChange, selectedValue, previewMode }) => {
  const { role } = useRoles();

  const isMaker = [Roles.PROCUREMENT_OFFICER, Roles.MINISTERIAL_TENDER_BOARD].includes(role);

  return (
    <Box marginBottom="30px">
      <EvaTitleText style={{ padding: 0 }}>{titleText}</EvaTitleText>
      <FormControl component="fieldset">
        <Box>
          <RadioGroup onChange={handleChange}>
            <Box display="flex">
              <EvalFormControlContainer
                checked={selectedValue[name]}
                active={(selectedValue[name] === true).toString()}
                control={<Radio name={name} size="small" color="primary" disabled={!isMaker || previewMode} />}
                label="Yes"
              />
              <EvalFormControlContainer
                checked={!selectedValue[name]}
                active={(selectedValue[name] === false).toString()}
                control={<Radio name={name} size="small" color="primary" disabled={!isMaker || previewMode} />}
                label="No"
              />
            </Box>
          </RadioGroup>
        </Box>
      </FormControl>
    </Box>
  );
};

const marginBottom = "30px";

const defaultRadio = {
  taxClearance: false,
  dsirCard: false,
  companyMemo: false,
  companyProfile: false,
  pastJobs: false,
};

const defaultEvalInfo = {
  quotation: "",
  completionNumber: "",
  completionPeriod: 1,
  recommendation: "",
  remark: "",
};

const baseFormState = {
  bidDocuments: [],
  formData: {
    quotation: 0,
    taxClearance: null,
    dsirCard: null,
    companyMemo: null,
    companyProfile: null,
    pastJobs: null,
    completionNumber: 0,
    completionPeriod: 1,
  },
};

const NoMarginInputField = ({ disabled, ...props }) => (
  <InputField disabled={disabled} {...props} style={{ margin: 0, padding: 0, backgroundColor: "#ffffff" }} />
);

const VendorEvaluation = ({ previewMode }) => {
  const { activityId, vendorId, procurementId } = useParams();
  const history = useHistory();

  const [selectedValues, setSelectedValues] = useState(defaultRadio);
  const [evalInfoValues, setEvalInfoValues] = useState(defaultEvalInfo);
  const [completedForm, setCompletedForm] = useState(new Set());
  const [warningModalState, setWarningModalState] = useState({
    vendorId: null,
    vendorName: null,
    open: false,
  });

  const { role } = useRoles();

  const isMaker = [Roles.PROCUREMENT_OFFICER].includes(role);
  const isReviewer = [Roles.TENDER_BOARD].includes(role);

  const biddersResponse = useQuery({
    queryFn: receiptOfBiddersResponseApi.getFormDetails,
    queryKey: [
      "biddersResponse",
      {
        vendorId,
        procurementId,
      },
    ],
  });

  const data = Object.assign(baseFormState, biddersResponse.data);

  // const [submitForm] = useMutation(receiptOfBiddersResponseApi.submitForm, {
  //   throwOnError: true,
  // });

  // const [submitForm, submitFormQuery] = useMutation(
  //   receiptOfBiddersResponseApi.submitForm,
  //   {
  //     throwOnError: true
  //   }
  // )

  const [recommendVendor, recommendVendorQuery] = useMutation(receiptOfBiddersResponseApi.addRecommendation, {
    throwOnError: true,
  });

  useEffect(() => {
    if (biddersResponse.isSuccess) {
      let {
        taxClearance,
        dsirCard,
        companyMemo,
        companyProfile,
        pastJobs,
        quotation,
        completionNumber,
        completionPeriod,
        type: recommendation,
        remark,
      } = data.formData;

      if (!completionPeriod) {
        completionPeriod = 1;
      }
      if (recommendation !== 1 || recommendation !== 9) {
        recommendation = 9;
      }

      setEvalInfoValues({
        quotation,
        completionNumber,
        completionPeriod,
        recommendation,
        remark,
      });

      setSelectedValues({
        taxClearance,
        dsirCard,
        companyMemo,
        companyProfile,
        pastJobs,
      });
    }

    if (biddersResponse.isError) {
      showAlert({
        message: biddersResponse.error?.message,
        severity: "error",
      });
    }
    // eslint-disable-next-line
  }, [biddersResponse.isSuccess, biddersResponse.isError]);

  useEffect(() => {
    setCompletedForm((current) => {
      const newSet = new Set(current);

      if (evalInfoValues.quotation) {
        newSet.add(0);
      } else {
        newSet.delete(0);
      }
      return newSet;
    });

    setCompletedForm((current) => {
      const newSet = new Set(current);

      const hasDuration = Boolean(evalInfoValues.completionNumber && evalInfoValues.completionPeriod);

      if (hasDuration) {
        newSet.add(1);
      } else {
        newSet.delete(1);
      }
      return newSet;
    });

    validateSelectedClearance(selectedValues.taxClearance, 2);
    validateSelectedClearance(selectedValues.dsirCard, 3);
    validateSelectedClearance(selectedValues.companyMemo, 4);
    validateSelectedClearance(selectedValues.companyProfile, 5);
  }, [evalInfoValues, selectedValues]);

  const { showAlert } = useContext(ToastContext);

  const validateSelectedClearance = (options, index) => {
    const hasOptions = options ?? null;

    setCompletedForm((current) => {
      const newSet = new Set(current);
      if (hasOptions !== null) {
        newSet.add(index);
      } else {
        newSet.delete(index);
      }

      return newSet;
    });
  };

  const handleChangeCheckbox = (event) => {
    setSelectedValues((prev) => ({ ...prev, [event.target.name]: !prev[event.target.name] }));
  };
  const updateEvalInfo = (key) => (value) => {
    setEvalInfoValues((prev) => ({ ...prev, [key]: value }));
  };
  // const onClickEvaluate = async () => {
  //   try {
  //     await submitForm({
  //       formData: Object.assign(selectedValues, evalInfoValues),
  //       procurementId,
  //       vendorId,
  //     });

  //     history.push(`/admin/procurement/activity/receiptofbiddersresponse/${activityId}/${procurementId}/evaluated/vendor`);
  //   } catch (e) {
  //     showAlert({
  //       message: e.message,
  //       severity: "error",
  //     });
  //   }
  // };

  const toggleWarningModalOpen = () => {
    setWarningModalState((currentState) => ({
      ...currentState,
      open: !currentState.open,
    }));
  };

  const onClickRecommend = async () => {
    try {
      const { remark, recommendation } = evalInfoValues;

      await recommendVendor({
        remark,
        recommendation,
        procurementId,
        vendorId,
        ...selectedValues,
      });

      if (isReviewer) {
        history.push(`/admin/procurement/activity/receiptofbiddersresponse/${activityId}/${procurementId}`);
      } else {
        history.push(`/admin/procurement/activity/receiptofbiddersresponse/${activityId}/${procurementId}/evaluated/vendor`);
      }
    } catch (e) {
      const errorDetails = JSON.parse(e.message);
      if (errorDetails.message === "A vendor has already been recommended") {
        setWarningModalState({
          open: true,
          vendorId: errorDetails.data.userId,
          vendorName: errorDetails.data.companyName,
        });
      } else {
        showAlert({
          message: errorDetails.message,
          severity: "error",
        });
      }
    }
  };

  const previousPageCrumb = useMemo(() => {
    if (previewMode) {
      return {
        url: `/admin/procurement/activity/certificateofnoobjection/${activityId}/${procurementId}`,
        title: "Certificate of No Objection",
      };
    } else {
      return {
        url: `/admin/procurement/activity/receiptofbiddersresponse/${activityId}/${procurementId}`,
        title: "Receipt of bidders response",
      };
    }
  }, [previewMode, activityId, procurementId]);

  return (
    <ContentWrapper>
      <WarningModal
        vendorName={warningModalState.vendorName}
        vendorId={warningModalState.vendorId}
        open={warningModalState.open}
        handleClose={toggleWarningModalOpen}
      />
      <Box marginTop="20px">
        <GeneralTitlePage>{data?.formData?.companyName}</GeneralTitlePage>
        <Breadcrumb
          values={[
            {
              url: "/dashboard/overview",
              title: "Home",
            },
            {
              url: `/admin/procurement/plans`,
              title: "Plans",
            },
            {
              url: `/admin/procurement/plan-details/${procurementId}`,
              title: "Plan Details",
            },
            previousPageCrumb,
            {
              url: `#`,
              title: "Evaluate Vendor",
            },
          ]}
        />
      </Box>
      {biddersResponse.isLoading ? (
        <Loader feedback="Fetching form details" />
      ) : (
        <Box marginTop="30px" display="flex">
          <Box width="100%">
            <EvaluationCard>
              <Box marginBottom="15px">
                <EvaTitleText>Evaluation</EvaTitleText>
              </Box>
              <EvaSubTitleText>Kindly fill in the fields</EvaSubTitleText>
              <Box padding="15px" marginBottom={marginBottom}>
                <EvaStyledContainer>
                  <Box marginBottom="15px">
                    <EvaTitleText noPadding>Bid Documents</EvaTitleText>
                  </Box>
                  <EvaSubTitleText noPadding>Submit your tax clearance certification for the past 3 years</EvaSubTitleText>
                  <FileGrid>
                    {data.bidDocuments.map(({ file, name }) => (
                      <FileProgress
                        progress={100}
                        fileName={name}
                        fileSize={file.bytes}
                        hasEyes
                        onClickEyes={() => openFileUrlInNewWindow(file.url)}
                      />
                    ))}
                  </FileGrid>
                </EvaStyledContainer>
              </Box>
              <VendorInfo titleText="Quotation" titleSubtext="Enter the amount vendor is willing to execute project" />
              <Box mb={2.5}>
                <EvaStyledContainer>
                  <NoMarginInputField
                    label="Quotation"
                    name="quotation"
                    value={evalInfoValues?.quotation}
                    setValue={updateEvalInfo("quotation")}
                    disabled
                  />
                </EvaStyledContainer>
              </Box>
              <VendorInfo titleText="Completion Period" titleSubtext="Enter the duration vendor is willing to execute project" />
              <Box mb={2.5}>
                <EvaStyledContainer>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <NoMarginInputField
                        label="Enter Number"
                        name="duration"
                        value={evalInfoValues?.completionNumber}
                        setValue={updateEvalInfo("completionNumber")}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ControlledDropdown
                        label="Select"
                        name="completionPeriod"
                        style={{
                          margin: 0,
                          padding: 0,
                          backgroundColor: isMaker ? "#ffffff" : "rgba(0, 0, 0, 0.12)",
                        }}
                        inputProps={{ disabled: true }}
                        disabled
                        value={evalInfoValues.completionPeriod}
                        onChange={({ target }) => updateEvalInfo("completionPeriod")(target.value)}
                        options={[
                          { label: "Weeks", value: 1 },
                          { label: "Months", value: 2 },
                          { label: "Years", value: 3 },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </EvaStyledContainer>
              </Box>
              <VendorInfo titleText="Clearance" titleSubtext="Please tick the one that suits the vendor" />
              <Box marginBottom={marginBottom}>
                <EvaStyledContainer>
                  <VendorInfoOptionSelection
                    titleText="Tax Clearance"
                    name="taxClearance"
                    handleChange={handleChangeCheckbox}
                    selectedValue={selectedValues}
                    previewMode={previewMode}
                  />
                  <VendorInfoOptionSelection
                    titleText="KSIR Card"
                    name="dsirCard"
                    handleChange={handleChangeCheckbox}
                    selectedValue={selectedValues}
                    previewMode={previewMode}
                  />
                  <VendorInfoOptionSelection
                    titleText="Company Memorandum/Article of Associates"
                    name="companyMemo"
                    handleChange={handleChangeCheckbox}
                    selectedValue={selectedValues}
                    previewMode={previewMode}
                  />
                  <VendorInfoOptionSelection
                    titleText="Company Profile"
                    name="companyProfile"
                    handleChange={handleChangeCheckbox}
                    selectedValue={selectedValues}
                    previewMode={previewMode}
                  />
                  <VendorInfoOptionSelection
                    titleText="Past Jobs"
                    name="pastJobs"
                    handleChange={handleChangeCheckbox}
                    selectedValue={selectedValues}
                    previewMode={previewMode}
                  />
                </EvaStyledContainer>
              </Box>
              <VendorInfo titleText="Remark" />
              <Box p={2.5}>
                <NoMarginInputField
                  name="remark"
                  value={evalInfoValues?.remark}
                  setValue={updateEvalInfo("remark")}
                  multiline
                  disabled={previewMode}
                />
              </Box>
              <EvaStyledContainer>
                <Grid container justify="space-between">
                  <Box width="390px">
                    <ControlledDropdown
                      label="Select Recommendation"
                      options={[
                        {
                          label: "Recommended",
                          value: 1,
                        },
                        {
                          label: "Not Recommended",
                          value: 9,
                        },
                      ]}
                      value={evalInfoValues.recommendation}
                      onChange={({ target }) => updateEvalInfo("recommendation")(target.value)}
                      style={{ margin: 0, padding: 0, backgroundColor: "#ffffff" }}
                      disabled={previewMode}
                    />
                  </Box>
                  {previewMode ? evalInfoValues?.recommendation === 1 ? <GreenCheck /> : <RedCheck /> : null}
                </Grid>
              </EvaStyledContainer>

              <AccessControl allowedRoles={[Roles.MINISTERIAL_TENDER_BOARD]}>
                <Box marginTop="30px" display="flex" justifyContent="flex-end" paddingRight="20px">
                  <LoadingButton onClick={onClickRecommend} color="#2AA96D" loading={recommendVendorQuery.isLoading}>
                    Submit Recommendations
                  </LoadingButton>
                </Box>
              </AccessControl>
            </EvaluationCard>
          </Box>
          <Box marginLeft="30px">
            <CheckEntryCard
              cardTitle="Check Entries"
              completedForm={[...completedForm]}
              textValues={["Quotation", "Completion", "Tax Clearance", "KSIR Card", "Article of Associates", "Company Profile"]}
            />
          </Box>
        </Box>
      )}
    </ContentWrapper>
  );
};

export default VendorEvaluation;
