import React, { useState } from "react";
import { useQuery } from "react-query";
import { Chip, Grid } from "@material-ui/core";
import format from "date-fns/format";
import { TitleContainer } from "../../style";
import { PageTitle, PageWrapper } from "../../../../Common/style";
import Breadcrumb from "../../../../Common/PageElements/Breadcrumb";
import TopStatisticsBar from "../../../../../components/TopStatisticsBar";
import {
  CertificateOfNoObjectionWidgetLeft,
  CertificateOfNoObjectionWidgetMiddle,
} from "./style";
import FileProgress from "../../../../../components/FileProgress/FileProgress";
import LoadingButton from "../../../../../components/LoadingButton/LoadingButton";
import Box from "@material-ui/core/Box";
import EvaluatedVendorsTable from "../../../../../components/EvaluatedVendorsTable/EvaluatedVendorsTable";
import { useParams } from "react-router-dom";
import certificateOfNoObjectionApi from "../../../../../redux/api/certificateOfNoObjectionApi";
import { openFileUrlInNewWindow } from "../../../../Common/util";
import AccessControl from "../../../../../components/AccessControl";
import { Roles } from "../../../../../components/AccessControl/UserRoles";
import { useRoles } from "../../../../../hooks/useUserDetails";
import procurementAPI from "../../../../../redux/api/procurementAPI";
import CNOForRFQPaymentDrawer from "./CNOForRFQPaymentDrawer";

export const pickStatus = (status) => {
  switch (status) {
    case "Completed":
      return <Chip label={status} style={{ minWidth: "100px", height: "24px", color: "#414F3E", background: "#BBE5B3" }} />;
    case "In Progress":
      return <Chip label={status} style={{ minWidth: "100px", height: "24px", color: "#FF8D26", background: "#FCEBDB" }} />;
    case "Under Review":
      return <Chip label={status} style={{ minWidth: "100px", height: "24px", color: "#FF8D26", background: "#FCEBDB" }} />;
    case "APPROVED":
      return <Chip label={status} style={{ minWidth: "100px", height: "24px", color: "white", background: "#00AFF0" }} />;
    default:
      return <Chip label={status} style={{ minWidth: "100px", height: "24px", color: "#C9D4DB", background: "#414F3E" }} />;
  }
};
const baseVendorObject = {
  data: {
    responseSummary: {
      total: 0,
      recommended: 0,
      notRecommended: 0,
    },
    bidRecommendations: [],
  },
  pagination: {
    totalEntries: 0,
  },
};

const RFQCertificateOfNoObjection = () => {
  const { procurementId, activityId } = useParams();
  const [openDrawer, setOpenDrawer] = useState(false);

  const generalPlanLinks = [
    {
      url: "/dashboard/overview",
      title: "Home",
    },
    {
      url: `/admin/procurement/plans`,
      title: "Plans",
    },
    {
      url: `/admin/procurement/plan-details/${procurementId}`,
      title: "Plan Details",
    },
  ];

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const bidRecommendationsQuery = useQuery({
    queryKey: [
      "bidRecommendations",
      {
        PageSize: pagination.pageSize,
        PageNumber: pagination.pageNumber,
        procurementId,
      },
    ],
    queryFn: certificateOfNoObjectionApi.getBidRecommendations,
  });


  const { data: CNO, isLoading: isLoadingCertificate } = useQuery(
    ["getPaymentCNO", procurementId],
    procurementAPI.getCNOPaymentCertificate
  );


  const updatePagination = (key) => (value) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      [key]: value,
    }));
  };

  const {
    data: { bidRecommendations, responseSummary },
    pagination: apiPagination,
  } = Object.assign(baseVendorObject, bidRecommendationsQuery.data);


  const { role } = useRoles();

  const { data: activityDetails } = useQuery({
    queryKey: [
      "activityDetails",
      {
        activityId,
      },
    ],
    queryFn: procurementAPI.getActivityById,
  });

  const renderLeftContent = () => {
    if (CNO?.length > 0 && role === Roles.PROCUREMENT_OFFICER) {
      const file = CNO[0];

      return (
        <CertificateOfNoObjectionWidgetLeft>
          <p>Date Recieved</p>
          <p>{format(new Date(file.createdAt), "dd MMM, yyyy")}</p>
        </CertificateOfNoObjectionWidgetLeft>
      );
    }

    return (
      <CertificateOfNoObjectionWidgetLeft>
        <p>Total Vendors</p>
        <p>{responseSummary.total}</p>
      </CertificateOfNoObjectionWidgetLeft>
    );
  };

  const renderCenterContent = () => {
    const hasFile = CNO?.length > 0;

    if (hasFile && [Roles.PROCUREMENT_OFFICER, Roles.KWPPA].includes(role)) {
      const file = CNO[0];

      return (
        <CertificateOfNoObjectionWidgetLeft style={{ width: "73%" }}>
          <p>Certificate of No Objection</p>
          <Box mt={1.2} container>
            <Grid item xs={6}>
              <FileProgress
                progress={100}
                hasEyes
                onClickEyes={() => openFileUrlInNewWindow(file.file.url)}
                fileName="CNO_payment"
                fileSize={file.file.bytes}
              />
            </Grid>
          </Box>
        </CertificateOfNoObjectionWidgetLeft>
      );
    }

    return (
      <CertificateOfNoObjectionWidgetMiddle>
        <div>
          <p>Recommended</p>
          <p>{responseSummary.recommended}</p>
        </div>
        <div>
          <p>Not Recommended</p>
          <p>{responseSummary.notRecommended}</p>
        </div>
        <div>
          <AccessControl allowedRoles={[Roles.PROCUREMENT_OFFICER]}>
            <LoadingButton
              disabled={activityDetails?.procurementPlanActivityStatus?.toLowerCase() === "awaiting"}
              onClick={() => setOpenDrawer(true)}
              color="#00b0f1"
            >
              {activityDetails?.procurementPlanActivityStatus?.toLowerCase() === "awaiting"
                ? "Waiting for Certificate of No Objection"
                : activityDetails?.procurementPlanActivityStatus?.toLowerCase() === "amendment"
                ? "Amend CNO Request"
                : "Request for certificate of no objection"}
            </LoadingButton>
          </AccessControl>
          <AccessControl allowedRoles={[Roles.KWPPA]}>
            <LoadingButton
              disabled={
                activityDetails?.procurementPlanActivityStatus?.toLowerCase() === "pending" ||
                activityDetails?.procurementPlanActivityStatus?.toLowerCase() === "ammendment"
              }
              onClick={() => setOpenDrawer(true)}
              color="#00b0f1"
            >
              {activityDetails?.procurementPlanActivityStatus?.toLowerCase() === "pending"
                ? "Waiting for Certificate Request"
                : activityDetails?.procurementPlanActivityStatus?.toLowerCase() === "amendment"
                ? "Awaiting ammendment"
                : "Review and Generate Certificate"}
            </LoadingButton>
          </AccessControl>
        </div>
      </CertificateOfNoObjectionWidgetMiddle>
    );
  };

  const hasFile = CNO?.length > 0;

  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  return (
    <>
      <PageWrapper>
        <TitleContainer>
          <div>
            <PageTitle>Certificate of No Objection</PageTitle>
            <Breadcrumb values={generalPlanLinks} />
          </div>
        </TitleContainer>
        <Box>
          <TopStatisticsBar
            leftContent={renderLeftContent()}
            centerContent={renderCenterContent()}
            status={hasFile ? "approved" : "pending"}
            isLoading={isLoadingCertificate || bidRecommendationsQuery.isLoading}
            hasMargin={false}
          />
        </Box>
        <EvaluatedVendorsTable
          isLoading={bidRecommendationsQuery.isLoading}
          vendors={bidRecommendations}
          showRecommended
          pageNumber={pagination.pageNumber}
          pageSize={pagination.pageSize}
          setPageNumber={updatePagination("pageNumber")}
          setPageSize={updatePagination("pageSize")}
          totalPages={apiPagination.totalEntries}
          type="certificate"
        />
      </PageWrapper>
      <CNOForRFQPaymentDrawer toggleDrawer={onCloseDrawer} openDrawer={openDrawer} />
    </>
  );
};

export default RFQCertificateOfNoObjection;
