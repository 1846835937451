import React, { useEffect, useMemo, useState } from "react";
import { ButtonsRow, SectionTitle, SectionWrapper } from "../style";
import DocumentUploadField from "../../../../../../components/DocumentUploadField";
import Button from "@material-ui/core/Button";
import LoadingButton from "../../../../../../components/LoadingButton/LoadingButton";
import { Procurement_Method } from "../../../../../../util/constants";

const SupportingDocuments = ({ onPrev, onPublish, activityIsApproved, loading, initialState = {}, method = ""}) => {
  const [documents, setDocuments] = useState({
    supporting: [],
    removed: [],
  });

  useEffect(() => {
    if (initialState.supporting) {
      setDocuments({
        supporting: initialState.supporting,
        removed: [],
      });
    }
  }, [initialState]);

  const addFiles = (newDocuments) => {
    setDocuments((prevDocuments) => ({
      ...prevDocuments,
      supporting: prevDocuments.supporting.concat(newDocuments),
    }));
  };

  const showRemove = useMemo(() => !documents.supporting.some(({ inDb }) => inDb), [documents]);
  return (
    <>
      <SectionTitle>Supporting Documents</SectionTitle>
      <SectionWrapper>
        <DocumentUploadField addFiles={addFiles} files={documents.supporting} showRemove={showRemove} />
      </SectionWrapper>
      <ButtonsRow>
        <Button variant="outlined" onClick={onPrev} style={{ textTransform: "none" }}>
          Back
        </Button>
        <LoadingButton variant="contained" onClick={() => onPublish(documents)} loading={loading} disabled={activityIsApproved}>
          {method === Procurement_Method.REQ_QUOTATION ? `Send For Approval` : `Publish`}
        </LoadingButton>
      </ButtonsRow>
    </>
  );
};

export default SupportingDocuments;
