import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Tooltip } from "@material-ui/core";

import EmptyTableBody from "../../../../components/EmptyTableBody";
import { StyledTableCell } from "../../../../components/SearchTable";
import { StyledTableRow, theme } from "../../../../util/theme";
import { EmptyTableSubTitle } from "./style";
import { useRoles } from "../../../../hooks/useUserDetails";

const useStyles = makeStyles({
  contractNo: {
    fontSize: 14,
  },
  ocid: {
    fontSize: 14,
    color: `${theme.color.primary}`,
    fontWeight: 600,
  },
  stickyCell: {
    position: "sticky",
    left: 0,
    width: 200,
    backgroundColor: "white",
    paddingRight: 0,
    display: "flex",
    alignItems: "center",
    borderRight: "2px solid #E4E9EC",
  },
});

const textOverFlowStyle = { width: 200, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" };

const CustomContractTable = ({ contracts = [], urlLink, isPublic, setOpenDrawer }) => {
  const classes = useStyles();
  const history = useHistory();
  const { role } = useRoles();
  const isAdmin = ["kwppa", "admin"].includes(role);

  return contracts.length ? (
    contracts.map((eachContract) => {
      const link = !!urlLink ? `${urlLink}/${eachContract.id}` : `/admin/contracts/${eachContract.id}`;
      return (
        <StyledTableRow
          key={eachContract.id}
          onClick={(e) => {
            e.stopPropagation();
            history.push(link);
          }}
          style={{ backgroundColor: "#fff" }}
        >
          <StyledTableCell className={classes.stickyCell}>
            <p style={{ width: 200 }} className={classes.ocid}>
              {eachContract?.referenceId || "N/A"}
            </p>
          </StyledTableCell>
          <StyledTableCell>
            <Tooltip title={eachContract.title || "N/A"} aria-label="add">
              <p className={classes.contractNo || "N/A"} style={textOverFlowStyle}>
                {eachContract.title}
              </p>
            </Tooltip>
          </StyledTableCell>

          <StyledTableCell>
            <Tooltip title={eachContract.ministry || ""} aria-label="add">
              <p style={textOverFlowStyle}> {eachContract?.ministryName || "N/A"}</p>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell>
            <p style={{ width: 100 }}> {eachContract?.categoryName || "N/A"}</p>
          </StyledTableCell>
          <StyledTableCell>
            <Tooltip title={eachContract.vendor || ""} aria-label="add">
              <p style={textOverFlowStyle}> {eachContract?.vendor || "N/A"}</p>
            </Tooltip>
          </StyledTableCell>
          {/* <StyledTableCell>
            <p style={{ width: 100 }}> {eachContract.startDate ? moment(eachContract.startDate).format("DD MMM, YYYY") : "N/A"}</p>
          </StyledTableCell> */}
          <StyledTableCell>
            <p style={{ width: 100 }}>
              {" "}
              {eachContract.awardedDate ? moment(eachContract.awardedDate).format("DD MMM, YYYY") : "N/A"}
            </p>
          </StyledTableCell>
          <StyledTableCell align="right">&#8358;{eachContract?.estimatedValue?.toLocaleString() || "N/A"}</StyledTableCell>
          <StyledTableCell>
            <p style={{ width: 100 }}>
              {" "}
              {eachContract.updatedAt ? moment(eachContract.updatedAt).format("DD MMM, YYYY, hh:mm a") : "N/A"}
            </p>
          </StyledTableCell>
          {isAdmin && (
            <StyledTableCell>
              <Button
                style={{ color: `${theme.color.primary}`, fontWeight: 700 }}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenDrawer({ id: eachContract.id, isOpen: true, data: eachContract });
                }}
                variant="text"
              >
                Edit
              </Button>
            </StyledTableCell>
          )}
        </StyledTableRow>
      );
    })
  ) : (
    <EmptyTableBody>
      <EmptyTableSubTitle>There are currently no contracts</EmptyTableSubTitle>
    </EmptyTableBody>
  );
};

export default CustomContractTable;
