import baseApiMethod from "./baseApiMethod";
import baseAxiosMethod from "./baseAxiosMethod";
import { handleAxiosError } from "./handleApiError";

const contractsAPI = {};

contractsAPI.getContracts = async (key, params) => {
  try {
    const response = await baseAxiosMethod("contracts", {
      params,
    });

    const {
      data,
      meta: { pagination },
    } = response.data;

    return { data, pagination };
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

contractsAPI.uploadOCDS = async ({ file }) => {
  try {
    const response = await baseAxiosMethod.post(`contracts/ocds-upload`, file);
    return response.data.message;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};
contractsAPI.updateOcdsContract = async ({ payload, id }) => {
  try {
    const response = await baseAxiosMethod.patch(`contracts/UpdateOcdsContractMainPortal?ocdsContractId=${id}`, payload);
    return response.data.message;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

contractsAPI.updateBidder = async ({ payload, id }) => {
  try {
    const response = await baseAxiosMethod.patch(`contracts/UpdateOcdsContractBiddersPortal?ocdsContractId=${id}`, payload);
    return response.data.message;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

contractsAPI.addBidder = async ({ payload, id }) => {
  try {
    const response = await baseAxiosMethod.post(`contracts/archives/${id}/bidder`, payload);
    return response.data.message;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};
contractsAPI.deleteBidder = async ({ contractId, id }) => {
  try {
    const response = await baseAxiosMethod.delete(`contracts/archives/${contractId}/bidder/${id}`, {});
    return response.data.message;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

contractsAPI.updateOcdsContractAwardPortal = async ({ payload, id }) => {
  try {
    const response = await baseAxiosMethod.patch(`contracts/UpdateOcdsContractAwardPortal?ocdsContractId=${id}`, payload);
    return response.data.message;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};
contractsAPI.updateOcdsContractTenderPortal = async ({ payload, id }) => {
  try {
    const response = await baseAxiosMethod.patch(`contracts/UpdateOcdsContractTenderPortal?ocdsContractId=${id}`, payload);
    return response.data.message;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};
contractsAPI.updateOcdsContractPlanningPortal = async ({ payload, id }) => {
  try {
    const response = await baseAxiosMethod.patch(`contracts/UpdateOcdsContractPlanningPortal?ocdsContractId=${id}`, payload);

    return response.data.message;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

contractsAPI.getContractsSummary = (param) => {
  return baseApiMethod({ param }, `contracts/summary${!!param.MinistryId ? `?MinistryId=${param.MinistryId}` : ""}`, "get", {
    param,
  });
};

contractsAPI.getSingleContract = (id) => baseApiMethod(null, `contracts/${id}`, "get");
contractsAPI.getAdminContractDetails = (id) => baseApiMethod(null, `contracts/${id}/details`, "get");

contractsAPI.getAwardedContract = async function (key, params = {}) {
  try {
    const response = await baseAxiosMethod("contracts/awardedProcurements", { params });
    return response.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

contractsAPI.getContractDetails = async function (key, contractId) {
  try {
    const response = await baseAxiosMethod(`contracts/${contractId}/awardedProcurement`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

contractsAPI.fetchContractSummary = async () => {
  try {
    const response = await baseAxiosMethod("contracts/summary");
    return response.data.data;
  } catch (e) {
    throw handleAxiosError(e);
  }
};
contractsAPI.fetchAllContract = async () => {
  try {
    const response = await baseAxiosMethod("contracts");
    return response.data.data;
  } catch (e) {
    throw handleAxiosError(e);
  }
};

contractsAPI.createContract = async (data) => {
  const { procurementId } = data;
  try {
    const response = await baseAxiosMethod.post(`contracts/${procurementId}`, data);
    return response.data;
  } catch (e) {
    throw handleAxiosError(e);
  }
};

contractsAPI.reviewVendor = async (data) => {
  const { procurementId } = data;
  try {
    const response = await baseAxiosMethod.post(`contracts/${procurementId}/reviewVendorBid`, data);
    return response.data;
  } catch (e) {
    throw handleAxiosError(e);
  }
};

contractsAPI.downloadJSON = async (queryKey, contractId) => {
  try {
    const response = await baseAxiosMethod(`contracts/${contractId}/ocid`);
    return response.data;
  } catch (e) {
    throw handleAxiosError(e);
  }
};

contractsAPI.getContractOCDS = async (queryKey, contractId) => {
  try {
    const response = await baseAxiosMethod(`contracts/ocds/${contractId}`);
    return response.data;
  } catch (e) {
    throw handleAxiosError(e);
  }
};

export default contractsAPI;
