export const tenderingStagesFunc = function (key = "") {
  if (typeof key === "string") {
    switch (key) {
      case "SINGLE STAGE": {
        return "1";
      }
      case "DOUBLE STAGE": {
        return "2";
      }

      default:
        return "1";
    }
  }
  return "1";
};

export const tenderingReviewMethodFunc = function (key = "") {
  if (typeof key === "string") {
    switch (key) {
      case "Prior Review": {
        return "0";
      }
      case "Post Review": {
        return "1";
      }

      default:
        return "0";
    }
  }
  return "1";
};

export const tenderingCompetitiveBiddingFunc = function (key = "") {
  if (typeof key === "string") {
    switch (key) {
      case "International Competitive Bidding": {
        return "0";
      }
      case "Direct Labor": {
        return "1";
      }
      case "National Competitive Bidding": {
        return "2";
      }
      case "Procurement Method": {
        return "3";
      }
      case "Restricted Tendering Method": {
        return "4";
      }
      case "Request For Quotations": {
        return "5";
      }
      default:
        return "1";
    }
  }
  return "1";
};
