import React from 'react'
import { ReactComponent as CancelIcon } from "../../assets/cancel.svg"
import { Box, Drawer, IconButton, Paper } from '@material-ui/core'
import { StyledButtonContainer, StyledTitle, StyledTitleContainer } from './style'
import { AddButton } from '../../pages/Common/style'
import { CancelButton } from '../../components/Forms/Common/style'

const AppDrawer = ({
  open,
  onClose,
  title,
  children,
  loading,
  actionText,
  onAction,
  onSecondaryAction,
  closeOnCancel,
  isForm,
  secondaryText
}) => {
  return (
    <Drawer
      style={{ '& .MuiDrawer-paper': { maxWidth: '563px', borderRadius: '8px 0px 0px 8px' } }}
      open={open}
      onClose={onClose}
      anchor="right"
    >
      <Box component={isForm ? "form" : "div"} style={{ height: "100%", borderRadius: "3px" }}>
        <Box style={{ display: "grid", gridTemplateRows: "64px auto 64px", height: "100%" }} >
          <Box style={{ width: '100%', background: '#ffffff', zIndex: 2 }}>
            <Paper elevation={0}
              style={{
                height: '100%',
                padding: '12px 32px',
                boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                textTransform: 'capitalize'

              }}
            >
              <StyledTitleContainer>
                <StyledTitle>
                  {title}
                </StyledTitle>
                <IconButton onClick={onClose}>
                  <CancelIcon fill="#EB5757" width={20} height={20} />
                </IconButton>
              </StyledTitleContainer>
            </Paper>
          </Box>
          <Box style={{ padding: "24px 24px" }}>
            {children}
          </Box>
          <StyledButtonContainer>
            <CancelButton
              onClick={closeOnCancel ? onClose : onSecondaryAction}
              style={{ marginRight: "20px" }}
            >
              {secondaryText || "Cancel"}
            </CancelButton>
            {actionText && (
              <AddButton onClick={onAction} loading={loading}>
                {actionText}
              </AddButton>
            )}
          </StyledButtonContainer>
        </Box>
      </Box>
    </Drawer>
  )
}

AppDrawer.propTypes = {

}

export default AppDrawer
