import React, { useState, useContext } from 'react';
import { Box, Grid, CircularProgress } from "@material-ui/core";
import { useHistory, useParams } from 'react-router-dom';
import Breadcrumb from '../../../../../Common/PageElements/Breadcrumb';
import { ContentWrapper } from '../../../../Administration/Staff/style';
import { GeneralTitlePage } from '../../../../../../components/AddGeneralPlan/style';
import FileProgress from '../../../../../../components/FileProgress/FileProgress';
import {
  FormWrapper,
  FormTitle,
  FormSubTitle,
  DocumentsWrapper,
  SectionLabel,
  BackButton,
  ProceedButton,
  Text,
  FlexContainer,
  StatsContainer,
  Score,
  CircularWrapper
} from './style';
import { useForm } from "react-hook-form";
import { openFileUrlInNewWindow } from '../../../../../Common/util'
import { useMutation, useQuery } from "react-query";
import procurementAPI from '../../../../../../redux/api/procurementAPI';
import ToastContext from '../../../../../../util/toastContext';
import { LoadingButton } from '../CreateTechnicalEvaluation/style';
import AccessControl from '../../../../../../components/AccessControl';
import { Roles } from '../../../../../../components/AccessControl/UserRoles';
import ConsultingServiceRequirement from './ConsultingServiceRequirement';
import EvaluationScoreInputField from './EvaluationScoreInputField';
import receiptOfResponseApi from '../../../../../../redux/api/receiptOfResponseApi';


// todo: Change breadcrumbs once design is updated
const breadcrumbs = [
  {
    url: "/dashboard/overview",
    title: "Home",
  },
  {
    url: `/admin/procurement/plans`,
    title: "Plans",
  },
  {
    url: `/admin/procurement/plan-details/jhghk`,
    title: "Plan Details",
  },
  {
    url: `/technical-reviews`,
    title: "Evaluate Vendor",
  },
];

function Index() {
  const history = useHistory()
  const useFormUtils = useForm();
  const { handleSubmit, reset } = useFormUtils;
  const { procurementId, vendorId } = useParams()
  const [evaluationData, setEvaluationData] = useState([])
  const [benchmark, setBenchMark] = useState(0)
  const [totalScore, setTotalScore] = useState(0)
  const [technicalEvaluationId, setTechnicalEvaluationId] = useState(0)

  const { showAlert } = useContext(ToastContext);

  const _evaluationData = evaluationData.sort((a, b) => {
    if (a.title === "Mandatory Requirements") {
      return -1
    } else {
      return 1
    }
  })

  const [mutate, { isLoading }] = useMutation(procurementAPI.createVendorEvaluation, {
    onSuccess: () => {
      showAlert({
        message: "Evaluation successfully submitted",
        severity: "success",
        durationInMs: 3000,
      });
      history.goBack();
    },
    onError: error => {
      showAlert({
        message: error.message,
        severity: "error",
        durationInMs: 2000,
      });
    }
  });

  const specificVendor = useQuery({
    queryKey: ['specificVendor', {
      procurementId,
      vendorId
    }],
    queryFn: receiptOfResponseApi.getSpecificVendor
  })

  const onSubmit = (values) => {

    const vendorEvaluations = formatEvaluationSubmission(values, evaluationData);

    const payload = {
      technicalEvaluationId,
      vendorEvaluations
    }

    mutate({ vendorId, payload });
  }

  const vendorEvaluationDataQuery = useQuery(
    ['vendor evaluation data', { procurementId, vendorId }],
    procurementAPI.getVendorEvaluation,
    {
      onSuccess: (data) => {
        if (data.data) {

          reset(getFormInputValues(data.data.vendorEvaluations, data.data.benchmark, data.data.totalScore));

          setEvaluationData(data.data.vendorEvaluations)
          setTotalScore(data.data.totalScore)
          setBenchMark(data.data.benchmark)
          setTechnicalEvaluationId(data.data.technicalEvaluationId)

          // data.data.vendorEvaluations[0].vendorEvaluationId && setIsEdit(true)
        }
      },
      onError: (error) => {
        showAlert({
          severity: "error",
          message: error.message,
          durationInMs: 3000,
        });
      },
    }
  )


  if (specificVendor.isLoading || vendorEvaluationDataQuery.isLoading) {
    return (
      <CircularWrapper>
        <CircularProgress size={20} />
      </CircularWrapper>
    )
  }

  return (
    <ContentWrapper>
      <Box mt={2}>
        <GeneralTitlePage>Evaluation</GeneralTitlePage>
        <Breadcrumb values={breadcrumbs} />
      </Box>
      <FlexContainer>
        <FormWrapper>
          <Box p={3}>
            <FormTitle>Evaluation</FormTitle>
            <FormSubTitle>Kindly fill in the fields</FormSubTitle>
            <DocumentsWrapper>
              <SectionLabel>Bid Documents</SectionLabel>
              {/* <FormSubTitle style={{ marginBottom: 8 }}>Submit your tax clearance certificate for the past 3 years</FormSubTitle> */}
              {specificVendor?.data?.requirements?.map((requirement) => {
                return (
                  <Box key={requirement.id} mt={2}>
                    <FormSubTitle style={{ marginBottom: 8 }}>{requirement.title}</FormSubTitle>
                    <Grid container spacing={4}>
                      {requirement.requiredDocuments?.map((document) => {
                        const { original_filename, bytes, secure_url } = document.file;
                        return (
                          <Grid item xs={6} key={secure_url}>
                            <FileProgress
                              progress={100}
                              fileName={original_filename}
                              fileSize={bytes}
                              hasEyes={true}
                              onClickEyes={() => { openFileUrlInNewWindow(secure_url) }}
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Box>
                )
              })}
            </DocumentsWrapper>
          </Box>
          {_evaluationData?.map((item, i) => {
            if (item.title === "Mandatory Requirements") {
              return <ConsultingServiceRequirement isEvaluated={totalScore > 0} key={item.title} useFormUtils={useFormUtils} {...item} />
            }
            return <EvaluationScoreInputField key={item.title} isEvaluated={totalScore > 0} useFormUtils={useFormUtils} {...item} />
          })}
          <Box display="flex" p={3} alignItems="center" justifyContent="space-between">
            <BackButton size="large" onClick={() => history.goBack()}>Back</BackButton>
            {totalScore === 0 && (
              <AccessControl allowedRoles={[Roles.MINISTERIAL_TENDER_BOARD]}>
                {isLoading ? (
                  <LoadingButton disabled>
                    Wait... {" "}<CircularProgress size={14} />
                  </LoadingButton>
                ) : (
                  <ProceedButton
                    variant="contained"
                    size="large"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Evaluate
                  </ProceedButton>
                )}
              </AccessControl>
            )}
          </Box>
        </FormWrapper>
        <StatsContainer>
          <Box p={"20px"}>
            <Text>Benchmark</Text>
            <Text fontWeight={600}>{benchmark}%</Text>
          </Box>
          <Box p={"20px"} borderTop="1px solid #DFE3E8">
            <Text >Total Score</Text>
            <Score color={totalScore >= benchmark ? '#287D3C' : '#DA1414'}>{totalScore.toFixed(2)}%</Score>
          </Box>
        </StatsContainer>
      </FlexContainer>
    </ContentWrapper>
  )
}

const formatEvaluationSubmission = (value, evaluationData) => {
  const formInputs = [];

  evaluationData.forEach((val) => {
    if (val.title === "Mandatory Requirements") {
      val.evaluations.forEach(el => {
        formInputs.push({
          requirementEvaluationId: el.requirementEvaluationId,
          score: 0,
          isMandatory: value[el.requirementEvaluationId] === "yes",
          remark: null
        })
      });
    } else {
      val.evaluations.forEach(el => {
        formInputs.push({
          requirementEvaluationId: el.requirementEvaluationId,
          score: value[`${el.requirementEvaluationId}_score`],
          isMandatory: null,
          remark: value[`${el.requirementEvaluationId}_remark`]
        })
      });
    }
  });

  return formInputs;
}

const getFormInputValues = (data, benchmark, totalScore) => {
  const formInputs = {};

  data.forEach((val) => {
    if (val.title === "Mandatory Requirements") {
      val.evaluations.forEach(el => {
        formInputs[el.requirementEvaluationId] = el.isMandatory === null
          ? null
          : el.isMandatory === true
            ? "yes"
            : "no"
      });
    } else {
      val.evaluations.forEach(el => {
        formInputs[`${el.requirementEvaluationId}_score`] = benchmark > 0 ? totalScore > 0 ? el.vendorScore : null : null;
        formInputs[`${el.requirementEvaluationId}_remark`] = el.remark;
      });
    }
  });

  return formInputs;
}

export default Index;
