import { Dialog } from "@material-ui/core";
import styled from "styled-components";
import { theme } from "../../../../util/theme";

export const ContentWrapper = styled.div`
  padding: 10.3px 0 60px;

  .MuiTableCell-body:first-child {
    div > p {
      color: ${theme.color.primary};
      font-weight: 600;
    }
    span {
      font-size: 14px;
    }
  }
`;

export const CustomDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 24px;
    min-width: 654px;
  }
`;

export const ModalHeader = styled.div`
  font-weight: bold;
  font-size: 24px;
  color: #393A4A;
  line-height: 33px;
`;

export const ModalWrapper = styled.div`
  padding: 24px;
  background: white;
  min-width: 654px;
  margin-bottom: 20%;
`;

export const MainText = styled.p`
  font-size: ${(props) => props.fontSize};
  color: ${(props) => (props.color ? props.color : `#5F7483`)};
  margin-top: ${(props) => props.mt};
  margin-right: 40px;
  font-weight: ${(props) => props.fw};
`;

export const BtnBox = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 2rem;
`;

export const StatusBox = styled.div`
  width: fit-content;
  background: ${(prop) => prop.colorStatus === "PENDING"
    ? theme.color.tags.pending.background
    : prop.colorStatus === "REVIEWED" ? "#F0F5FF"
      : theme.color.tags.approved.background};
  border-radius: 10px;
  color: ${prop => prop.colorStatus === "PENDING"
    ? theme.color.tags.pending.text
    : prop.colorStatus === "REVIEWED" ? "#0050C8"
      : theme.color.tags.approved.text};
  line-height: 0.8;
  padding: 10px 10px;`


 export const BudgetBox = styled.div`
  .budget {
    font-size: 16px;
    font-weight: 600;
  }
  .supplementary {
    font-size: 24px;
    font-weight: 700;
  }
  `