import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import NumberFormat from "react-number-format";
import Dropdown from "../../../DropdownField";
import InputField from "../../../InputField";
import { BottomGroupWrapper, FormTitle, IdInputField, PrimaryButton } from "../style";
import { ErrorContainer } from "../../Common/style";
import useAlert from "../../../../hooks/useAlert";
import FormContext from "../../../../util/context";
import ReviewCommentsSection from "../../../ReviewCommentsSection";
import { ButtonGroup } from "../../../NavigationButtons/ButtonGroup";
import { Roles } from "../../../AccessControl/UserRoles";
import { queryCache, useMutation, useQuery } from "react-query";
import procurementAPI from "../../../../redux/api/procurementAPI";
import useUserDetails from "../../../../hooks/useUserDetails";
import Loader from "../../../Common/Loader";
import adminAPI from "../../../../redux/api/adminAPI";
import { createTenderProcumentDefaultValues, createTenderProcurementResolver } from "../validation";
// import { onNumberValidator } from "../../../../util/truncateValue";

export const convertArrayToOptions = (array) => {
  return (
    array?.map(({ name, id }) => ({
      label: name,
      value: id,
    })) || []
  );
};

const KeyDetails = ({ completedForm, addCompletedForm }) => {
  const [isEdit, setIsEdit] = React.useState(false);
  const width = "100%";
  const backgroundColor = "#FFFFFF";
  const [procurmentMethodsOptions, setProcurmentMethodsOptions] = React.useState([]);
  const [reviewMethodsOptions, setReviewMethodsOptions] = React.useState([]);
  const [tenderStagesOptions, setTenderStagesOptions] = React.useState([]);
  const [procurementCategoriesOptions, setProcurementCategoriesOptions] = React.useState([]);

  const { isReviewMode, setActiveForm, userRole } = useContext(FormContext);

  const isMaker = userRole === Roles.PROCUREMENT_OFFICER;
  const isChecker = [Roles.COMMISSIONER, Roles.PERMANENT_SECRETARY].includes(userRole);
  const [procurementMethod, setProcurementMethod] = useState();

  const [submitting, setSubmitting] = useState(false);
  const [gettingPackageNumber, setGettingPackageNumber] = useState(false);
  // const [budgetValue, setbudgetValue] = useState(null);

  const { data: ministries } = useQuery({
    queryFn: adminAPI.getMinistriesAxios,
    queryKey: [
      "ministries",
      {
        pageSize: 0,
        pageNumber: null,
      },
    ],
  });

  const { handleSubmit, errors, setValue, control, reset, watch } = useForm({
    defaultValues: createTenderProcumentDefaultValues,
    resolver: createTenderProcurementResolver,
  });
  const { id: generalPlanId, procurementId } = useParams();
  const history = useHistory();

  const { data: formOptions, isLoading, isSuccess: optionSuccess } = useQuery({
    queryKey: "keyDetailsFormOptions",
    queryFn: procurementAPI.getKeyDetailsFormOptions,
  });
  React.useEffect(() => {
    if (optionSuccess || formOptions) {
      setProcurementCategoriesOptions(convertArrayToOptions(formOptions.procurementCategories) || []);
      setProcurmentMethodsOptions(convertArrayToOptions(formOptions.procurementMethods) || []);
      setReviewMethodsOptions(convertArrayToOptions(formOptions.reviewMethods) || []);
      setTenderStagesOptions(convertArrayToOptions(formOptions.tenderStages) || []);
    }
  }, [optionSuccess, formOptions]);
  const handleProcurementMethodSelection = (id, onChange) => {
    const methodObj = convertArrayToOptions(formOptions.procurementMethods).find(({ value }) => value === id);
    const method = methodObj.label.split(" ")[methodObj.label.split(" ").length - 1];
    setProcurementMethod(method);
    if (onChange) {
      onChange(id);
    }
  };

  const generalPlanDetails = useQuery({
    queryKey: ["generalPlanDetails", procurementId],
    queryFn: procurementAPI.getPlanDetails,
    config: {
      enabled: procurementId !== "new",
      staleTime: 900 * 1000,
    },
  });

  React.useEffect(() => {
    if (procurementId === "new") {
      setIsEdit(false);
    } else {
      setIsEdit(true);
    }
  }, [procurementId]);

  const [createPlan] = useMutation(procurementAPI.createProcurementPlanAxios, {
    throwOnError: true,
  });
  console.log({
    watch: watch(),
  });

  useEffect(() => {
    setActiveForm(0);
  }, [setActiveForm]);

  React.useEffect(() => {
    if (procurementMethod === "(DPM)") {
      const foundReview = convertArrayToOptions(formOptions?.reviewMethods)?.find((v) => v?.name === "Prior Review");
      if (foundReview) {
        setValue("reviewMethodId", foundReview?.id || "");
      }
    }
    // eslint-disable-next-line
  }, [procurementMethod]);

  React.useEffect(() => {
    if (isEdit) {
      let ministryId;
      const payload = {};
      if (generalPlanDetails.isSuccess) {
        if (generalPlanDetails.data && ministries?.data) {
          if (generalPlanDetails.data?.ministryId) {
            ministryId = generalPlanDetails.data?.ministryId;
          } else {
            ministryId = ministries?.data?.find(({ code }) => code === generalPlanDetails.data.ministryCode)?.id;
          }
        }
        const fields = Object.keys(createTenderProcumentDefaultValues);
        fields.forEach((field) => {
          if (field === "tenderingStageId") {
            payload[field] = generalPlanDetails.data?.tenderingStage?.id || createTenderProcumentDefaultValues[field];
          } else if (field === "procurementCategoryId") {
            payload[field] = generalPlanDetails.data?.procurementCategory?.id || createTenderProcumentDefaultValues[field];
          } else if (field === "procurementMethodId") {
            payload[field] = generalPlanDetails.data?.procurementMethod?.id || createTenderProcumentDefaultValues[field];
          } else if (field === "reviewMethodId") {
            payload[field] = generalPlanDetails.data?.reviewMethod?.id || createTenderProcumentDefaultValues[field];
          } else if (field === "ministryId") {
            payload[field] = ministryId || createTenderProcumentDefaultValues[field];
          } else if (field === "isEdit") {
            payload[field] = true || createTenderProcumentDefaultValues[field];
          } else {
            payload[field] = generalPlanDetails.data?.[field] || createTenderProcumentDefaultValues[field];
          }
        });

        // const procurementPlanDefault = Object.assign({}, generalPlanDetails.data, {
        //   ministryId,
        //   reviewMethodId: generalPlanDetails?.data?.reviewMethod?.id,
        // });
        let methodName = generalPlanDetails?.data.procurementMethod.name;
        const method = methodName?.split(" ")[methodName?.split(" ").length - 1];
        setProcurementMethod(method);

        reset(payload);
      }
    }
     // eslint-disable-next-line
  }, [isEdit, ministries, generalPlanDetails.data]);

  React.useEffect(() => {
    if (!isEdit) {
      const payload = {};
      const fields = Object.keys(createTenderProcumentDefaultValues);
      fields.forEach((field) => {
        if (field === "tenderingStageId") {
          if (tenderStagesOptions?.length > 0) {
            payload.tenderingStageId = tenderStagesOptions[0]?.value || createTenderProcumentDefaultValues[field];
          }
        } else if (field === "procurementCategoryId") {
          if (procurementCategoriesOptions?.length > 0) {
            payload.procurementCategoryId = procurementCategoriesOptions[0]?.value || createTenderProcumentDefaultValues[field];
          }
        } else if (field === "procurementMethodId") {
          if (procurmentMethodsOptions?.length > 0) {
            payload.procurementMethodId = procurmentMethodsOptions[0]?.value || createTenderProcumentDefaultValues[field];
          }
        } else if (field === "reviewMethodId") {
          if (reviewMethodsOptions?.length > 0) {
            payload.reviewMethodId = reviewMethodsOptions[0]?.value || createTenderProcumentDefaultValues[field];
          }
        } else {
          payload[field] = createTenderProcumentDefaultValues[field];
        }
      });

      reset(payload);
    }
     // eslint-disable-next-line
  }, [isEdit, optionSuccess, tenderStagesOptions]);

  const { showAlert, Toast } = useAlert();

  const exitReview = () => {
    history.push(`/admin/procurement/plans/${generalPlanId}`);
  };
  const { packageNumber, reviewMethodId, tenderingStageId, procurementCategoryId, procurementMethodId } = watch();

  const { user } = useUserDetails();

  const handleGeneratePackageNumber = async () => {
    if (procurementCategoryId && procurementMethodId) {
      const { name } = formOptions.procurementCategories.find((p) => p.id === procurementCategoryId);
      const { name: methodName } = formOptions.procurementMethods.find((p) => p.id === procurementMethodId);

      const data = {
        ministry: user?.ministry?.code,
        procurementCategory: name,
        procurementMethod: methodName,
      };

      setGettingPackageNumber(true);

      try {
        const planNumber = await procurementAPI.getPackageNumberAxios(data);
        setGettingPackageNumber(false);
        setValue("packageNumber", planNumber, {
          shouldValidate: true,
          shouldDirty: true,
        });
      } catch (e) {
        //TODO: Show error
      }
    }
  };

  const onSubmit = (values) => {
    setSubmitting(true);

    let isEditMode = procurementId !== "new";

    if (isEditMode) {
      values.id = procurementId;
      values.generalPlanId = generalPlanId;
    }

    const data = {
      ...values,
      packageNumber,
      reviewMethodId,
      tenderingStageId,
    };

    console.log({ values, data });

    createPlan({ data, generalPlanId })
      .then(({ id }) => {
        showAlert({
          severity: "success",
          message: `Successfulled ${isEditMode ? "updated" : "created"} plan`,
        });

        if (!completedForm.includes(0)) {
          addCompletedForm((state) => [...state, 0]);
        }

        queryCache.removeQueries(["generalPlanDetails", procurementId]);

        history.push(`/admin/procurement/plans/${generalPlanId}/create/planning/${id}`);
      })
      .catch((err) => {
        showAlert({
          severity: "error",
          message: renderError({
            message: err?.message,
            errors: err?.errors,
          }),
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onRenderError = (error) => <ErrorContainer>{error && error.message}</ErrorContainer>;

  const renderError = ({ message, errors }) => {
    return (
      <div>
        <div style={{ fontWeight: "bold" }}>{message}</div>
        <div>
          {Object.keys(errors).map((errorPath) => (
            <div style={{ marginTop: 10, fontSize: "0.8em" }} key={errorPath}>
              <span style={{ textTransform: "capitalize" }}>{errorPath}</span> - {errors[errorPath]}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderMakerButtons = () => {
    if (!isMaker) {
      return null;
    }

    if (isReviewMode) {
      return (
        <ButtonGroup
          firstBtnText="Exit"
          thirdBtnText={submitting ? "Please wait..." : "Continue"}
          handleFirstBtn={exitReview}
          handleThirdBtn={handleSubmit(onSubmit)}
          isReviewMode={isReviewMode}
          disabled={submitting}
        />
      );
    }

    return (
      <ButtonGroup
        firstBtnText="Previous"
        thirdBtnText={submitting ? "Please wait..." : "Continue"}
        handleFirstBtn={exitReview}
        handleThirdBtn={handleSubmit(onSubmit)}
        isReviewMode={isReviewMode}
        disabled={submitting}
      />
    );
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <FormTitle>Basic Information</FormTitle>
      <form>
        <Toast float />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Controller
              name="procurementCategoryId"
              control={control}
              render={({ ref, ...props }) => (
                <Dropdown
                  label="Procurement Category"
                  values={procurementCategoriesOptions}
                  // initialState={generalPlanDetails.data?.procurementCategory?.id}
                  style={{
                    backgroundColor,
                    width,
                    margin: "0",
                    marginTop: "20px",
                  }}
                  inputRef={ref}
                  disabled={!isMaker}
                  {...props}
                />
              )}
            />
            {onRenderError(errors.procurementCategoryId)}
          </Grid>
          <Grid item xs={6} />
        </Grid>
        <BottomGroupWrapper>
          <Grid container spacing={3} style={{ marginTop: "20px" }}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="name"
                render={({ ref, ...props }) => (
                  <InputField
                    label="Tender Title"
                    style={{ backgroundColor, width, margin: 0, padding: 0 }}
                    inputRef={ref}
                    disabled={!isMaker}
                    {...props}
                  />
                )}
              />
              {onRenderError(errors.name)}
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="description"
                render={({ ref, ...props }) => (
                  <InputField
                    type="text"
                    label="Description"
                    style={{
                      width,
                      margin: "0",
                      marginTop: "20px",
                      padding: 0,
                      backgroundColor,
                    }}
                    multiline
                    inputRef={ref}
                    disabled={!isMaker}
                    {...props}
                  />
                )}
              />
              {onRenderError(errors.description)}
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="procurementMethodId"
                render={({ ref, onChange, ...props }) => (
                  <Dropdown
                    label="Procurement Method"
                    values={procurmentMethodsOptions}
                    onChange={(value) => handleProcurementMethodSelection(value, onChange)}
                    // initialState={generalPlanDetails.data?.procurementMethod?.id}
                    style={{ backgroundColor, width, margin: 0 }}
                    inputRef={ref}
                    disabled={!isMaker}
                    {...props}
                  />
                )}
              />
              {onRenderError(errors.procurementMethodId)}
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="tenderingStageId"
                render={({ ref, ...props }) => (
                  <Dropdown
                    label="Tendering Stage"
                    values={
                      !isMaker || procurementMethod === "(DLM)" || procurementMethod === "(RQ)" || procurementMethod === "(DPM)"
                        ? [{ label: "N/A", value: "na" }]
                        : tenderStagesOptions
                    }
                    // initialState={generalPlanDetails.data?.tenderingStage?.id}
                    style={{ backgroundColor, width, margin: 0 }}
                    inputRef={ref}
                    disabled={
                      !isMaker || procurementMethod === "(DLM)" || procurementMethod === "(RQ)" || procurementMethod === "(DPM)"
                    }
                    {...props}
                  />
                )}
              />
              {onRenderError(errors.tenderingStageId)}
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: "10px" }} />
          <Grid container spacing={3} style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="reviewMethodId"
                render={({ ref, ...props }) => (
                  <Dropdown
                    label="Review Method"
                    disabled={
                      !isMaker || procurementMethod === "(DLM)" || procurementMethod === "(RQ)" || procurementMethod === "(DPM)"
                    }
                    values={
                      !isMaker || procurementMethod === "(DLM)" || procurementMethod === "(RQ)" || procurementMethod === "(DPM)"
                        ? [{ label: "N/A", value: "na" }]
                        : reviewMethodsOptions
                    }
                    style={{ backgroundColor, width, margin: 0 }}
                    inputRef={ref}
                    {...props}
                  />
                )}
              />
              {onRenderError(errors.reviewMethodId)}
            </Grid>
            {/* <Grid item xs={6}>
              <Controller
                name="budget"
                control={control}
                render={({ ref, onChange, ...props }) => (
                  <InputField
                    label="Budget"
                    style={{ backgroundColor, width, margin: 0, padding: 0 }}
                    inputRef={ref}
                    onChange={(e) => onNumberValidator(e, onChange)}
                    disabled={!isMaker}
                    {...props}
                  />
                )}
              />
              {onRenderError(errors.budget)}
            </Grid> */}
            <Grid item xs={6}>
              <Controller
                name="budget"
                control={control}
                render={({ onChange, ...props }) => (
                  <NumberFormat
                    customInput={InputField}
                    label="Budget"
                    thousandSeparator
                    prefix="₦"
                    variant="outlined"
                    disabled={!isMaker}
                    onValueChange={(values) => {
                      const { value } = values;
                      onChange(value);
                    }}
                    {...props}
                  />
                )}
              />
              {onRenderError(errors.budget)}
            </Grid>
            <Grid item xs={6} />
          </Grid>

          <Grid container spacing={2} alignItems="center" style={{ marginBottom: "40px" }}>
            <Grid item xs={6}>
              <Controller
                name="packageNumber"
                control={control}
                render={({ ref, ...props }) => (
                  <IdInputField
                    type="text"
                    style={{ width, margin: "0", marginTop: "20px" }}
                    disabled={true}
                    inputRef={ref}
                    {...props}
                  />
                )}
              />
              {onRenderError(errors.packageNumber)}
            </Grid>
            {isMaker ? (
              <Grid item>
                <PrimaryButton
                  disabled={gettingPackageNumber || (isReviewMode && isChecker)}
                  onClick={handleGeneratePackageNumber}
                  type="button"
                  style={{ marginTop: "20px" }}
                >
                  {gettingPackageNumber ? "Generating..." : "Generate Package Number"}
                </PrimaryButton>
              </Grid>
            ) : null}
          </Grid>
        </BottomGroupWrapper>

        {/* {isReviewMode ?  */}
        <ReviewCommentsSection objectId={procurementId} />
        {/* : null} */}

        {renderMakerButtons()}
      </form>
    </>
  );
};

export default KeyDetails;
