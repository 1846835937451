import { Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { useContext, useEffect} from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import adminAPI from "../../../redux/api/adminAPI";
import { getGeneralCompanyDetails, getVendorRegistrationPlan } from "../../../redux/reducer/vendorReducer";
import ToastContext from "../../../util/toastContext";
import withLoader from "../../Common/HOC/withLoader";
import Breadcrumb from "../../Common/PageElements/Breadcrumb";
import { PageTitle } from "../../Common/style";
import { ContentWrapper } from "../Administration/Staff/style";
import withAdminDashboard from "../HOC/withAdminDashboard";

import MoreInformation from "./MoreInformation";
import {
  ActiveText,
  CompletedText,
  CountText,
  InfoText,
  RejectText,
  SignedText,
  TextFlexContainer,
  UnsignedText,
  ValueText,
  VendorCardContainer,
  VendorDetailsContainer,
  VendorInfoCard,
  FlexContainer
} from "./style";
import VendorCompanyDetails from "./VendorCompanyDetails";
import VendorRegistrationCategory from "./VendorRegistrationCategory";
import VendorDetailsTable from "./VendorDetailsTable";

  
 
const VendorDetails = () => {
  const { vendorId } = useParams();
  const { companyDetails } = useSelector((state) => state.vendor);
 
  const BreadLinks = [
    { url: "/admin/vendorsList/vendors", title: "Administration" },
    { url: "#", title: "Vendors" },
  ];

  const { vendorId: id } = useParams();
  const { data: projectSummary = {}, isLoading, error } = useQuery(
    ["vendorProjectSummary", id],
    adminAPI.getVendorProjectSummary
  );
  // const { data: projectDetails = [], isLoading: isProjectLoading, error: isProjectError } = useQuery(
  //   ["vendorProjects", id],
  //   adminAPI.getVendorProjects
  // );


  

  const { data: contractSummary = {}, isLoading: isContractSummaryLoading, error: isContractError } = useQuery(
    ["vendorContractSummary", id],
    adminAPI.getVendorContractsSummary
  );

  const { data: bidSummary = {}, isLoading: isBidSummaryLoading } = useQuery(
    ["vendorBidSummary", id],
    adminAPI.getVendorBidsSummary
  );
 
  
  

  const { showAlert } = useContext(ToastContext);

  useEffect(() => {
    if (error) {
      showAlert({
        severity: "error",
        message: `Something went wrong while fetching project summary: ${error}`,
        durationInMs: 5000,
      });
    }

    // if (isProjectError) {
    //   showAlert({
    //     severity: "error",
    //     message: `Something went wrong while fetching project details: ${isProjectError}`,
    //     durationInMs: 5000,
    //   });
    // }

    if (isContractError) {
      showAlert({
        severity: "error",
        message: `Something went wrong while fetching contracts summary: ${isContractError}`,
        durationInMs: 5000,
      });
    }
  }, [error, isContractError, showAlert]);

  return (
    <ContentWrapper>
      <PageTitle>{companyDetails.companyName || null}</PageTitle>
      <Breadcrumb values={BreadLinks} />
      <VendorDetailsContainer container spacing={4}>
        <Grid item xs={12}>
          <VendorCardContainer>
            {withLoader(VendorCompanyDetails)({
              apis: [getGeneralCompanyDetails(vendorId), getVendorRegistrationPlan(vendorId)],
              conditioners: ["companyDetails", "selectedRegPlan"],
              stateName: "vendor",
              reload: true,
            })}
          </VendorCardContainer>
        </Grid>
        <Grid item xs={12}>
          <MoreInformation/>
        </Grid>
       <Grid container item  spacing={2} direction="row">
       <Grid item xs={8}>
          <VendorInfoCard >
            <FlexContainer>
              {isLoading ? (
                <Skeleton width={270} height={150} variant={"rect"} />
              ) : (
                <div>
                  <ValueText>Total Projects</ValueText>
                  <CountText>{(!isLoading && projectSummary?.total) || 0}</CountText>
                  <InfoText>All active and completed projects</InfoText>
                  <TextFlexContainer>
                    <ActiveText>{projectSummary?.active} Active</ActiveText>
                    <CompletedText>{projectSummary?.completed} Completed</CompletedText>
                  </TextFlexContainer>
                </div>
              )}
              {isBidSummaryLoading ? (
                <Skeleton width={270} height={150} variant={"rect"} />
              ) : (
                <div>
                  <ValueText>Total Bids</ValueText>
                  <CountText>{!isBidSummaryLoading && bidSummary?.total}</CountText>
                  <InfoText>All approved and rejected bids</InfoText>
                  <TextFlexContainer>
                    <ActiveText>{bidSummary?.approved} Approved</ActiveText>
                    <RejectText>{bidSummary?.rejected} Rejected</RejectText>
                  </TextFlexContainer>
                </div>
              )}
              {isContractSummaryLoading ? (
                <Skeleton width={270} height={150} variant={"rect"} />
              ) : (
                <div>
                  <ValueText>Total Contracts</ValueText>
                  <CountText>{!isContractSummaryLoading && contractSummary?.total}</CountText>
                  <InfoText>All signed and unsigned contracts</InfoText>
                  <TextFlexContainer>
                    <SignedText>{contractSummary?.signed} Signed</SignedText>
                    <UnsignedText>{contractSummary?.unsigned} Unsigned</UnsignedText>
                  </TextFlexContainer>
                </div>
              )}
            </FlexContainer>
          </VendorInfoCard>
          </Grid>
         <Grid item xs={4}>
         <VendorCardContainer>
            {withLoader(VendorRegistrationCategory)({
              apis: [getVendorRegistrationPlan(vendorId)],
              conditioners: ["selectedRegPlan"],
              stateName: "vendor",
              reload: true,
            })}
          </VendorCardContainer>
         </Grid>
        
        </Grid>
       
        <VendorDetailsTable/>
      </VendorDetailsContainer>
    </ContentWrapper>
  );
};

export default withAdminDashboard(VendorDetails);
