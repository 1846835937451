export const Roles = Object.freeze({
  VENDOR: "vendor",
  PROCUREMENT_OFFICER: "procurement_officer",
  PERMANENT_SECRETARY: "permanent_secretary",
  COMMISSIONER: "commissioner",
  GOVERNOR: "governor",
  KWPPA: "kwppa",
  MODERATOR: "moderator",
  EXECUTIVE: "executive",
  SSG: "ssg",
  MINISTRY_OF_JUSTICE: "ministry_of_justice",
  ADMIN: "admin",
  TENDER_BOARD: "tender_board",
  CONTRACTORS_REG_BOARD: "contractors_reg_board",
  MINISTERIAL_TENDER_BOARD: "ministerial_tender_board",
  PARASTATALS_TENDERS_BOARD: "parastatals_tenders_board",
  ACCOUNTING_OFFICERS: "accounting_officers",
  ACCOUNTING_OFFICER: "accounting_officer",
  SUPERVISING_OFFICER: "supervising_officer"
});
