import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import BidSubmission from "../Procurement/Activitiies/BidSubmission";
import BidInvitation from "../Procurement/Activitiies/BidInvitation";
import PlanningTracking from "../Procurement/Activitiies/PlanningTracking";

import DraftBidding from "../Procurement/Activitiies/DraftBidding";
import SpecificProcurementNotice from "../Procurement/Activitiies/SpecificProcurementNotice";
import CustomStageView from "../Procurement/Activitiies/CustomStageView";
import LetterOfAcceptance from "../Procurement/Activitiies/LOA";
import ApprovalPayment from "../Procurement/Activitiies/ApprovalPayment";
import CompleteReport from "../Procurement/Activitiies/CompleteReport";
import ContractPublication from "../Procurement/Activitiies/ContractPublication";
import ContractSigning from "../Procurement/Activitiies/ContractSigning";
import ContractAwardDecision from "../Procurement/Activitiies/ContractAwardDecision";
import BidEvaluation from "../Procurement/Activitiies/BidEvaluation";
import PreQualificationDocuments from "../Procurement/Activitiies/PrequalificationDocuments/PreQualificationDocuments";
import BidderResponseReceiptWrapper from "../Procurement/Activitiies/BidderResponseReceipt";
import ReceiptOfResponseWrapper from "../Procurement/Activitiies/ReceiptOfResponse/ReceiptOfResponseWrapper";
import ContractSupervisionWrapper from "../Procurement/Activitiies/ContractSupervision/ContractSupervisionWrapper";
import LegalDocumentSigning from "../Procurement/Activitiies/LegalDocumentSigning";
import AwardOfContract from "../Procurement/Activitiies/AwardOfContract";
import CertificateOfNoObjectionWrapper from "../Procurement/Activitiies/CertificateOfNoObjection/CertificateOfNoObjectionWrapper";
import BillOfQuantityPreparation from "../Procurement/Activitiies/BillOfQuantityPreparation";
import BIllOfQuantityAppraval from "../Procurement/Activitiies/BIllOfQuantityAppraval";
import QuotationRequirements from "../Procurement/Activitiies/QuotationRequirementsPreparation";
import ApprovalOfQuotationRequirement from "../Procurement/Activitiies/ApprovalOfQuotationRequirement";

const activities = {
  draftbiddingdocument: <DraftBidding />,
  specificprocurementnotice: <SpecificProcurementNotice />,
  bidsubmissionopeningminutes: <BidSubmission />,
  bidinvitation: <BidInvitation />,
  bidevaluationreportandrecommendationforawards: <BidEvaluation />,
  letterofacceptance: <LetterOfAcceptance />,
  contractawarddecision: <ContractAwardDecision />,
  contractsigning: <ContractSigning />,
  publicationofcontractaward: <ContractPublication />,
  planningandtracking: <PlanningTracking />,
  approvalofpayment: <ApprovalPayment />,
  completionreport: <CompleteReport />,
  preparationoftenderdocument: <SpecificProcurementNotice />,
  preparationoftenderdocuments: <SpecificProcurementNotice />,
  contractsupervisionandmanagement: <ContractSupervisionWrapper />,
  receiptofbiddersresponse: <BidderResponseReceiptWrapper />,
  receiptofresponses: <ReceiptOfResponseWrapper />,
  receiptofresponse: <ReceiptOfResponseWrapper />,
  certificateofnoobjection: <CertificateOfNoObjectionWrapper />,
  signingoflegaldocument: <LegalDocumentSigning />,
  signingoflegaldocuments: <LegalDocumentSigning />,
  contractaward: <AwardOfContract />,
  preparationofbillofquantitydocuments: <BillOfQuantityPreparation />,
  approvalofbillquantity: <BIllOfQuantityAppraval />,
  // used to be letter of intent, left as a comment for reference
  // letterofintent: <AwardOfContract />,
  preparationofprequalificationdocuments: <PreQualificationDocuments />,
  preparationofprequalificationdocument: <PreQualificationDocuments />,
  preparationofquotationrequirements: <QuotationRequirements />,
  approvalofquotationrequirements: <ApprovalOfQuotationRequirement />,
  certificateofnoobjectionforpayment: <CertificateOfNoObjectionWrapper />,
  awardofcontract: <AwardOfContract />, //this is temporary solution mismapping to contract award
};

const ActivityPageWrapper = () => {
  const { activityName } = useParams();

  return <Fragment>{activities[activityName] || <CustomStageView />}</Fragment>;
};

export default ActivityPageWrapper;
