import styled from "styled-components";
import { Button } from "@material-ui/core";
import { theme } from "../../../../util/theme";

export const TitleContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 24px;
`;

export const FormContainer = styled.div`
  background-color: #fff ;
    padding: 1rem;
`;

export const FormTitle = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #393A4A;
  margin-bottom: 20px;
  flex: 1;
`;

export const FormDescriptionBox = styled.div`
  background-color: #F8F7FA;
  box-shadow: inset 0px -1px 0px #E5E5EA;
  padding: 5px;
  margin-bottom: 1rem;
`;

export const FormDescrition = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: #6B6C7E;
  margin-bottom: 1rem;
`;

export const InputFieldBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CancelButton = styled(Button)`
text-transform: capitalize !important;
    && {
        background-color: #EEFBF5;
    border-radius: 8px;
    color: ${theme.color.primary};
    &:hover {
      background-color: #EEFBF5;
      opacity: 0.7;
    }
  }
`;

export const UpdateButton = styled(Button)`
text-transform: capitalize !important;
    && {
        background-color:  ${theme.color.primary};
    border-radius: 8px;
    color: white;
    &:hover {
      background-color:  ${theme.color.primary};
      opacity: 0.8;
    }
    &:disabled {
      background-color: ${theme.color.primary};
      cursor: not-allowed !important;
      opacity: 0.5;
      color: white;
    }
  }
`;

export const BtnBox = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 2rem;
`;