import React from "react";
import { Box, Modal } from "@material-ui/core";
import { ModalBody, ModalDescription, ModalTitle, UpdateButton } from "./style";
import { CancelButton } from "../../../../../components/Forms/Common/style";
// import { Procurement_Method } from "../../../../../util/constants";

const GenerateCerificateModal = ({ open, onClose, onAction, loading, method }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalBody>
        <ModalTitle>Generate Certificate</ModalTitle>
        {/* {[Procurement_Method.DIRECT_LABOUR, Procurement_Method.REQ_QUOTATION].includes(method) ? (
          <ModalDescription>Are you sure you want to generate the Certificate of No Objection for Payment?</ModalDescription>
        ) : ( */}
          <ModalDescription>
            Are you sure you want to generate the Certificate of No Objection?
          </ModalDescription>
        {/* )} */}
        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <UpdateButton disabled={loading} onClick={onAction}>
            {loading ? "Generating..." : "Confirm"}
          </UpdateButton>
          <CancelButton
            onClick={onClose}
            style={{
              marginLeft: "1rem",
            }}
          >
            Cancel
          </CancelButton>
        </Box>
      </ModalBody>
    </Modal>
  );
};

export default GenerateCerificateModal;
