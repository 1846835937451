import React, { useContext } from "react";
import format from "date-fns/format";

import { ReactComponent as GreenLine } from "../../../../../assets/GreenLine.svg";
import watermarkmain from "../../../../../assets/watermarkmain.png";
import watermark2 from "../../../../../assets/watermark2.png";
import watermark3 from "../../../../../assets/watermark3.png";
import Logo from "../../../../../assets/KWPPA_logo.png"
import {
  RedTitle,
  LogoDiv,
  CertificateContainer,
  CertName,
  WaterMark1,
  WaterMark2,
  WaterMark3,
  SignatureTitle,
  QRCodeStyle,
  SignatureBox,
  SignatureContainer,
  CertNoAnswer,
  CertificateItemsTitle,
  CertNoTitle,
  CertificateTitle,
  GreenTitle,
  CertificateBody,
  CertificateItemTitle,
  CertificateItemAnswer,
  CertificateItemsBox,
  CertificateTitleBox,
  CertificateAnswerBox,
} from "./style";
import QRCode from "qrcode.react";
import { useQuery } from "react-query";
import procurementAPI from "../../../../../redux/api/procurementAPI";
import Loader from "../../../../../components/Common/Loader";
import ToastContext from "../../../../../util/toastContext";
import { useParams } from "react-router-dom";
import { CERTIFICATE_TYPES } from "../../../../../util/constants";
import { formatCurrency } from "../../../../../util/formatCurrency";

function Certificate({ CertRef, showPreview, procurementMtd }) {
  const { showAlert } = useContext(ToastContext);
  const { procurementId } = useParams();

  const { data: authorizersData, isFetching } = useQuery(["getAuthoriserSignature"], procurementAPI.getAuthorizerSignature, {
    enabled: true,
    onError: (error) => {
      showAlert({
        message: error.message,
        severity: "error",
      });
    },
  });

  const { data: certificateData, isFetching: isFetchingCertData } = useQuery(
    ["getAwardCertificateData", procurementId],
    procurementAPI.getAwardCertificateData,
    {
      enabled: true,
      onError: (error) => {
        showAlert({
          message: error.message,
          severity: "error",
        });
      },
    }
  );

  if (isFetching || isFetchingCertData) {
    return <Loader />;
  }


  return (
    <CertificateContainer ref={CertRef}>
      <WaterMark1>
        <img src={watermarkmain} alt="Kwara state logo" />
      </WaterMark1>
      {showPreview && (
        <WaterMark2>
          <img src={watermark2} alt="preview watermark" />
        </WaterMark2>
      )}
      <WaterMark3>
        <img src={watermark3} alt="Kwara state logo" />
      </WaterMark3>
      <LogoDiv>
        <div style={{ alignSelf: "center" }}>
          <GreenLine />
        </div>
        <img src={Logo} alt="Kwara state logo" />
        <div style={{ alignSelf: "center" }}>
          <GreenLine />
        </div>
      </LogoDiv>
      <CertificateTitle>KWARA STATE PUBLIC PROCUREMENT AGENCY</CertificateTitle>
      <RedTitle>
        {procurementMtd === "dlm"
          ? "FULFILLMENT OF DUE PROCESS FOR PAYMENT"
          : `FULFILLMENT OF DUE PROCESS FOR THE AWARD OF CONTRACT`}
      </RedTitle>
      <GreenTitle>CERTIFICATE OF NO OBJECTION</GreenTitle>
      <CertNoTitle>
        CONTRACT REF. NO.
        <CertNoAnswer>{certificateData?.data?.packageNumber?.toUpperCase()}</CertNoAnswer>
      </CertNoTitle>
      <CertificateBody>
        {procurementMtd === "dlm" ? (
          <>
            {" "}
            The Kwara State Public Procurement Agency, hereby issue{" "}
            <span style={{ fontWeight: 700 }}> Certificate of No Objection </span>
            in conformity with Section 15(1g) of the Kwara State Public Procurement Law 2018 as amended, for the payment as
            follows:{" "}
          </>
        ) : (
          <>
            {" "}
            `The Kwara State Public Procurement Agency, having carefully examined your proposal and the documents presented,
            confirms that the submission has satisfied Section 34 (1) and (2) of the Kwara State Public Procurement Law 2018, as
            amended, for issuance of <span style={{ fontWeight: 700 }}>Certificate of No Objection</span> for the Contract Award.`{" "}
          </>
        )}
      </CertificateBody>
      <CertificateBody>
        {procurementMtd === "dlm" ? (
          ""
        ) : (
          <>
            Accordingly, the Agency hereby grants you a <span style={{ fontWeight: 700 }}>Certificate of No Objection</span> to
            award Contract.{" "}
          </>
        )}
      </CertificateBody>
      <CertificateItemsTitle>PROJECT DETAILS</CertificateItemsTitle>
      <CertificateItemsBox>
        <CertificateTitleBox>
          <CertificateItemTitle>Project description</CertificateItemTitle>
        </CertificateTitleBox>
        <CertificateAnswerBox>
          <CertificateItemAnswer>{certificateData?.data?.projectDescription?.toUpperCase()}</CertificateItemAnswer>
        </CertificateAnswerBox>
      </CertificateItemsBox>
      {procurementMtd !== "dlm" && (
        <CertificateItemsBox>
          <CertificateTitleBox>
            <CertificateItemTitle>Project contractor</CertificateItemTitle>
          </CertificateTitleBox>
          <CertificateAnswerBox>
            <CertificateItemAnswer>{certificateData?.data?.contractor?.toUpperCase()}</CertificateItemAnswer>
          </CertificateAnswerBox>
        </CertificateItemsBox>
      )}
      <CertificateItemsBox>
        <CertificateTitleBox>
          <CertificateItemTitle>{procurementMtd === "dlm" ? "Proposed Cost" : `Project cost as submitted`}</CertificateItemTitle>
        </CertificateTitleBox>
        <CertificateAnswerBox>
          <CertificateItemAnswer>
            ₦{certificateData?.data?.quotation && formatCurrency(certificateData?.data?.quotation)}
          </CertificateItemAnswer>
        </CertificateAnswerBox>
      </CertificateItemsBox>
      <CertificateItemsBox>
        <CertificateTitleBox>
          <CertificateItemTitle>{procurementMtd === "dlm" ? "Approved Sum" : `Project cost as reviewed`}</CertificateItemTitle>
        </CertificateTitleBox>
        <CertificateAnswerBox>
          <CertificateItemAnswer>
            ₦{certificateData?.data?.reviewedQuotation && formatCurrency(certificateData?.data?.reviewedQuotation)}
          </CertificateItemAnswer>
        </CertificateAnswerBox>
      </CertificateItemsBox>
      {procurementMtd === "dlm" && (
        <CertificateItemsBox>
          <CertificateTitleBox>
            <CertificateItemTitle>PPA Review Cost</CertificateItemTitle>
          </CertificateTitleBox>
          <CertificateAnswerBox>
            <CertificateItemAnswer>
              ₦{certificateData?.data?.reviewedQuotation && formatCurrency(certificateData?.data?.reviewedQuotation)}
            </CertificateItemAnswer>
          </CertificateAnswerBox>
        </CertificateItemsBox>
      )}
      <CertificateItemsBox>
        <CertificateTitleBox>
          <CertificateItemTitle>Cost Reduction</CertificateItemTitle>
        </CertificateTitleBox>
        <CertificateAnswerBox>
          <CertificateItemAnswer>
            ₦{certificateData?.data?.costReduction && formatCurrency(certificateData?.data?.costReduction)}
          </CertificateItemAnswer>
        </CertificateAnswerBox>
      </CertificateItemsBox>
      <CertificateItemsBox>
        <CertificateTitleBox>
          <CertificateItemTitle>Client ministry/department/agency</CertificateItemTitle>
        </CertificateTitleBox>
        <CertificateAnswerBox>
          <CertificateItemAnswer>{certificateData?.data?.ministry?.toUpperCase()}</CertificateItemAnswer>
        </CertificateAnswerBox>
      </CertificateItemsBox>
      <CertificateItemsBox>
        <CertificateTitleBox>
          <CertificateItemTitle>Head/Sub-head/appropriation Code</CertificateItemTitle>
        </CertificateTitleBox>
        <CertificateAnswerBox>
          <CertificateItemAnswer>
            {`${certificateData?.data?.headCode}/${certificateData?.data?.subHeadCode}/${certificateData?.data?.appropriationCode}` ||
              "NIL"}
          </CertificateItemAnswer>
        </CertificateAnswerBox>
      </CertificateItemsBox>
      <SignatureBox>
        <SignatureContainer style={{ marginRight: "1rem", marginTop: "1rem" }}>
          <CertName style={{ textAlign: "center" }}>{authorizersData?.data?.authorizerFullName?.toUpperCase()}</CertName>
          <SignatureTitle>.........................................................................</SignatureTitle>
          <SignatureTitle style={{ textAlign: "center", marginBottom: "2rem" }}>Name</SignatureTitle>
          <CertName style={{ textAlign: "center" }}>{authorizersData?.data?.authorizerPosition?.toUpperCase()}</CertName>
          <SignatureTitle>.........................................................................</SignatureTitle>
          <SignatureTitle style={{ textAlign: "center" }}>Post</SignatureTitle>
        </SignatureContainer>
        <QRCodeStyle>
          <QRCode
            value={`${window.location.origin}/verify-cno/${certificateData?.data?.id}/${CERTIFICATE_TYPES.AWARD}`}
            renderAs="canvas"
            size={120}
          />
        </QRCodeStyle>
        <SignatureContainer style={{ marginLeft: "1rem" }}>
          <div style={{ textAlign: "center" }}>
            <img src={authorizersData?.data?.signatureUrl} alt="Kwara state logo" height={30} crossorigin />
          </div>
          <SignatureTitle>.........................................................................</SignatureTitle>
          <SignatureTitle style={{ textAlign: "center", marginBottom: "2rem" }}>Signature</SignatureTitle>
          <CertName style={{ textAlign: "center" }}>{format(new Date(), "dd MMMM, yyyy")}</CertName>
          <SignatureTitle>.........................................................................</SignatureTitle>
          <SignatureTitle style={{ textAlign: "center" }}>Date</SignatureTitle>
        </SignatureContainer>
      </SignatureBox>
    </CertificateContainer>
  );
}

export default Certificate;
