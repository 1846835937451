import React, { useContext } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
// components
import { PrevBtn, SubmitButton, ModalBody, ModalButtons, ModalDescription, ModalTitle, StyledModal } from "./style";
import { ReactComponent as ApproveLogo } from "../../assets/approved-check.svg";
// api services
import vendorsAPI from "../../redux/api/vendorsAPI";
// context
import ToastContext from "../../util/toastContext";

const ApprovalModal = ({ showApprovalModal, setShowApprovalModal, userId, classId }) => {
  const history = useHistory();
  const { showAlert } = useContext(ToastContext);
  const [approveUpgrade, approveUpgradeResp] = useMutation(vendorsAPI.upgradeApproval, {
    onSuccess: () => {
      showAlert({
        severity: "success",
        message: "Vendor's upgrade request has been approved",
      });
      setShowApprovalModal(false);
      history.push("/admin/vendorsList/vendors/vendor-upgrade-request");
    },
    throwOnError: true,
    onError: (e) => {
      showAlert({
        severity: "error",
        message: e.message,
      });
      setShowApprovalModal(false);
    },
  });
  const invalidIdAlert = () => {
    showAlert({
      severity: "error",
      message: "User Id Not Provided",
    });
    setShowApprovalModal(false);
  };
  const handleUpgradeApproval = async () => {
    try {
      !!userId && !!classId
        ? await approveUpgrade({
            userId,
            classId,
          })
        : invalidIdAlert();
    } catch (e) {
      showAlert({
        severity: "error",
        message: "Approval not successful",
      });
      setShowApprovalModal(false);
    }
  };

  const closeApproval = () => {
    setShowApprovalModal && setShowApprovalModal(false);
  };
  return (
    <React.Fragment>
      <StyledModal open={showApprovalModal} onClose={closeApproval}>
        {approveUpgradeResp?.isSuccess ? (
          <div style={{ textAlign: "center" }}>
            <ApproveLogo height={100} width={100} title="Approve Successful" />
          </div>
        ) : (
          <>
            <ModalBody>
              <ModalTitle>Approve Upgrade Request?</ModalTitle>
              <ModalDescription>You agree to have thoroughly gone through the proccess</ModalDescription>
              <ModalButtons>
                <SubmitButton loading={approveUpgradeResp.isLoading} onClick={handleUpgradeApproval}>
                  Yes, I Approve
                </SubmitButton>
                <PrevBtn onClick={closeApproval}>Cancel</PrevBtn>
              </ModalButtons>
            </ModalBody>
          </>
        )}
      </StyledModal>
    </React.Fragment>
  );
};

export default ApprovalModal;
