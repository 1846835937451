import { Grid, TableCell, TableRow, Typography, Card } from "@material-ui/core";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import SearchTable, { StyledTableCell, StyledTableRow } from "../../../components/SearchTable";
import adminAPI from "../../../redux/api/adminAPI";
import ToastContext from "../../../util/toastContext";
import { StyleTableCellDesc, StyleTableCellId } from "../../Common/style";
import TableEmptyContent from "../../Common/TableEmptyContent";
// import ActivitiesDetails from "./Activities";
import {
  IdText,
  TableFirstCell,
} from "./style";

import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { HoverTableStyle } from "../../../util/theme";
import { pickStatus } from "../../Vendor/Bid";
import vendorsAPI from "../../../redux/api/vendorsAPI";
import Loader from "../../../components/Common/Loader";


const TableBody = ({ allVendorProjects }) =>
  allVendorProjects.map((eachProject, index) => (
    <StyledTableRow key={`${index}_${eachProject.id}`}>
      <TableFirstCell>
        <IdText>{eachProject.title}</IdText>
        <Typography>{eachProject.description}</Typography>
      </TableFirstCell>
      <StyledTableCell>
        <Typography sx={{backgroundColor:"#ffffff"}}>{moment(eachProject.startDate).format("DD, MMMM, yyyy")}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography>{moment(eachProject.endDate).format("DD, MMMM, yyyy")}</Typography>
      </StyledTableCell>
    </StyledTableRow>
  ));
  const ActivitiesDetails = ({ allActivities }) =>
  allActivities.map((eachActivity, index) => (
    <StyledTableRow key={eachActivity.id}>
      <StyledTableCell>
         <Typography>{eachActivity.eventType}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography>{moment(eachActivity.date).format("DD, MMMM, yyyy")}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography>{moment(eachActivity.date).format("LT")}</Typography>
      </StyledTableCell>
    </StyledTableRow>
  ));
  
  const History = ({ allHistory }) =>
  allHistory.map((eachHistory, index) => (
    <StyledTableRow key={eachHistory.id}>
      <StyledTableCell>   
        <Typography>{eachHistory.refrenceId}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography>{eachHistory.amount}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography>{eachHistory.transactionType}</Typography>
      </StyledTableCell>  
      <StyledTableCell>
        <Typography>{moment(eachHistory.date).format("DD, MMMM, yyyy")}</Typography>
      </StyledTableCell>
      <StyledTableCell>
      <Typography style={{
        textAlign:"center",
        borderRadius:"10px",
        backgroundColor: eachHistory?.status === "PAID"? "#b0e7ae": "#F49342",
        color: "black"
    }} >
        {eachHistory?.status}</Typography>        
      </StyledTableCell>
    </StyledTableRow>
  ));

  
 
const VendorDetailsTable = () => {
  const { vendorId } = useParams();
 
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const [pageSize, setPageSize] = React.useState(10);
  const [pageNumber, setPageNumber] = React.useState(1);


  const payload = {
    pageNumber,
    pageSize,
    vendorId: vendorId,
  };
  
  const queryKey = ["getAllBids", payload];
  const { error: isBidError, data: vendorData, isLoading: isVendorDataLoading } = useQuery(
    queryKey,
    vendorsAPI.getBids,
    {
      enabled: !!payload?.vendorId,
    }
  );
  const history = useHistory()
  const BidTableBody = ({ history: historyData }) =>
  vendorData?.data?.map((eachBid) => {
    return (
      <HoverTableStyle key={eachBid.id} 
      onClick={() => historyData.push(`/vendor/bids/${eachBid.procurementId}`)}
      >
        <StyledTableCell>
          <StyleTableCellId>{eachBid?.packageNumber || "DT-101"}</StyleTableCellId>
          <StyleTableCellDesc>{eachBid?.title || "Demo Title N/A"}</StyleTableCellDesc>
        </StyledTableCell>
        <StyledTableCell>{eachBid?.process || "N/A"}</StyledTableCell>
        <StyledTableCell>{pickStatus(eachBid?.bidStatus || "N/A")}</StyledTableCell>
        <StyledTableCell>
          {(eachBid.expiryDate && moment(eachBid.expiryDate).format("DD, MMMM, yyyy")) || "N/A"}
        </StyledTableCell>
        <StyledTableCell>N{eachBid?.value ? eachBid?.value : "/A"}</StyledTableCell>
      </HoverTableStyle>
    );
  });

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }



  const { vendorId: id } = useParams();

  const { data: projectDetails = [], isLoading: isProjectLoading, error: isProjectError } = useQuery(
    ["vendorProjects", id],
    adminAPI.getVendorProjects
  );
//   const { data: bids = [], isLoading: isBidLoading, error: isBidError } = useQuery(
//     "getAllBid",
//     adminAPI.getAllBid
//   );
  const { data: paymentHistory = [], isLoading: isHistoryLoading, error: isHistoryError } = useQuery(
    ["paymentHistory", id],
    adminAPI.getPaymentHistory
  );

  const { data: vendorActivities = [], isLoading: isActivityLoading , error:isActivityError} = useQuery(
    ["vendorActivities", id],
    adminAPI.getVendorActivities
  );


 
  
  const loader = () => {
    if (isVendorDataLoading) {
      return (
        <TableRow>
          <TableCell colSpan={5}>
            <Loader />
          </TableCell>
        </TableRow>
      );
    }
    if (vendorData?.data?.length === 0) {
      return <TableEmptyContent noColSpan={5} textDisplayed="No Bid Activities" />;
    }
  };

  const { showAlert } = useContext(ToastContext);

  useEffect(() => {
    if (isHistoryError) {
      showAlert({
        severity: "error",
        message: `Something went wrong while fetching payment history: ${isHistoryError}`,
        durationInMs: 5000,
      });
    }

    if (isProjectError) {
      showAlert({
        severity: "error",
        message: `Something went wrong while fetching project details: ${isProjectError}`,
        durationInMs: 5000,
      });
    }

    if (isActivityError) {
      showAlert({
        severity: "error",
        message: `Something went wrong while fetching Activity List: ${isActivityError}`,
        durationInMs: 5000,
      });
    }
    if (isBidError) {
      showAlert({
        severity: "error",
        message: `Something went wrong while fetching Bid History: ${isBidError}`,
        durationInMs: 5000,
      });
    }
  }, [isHistoryError, isActivityError, isProjectError, showAlert, isBidError]);

  return (
       
        <Grid item xs={12}>
        <Card sx={{ width: '100%', backgroundColor:"white"}}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" textColor="inherit" TabIndicatorProps={{
    style: {
      backgroundColor: "#2DAA6F",
     color:"#2DAA6F",
    }
  }} >
          <Tab label="Projects" {...a11yProps(0)} />
          <Tab label="Activity list" {...a11yProps(1)} />
          <Tab label="Payment History" {...a11yProps(2)} />
          <Tab label="Bidding History" {...a11yProps(3)} />
        </Tabs>
      </Box>
              <TabPanel value={value} index={0}>
        <SearchTable
            rows={projectDetails?.length}
            columns={["NAME", "START DATE", "END DATE"]}
            tableHeaderText="Projects"
            isLoading={isProjectLoading}
            showPadding={false}        
          >
            {!isProjectLoading && projectDetails?.length > 0 && <TableBody allVendorProjects={projectDetails} />}
            {!isProjectLoading && projectDetails?.length === 0 && (
              <TableEmptyContent noColSpan={3} textDisplayed="This vendor has no project yet" />
            )}
            
          </SearchTable>
        </TabPanel>
        <TabPanel value={value} index={1}>
        <SearchTable
            rows={vendorActivities?.length}
            columns={["ACTIVITY NAME", "DATE", "TIME"]}
            tableHeaderText="Activity"
            isLoading={isActivityLoading}
            showPadding={false}        
          >
            {!isActivityLoading && vendorActivities?.length > 0 && <ActivitiesDetails allActivities={vendorActivities} />}
            {!isActivityLoading && vendorActivities?.length === 0 && (
              <TableEmptyContent noColSpan={3} textDisplayed="This vendor has no Activity yet" />
            )}
          </SearchTable>
        </TabPanel>
      <TabPanel value={value} index={2}>
      <SearchTable
            rows={paymentHistory?.length}
            columns={["REFERENCE-ID", "AMOUNT", "TRANSACTION TYPE", "DATE", "STATUS"]}
            tableHeaderText="PaymentHistory"
            isLoading={isHistoryLoading}
            showPadding={false}        
          >
            {!isHistoryLoading && paymentHistory?.length > 0 && <History allHistory={paymentHistory} />}
            {!isHistoryLoading && paymentHistory?.length === 0 && (
              <TableEmptyContent noColSpan={3} textDisplayed="This vendor has no payment history yet" />
            )}
            
          </SearchTable>
        </TabPanel>
        <TabPanel value={value} index={3}>
        <SearchTable
        rows={vendorData?.data?.length}
        columns={["REF ID/TITLE", "PROCESS", "BID STATUS", "EXPIRY DATE", "VALUE"]}
        // tableHeaderText={`Showing ${vendorData?.data?.length} Results ` || "Showing 0 Result"}
        page={pageNumber}
        setPage={setPageNumber}
        rowsPerPage={pageSize}
        setRowsPerPage={setPageSize}
        totalNumberOfPages={vendorData?.meta?.pagination?.totalEntries}
        showPadding={false}        
      >
        {vendorData?.data?.length > 0 && <BidTableBody history={history} />}
        {loader()}
      </SearchTable>
        </TabPanel>

    </Card>
       
        </Grid>
   
  );
};

export default VendorDetailsTable
