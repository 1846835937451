import React from "react";
import { Link, TenderDescription, Divider, DetailItem, Bold } from "./style";
import { SectionContainer, SectionContent, SectionHeader, SectionDateInfo, Title } from "../style";
import Loader from "../../../../components/Common/Loader";
import { DetailItemText } from "../../../../components/DetailItem/style";
import { formatCurrency } from "../../../../util/formatCurrency";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { parseISO, format } from "date-fns";
import useBidPaymentStatus from "../useBidPaymentStatus";
import PaymentStatus from "../BidDocuments/PaymentStatus";
import DocumentCategories from "./DocumentCategories";

const formatDate = (date) => format(parseISO(date), "dd/MM/yyyy");

const BidInfoCard = ({ bidQueryStatus, bid, tenderId, method }) => {
  const showPaymentAndDocuments = ["SHORTLISTED", "NOTRECOMMENDED", "EVALUATED", "REJECTED", "RECOMMENDED"].includes(
    bid?.bidStatus
  );

  const { query: paymentStatusQuery, paymentInfo } = useBidPaymentStatus({
    tenderId,
    enabled: showPaymentAndDocuments,
  });

  const isLoading = bidQueryStatus === "loading";
  const isError = bidQueryStatus === "error" || paymentStatusQuery.isError;

  const renderDetail = (label, text, { span = 3, rightAlign = false } = {}) => {
    return (
      <Grid item xs={span}>
        <Box display="flex" justifyContent={rightAlign ? "flex-end" : "flex-start"}>
          <DetailItem label={label} text={(["RQ", "DPM"].includes(method) && ["Tendering Method", "Review Method"].includes(label)) ? `N/A` : text} />
        </Box>
      </Grid>
    );
  };

  if (isLoading)
    return (
      <SectionContainer>
        <Loader />
      </SectionContainer>
    );

  return (
    <SectionContainer noBottomPadding={showPaymentAndDocuments && paymentStatusQuery.isSuccess}>
      {!isError ? (
        <React.Fragment>
          <SectionContent>
            <SectionHeader mb={3.75}>
              <div>
                <Title bottomSpacing="5px">Procurement Information</Title>
                <DetailItemText>{bid.ministry.name}</DetailItemText>
              </div>
              <SectionDateInfo offsetMargin>
                <DetailItem label="Date Published" text={formatDate(bid.datePublished)} />
              </SectionDateInfo>
            </SectionHeader>
            <Title bottomSpacing="20px">{bid.name}</Title>
            <DetailItem
              label="Tender Description"
              labelSpacing="10px"
              text={<TenderDescription>{bid.description}</TenderDescription>}
            />
            <Divider />
            <DetailItem label="Budget" text={<Bold>{formatCurrency(bid.budget, true)}</Bold>} />
            <Divider />
            {paymentStatusQuery.isSuccess && 
            <Grid container>
              {renderDetail("Procurement Category", bid.procurementCategory)}
              {renderDetail("Procurement Method", bid.procurementMethod)}
              {renderDetail("Tendering Method", bid.tenderingStage)}
              {renderDetail("Review Method", bid?.reviewMethod)}
            </Grid>
            }
            <Divider />
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <DetailItem label="Procurement ID" text={<Bold>{bid.packageNumber}</Bold>} />
              <Link to={`/vendor/procurement/${tenderId}`}>View Attachments</Link>
            </Box>
          </SectionContent>

          {showPaymentAndDocuments && (
            <Box mt={4.375}>
              {paymentStatusQuery.isLoading && <Loader />}
              {paymentStatusQuery.isSuccess && (
                <React.Fragment>
                  <PaymentStatus bottomSpacing={0} paymentInfo={paymentInfo} />
                  <Divider />
                  <Box mb={2.5}>
                    <Title bottomSpacing="15px">Documents</Title>
                    <DetailItemText>List of all documents exchanged</DetailItemText>
                  </Box>
                  <DocumentCategories tenderId={tenderId} />
                </React.Fragment>
              )}
            </Box>
          )}
        </React.Fragment>
      ) : null}
    </SectionContainer>
  );
};

export default BidInfoCard;
