import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as MenuIcon } from "../../../../assets/menu-icon.svg";
import { ReactComponent as MenuIconFaint } from "../../../../assets/menu-icon-faint.svg";
import Menu from "@material-ui/core/Menu";
import useMenu from "../../../../hooks/useMenu";
import { Roles } from "../../../../components/AccessControl/UserRoles";
import { useRoles } from "../../../../hooks/useUserDetails";
import { supplementaryBudgetStatus } from "../../../../util/constants";

const MenuDropDown = ({ data, menuActionClick }) => {
  const { anchorEl, toggleMenu, openMenu, handleClose } = useMenu();
  const { role } = useRoles();
  const isProcurementOfficer = role === Roles.PROCUREMENT_OFFICER;

  const accessPermission = (key) => {
    switch (key) {
      case Roles.ACCOUNTING_OFFICER: {
        return {
          text: "Reviewed",
          value: "Reviewed",
          disabled: data?.status?.toLowerCase() !== supplementaryBudgetStatus.pending,
        };
      }
      case Roles.PERMANENT_SECRETARY: {
        return {
          text: "Reviewed",
          value: "Reviewed",
          disabled: data?.status?.toLowerCase() !== supplementaryBudgetStatus.pending,
        };
      }
      case Roles.COMMISSIONER: {
        return {
          text: "Endorse",
          value: "Endorsed",
          disabled: data?.status?.toLowerCase() !== supplementaryBudgetStatus.reviewed,
        };
      }
      case Roles.SUPERVISING_OFFICER: {
        return {
          text: "Endorse",
          value: "Endorsed",
          disabled: data?.status?.toLowerCase() !== supplementaryBudgetStatus.reviewed,
        };
      }
      case Roles.KWPPA: {
        return {
          text: "Approve",
          value: "Approved",
          disabled: data?.status?.toLowerCase() !== supplementaryBudgetStatus.endorsed,
        };
      }
      // case Roles.GOVERNOR: {
      //   return {
      //     text: "Approve",
      //     value: "Approved",
      //     disabled: data?.status?.toLowerCase() !== supplementaryBudgetStatus.endorsed,
      //   };
      // }

      default:
        return {
          text: "Reviewed",
          value: "Reviewed",
          disabled: true,
        };
    }
  };
  return (
    <div>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          toggleMenu(e);
        }}
        disabled={data?.status?.toLowerCase() === "approved"}
      >
        {data?.status === "Approved" ? <MenuIconFaint /> : <MenuIcon />}
      </IconButton>
      <Menu
        open={openMenu}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        onClose={(e) => {
          e.stopPropagation();
          handleClose(e);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            menuActionClick(data, "view-list");
          }}
        >
          View
        </MenuItem>
        {!isProcurementOfficer && (
          <>
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                menuActionClick({ ...data, menuValue: accessPermission(role).value }, "approve-list");
              }}
              disabled={accessPermission(role).disabled}
            >
              {accessPermission(role).text}
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                menuActionClick({ ...data, menuValue: "Rejected" }, "reject-list");
              }}
              disabled={accessPermission(role).disabled}
            >
              Reject
            </MenuItem>
          </>
        )}
      </Menu>
    </div>
  );
};

MenuDropDown.propTypes = {
  menuActionClick: PropTypes.func,
  data: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }).isRequired,
};

MenuDropDown.defaultProps = {
  menuActionClick: () => {},
};

export default MenuDropDown;
