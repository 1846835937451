import { TablePagination, useMediaQuery, Grid, Button, SvgIcon, Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

import { Dropdown } from "../../../components";
import AdminSearchFilter from "../../../components/AdminSearchFilter";
import DateRangePicker from "../../../components/DateRangePicker";
import InputField from "../../../components/InputField";
import { PaginationWrapper, ClearButton } from "../TenderList/style";
import procurementAPI from "../../../redux/api/procurementAPI";
import Loader from "../../../components/Common/Loader";
import contractsAPI from "../../../redux/api/contractAPI";
import CustomSearchTable from "../../../components/SearchTable/CustomSearchTable";
import CustomContractTable from "../../Admin/Contracts/ContractTable/CustomContractTable";
import MinistryDropdown from "../../../components/MinistryDropdown";
import { convertArrayToOptions } from "../../../components/Forms/CreateProcurementPlan/KeyDetails";
import TendersOrContractCard from "../../../components/TendersOrContractsListCard";
import { formatCurrency } from "../../../util/formatCurrency";
// import { ReactComponent as CodeSvg } from "../../../assets/code.svg";
import { ReactComponent as CopySvg } from "../../../assets/copy.svg";
import { ReactComponent as PdfSvg } from "../../../assets/file_pdf.svg";
import { ReactComponent as PrintPdf } from "../../../assets/print.svg";
import DownloadJSONModal from "../AwardedContracts/DownloadJSONModal";
import AwardedContractPDF from "../AwardedContracts/AwardedContractPDF";
import EditDrawer from "../../Admin/OCDS/EditTabs/components/EditDrawer";

const withAwardsNTenders = () => ({ isAdminPortal, title }) => {
  const [openDownloadJSON, setOpenDownloadJSON] = useState(false);
  const classes = useStyles();
  const width = "100%";
  const marginTop = "40px";
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const { pathname } = useLocation();
  const [filters, setFilters] = useState({
    Category: "",
    Search: "",
    ministry: "",
    DateAwarded: "",
    Status: "Open",
    StartDate: "",
    EndDate: "",
  });
  /*
   * The pageNumber and pageSize should be managed separately like below rather than in a single object with keys and values for both. e.g const [pagination] =useState({pageNumber: 1, pageSize: 10})
   * This ensures the Material UI TablePagination component to work correctly
   */
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [openDrawer, setOpenDrawer] = useState({ id: null, isOpen: false, data: {} });
  const toggleDrawer = () => setOpenDrawer(!openDrawer.isOpen);

  const isTenderListView = title === "tenders";

  const apiCall = isTenderListView ? procurementAPI.getAllProcurements : contractsAPI.getAwardedContract;
  const fetchParams = isAdminPortal
    ? {
        pageSize: pageSize,
        pageNumber: pageNumber,
        isUploaded: true,
        ...filters,
      }
    : {
        pageSize: pageSize,
        pageNumber: pageNumber,
        ...filters,
      };

  const { data: apiData, status, isLoading } = useQuery(["tenderAndAwardRequest", fetchParams], apiCall);

  const { data: formOptions } = useQuery({
    queryKey: "keyDetailsFormOptions",
    queryFn: procurementAPI.getKeyDetailsFormOptions,
  });

  const responseData = apiData?.data || [];
  const meta = apiData?.meta || {};

  const updateFilters = (key) => (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const getTenderStatus = (closeDate) => {
    let status;
    let date = new Date(closeDate);
    let now = new Date();

    if (date > now) {
      status = "Opened";
    } else {
      status = "Closed";
    }
    return status;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChangePage = (event, pageNumber) => {
    setPageNumber(pageNumber + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(1);
  };

  const handleCopyTable = () => {
    var urlField = document.getElementById("contracts");

    // create a Range object
    var range = document.createRange();
    // set the Node to select the "range"
    range.selectNode(urlField);
    // add the Range to the set of window selections
    window.getSelection().addRange(range);

    // execute 'copy', can't 'cut' in this case
    document.execCommand("copy");
  };

  const renderTenderTable = () => {
    return (
      <Grid container spacing={3}>
        {responseData.map((data, i) => (
          <TendersOrContractCard
            key={data.id}
            urlLink={`${pathname}/${data.id}`}
            awardedContracts={false}
            service={data.name}
            status={getTenderStatus(data.closeDate)}
            description={data.description}
            openOrAwardedDate={moment(data.openDate).format("DD/MM/YY")}
            closeOrExpiryDate={moment(data.closeDate).format("DD/MM/YY")}
            category={data.procurmentCategory}
            ministry={data?.ministryName}
            contractAmount={formatCurrency(data.budget, true)}
            vendor={data.vendor}
          />
        ))}
      </Grid>
    );
  };

  const renderAwardedContractTable = () => {
    if (isSmallScreen) {
      return (
        <Grid container spacing={3}>
          {responseData.slice(0, 6).map((data, i) => {
            return (
              <TendersOrContractCard
                key={data.id}
                urlLink={`${pathname}/${data.id}`}
                awardedContracts={true}
                service={data.procurement.category.name}
                status={"Awarded Contract"}
                description={data.title}
                openOrAwardedDate={moment(data.dateAwarded).format("DD/MM/YY")}
                closeOrExpiryDate={moment(data.expiryDate).format("DD/MM/YY")}
                category={data.procurmentCategory}
                ministry={data?.ministryName}
                contractAmount={formatCurrency(data.estimatedValue, true)}
                vendor={data.vendor}
                // referenceId={data.referenceId}
              />
            );
          })}
        </Grid>
      );
    }

    return (
      <div id="contracts">
        <CustomSearchTable
          rows={responseData?.length}
          showPadding={false}
          columns={
            ["Project Title", "MDA", "Category", "Contractor", "Date of Award", "value", "Last Modified By", ""]
            // ["Project Title", "Budget Description", "MDA", "Category", "Contractor", "start date", "end date", "value"]
          }
        >
          <CustomContractTable
            // isPublic={isPublic}
            setOpenDrawer={!false ? setOpenDrawer : () => {}}
            urlLink={`${pathname}`}
            contracts={responseData.map((contract) => ({
              ...contract,
              ministry: contract?.procurement?.ministry?.name,
              contractor: {
                companyName: contract.vendor,
              },
            }))}
          />
        </CustomSearchTable>
        <EditDrawer open={openDrawer.isOpen} toggleDrawer={toggleDrawer} id={openDrawer.id} />
      </div>
    );
  };

  return (
    <div>
      <AdminSearchFilter normalSearch>
        {isTenderListView ? (
          <>
            <InputField style={{ width, height: "100%" }} type="text" label="Tender name" setValue={updateFilters("Search")} />
            <Dropdown
              style={{ width: isSmallScreen ? "auto" : width, height: "100%" }}
              name="Procurement status"
              label="By Status"
              values={[
                {
                  label: "Open",
                  value: "Open",
                },
                {
                  label: "Close",
                  value: "Close",
                },
                {
                  label: "All",
                  value: "All",
                },
              ]}
              onChange={updateFilters("Status")}
            />
            <Dropdown
              style={{ width: isSmallScreen ? "auto" : width, height: "100%" }}
              name="Procurement category"
              label="By Procurement Category"
              values={
                !!formOptions
                  ? [
                      {
                        label: "All",
                        value: null,
                      },
                      ...convertArrayToOptions(formOptions.procurementCategories),
                    ]
                  : []
              }
              onChange={updateFilters("ProcurementCategoryId")}
            />
            <Dropdown
              style={{ width: isSmallScreen ? "auto" : width, height: "100%" }}
              name="Procurement method"
              label="By Procurement Method"
              values={
                !!formOptions
                  ? [
                      {
                        label: "All",
                        value: null,
                      },
                      ...convertArrayToOptions(formOptions.procurementMethods),
                    ]
                  : []
              }
              onChange={updateFilters("ProcurementMethodId")}
            />
            <MinistryDropdown style={{ width: isSmallScreen ? "auto" : width }} onChange={updateFilters("ministryId")} />
          </>
        ) : (
          <>
            <InputField
              style={{ width, height: "100%" }}
              type="text"
              label="Search ID, Title"
              setValue={updateFilters("Search")}
              required
            />
            <MinistryDropdown
              style={{ width: isSmallScreen ? "auto" : width }}
              onChange={(value, ministry) => {
                updateFilters("ministry")(ministry?.label === "All" ? "" : ministry?.label);
              }}
            />
            <div style={{ width: "100%" }}>
              <DateRangePicker
                handleStartChange={updateFilters("StartDate")}
                handleEndChange={updateFilters("EndDate")}
                style={{ marginTop: 0, marginBottom: 0, width: "100%" }}
                label={["Start Date", "End Date"]}
              />
              {!!filters.DateAwarded && (
                <div>
                  <ClearButton onClick={() => updateFilters("DateAwarded")(null)}>Clear Date</ClearButton>
                </div>
              )}
            </div>
          </>
        )}
      </AdminSearchFilter>
      {!isTenderListView && (
        <div style={{ marginTop }}>
          <Box display="flex">
            <Box mr={2}>
              <Button
                classes={{ startIcon: classes.btnStartIcon, root: classes.downLoadBtn }}
                startIcon={<SvgIcon component={CopySvg} style={{ color: "#fff", height: 30, width: 30, margin: 0 }} />}
                disabled={isLoading}
                onClick={handleCopyTable}
              >
                Copy
              </Button>
            </Box>
            <Box mr={2}>
              <PDFDownloadLink
                document={<AwardedContractPDF contracts={responseData} />}
                fileName={`Awarded-contract-${Date.now()}`}
                style={{ textDecoration: "none" }}
              >
                {({ loading }) => {
                  return (
                    <Button
                      classes={{ startIcon: classes.btnStartIcon, root: classes.downLoadBtn }}
                      startIcon={<SvgIcon component={PdfSvg} style={{ color: "#fff", height: 30, width: 30, margin: 0 }} />}
                      disabled={isLoading}
                    >
                      {loading ? "Downloading" : "Download PDF"}
                    </Button>
                  );
                }}
              </PDFDownloadLink>
            </Box>
            <Box>
              <Button
                classes={{ startIcon: classes.btnStartIcon, root: classes.downLoadBtn }}
                startIcon={<SvgIcon component={PrintPdf} style={{ color: "#fff", height: 30, width: 30, margin: 0 }} />}
                disabled={isLoading}
                onClick={() => window.print()}
              >
                Print
              </Button>
            </Box>
          </Box>
        </div>
      )}
      <div style={{ marginTop, overflowX: "scroll" }}>
        {status === "loading" ? (
          <div style={{ width: "100%" }}>
            <Loader feedback="Fetching tenders..." />
          </div>
        ) : isTenderListView ? (
          renderTenderTable()
        ) : (
          renderAwardedContractTable()
        )}
      </div>
      <div>
        {meta?.pagination?.totalEntries > 0 && (
          <PaginationWrapper>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={meta?.pagination?.totalEntries}
              rowsPerPage={pageSize}
              page={pageNumber - 1}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </PaginationWrapper>
        )}
      </div>
      <DownloadJSONModal
        open={openDownloadJSON}
        onClose={() => setOpenDownloadJSON(false)}
        totalData={meta?.pagination?.totalEntries}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  btnStartIcon: {
    margin: 0,
  },
  downLoadBtn: {
    border: "1px solid #E5E5EA",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    textTransform: "none",
  },
}));

export default withAwardsNTenders;
