import { Box, Grid } from "@material-ui/core";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import moment from "moment";
import Loader from "../../../components/Common/Loader";
import FileProgress from "../../../components/FileProgress/FileProgress";
import vendorsAPI from "../../../redux/api/vendorsAPI";
import { truncateString, truncateValue } from "../../../util/truncateValue";
import {
  CardHeaderText,
  FeeContainer,
  SubscriptionIcon,
  SubscriptionTextContainer,
  TextLabel,
  ValueText,
} from "./style";
import { PaymentReceiptText } from "./VendorVerify/style";
import { openFileUrlInNewWindow } from "../../Common/util";
import RenderComponent from "../../../components/RenderComponent";

const VendorRegistrationCategory = ({
  cardTitle = "Subscription",
  showDate = true,
  receipts = [],
  showPaymentReceipt = false,
}) => {
  const { vendorId } = useParams();
  const { data: companyDetails, isLoading } = useQuery(["companyDetails", { vendorId }], vendorsAPI.getVendorsCompanyDetails);
  const { contractMaxValue, contractMinValue, fee, title } = companyDetails?.registrationPlan || {};
  const {data: paymentData} = useQuery(["vendorPaymentData", vendorId], vendorsAPI.getVendorLastPayment)

  return (
    <Box>
      {isLoading && <Loader />}
      {!isLoading && (
        <Box >
          <CardHeaderText>{cardTitle}</CardHeaderText>
          <Box style={{display:"flex", alignItems:"flex-start"  }}>
            <SubscriptionIcon>{truncateString(title)}</SubscriptionIcon>
          <Box style={{display:"flex", flexDirection:"column",marginLeft:"20px", width:"100%"}}>
            <SubscriptionTextContainer>
            <FeeContainer>
              <TextLabel>Contracts Fee</TextLabel>
              <ValueText>N{fee}</ValueText>
            </FeeContainer>
           
            <FeeContainer>
              <TextLabel>Contracts Value Above</TextLabel>
              <ValueText>
                N{truncateValue(contractMinValue)} - N{truncateValue(contractMaxValue)}
              </ValueText>
            </FeeContainer>
          </SubscriptionTextContainer>
          {showDate && (
            <SubscriptionTextContainer>
              <FeeContainer>
                <TextLabel>Last Payment</TextLabel>
                <ValueText>{(moment(paymentData?.lastPaymentDate).format("MMM Do, YYYY")) || "N/A"}</ValueText>
              </FeeContainer>
              <FeeContainer>
                <TextLabel>Expiry Date</TextLabel>
                <ValueText>{(moment(paymentData?.expiryDate).format("MMM Do, YYYY")) || "N/A"}</ValueText>
              </FeeContainer>
            </SubscriptionTextContainer>
          )}

          <RenderComponent condition={receipts?.length > 0 && showPaymentReceipt}>
            <Box mt="20px">
              <PaymentReceiptText>Payment Receipt</PaymentReceiptText>
              <Grid>
                {receipts.map((eachReceipt, index) => (
                  <Grid key={index} item xs={12}>
                    <Box mb="15px" width="100%">
                      <FileProgress onClickEyes={() => openFileUrlInNewWindow(eachReceipt?.url)} fileSize={1000} fileName={eachReceipt.name} progress={100} hasEyes={true} />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </RenderComponent>
          </Box>
        </Box>
        </Box>
      )}
    </Box>
  );
};

export default VendorRegistrationCategory;
