import React from "react";
import PropTypes from "prop-types";
import { Box, Modal } from "@material-ui/core";
import { ModalBody, ModalDescription, ModalTitle } from "./style";
import { UpdateButton } from "../CertificateOfNoObjection/style";
import { CancelButton } from "../../GeneralPlanDetails/style";
import { Roles } from "../../../../../components/AccessControl/UserRoles";

/**
 *
 * @param {boolean} open
 * @param {func} onClose
 * @param {func} onAction
 * @param {boolean} loading
 * @returns {null} for the Endorse modal of the bill of quantities
 */
const EndorseBillModal = ({ open = false, onClose, onAction, loading, role }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalBody>
        <ModalTitle>Endorse Bill of Quantity</ModalTitle>
        {role === Roles.GOVERNOR ? (
          <ModalDescription>Are you sure you want to approve this Bill of Quanitity</ModalDescription>
        ) : (
          <ModalDescription>Are you sure you want to endorse this Bill of Quanitity</ModalDescription>
        )}
        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <UpdateButton disabled={loading} onClick={onAction}>
            {loading ? "Endorsing..." : "Confirm"}
          </UpdateButton>
          <CancelButton onClick={onClose} style={{ marginLeft: "1rem" }}>
            Cancel
          </CancelButton>
        </Box>
      </ModalBody>
    </Modal>
  );
};

EndorseBillModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  loading: PropTypes.bool,
};

EndorseBillModal.defaultProps = {
  onAction: () => {},
  onClose: () => {},
  loading: false,
};

export default EndorseBillModal;
