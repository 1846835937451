import React, { useContext, useEffect, useState } from "react";
import ActivityTabs from "../../../../../components/ActivityTabs/ActivityTabs";
import Requirements from "./Requirements/Requirements";
import withAdminDashboard from "../../../HOC/withAdminDashboard";
import SupportingDocuments from "./SupportingDocuments/SupportingDocuments";
import { GeneralTitlePage } from "../SpecificProcurementNotice/style";
import Breadcrumb from "../../../../Common/PageElements/Breadcrumb";
import { Box } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import procurementAPI from "../../../../../redux/api/procurementAPI";
import ToastContext from "../../../../../util/toastContext";
import { useProcurementMethod } from "../../../../../hooks/queries/useProcurement";
import { Procurement_Method } from "../../../../../util/constants";

const PreQualificationDocuments = () => {
  const [formValues, setFormValues] = useState({});

  const { procurementId = "", activityId } = useParams();

  const { method } = useProcurementMethod({ procurementId });

  const history = useHistory();

  const breadcrumbLinks = [
    {
      url: "/admin/procurement/plans",
      title: "Home",
      forwardSlash: true,
    },
    {
      url: `/admin/procurement/plan-details/${procurementId}`,
      title: "Activities List",
    },
    {
      url: "#",
      title: "Preparation of Prequalification Documents",
    },
  ];

  const getDetails = useQuery({
    queryFn: procurementAPI.getPreQualificationDocuments,
    queryKey: ["getDetails", activityId],
  });

  const activityDetails = useQuery({
    queryKey: [
      "activityDetails",
      {
        activityId,
      },
    ],
    queryFn: procurementAPI.getActivityById,
  });

  const [submitResults, submitResultsQuery] = useMutation(
    method === Procurement_Method.DIRECT_PROCUREMENT
      ? procurementAPI.postDPMPreQualificationDocuments
      : procurementAPI.postPreQualificationDocuments,
    {
      throwOnError: true,
    }
  );

  const { showAlert } = useContext(ToastContext);

  const saveState = (newState) => {
    setFormValues({
      ...formValues,
      ...newState,
    });
  };

  const onPublish = ({ supporting, removed }) => {
    saveState({ supporting, removed });

    submitResults({ ...formValues, supporting, removed, activityId })
      .then(() => {
        showAlert({
          message: "Successfully uploaded details",
          severity: "success",
          durationInMs: 3000,
        });

        history.push(`/admin/procurement/plan-details/${procurementId}`);
      })
      .catch((e) => {
        showAlert({
          message: e.message,
          severity: "error",
          durationInMs: 3000,
        });
      });
  };

  useEffect(() => {
    if (getDetails.isSuccess) {
      const newFormValues = {};

      const baseState = {
        supportingDocuments: [],
        prequalificationRequirements: [],
        description: "",
        deadline: null,
      };

      const { supportingDocuments, prequalificationRequirements, description, expiryDate: deadline } = Object.assign(
        baseState,
        getDetails.data
      );

      newFormValues.supporting = supportingDocuments?.map((file) => ({
        inDb: true,
        id: file.id,
        name: file.file.original_filename,
        size: file.file.bytes,
        downloadUrl: file.file.url,
      }));

      newFormValues.requiredDocuments = prequalificationRequirements;

      newFormValues.editorState = description;

      newFormValues.deadline = deadline;

      setFormValues(newFormValues);
    }

    if (getDetails.isError) {
      showAlert({
        message: getDetails.error.message,
        severity: "error",
        durationInMs: 3000,
      });
    }
    //I added the disable because I can't add showAlert to the dependency array because it changes every render
    // eslint-disable-next-line
  }, [getDetails.isSuccess, getDetails.isError, getDetails.data, getDetails.error]);

  const activityIsApproved = activityDetails.data?.procurementPlanActivityStatus === "Approved";

  return (
    <>
      <Box mb={4} mt={2}>
        <GeneralTitlePage>Preparation of Prequalification Documents</GeneralTitlePage>
        <Breadcrumb values={breadcrumbLinks} />
      </Box>
      <ActivityTabs
        tabs={[
          {
            title: "Requirements",
            component: (
              <Requirements
                initialState={formValues}
                saveState={saveState}
                activityIsApproved={activityIsApproved}
                procurementId={procurementId}
                method={method}
              />
            ),
          },
          {
            title: "Documents",
            component: (
              <SupportingDocuments
                initialState={formValues}
                loading={submitResultsQuery.isLoading}
                activityIsApproved={activityIsApproved}
                onPublish={onPublish}
              />
            ),
          },
        ]}
        entries={["Draft Pre-Qualification Documents"]}
        loading={getDetails.isLoading}
      />
    </>
  );
};

export default withAdminDashboard(PreQualificationDocuments);
