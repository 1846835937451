import React from "react";
import ReceiptOfResponse from "./index";
import { Route, Switch, useParams } from "react-router-dom";
import withAdminDashboard from "../../../HOC/withAdminDashboard";
import VendorsCategorized from "./VendorsCategorized/VendorsCategorized";
import SpecificVendor from "./SpecificVendor/SpecificVendor";
import TechnicalReviewDetails from "./TechnicalReviewDetails";
import CreateTechnicalEvaluation from "./CreateTechnicalEvaluation/Index";
import UpdateTechnicalEvaluation from "./CreateTechnicalEvaluation/UpdateTechEvaluation";
import { useProcurementMethod } from "../../../../../hooks/queries/useProcurement";
import { Procurement_Method } from "../../../../../util/constants";



// const basePath = '/admin/procurement/activity/receiptofresponses/:activityId/:procurementId'
// const altBasePath = '/admin/procurement/activity/receiptofresponse/:activityId/:procurementId'

const ReceiptOfResponseWrapper = () => {
const { procurementId } = useParams()
const { method } = useProcurementMethod({ procurementId })

const routeMap = {
  [Procurement_Method.DIRECT_PROCUREMENT] : 'receiptofresponse'
}

const basePath = `/admin/procurement/activity/${routeMap[method] ?? `receiptofresponses`}/:activityId/:procurementId`

  return (
    <Switch>
      <Route path={basePath} component={ReceiptOfResponse} exact />
      <Route path={`${basePath}/vendors/:type`} component={VendorsCategorized} exact />
      <Route
        path={`${basePath}/vendorsDetails/:vendorId/:vendorName`}
        component={SpecificVendor}
        exact
      />
      <Route
        path={`${basePath}/vendorsDetails/:vendorId/:vendorName/vendor-evaluation`}
        component={TechnicalReviewDetails}
        exact
      />
      <Route
        path={`${basePath}/create-technical-evaluation`}
        component={CreateTechnicalEvaluation}
        exact
      />
      <Route
        path={`${basePath}/edit-technical-evaluation`}
        component={UpdateTechnicalEvaluation}
        exact
      />
    </Switch>
  );
};


export default withAdminDashboard(ReceiptOfResponseWrapper);
