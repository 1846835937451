import React from "react";

import Loader from "../../../../components/Common/Loader";
import { MainText, MainTitle, InfoCardWrapper, HL, CuteGrid } from "../style";
import moment from "moment";

const ProcurementInfo = ({ info, isLoading = false, method }) => {
  return (
    <InfoCardWrapper>
      <MainTitle>Procurement Information</MainTitle>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <MainText mt="5px" fontSize="14px">
            Ministry of works
          </MainText>
          <MainTitle mt="43px">{info?.tender?.name}</MainTitle>
          <MainText mt="20px" fontSize="13px">
            Tender Description
          </MainText>
          <MainText mt="10px" fontSize="14px">
            {info?.tender?.description}
          </MainText>
          <HL mt="20px" />
          <MainText mt="20px" fontSize="13px">
            Budget
          </MainText>
          <MainTitle>&#8358;{info?.tender?.budget.toLocaleString()}</MainTitle>
          <HL mt="20px" />
          <CuteGrid>
            <div>
              <MainText mt="20px" fontSize="13px">
                Procurement Category
              </MainText>
              <MainText fw="600" fontSize="14px">
                {info?.tender?.procurementCategory}
              </MainText>
            </div>
            <div>
              <MainText mt="20px" fontSize="13px">
                Procurement Method
              </MainText>
              <MainText fw="600" fontSize="14px">
                {info?.tender?.procurementMethod}
              </MainText>
            </div>
            <div>
              <MainText mt="20px" fontSize="13px">
                Tendering Method
              </MainText>
              <MainText fw="600" fontSize="14px">
                {["rq", "dpm"].includes(method) ? `N/A` : info?.tender?.tenderingMethod}
              </MainText>
            </div>
            <div>
              <MainText mt="20px" fontSize="13px">
                Review Method
              </MainText>
              <MainText fw="600" fontSize="14px">
                {["rq", "dpm"].includes(method) ? `N/A` :  info?.tender?.reviewMethod}
              </MainText>
            </div>
          </CuteGrid>
          <HL mt="20px" />
          <MainText mt="20px" fontSize="13px">
            Package Number
          </MainText>
          <MainTitle>{info?.tender?.packageNumber}</MainTitle>
        </>
      )}
      <div className="topRightCard">
        <CuteGrid>
          <div>
            <MainText mt="20px" mr="10px" fontSize="13px">
              Date Published
            </MainText>
            <MainText fw="600" fontSize="14px">
              {moment(info?.createdAt).format("DD/MM/YYYY")}
            </MainText>
          </div>
          <div>
            <MainText mt="20px" fontSize="13px">
              Expiry Date
            </MainText>
            <MainText fw="600" fontSize="14px">
              {moment(info?.expiryDate).format("DD/MM/YYYY") || "N/A"}
            </MainText>
          </div>
        </CuteGrid>
      </div>
    </InfoCardWrapper>
  );
};

export default ProcurementInfo;
