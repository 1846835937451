import React from "react";
import { Chip, IconButton } from "@material-ui/core";
import { capitalize } from "lodash";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { EmptyTableSubTitle } from "../../../../components/EmptyTableBody/style";
import { StyledTableCell } from "../../../../components/SearchTable";
import { Url, VendorTableStyle } from "../style";
import { theme } from "../../../../util/theme";
import EmptyTableBody from "../../../../components/EmptyTableBody";

const statusColors = {
  PENDING: theme.color.tags.pending,
  APPROVED: theme.color.tags.approved,
  REJECTED: theme.color.tags.rejected,
  SUSPENDED: theme.color.tags.rejected,
};

const VendorTable = ({ vendors, setVendorToDelete }) => {
  const history = useHistory();

  const handleSelectVendorToDelete = (event, vendor) => {
    event.stopPropagation();
    setVendorToDelete(vendor);
  };

  return vendors.length ? (
    vendors.map((row) => (
      <VendorTableStyle key={`${row?.id}`} onClick={() => history.push(`/admin/vendorsList/${row?.id}`)}>
        <StyledTableCell component="th" scope="row">
          <p>{row?.vendorProfile?.companyName}</p>
        </StyledTableCell>
        <StyledTableCell>
          <Chip
            label={capitalize(row?.vendorProfile?.status || "UNKNOWN")}
            style={{
              background: statusColors[row?.vendorProfile?.status]?.background || statusColors.SUSPENDED.background,
              height: "24px",
              color: statusColors[row?.vendorProfile?.status]?.text,
              minWidth: "100px",
            }}
          />
        </StyledTableCell>
        <StyledTableCell>{row?.vendorProfile?.registrationPlan?.title}</StyledTableCell>
        <StyledTableCell>
          {row?.vendorProfile?.state && `${row?.vendorProfile?.state}, `}
          {row?.vendorProfile?.country || "Nigeria"}
        </StyledTableCell>
        <StyledTableCell style={{ paddingLeft: 0 }}>
          <Url href="#">{row?.vendorProfile?.website || <>&#8212;</>}</Url>
        </StyledTableCell>
        <StyledTableCell>
          {row?.vendorProfile?.isRegistrationComplete === false && row?.vendorProfile?.status === "PENDING" && (
            <IconButton onClick={(event) => handleSelectVendorToDelete(event, row)}>
              <DeleteIcon style={{ color: "#999" }} />
            </IconButton>
          )}
        </StyledTableCell>
      </VendorTableStyle>
    ))
  ) : (
    <EmptyTableBody>
      <EmptyTableSubTitle>No vendor registered yet</EmptyTableSubTitle>
    </EmptyTableBody>
  );
};

export default VendorTable;
