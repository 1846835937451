import baseApiMethod from "./baseApiMethod";
import baseAxiosMethod from "./baseAxiosMethod";
import { handleAxiosError } from "./handleApiError";
import { getUserDetails } from "../../util/getUserDetails";
import { getVendorRegStage } from "../../util/getVendorRegStage";
import { sectionedDocs } from "../../util/sectionedDocs";
import { transformVendorDoc } from "../../util/transforms/vendorDoc";

const vendorsAPI = {};

vendorsAPI.checkAccessToken = async () => {
  const accessToken = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");
  try {
    const response = await baseAxiosMethod.post(`users/refreshToken`, { accessToken, refreshToken });

    const { accessToken: newAccessToken, refreshToken: newRefreshToken } = response.data.data;


    localStorage.setItem("token", newAccessToken);
    localStorage.setItem("refreshToken", newRefreshToken);

    return response.data.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

vendorsAPI.approveVendor = ({ companyDetails: { id } }) => baseApiMethod(null, `staffs/${id}/approveVendor`, "post");
vendorsAPI.rejectVendor = ({ companyDetails: { id } }) => baseApiMethod(null, `staffs/${id}/rejectVendor`, "post");

vendorsAPI.approveVendorProfile = async function approveVendorProfile({ vendorId, file }) {
  try {
    const fd = new FormData();

    fd.set("File", file);

    const response = await baseAxiosMethod.post(`staffs/${vendorId}/approveVendor`, fd);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getVendorProfileDoc = async function approveVendorProfile(key, { vendorId }) {
  try {
    const response = await baseAxiosMethod(`vendors/${vendorId}/documents`);

    return transformVendorDoc(response.data.data);
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.rejectVendorProfile = async function rejectVendorProfile({ vendorId, description }) {
  try {
    const response = await baseAxiosMethod.post(`staffs/${vendorId}/rejectVendor`, { description });
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};
vendorsAPI.createMilestoneTask = async function createMilestoneTask({ projectMileStoneId, data }) {
  try {
    const response = await baseAxiosMethod.post(`projects/${projectMileStoneId}/mileStoneTask`, {
      ...data,
    });
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getProcurementContractInfo = async function getProcurementContractInfo(key, procurementId) {
  try {
    const response = await baseAxiosMethod.get(`procurements/${procurementId}/contracts?Status=3`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};
vendorsAPI.getBidEvaluationReport = async function getBidEvaluationReport(key, procurementId, pageSize, pageNumber) {
  try {
    const response = await baseAxiosMethod.get(
      `procurements/${procurementId}/evaluatedReport?PageNumber=${pageNumber}&PageSize=${pageSize}`
    );
    return {
      bidEvaluation: response.data.data,
      meta: response.data.meta,
    };
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getUploadedApprovedInvoice = async function getUploadedApprovedInvoice(key, objectId) {
  try {
    const response = await baseAxiosMethod.get(`documentUpload/${objectId}/documents?objectType=2`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getOfferLetterDoc = async function getOfferLetterDoc(key, objectId) {
  try {
    const response = await baseAxiosMethod.get(`contracts/acceptanceLetter?procurementPlanId=${objectId}`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getProjectReportDoc = async function getProjectReportDoc(key, projectId) {
  try {
    const response = await baseAxiosMethod.get(`projects/${projectId}/reportDocuments`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.uploadProjectReportDoc = async function uploadProjectReportDoc({ projectId, files = [] }) {
  try {
    const fd = new FormData();

    files.forEach((document) => {
      fd.append("files", document);
    });

    const response = await baseAxiosMethod.post(`projects/${projectId}/reportDocuments`, fd);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.uploadApprovedInvoice = async function uploadApprovedInvoice({ formData }) {
  try {
    const response = await baseAxiosMethod.post("documentUpload", formData);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getVendorTransactionInfoByMilestoneId = async function getVendorTransactionInfoByMilestoneId(key, milestoneId) {
  try {
    const response = await baseAxiosMethod.get(`transactions/${milestoneId}`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getVendorTransaction = async function getVendorTransaction() {
  try {
    const response = await baseAxiosMethod.get("transactions");
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getVendorContracts = async function getVendorContracts(key, params, userId) {
  try {
    const response = await baseAxiosMethod.get(`contracts/vendor/${userId}`, {
      params,
    });

    const {
      data,
      meta: { pagination },
    } = response.data;

    return { contracts: data, pagination };
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};
vendorsAPI.uploadSignedDocument = async function uploadSignedDocument(data) {
  const { procurementId: tenderId, formData } = data;
  try {
    const response = await baseAxiosMethod.post(`vendors/${tenderId}/vendorContractSigningDocument`, formData);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.acceptOfferLetter = async function acceptOfferLetter({ contractId }) {
  try {
    const response = await baseAxiosMethod.post(`vendors/acceptIntent/${contractId}`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getVendorLetterOfIntent = async (key, procurementId) => {
  try {
    const response = await baseAxiosMethod.get(`procurements/${procurementId}/contractDocuments?objectType=1`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getVendorSignedContract = async (key, procurementId) => {
  try {
    const response = await baseAxiosMethod.get(`procurements/${procurementId}/contractDocuments?objectType=2`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getContractDraft = async (key, procurementId) => {
  try {
    const response = await baseAxiosMethod.get(`procurements/${procurementId}/contractDocuments?objectType=5`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getUnsignedContractDraft = async (key, procurementId) => {
  try {
    const response = await baseAxiosMethod.get(`procurements/${procurementId}/unsignedContractDocuments`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.rejectOfferLetter = async function rejectOfferLetter({ contractId }) {
  try {
    const response = await baseAxiosMethod.post(`vendors/rejectIntent/${contractId}`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getVendorContractById = async function getVendorContractById(key, contractId) {
  try {
    const response = await baseAxiosMethod.get(`contracts/${contractId}`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getMilestoneTasks = async function getMilestoneTask(key, milestoneId) {
  try {
    const response = await baseAxiosMethod(`projects/mileStone/${milestoneId}/mileStoneTasks`);

    const {
      data,
      meta: { pagination },
    } = response.data;

    return { tasks: data, pagination };
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getVendorContractSummary = async function getVendorContractSummary(key, vendorId) {
  try {
    const response = await baseAxiosMethod(`contracts/summary/vendor/${vendorId}`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getVendorsCompanyDetails = async function getVendorsCompanyDetails(key, { vendorId }) {
  try {
    const response = await baseAxiosMethod(`vendors/${vendorId}`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.saveGeneralCompanyDetails = ({ data, userId }) => baseApiMethod(data, `vendors/${userId}/CreateProfile`, "post");

vendorsAPI.addDirectorDetails = ({ data, userId }) =>
  baseApiMethod(data, `vendors/${userId}/directors`, "post", "multipart/form-data");
vendorsAPI.getDirectorDetails = (userId) => baseApiMethod(null, `vendors/${userId}/directors?PageSize=30`, "get");

vendorsAPI.getGeneralCompanyDetails = (id) => baseApiMethod(null, `vendors/${id}`, "get");

vendorsAPI.postAttestation = (data) => baseApiMethod(data, `vendors/${data.userId}/attestations`, "post");

vendorsAPI.getCountries = () => baseApiMethod(null, "vendors/countries", "get");

vendorsAPI.getStates = () => baseApiMethod(null, "vendors/states?PageNumber=1&PageSize=37", "get");

vendorsAPI.getProducts = () => baseApiMethod(null, "vendors/services?PageSize=0", "get");

vendorsAPI.getVendorProducts = (userId) => baseApiMethod(null, `vendors/${userId}/services`, "get");

vendorsAPI.postVendorServices = ({ data, userId }) => baseApiMethod(data, `vendors/${userId}/services`, "post");

vendorsAPI.getRegistrationPlans = () => baseApiMethod(null, `vendors/registrationPlans`, "get");

vendorsAPI.getAttestation = (data) => baseApiMethod(null, `vendors/${data.userId}/attestations`, "get");
vendorsAPI.deleteDirectorDetails = ({ id, directorId }) => baseApiMethod(null, `vendors/${id}/directors/${directorId}`, "delete");
vendorsAPI.editDirectorDetails = ({ data, directorId, userId }) =>
  baseApiMethod(data, `vendors/${userId}/directors/${directorId}`, "put", "multipart/form-data");

vendorsAPI.getVendorRegistrationPlan = (id) => baseApiMethod(null, `vendors/${id}/registrationCategory`, "get");

vendorsAPI.postVendorRegistrationPlan = ({ userId, registrationPlanId }) =>
  baseApiMethod(null, `vendors/${userId}/vendorRegistrationCategory/${registrationPlanId}`, "post");

vendorsAPI.postDocuments = ({ data, userId }) =>
  baseApiMethod(data, `vendors/${userId}/documents`, "post", "multipart/form-data");

vendorsAPI.getDocumentGroups = () => baseApiMethod(null, "vendors/documentTypes", "get");

vendorsAPI.getDocuments = (id) => baseApiMethod(null, `vendors/${id}/documents`, "get");

vendorsAPI.deleteDocuments = ({ userId, id }) => baseApiMethod(null, `vendors/${userId}/documents/${id}`, "delete");

vendorsAPI.completeProfile = (id) => baseApiMethod(null, `vendors/${id}/completeVendorRegistration`, "put");

vendorsAPI.getVendorBidSummary = () => baseApiMethod(null, "bids/summary", "get");

vendorsAPI.getBids = async (key, payload) => {
  try {
    const response = await baseAxiosMethod(`bids/vendor/${payload.vendorId}`, {
      params: {
        vendorId: payload.vendorId,
        Search: payload.title,
        Process: payload.process,
        Type: payload.status,
        ExpiryDate: payload.expiryDate,
        PageNumber: payload.pageNumber,
        PageSize: payload.pageSize,
      },
    });

    return response.data;
  } catch (e) {
    throw handleAxiosError(e);
  }
};

vendorsAPI.fetchContractSummary = async (key, vendorId) => {
  try {
    const response = await baseAxiosMethod(`contracts/summary/vendor/${vendorId}`);
    return response.data.data;
  } catch (e) {
    throw handleAxiosError(e);
  }
};

vendorsAPI.fetchUploadedDoc = async (key, { vendorId, id }) => {
  try {
    const response = await baseAxiosMethod(`procurements/${id}/interestedVendor/${vendorId}`);
    return response.data.data;
  } catch (e) {
    throw handleAxiosError(e);
  }
};

vendorsAPI.getVendorProjectSummary = async (key, vendorId) => {
  try {
    const response = await baseAxiosMethod(`projects/summary/vendor/${vendorId}`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getVendorProjects = async (key, payload) => {
  try {
    const response = await baseAxiosMethod(`projects/${payload.UserId}/projects`, {
      params: {
        Search: payload.title,
        Status: payload.status,
        StartDate: payload.startDate,
        ExpiryDate: payload.expiryDate,
        PageNumber: payload.pageNumber,
        PageSize: payload.pageSize,
      },
    });

    const {
      data,
      meta: { pagination },
    } = response.data;

    return { vendorProjects: data, pagination };
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getProjectMilestones = async (_, projectId) => {
  try {
    const response = await baseAxiosMethod(`projects/${projectId}/mileStone`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.createProjectMilestone = async ({ projectId, data }) => {
  try {
    const response = await baseAxiosMethod.post(`projects/${projectId}/mileStone`, data);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.fetchAllMinistries = async () => {
  try {
    const response = await baseAxiosMethod("ministries");
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getProjectDetails = async (_, id) => {
  try {
    const response = await baseAxiosMethod(`projects/${id}`);
    return response.data.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

vendorsAPI.hasVendorExpressedInterest = async (_, procurementPlanId) => {
  try {
    const response = await baseAxiosMethod(`/procurements/${procurementPlanId}/vendors`);
    const { data: vendors } = response.data;
    const user = getUserDetails();
    if (vendors.some((vendor) => vendor.userId === user.UserId)) {
      return true;
    }
    return false;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getAllRegistrationPlan = async () => {
  try {
    const response = await baseAxiosMethod(`vendors/registrationPlan`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.expressInterest = async ({ procurementPlanId, formData }) => {
  try {
    await baseAxiosMethod.post(`vendors/${procurementPlanId}/expressInterest`, formData);
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getMilestoneDetails = async (_, projectId, milestoneId) => {
  try {
    const response = await baseAxiosMethod(`projects/${projectId}/mileStone/${milestoneId}`);
    return response.data.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

vendorsAPI.getMilestoneComments = async (_, milestoneId) => {
  try {
    const response = await baseAxiosMethod(`comments/${milestoneId}`);
    return response.data.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

vendorsAPI.getMilestoneInvoices = async (_, milestoneId) => {
  try {
    const response = await baseAxiosMethod(`projects/${milestoneId}/milestoneInvoice`);
    return response.data.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

vendorsAPI.getMilestoneDetails = async (_, projectId, milestoneId) => {
  try {
    const response = await baseAxiosMethod(`projects/${projectId}/mileStone/${milestoneId}`);
    return response.data.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

vendorsAPI.createInvoice = async ({ milestoneId, data }) => {
  try {
    const fd = new FormData();

    fd.set("DueDate", data.dueDate);

    data.fileNames.forEach((document) => {
      fd.append("Documents", document);
    });

    const response = await baseAxiosMethod.post(`projects/${milestoneId}/milestoneInvoice`, fd);
    return response.data.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

vendorsAPI.uploadInvoiceProofOfPayment = async ({ invoiceId, fileNames }) => {
  try {
    const fd = new FormData();

    fd.set("Status", "5");
    fd.set("ObjectId", invoiceId);
    fd.set("ObjectType", "8");

    fileNames.forEach((document) => {
      fd.set("Documents", document);
    });

    await baseAxiosMethod.post("documentUpload", fd);
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getInvoiceProofOfPayment = async (key, { invoiceId }) => {
  try {
    const response = await baseAxiosMethod(`documentUpload/${invoiceId}/documents`, {
      params: {
        objectType: 8,
      },
    });

    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getSummary = async () => {
  try {
    const response = await baseAxiosMethod("Summary/vendorOverviewSummary");
    return response.data.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

vendorsAPI.getTaskDetails = async (_, milestoneId, taskId) => {
  try {
    const response = await baseAxiosMethod(`projects/${milestoneId}/mileStoneTasks/${taskId}`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.updateVendorRegistrationStage = async (stage) => {
  try {
    const currentStage = getVendorRegStage();
    if (stage <= currentStage) return;
    await baseAxiosMethod.put(`/vendors/UpdateVendorRegistrationStage/${stage}`);
    localStorage.setItem("vendorRegStage", stage);
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.fetchVendorBidSummary = async (_, userId) => {
  try {
    if (userId) {
      const response = await baseAxiosMethod(`bids/summary/${userId}/vendor`);
      return response.data.data;
    }
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

vendorsAPI.markTaskAsDone = async (data) => {
  try {
    const { milestoneId, taskId } = data;
    const response = await baseAxiosMethod(`projects/mileStone/${milestoneId}/tasks/${taskId}/review`);
    return response.data.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

vendorsAPI.markTaskAsCompleted = async (data) => {
  try {
    const { milestoneId, taskId } = data;
    const response = await baseAxiosMethod(`projects/mileStone/${milestoneId}/tasks/${taskId}/completed`);
    return response.data.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

vendorsAPI.getMilestoneDetails = async (_, projectId, milestoneId) => {
  try {
    const response = await baseAxiosMethod(`projects/${projectId}/mileStone/${milestoneId}`);
    return response.data.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

vendorsAPI.getSingleBid = async (_, bidderId) => {
  try {
    if (bidderId) {
      const response = await baseAxiosMethod(`bids/${bidderId}`);
      const bid = response.data.data;
      bid.bidStatus = bid.bidStatus.toUpperCase();
      return bid;
    }
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.editTask = async ({ milestoneId, taskId, data }) => {
  try {
    const response = await baseAxiosMethod.put(`projects/${milestoneId}/mileStoneTask/${taskId}`, data);
    return response.data.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

vendorsAPI.createDocuments = ({ documentIds, data }) => {
  return (async function (documentIds) {
    try {
      const response = await baseAxiosMethod.post(`documentUpload`, data);
      return { documents: response.data.data, documentIds };
    } catch (error) {
      throw new Error(JSON.stringify({ documentIds, message: error?.response?.data?.message || error?.message }));
    }
  })(documentIds);
};

vendorsAPI.getUploadedDocuments = async (_, objectId, objectType) => {
  try {
    const response = await baseAxiosMethod(`documentUpload/${objectId}/documents?objectType=${objectType}`);
    return response.data.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

vendorsAPI.getVendorProcurements = async (_, procurementId) => {
  try {
    if (procurementId) {
      const response = await baseAxiosMethod(`procurements/${procurementId}/bidVendors`);
      return response.data.data;
    }
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

vendorsAPI.removeDocument = async (documentId) => {
  try {
    const response = await baseAxiosMethod.delete(`documentUpload/${documentId}`);
    return response.data.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

vendorsAPI.getBidInvitationDocuments = async (_, procurementId) => {
  try {
    if (procurementId) {
      const response = await baseAxiosMethod(`procurements/${procurementId}/tenderPreparation`);
      return response.data.data;
    }
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

vendorsAPI.getVendorDetails = async (_, vendorId) => {
  try {
    const response = await baseAxiosMethod(`vendors/${vendorId}`);
    return response.data.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

vendorsAPI.placeBid = async ({
  procurementId,
  documents = [],
  quotation,
  completionNumber,
  completionPeriod
}) => {
  try {
    const fd = new FormData();
    documents.forEach((document) => fd.append("documents", document));
    fd.append("quotation", quotation)
    fd.append("completionNumber", completionNumber)
    fd.append("completionPeriod", completionPeriod)
    const response = await baseAxiosMethod.post(`vendors/placeBid/${procurementId}`, fd);
    return response.data.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

vendorsAPI.saveBidsDocument = async function ({ mandatory, supporting, removed, activityId }) {
  const formData = sectionedDocs({ mandatory, supporting, removed });
  formData.append("ObjectType", 2);
  try {
    await baseAxiosMethod.post(`procurements/${activityId}/procurementPlanDocument`, formData);
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

vendorsAPI.getBidsDocument = async function (_, tenderId) {
  try {
    const vendor = getUserDetails();
    const response = await baseAxiosMethod.get(`/procurements/${tenderId}/bidDocuments/${vendor.UserId}`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getVendor = async (key, vendorId) => {
  try {
    const response = await baseAxiosMethod(`vendors/${vendorId}`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getVendorsUploadList = async (key, params = {}) => {
  try {
    const response = await baseAxiosMethod(`vendors/upload`, { params });
    return response.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.deleteVendorUploadList = async (uploadId) => {
  try {
    const response = await baseAxiosMethod.delete(`vendors/upload/${uploadId}`);
    return response.data.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};

vendorsAPI.getVendorsUploadStat = async () => {
  try {
    const response = await baseAxiosMethod(`vendors/upload/stat`);
    return response.data.data;
  } catch (error) {
    throw new Error(handleAxiosError(error));
  }
};


vendorsAPI.getVendorUpgradeList = async (key, params = {}) => {
  try {
    const response = await baseAxiosMethod(`vendors/class/upgrade`, { params })
    return response.data
  } catch (e) {
    throw new Error(handleAxiosError(e));

  }
}



vendorsAPI.getVendorUpgrade = async (key, { vendorId }) => {
  try {
    const response = await baseAxiosMethod(`vendors/${vendorId}/class/upgrade`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
}

vendorsAPI.getVendorProfile = async (id) => {
  try {
    const response = await baseAxiosMethod(`vendors/${id}/profile`)
    return response.data.data
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
}

vendorsAPI.upgradeApproval = async ({ userId, classId }) => {
  try {
    const response = await baseAxiosMethod.post(`staffs/${userId}/class/${classId}/approval`)
    return response.data.data
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
}

vendorsAPI.upgradeRejection = async ({ userId, description }) => {
  try {
    const response = await baseAxiosMethod.post(`staffs/${userId}/class/rejection`, { description })
    return response.data.data
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
}

vendorsAPI.getWebVendorList = async function (key, params = {}) {
  try {
    const response = await baseAxiosMethod(`vendors/website`, { params })
    return response.data
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getWebVendor = async (key, id) => {
  try {
    const response = await baseAxiosMethod(`vendors/${id}/webiste`)
    return response.data.data
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
}

vendorsAPI.getVendorClass = async (_, { UserId }) => {
  try {
    const response = await baseAxiosMethod(`vendors/${UserId}/registration-plan`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};


vendorsAPI.getVendorLastPayment = async (key, vendorId) => {
  try {
    const response = await baseAxiosMethod(`vendors/${vendorId}/last-payment`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getRenewalPaymentProofs = async function (_, params) {
  try {
    const response = await baseAxiosMethod.get(`vendors/paymentproofs`, { params });
    return response.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.approveVendorRenewal = async function (vendorId) {
  try {
    const response = await baseAxiosMethod.patch(`vendors/renewal-payment-proof/${vendorId}/approve`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.toggleVendorStatus = async function (params) {
  try {
    const response = await baseAxiosMethod.post(`vendors/${params.vendorId}/toggle_status`, params);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getVendorRegistrationPlans = async function (_, params) {
  try {
    const response = await baseAxiosMethod.get(`vendors/registrationPlan`, { params });
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getVendorRegistrationPaymentStatus = async function (_, userId) {
  try {
    const response = await baseAxiosMethod.get(`vendors/${userId}/registration-payment`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.getVendorRenewalPaymentStatus = async (_, userId) => {
  try {
    const response = await baseAxiosMethod(`payments/${userId}/renewal-payment`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

vendorsAPI.submitRFQuotation = async ({tenderId, formData}) => {
  try {
    const response = await baseAxiosMethod.post(`vendors/${tenderId}/quotation`, formData);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

export default vendorsAPI;
